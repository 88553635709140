import 'rc-tooltip/assets/bootstrap.css';
import React, { useEffect, useState } from 'react';
import Slider from 'rc-slider';
import './tierslider.scss';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

export default function TierSlider({ tierPlans, setCurrentProgressTier, type }) {
    const [currentPt, setCurrentPt] = useState(null);

    useEffect(() => {
        setCurrentProgressTier(0);
        if (type === 'spaces') {
            setCurrentPt(11);
        } else {
            setCurrentPt(800);
        }
    }, []);

    if (tierPlans) {
        const sliderObj = { min: 0, max: 5, values: { ...tierPlans } };

        const sliderChange = (value) => {
            const maxValueSelected = sliderObj.values[value[1]];
            setCurrentPt(maxValueSelected);
            setCurrentProgressTier(value[1]);
        };

        const toolTipFormat = () => {
            if (currentPt <= 500) {
                return (
                    <div className="nos">
                        {currentPt} <span> Spaces</span>
                    </div>
                );
            } else if (currentPt) {
                return (
                    <div className="nos">
                        &gt;500 <span>Spaces</span>
                    </div>
                );
            }
            return null;
        };
        return (
            <div className="myslider">
                <Range
                    min={sliderObj.min}
                    defaultValue={[0, 0]}
                    max={sliderObj.max}
                    marks={sliderObj.values}
                    onChange={sliderChange}
                    dots={type === 'spaces' ? true : false}
                    tipFormatter={toolTipFormat}
                    dotStyle={
                        type === 'spaces'
                            ? {
                                  width: '0px',
                                  boxSizing: 'border-box',
                                  height: '12px',
                                  opacity: '0.5',
                                  border: '1px solid #FFFFFF',
                                  bottom: '0px'
                              }
                            : {}
                    }
                />
            </div>
        );
    }
    return null;
}
