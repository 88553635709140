import React, { useEffect, useContext, useState } from 'react';
import './stats.scss';
import { Redirect } from 'react-router-dom';
import { Fade } from 'react-reveal';
import NavBar from '../../components/NavBar';
import { getReq, postReq } from '../../utils/api';
import { formatDate, isEmpty, getEnergyRatings, getSessionStorage } from '../../utils';
import StatsCard from '../../components/StatsCard';
import StatsCardHeatmap from '../../components/StataCardHeatMap';
import StatsCardGraph from '../../components/StatsCardGraph';
import StatsPopUpGraph from '../../components/StatsPopUpGraph';
import { SettingsContext, StatsContext } from '../../context';
import { DOMAIN } from '../../constants/base';
import { InView } from 'react-intersection-observer';
import DetailStats from '../StatsDetail/index';
import EmptyState from '../../components/EmptyState';
import { DateRangePicker } from 'react-date-range';

//Assets
import EyeIcon from '../../assets/impression-stat.svg';
import EyeIconViewed from '../../assets/most-viewed-times-stat.svg';
import RetentionIcon from '../../assets/retention-stat.svg';
import CtaActionIcon from '../../assets/cta-action-stat.svg';
import SearchIcon from '../../assets/search.svg';
import StatsUpDownIcon from '../../assets/stats-up-down.png';
import StatsUpIcon from '../../assets/stats-up.png';
import StatsDownIcon from '../../assets/stats-down.png';
import RightArrIcon from '../../assets/right-arr.png';
// import { set } from 'date-fns';
import FallBackImg from '../../assets/skillPlaceholder.png';
// import tr from 'date-fns/esm/locale/tr/index.js';
import BedIcon from '../../assets/bed-icon.svg';
import BathIcon from '../../assets/bath-icon.svg';
import AreaIcon from '../../assets/area-icon.svg';
import StatsCalendar from '../../assets/stats-calendar.png';
import { useTranslation } from 'react-i18next';
import EnergyRatings from '../../assets/energy-ratings.svg';

const Stats = () => {
    const [statsValue, dispatch] = useContext(StatsContext);
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const userDetails = getSessionStorage('ss-user-spaces') || {};
    const [globalSettings] = useContext(SettingsContext);

    const { role } = userDetails;
    const admin = role === 'ADMIN',
        owner = role === 'OWNER';
    const { adminGlobalSettings, memberGlobalSettings } = globalSettings;
    const [noData, setNoData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [spacesData, setSpacesData] = useState([]);
    const [totalDocs, setTotalDocs] = useState(0);
    const [searchString, setSearchString] = useState('');
    const [impressionIncreasing, setImpressionIncreasing] = useState(null);
    const [retentionIncreasing, setRetentionIncreasing] = useState(null);
    const [ctaActionRateIncreasing, setCtaActionRateIncreasing] = useState(null);
    const [cardsSpinner1, setCardsSpinner1] = useState(true);
    const [cardsSpinner2, setCardsSpinner2] = useState(true);
    const [cardsSpinner3, setCardsSpinner3] = useState(true);
    const [cardsSpinner4, setCardsSpinner4] = useState(true);
    const [cardsSpinner5, setCardsSpinner5] = useState(true);
    const [toggleStatsTab, setToggleStatsTab] = useState(false);
    const [validRange, setValidRange] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false);
    const endDateI = new Date(new Date().setHours(0, 0, 0, 0));
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const [range, setRange] = useState({
        startDate: today.getTime() - 7 * 24 * 60 * 60 * 1000,
        endDate: endDateI.getTime() + (24 * 60 * 60 * 1000 - 1000)
    });
    const [rangesInMillis, setRangesInMillis] = useState({
        startDate: today.getTime() - 7 * 24 * 60 * 60 * 1000,
        endDate: endDateI.getTime() + (24 * 60 * 60 * 1000 - 1000)
    });

    const [minimumDate, setMinimumDate] = useState();
    // States
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [topOverallTraffic, setTopOverallTraffic] = useState([]);

    // Details Stats toggle
    const [showDetailStats, setShowDetailStats] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        if (isEmpty(userToken)) {
            setShouldRedirect(true);
        }
    }, []);

    const selectionRange = {
        startDate: range.startDate,
        endDate: range.endDate,
        key: 'selection'
    };
    async function getOverallImpressions() {
        if (rangesInMillis?.startDate > 1) {
            setCardsSpinner1(true);
            const url = `${DOMAIN}/v1/dashboard/space/stats/v3/overall-impressions`;
            const requestHeaders = {
                authorization: userToken.accessToken
            };
            const payload = {
                startMillis: rangesInMillis.startDate,
                endMillis: rangesInMillis.endDate
            };
            const activeSpacesResponse = await postReq({
                url,
                payload,
                requestHeaders
            });
            const { code, data = {} } = activeSpacesResponse;
            if (code === 200) {
                setCardsSpinner1(false);
                dispatch({
                    type: 'SET_OVERALL_IMPRESSION',
                    payload: {
                        value: data.totalViews,
                        graphPlot: data.overview,
                        startDate: data.startMillis,
                        endDate: data.endMillis,
                        accountCreated: data.accountCreated
                    }
                });
                let d = new Date(data.accountCreated);
                d.setTime(d.getTime() - d.getHours() * 3600 * 1000 - d.getMinutes() * 60 * 1000 - d.getSeconds() * 1000 - 1000);
                setMinimumDate(new Date(d));
            }
        }
    }
    async function getOverallRetention() {
        setCardsSpinner2(true);
        const url = `${DOMAIN}/v1/dashboard/space/stats/v3/overall-retention-rate`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const activeSpacesResponse = await postReq({
            url,
            requestHeaders
        });
        const { code, data = {} } = activeSpacesResponse;

        if (code === 200) {
            setCardsSpinner2(false);
            dispatch({
                type: 'SET_OVERALL_RETENTION',
                payload: {
                    value: data.retentionRate === null ? 0 : data.retentionRate,
                    graphPlot: data.overview,
                    startDate: data.startMillis,
                    endDate: data.endMillis,
                    accountCreated: data.accountCreated
                }
            });
        }
    }
    async function getOverallCTAActionRate() {
        if (rangesInMillis?.startDate > 1) {
            setCardsSpinner3(true);
            const url = `${DOMAIN}/v1/dashboard/space/stats/v3/cta-action-rates`;
            const requestHeaders = {
                authorization: userToken.accessToken
            };
            const payload = {
                startMillis: rangesInMillis.startDate,
                endMillis: rangesInMillis.endDate
            };
            const activeSpacesResponse = await postReq({
                url,
                payload,
                requestHeaders
            });
            const { code, data = {} } = activeSpacesResponse;

            if (code === 200) {
                setCardsSpinner3(false);
                dispatch({
                    type: 'SET_OVERALL_CTAACTIONRATE',
                    payload: {
                        value: data.avgConversion,
                        graphPlot: data.overview,
                        startDate: data.startMillis,
                        endDate: data.endMillis,
                        accountCreated: data.accountCreated
                    }
                });
            }
        }
    }

    async function getMostViewedTimes() {
        setCardsSpinner4(true);
        const url = `${DOMAIN}/v1/dashboard/space/stats/v3/most-viewed-times`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const activeSpacesResponse = await getReq({
            url,
            requestHeaders
        });
        const { code, data = {} } = activeSpacesResponse;
        if (code === 200) {
            chunkArray(data);
        }
    }

    async function getTopTrafficSources() {
        if (rangesInMillis?.startDate > 1) {
            setCardsSpinner5(true);
            const url = `${DOMAIN}/v1/dashboard/space/stats/v3/top-traffic-sources`;
            const requestHeaders = {
                authorization: userToken.accessToken
            };
            const payload = {
                startMillis: rangesInMillis.startDate,
                endMillis: rangesInMillis.endDate
            };
            const activeSpacesResponse = await postReq({
                url,
                payload,
                requestHeaders
            });
            const { code, data = {} } = activeSpacesResponse;

            if (code === 200) {
                setCardsSpinner5(false);
                let sortedData = data.trafficRes.sort((s1, s2) => (s1.value > s2.value ? -1 : 1));

                dispatch({
                    type: 'SET_TOP_TRAFFIC_SOURCES',
                    payload: {
                        startDate: data.startMillis,
                        endDate: data.endMillis,
                        graph: sortedData,
                        accountCreated: data.accountCreated
                    }
                });
                if (data.trafficRes.length >= 4) {
                    setTopOverallTraffic(sortedData.slice(0, 3));
                } else {
                    setTopOverallTraffic(sortedData);
                }
            }
        }
    }

    // Spaces Get Req

    // Getting All Spaces
    async function getAllSpacesStats() {
        setLoading(true);
        let url = `${DOMAIN}/v1/dashboard/spaces/page/${page}/v3/stats?searchStr=${searchString}`;
        if (impressionIncreasing === true) {
            url = `${DOMAIN}/v1/dashboard/spaces/page/${page}/v3/stats?impressionsInc=true&searchStr=${searchString}`;
        } else if (impressionIncreasing === false) {
            url = `${DOMAIN}/v1/dashboard/spaces/page/${page}/v3/stats?impressionsDec=true&searchStr=${searchString}`;
        }

        if (retentionIncreasing === true) {
            url = `${DOMAIN}/v1/dashboard/spaces/page/${page}/v3/stats?retentionRateInc=true&searchStr=${searchString}`;
        } else if (retentionIncreasing === false) {
            url = `${DOMAIN}/v1/dashboard/spaces/page/${page}/v3/stats?retentionRateDec=true&searchStr=${searchString}`;
        }

        if (ctaActionRateIncreasing === true) {
            url = `${DOMAIN}/v1/dashboard/spaces/page/${page}/v3/stats?ctaActionRateInc=true&searchStr=${searchString}`;
        } else if (ctaActionRateIncreasing === false) {
            url = `${DOMAIN}/v1/dashboard/spaces/page/${page}/v3/stats?ctaActionRateInc=false&searchStr=${searchString}`;
        }

        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const activeSpacesResponse = await getReq({
            url,
            requestHeaders
        });

        const { code, data = {} } = activeSpacesResponse;

        if (code === 200) {
            setPage(1);
            setHasNextPage(data.hasNextPage);
            setSpacesData(data.docs);
            setTotalDocs(data.totalDocs);
            setLoading(false);
            if (page === 1 && searchString?.length === 0 && data.docs?.length === 0) {
                setNoData(true);
            }
        }
    }

    // Getting All Spaces
    async function filterByImpressions() {
        setLoading(true);
        let url = `${DOMAIN}/v1/dashboard/spaces/page/${page}/v3/stats?impressionsInc=true&searchStr=${searchString}`;
        if (impressionIncreasing === true) {
            url = `${DOMAIN}/v1/dashboard/spaces/page/${page}/v3/stats?impressionsInc=true&searchStr=${searchString}`;
        } else if (impressionIncreasing === false) {
            url = `${DOMAIN}/v1/dashboard/spaces/page/${page}/v3/stats?impressionsDec=true&searchStr=${searchString}`;
        }
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const activeSpacesResponse = await getReq({
            url,
            requestHeaders
        });

        const { code, data = {} } = activeSpacesResponse;

        if (code === 200) {
            setHasNextPage(data.hasNextPage);
            setSpacesData(data.docs);
            setTotalDocs(data.totalDocs);
            setLoading(false);
        }
    }

    // Getting All Spaces
    async function filterByRetention() {
        setLoading(true);
        let url = `${DOMAIN}/v1/dashboard/spaces/page/${page}/v3/stats?retentionRateInc=true&searchStr=${searchString}`;
        if (retentionIncreasing === true) {
            url = `${DOMAIN}/v1/dashboard/spaces/page/${page}/v3/stats?retentionRateInc=true&searchStr=${searchString}`;
        } else if (retentionIncreasing === false) {
            url = `${DOMAIN}/v1/dashboard/spaces/page/${page}/v3/stats?retentionRateDec=true&searchStr=${searchString}`;
        }
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const activeSpacesResponse = await getReq({
            url,
            requestHeaders
        });

        const { code, data = {} } = activeSpacesResponse;
        if (code === 200) {
            setHasNextPage(data.hasNextPage);
            setSpacesData(data.docs);
            setTotalDocs(data.totalDocs);
            setLoading(false);
        }
    }

    // Getting All Spaces
    async function filterByCtaActionRate() {
        setLoading(true);
        let url = `${DOMAIN}/v1/dashboard/spaces/page/${page}/v3/stats?ctaActionRateInc=true&searchStr=${searchString}`;
        if (ctaActionRateIncreasing === true) {
            url = `${DOMAIN}/v1/dashboard/spaces/page/${page}/v3/stats?ctaActionRateInc=true&searchStr=${searchString}`;
        } else if (ctaActionRateIncreasing === false) {
            url = `${DOMAIN}/v1/dashboard/spaces/page/${page}/v3/stats?ctaActionRateDec=true&searchStr=${searchString}`;
        }
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const activeSpacesResponse = await getReq({
            url,
            requestHeaders
        });

        const { code, data = {} } = activeSpacesResponse;

        if (code === 200) {
            setHasNextPage(data.hasNextPage);
            setSpacesData(data.docs);
            setTotalDocs(data.totalDocs);
            setLoading(false);
        }
    }

    // Fetching More Spaces
    async function fetchMoreSpace(nextPage) {
        setLoading(true);
        let url = `${DOMAIN}/v1/dashboard/spaces/page/${nextPage}/v3/stats?searchStr=${searchString}`;
        if (impressionIncreasing === true) {
            url = `${DOMAIN}/v1/dashboard/spaces/page/${nextPage}/v3/stats?impressionsInc=true&searchStr=${searchString}`;
        } else if (impressionIncreasing === false) {
            url = `${DOMAIN}/v1/dashboard/spaces/page/${nextPage}/v3/stats?impressionsDec=true&searchStr=${searchString}`;
        }

        if (retentionIncreasing === true) {
            url = `${DOMAIN}/v1/dashboard/spaces/page/${nextPage}/v3/stats?retentionRateInc=true&searchStr=${searchString}`;
        } else if (retentionIncreasing === false) {
            url = `${DOMAIN}/v1/dashboard/spaces/page/${nextPage}/v3/stats?retentionRateDec=true&searchStr=${searchString}`;
        }

        if (ctaActionRateIncreasing === true) {
            url = `${DOMAIN}/v1/dashboard/spaces/page/${nextPage}/v3/stats?ctaActionRateInc=true&searchStr=${searchString}`;
        } else if (ctaActionRateIncreasing === false) {
            url = `${DOMAIN}/v1/dashboard/spaces/page/${nextPage}/v3/stats?ctaActionRateDec=true&searchStr=${searchString}`;
        }
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const activeSpacesResponse = await getReq({
            url,
            requestHeaders
        });

        const { code, data = {} } = activeSpacesResponse;

        if (code === 200) {
            setHasNextPage(data.hasNextPage);
            setSpacesData([...spacesData, ...data.docs]);
            setLoading(false);
        }
    }

    async function getLastStatsUpdated() {
        setLoading(true);
        const url = `${DOMAIN}/v1/dashboard/account/stats/last-updated`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const activeSpacesResponse = await getReq({
            url,
            requestHeaders
        });
        const { code, data = {} } = activeSpacesResponse;

        if (code === 200) {
            dispatch({
                type: 'SET_LAST_UPDATED',
                payload: {
                    data: data
                }
            });
            setLoading(false);
        }
    }
    useEffect(() => {
        getLastStatsUpdated();
        getOverallImpressions();
        getOverallRetention();
        getOverallCTAActionRate();
        getMostViewedTimes();
        getTopTrafficSources();
        getAllSpacesStats();
    }, []);

    useEffect(() => {
        getAllSpacesStats();
    }, [searchString]);

    useEffect(() => {
        if (impressionIncreasing !== null) {
            filterByImpressions();
        }
    }, [impressionIncreasing]);

    useEffect(() => {
        if (retentionIncreasing !== null) {
            filterByRetention();
        }
    }, [retentionIncreasing]);
    useEffect(() => {
        if (ctaActionRateIncreasing !== null) {
            filterByCtaActionRate();
        }
    }, [ctaActionRateIncreasing]);

    const getDay = (data) => {
        let date = new Date(data);
        return date.getDay();
    };

    const handleDateSelect = (ranges) => {
        let startMillis = new Date(ranges.selection.startDate);
        startMillis = startMillis.getTime();
        let endMillis = new Date(ranges.selection.endDate);
        endMillis.setTime(
            endMillis.getTime() - endMillis.getHours() * 3600 * 1000 - endMillis.getMinutes() * 60 * 1000 - endMillis.getSeconds() * 1000
        );
        endMillis = endMillis.getTime();
        endMillis = endMillis + (24 * 60 * 60 * 1000 - 1000);
        setRangesInMillis({
            startDate: startMillis,
            endDate: endMillis
        });
        setRange(ranges.selection);
    };
    const handleApplyFilter = () => {
        getOverallImpressions();
        getOverallRetention();
        getOverallCTAActionRate();
        getMostViewedTimes();
        getTopTrafficSources();
        setShowCalendar(false);
    };
    // method to split heatmap data arrays
    const chunkArray = (data) => {
        if (data?.keys?.length && data?.data?.length) {
            let sunData = [];
            let monData = [];
            let tuesData = [];
            let wedData = [];
            let thursData = [];
            let friData = [];
            let satData = [];
            for (let i = 0; i < data.keys.length; i++) {
                data.keys[i] = getDay(data.keys[i]);
                switch (data.keys[i]) {
                    case 0:
                        sunData.push(data.data[i]);
                        break;
                    case 1:
                        monData.push(data.data[i]);
                        break;
                    case 2:
                        tuesData.push(data.data[i]);
                        break;
                    case 3:
                        wedData.push(data.data[i]);
                        break;
                    case 4:
                        thursData.push(data.data[i]);
                        break;
                    case 5:
                        friData.push(data.data[i]);
                        break;
                    default:
                        satData.push(data.data[i]);
                }
            }
            setCardsSpinner4(false);
            dispatch({
                type: 'SET_MOST_VIEWED_TIMES',
                payload: {
                    graphData: [sunData, monData, tuesData, wedData, thursData, friData, satData]
                }
            });
        }
    };

    // Pop Up States
    const openPopUp = (graphType, isDetail) => {
        // console.log(isDetail);
        dispatch({
            type: 'SET_SHOW_POPUP',
            payload: {
                currentGraphType: graphType,
                showPopUp: true,
                isDetail: isDetail
            }
        });
        // if (isDetail) {
        //   dispatch({
        //     type: 'SET_SHOW_POPUP',
        //     payload: {
        //       currentGraphType: graphType,
        //       showPopUp: true,
        //       isDetail: true,
        //     },
        //   });
        // } else if(isDetail === undefined) {
        //   dispatch({
        //     type: 'SET_SHOW_POPUP',
        //     payload: {
        //       currentGraphType: graphType,
        //       showPopUp: true,
        //       isDetail: undefined,
        //     },
        //   });
        // }
    };

    const closePopUp = () => {
        dispatch({
            type: 'SET_SHOW_POPUP',
            payload: {
                showPopUp: false
            }
        });
    };

    if (shouldRedirect) {
        return <Redirect to="/signin" />;
    }

    const createSpaceCheck = () => {
        if (owner) return true;
        else if (admin && adminGlobalSettings?.globalAdminCreateSpace) return true;
        else if (!owner && !admin && memberGlobalSettings?.globalMemberCreateSpace) return true;
        else return false;
    };

    return (
        <div className="d-flex main-dash-container">
            <NavBar selected="myStats" />
            {noData ? (
                <EmptyState renderComp={'stats'} isDisabledAddSpace={!createSpaceCheck()} />
            ) : (
                <div className="w-100p mt-20 ml-20 mr-20 content-width">
                    <Fade duration="400">
                        {showDetailStats ? (
                            <DetailStats
                                setShowDetailStats={setShowDetailStats}
                                openPopUp={openPopUp}
                                closePopUp={closePopUp}
                                minimumDate={minimumDate}
                            ></DetailStats>
                        ) : (
                            <>
                                <div className="d-flex f-col space-between mb-5">
                                    <div className="products-heading d-flex f-row space-between center-align fs-18 fw-900 h-60 mb-20">
                                        <h1>{t('stats.title')}</h1>
                                        {toggleStatsTab ? (
                                            ''
                                        ) : (
                                            <button
                                                onClick={() => setShowCalendar(!showCalendar)}
                                                className="stats-popup-calendar-btn mt-5"
                                            >
                                                <img src={StatsCalendar} alt="icon"></img>
                                                {range.startDate &&
                                                    range.endDate &&
                                                    `${formatDate(range.startDate, true)} - ${formatDate(range.endDate, true)}`}
                                            </button>
                                        )}
                                    </div>
                                    {showCalendar && (
                                        <div className="stats-calendar-popup">
                                            <DateRangePicker
                                                maxDate={new Date()}
                                                minDate={minimumDate}
                                                disabledDates={[new Date(minimumDate)]}
                                                ranges={[selectionRange]}
                                                onChange={handleDateSelect}
                                                className="stats-calendar"
                                                rangeColors={['black']}
                                                color="black"
                                            />
                                            <div className="stats-calendar-apply">
                                                <div className="stats-calendar-apply-btn-wrapper">
                                                    <button
                                                        onClick={() => {
                                                            setShowCalendar(false);
                                                            if (validRange) {
                                                                setRange(validRange);
                                                                setValidRange(null);
                                                            }
                                                        }}
                                                        className="stats-calendar-apply-btn"
                                                    >
                                                        {t('close')}
                                                    </button>
                                                    <button onClick={handleApplyFilter} className="stats-calendar-apply-btn-fill">
                                                        {t('apply_filter')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="d-flex publish-heading-container">
                                        <h4
                                            onClick={() => setToggleStatsTab(false)}
                                            style={{
                                                color: !toggleStatsTab ? '#000' : '#0000003D',
                                                fontWeight: !toggleStatsTab ? 800 : 700
                                            }}
                                            className="mr-20"
                                        >
                                            {t('overview')}
                                        </h4>
                                        <h4
                                            onClick={() => {
                                                setToggleStatsTab(true);
                                                setShowCalendar(false);
                                            }}
                                            style={{
                                                color: toggleStatsTab ? '#000' : '#0000003D',
                                                fontWeight: toggleStatsTab ? 800 : 700
                                            }}
                                        >
                                            {t('spaces_title')}
                                            <span className="ml-2" style={{ fontWeight: toggleStatsTab ? 700 : 600 }}>
                                                ({totalDocs})
                                            </span>
                                        </h4>
                                    </div>
                                </div>
                                <div className="stats-container" style={{ paddingTop: !toggleStatsTab ? '30px' : 0 }}>
                                    {!toggleStatsTab ? (
                                        <div className="stats-wrapper">
                                            <div className="stats-wrapper-cards mb-40">
                                                <StatsCard
                                                    cardsSpinner={cardsSpinner1}
                                                    icon={EyeIcon}
                                                    title={t('stats.Impressions')}
                                                    totalValue={statsValue?.overallImpressions?.value}
                                                    subtitle={statsValue?.lastUpdated?.totalImpressions}
                                                    graph={statsValue?.overallImpressions?.graphPlot}
                                                    onClick={() => openPopUp('overall-impressions', undefined)}
                                                    tempCondition={false}
                                                ></StatsCard>
                                                <StatsCard
                                                    cardsSpinner={cardsSpinner2}
                                                    icon={RetentionIcon}
                                                    title={t('stats.retention_rate')}
                                                    totalValue={statsValue?.overallRetention?.value}
                                                    subtitle={statsValue?.lastUpdated?.overAllRetentionRate}
                                                    graph={statsValue?.overallRetention?.graphPlot}
                                                    onClick={() => openPopUp('overall-retention-rate', undefined)}
                                                    tempCondition={true}
                                                ></StatsCard>
                                                <StatsCard
                                                    cardsSpinner={cardsSpinner3}
                                                    icon={CtaActionIcon}
                                                    title={t('stats.cta_action_rate')}
                                                    subtitle={statsValue?.lastUpdated?.overAllCtaActionRate}
                                                    totalValue={statsValue?.overallCTA?.value}
                                                    graph={statsValue?.overallCTA?.overAllCtaActionRate}
                                                    onClick={() => openPopUp('cta-action-rates', undefined)}
                                                    tempCondition={false}
                                                ></StatsCard>
                                                <StatsCardHeatmap
                                                    cardsSpinner={cardsSpinner4}
                                                    graph={statsValue?.mostViewedTimes?.graphData}
                                                    icon={EyeIconViewed}
                                                ></StatsCardHeatmap>
                                                <StatsCardGraph
                                                    cardsSpinner={cardsSpinner5}
                                                    graph={topOverallTraffic}
                                                    handleClick={() => openPopUp('top-traffic-sources', false)}
                                                ></StatsCardGraph>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="stats-product-performance-wrapper">
                                            {/* <div className="header">
                                                <h1>Space Performance</h1>
                                                <h2>Last Updated On {statsValue?.lastUpdated?.ctaActionRate}</h2>
                                            </div> */}
                                            <div className="stats-product-performance-grid-wrapper">
                                                <div className="stats-product-performance-input-wrapper">
                                                    <img src={SearchIcon} alt="" />
                                                    <input
                                                        type="text"
                                                        placeholder={t('spaces.search.placeholder')}
                                                        value={searchString}
                                                        onChange={(e) => setSearchString(e.target.value)}
                                                    />
                                                </div>
                                                {/* <h2>Total Videos</h2> */}
                                                <button
                                                    onClick={() => {
                                                        setPage(1);
                                                        setSpacesData([]);
                                                        if (spacesData.length > 0) {
                                                            setImpressionIncreasing(!impressionIncreasing);
                                                        }
                                                        setRetentionIncreasing(null);
                                                        setCtaActionRateIncreasing(null);
                                                    }}
                                                    className="stats-product-performance-sorting-btn"
                                                >
                                                    {t('stats.Impressions')}
                                                    <img
                                                        src={
                                                            impressionIncreasing === null
                                                                ? StatsUpDownIcon
                                                                : impressionIncreasing === true
                                                                ? StatsUpIcon
                                                                : StatsDownIcon
                                                        }
                                                        alt=""
                                                    />
                                                </button>
                                                <button
                                                    className="stats-product-performance-sorting-btn"
                                                    onClick={() => {
                                                        setPage(1);
                                                        setSpacesData([]);
                                                        if (spacesData.length > 0) {
                                                            setRetentionIncreasing(!retentionIncreasing);
                                                        }
                                                        setImpressionIncreasing(null);
                                                        setCtaActionRateIncreasing(null);
                                                    }}
                                                >
                                                    {t('stats.retention_rate')}
                                                    <img
                                                        src={
                                                            retentionIncreasing === null
                                                                ? StatsUpDownIcon
                                                                : retentionIncreasing === true
                                                                ? StatsUpIcon
                                                                : StatsDownIcon
                                                        }
                                                        alt=""
                                                    />
                                                </button>
                                                <button
                                                    className="stats-product-performance-sorting-btn"
                                                    onClick={() => {
                                                        setPage(1);
                                                        setSpacesData([]);
                                                        if (spacesData.length > 0) {
                                                            setCtaActionRateIncreasing(!ctaActionRateIncreasing);
                                                        }
                                                        setImpressionIncreasing(null);
                                                        setRetentionIncreasing(null);
                                                    }}
                                                >
                                                    {t('stats.cta_action_rate')}
                                                    <img
                                                        src={
                                                            ctaActionRateIncreasing === null
                                                                ? StatsUpDownIcon
                                                                : ctaActionRateIncreasing === true
                                                                ? StatsUpIcon
                                                                : StatsDownIcon
                                                        }
                                                        alt=""
                                                    />
                                                </button>
                                            </div>
                                            <div className="stats-product-performance-products-wrapper h-100p pt-15 ovf-y-scroll-show">
                                                {spacesData &&
                                                    spacesData.length > 0 &&
                                                    spacesData.map(
                                                        (space, index) =>
                                                            space && (
                                                                <div
                                                                    className="stats-product-performance-product-card"
                                                                    key={index}
                                                                    onClick={() => {
                                                                        setShowDetailStats(true);
                                                                        dispatch({
                                                                            type: 'SET_ACTIVE_SPACE',
                                                                            payload: {
                                                                                spaceName: `${space.flatAddress}`,
                                                                                poster: `${space.skills[0].poster}`,
                                                                                spaceId: `${space.id}`,
                                                                                createdDate: `${space.createdAt}`,
                                                                                bedrooms: space.rooms?.bed ? space.rooms?.bed : 0,
                                                                                bathrooms: space.rooms?.bath ? space.rooms?.bath : 0,
                                                                                dimension: space.dimensions?.size
                                                                                    ? space.dimensions?.size
                                                                                    : 0,
                                                                                dimensionUnit: space.dimensions?.size
                                                                                    ? space.dimensions?.unit
                                                                                    : null
                                                                            }
                                                                        });
                                                                    }}
                                                                >
                                                                    <div className="stats-product-performance-product">
                                                                        {space.skills[0].poster.length > 0 ? (
                                                                            <img src={space.skills[0].poster} alt="" />
                                                                        ) : (
                                                                            <img src={FallBackImg} alt="" />
                                                                        )}
                                                                        <div className="stats-product-performance-product-title">
                                                                            <div className="heading-status">
                                                                                <span className="heading-title-pub heading-title">
                                                                                    {space?.status
                                                                                        ? t('spaces.space_status.published')
                                                                                        : t('spaces.space_status.unpublished')}
                                                                                </span>
                                                                            </div>
                                                                            <h1 className="heading">
                                                                                {space?.flatAddress.length > 65
                                                                                    ? space?.flatAddress.substring(0, 65) + '...'
                                                                                    : space?.flatAddress}
                                                                            </h1>
                                                                            <div className="space-details-bed-bath">
                                                                                {space?.rooms?.bed ? (
                                                                                    <div className="space-comp">
                                                                                        <img src={BedIcon} alt="Bed" />
                                                                                        <span className="text">{space.rooms.bed}</span>
                                                                                    </div>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                                {space?.rooms?.bath ? (
                                                                                    <div className="space-comp">
                                                                                        <img src={BathIcon} alt="Bath" />
                                                                                        <span className="text">{space.rooms.bath}</span>
                                                                                    </div>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                                {space?.dimensions?.size ? (
                                                                                    <div className="space-comp">
                                                                                        <img src={AreaIcon} alt="Area" />
                                                                                        <span className="text">
                                                                                            {space.dimensions.size}{' '}
                                                                                        </span>
                                                                                        <span className="text ml-2">
                                                                                            {space.dimensions.measure &&
                                                                                            space.dimensions.measure.toLowerCase() ===
                                                                                                'sqft'
                                                                                                ? 'ft2'
                                                                                                : 'mt2'}
                                                                                        </span>
                                                                                    </div>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                                {space?.energyMeter?.current > 0 ? (
                                                                                    <div className="space-comp">
                                                                                        <img src={EnergyRatings} alt="ratings" />
                                                                                        <span className="text uppercase">
                                                                                            {getEnergyRatings(space.energyMeter.current)} (
                                                                                            {space.energyMeter.current})
                                                                                        </span>
                                                                                    </div>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="total-videos">
                                                                        {space?.skills?.length === 1
                                                                            ? space?.skills?.length + ' Video'
                                                                            : space?.skills?.length + ' Videos'}
                                                                    </div> */}
                                                                    <div className="product-values">{space.impressions}</div>
                                                                    <div className="product-values">{space.retentionRate}%</div>
                                                                    <div className="product-values">{space.ctaActionRate}%</div>
                                                                    <button className="arr-btn">
                                                                        <img src={RightArrIcon} alt="" />
                                                                    </button>
                                                                </div>
                                                            )
                                                    )}
                                                {loading && (
                                                    <div className="product-empty-container">
                                                        <div className="product-empty-main">
                                                            <div className="shimmerBG product-empty-card"></div>
                                                            <div className="product-empty-product-details-wrapper">
                                                                <div className="shimmerBG product-empty-productname"></div>
                                                                <div className="shimmerBG product-empty-producttagline"></div>
                                                            </div>
                                                        </div>
                                                        <div className="shimmerBG product-empty-productval"></div>
                                                        <div className="shimmerBG product-empty-productval"></div>
                                                        <div className="shimmerBG product-empty-productval"></div>
                                                        <div className="shimmerBG product-empty-productval"></div>
                                                    </div>
                                                )}
                                                {/* <div className="stats-product-performance-product-card"></div> */}
                                                <InView
                                                    as="div"
                                                    onChange={(inView) => {
                                                        if (inView && hasNextPage && !loading) {
                                                            setPage(page + 1);
                                                            fetchMoreSpace(page + 1);
                                                        }
                                                    }}
                                                    className="stats-product-performance-product-card"
                                                ></InView>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </Fade>
                </div>
            )}
            {statsValue.showPopUp && <StatsPopUpGraph onBack={closePopUp}></StatsPopUpGraph>}
        </div>
    );
};

export default Stats;
