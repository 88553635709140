import React, { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import ReactDragListView from 'react-drag-listview/lib/index.js';
import { useTranslation } from 'react-i18next';

import VideoUploader from '../VideoUploader';
import LoadingSpin from '../LoadingSpin';

import { DOMAIN } from '../../constants/base';
import { putReq } from '../../utils/api';

import './manageskills.scss';

import UploadIcon from '../../assets/add.svg';

export default function ManageSkills(props) {
    const {
        videoFiles,
        setVideoFiles,
        availableSkillsPoster,
        setAvailableSkillsPoster,
        spaceId,
        nextClickLoader,
        setNextClickLoader,
        setCurrentStep,
        setSpacePoster,
        videoUploaded,
        setVideoUploaded
    } = props;
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};

    const [showVideosUpload, setShowVideosUpload] = useState(false);
    const [skillNameError, setSkillNameError] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (videoFiles.length === 0) {
            setShowVideosUpload(false);
            setSpacePoster(null);
        } else setShowVideosUpload(true);
    }, [videoFiles]);

    const onDrop = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            toast.error('File Limit Size Exceeded (500 MB)', {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
            return;
        }
        for (let i = 0; i < acceptedFiles.length; i++) {
            acceptedFiles[i].id = videoFiles.length + i + 1;
        }

        setVideoFiles(videoFiles.concat(acceptedFiles));

        setShowVideosUpload(true);
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: '.mp4,.mov',
        maxSize: 524288000
    });

    // Drag
    const { DragColumn } = ReactDragListView;
    const dragPropsVideoUploader = {
        onDragEnd(fromIndex, toIndex) {
            const data = [...videoFiles];
            const item = data.splice(fromIndex, 1)[0];
            data.splice(toIndex, 0, item);
            setVideoFiles(data);
        },
        nodeSelector: 'li',
        handleSelector: 'li',
        lineClassName: 'sort-line-video-upload'
    };

    const DeleteFile = (skillId, fileNumber) => {
        const url = `${DOMAIN}/v1/dashboard/space/skill/${skillId}`;
        fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                authorization: userToken.accessToken
            }
        })
            .then((response) => response.json())
            .then((data) => {
                const itemToDelete = videoFiles.find((item) => item.id === fileNumber);
                if (itemToDelete.uploaded) {
                    setVideoUploaded(videoUploaded - 1);
                }
                const newData = videoFiles.filter((item) => item.id !== fileNumber);
                setVideoFiles(newData);
                const newAvailablePoster = availableSkillsPoster.filter((item) => item.id !== skillId);
                setAvailableSkillsPoster(newAvailablePoster);
            });
    };

    const handleSortingVidoes = async () => {
        if (videoFiles.length === 0) {
            setCurrentStep('5');
            return;
        }
        const url = `${DOMAIN}/v1/dashboard/space/skill/${spaceId}/position`;
        let payload = {
            skillIdPosition: videoFiles.map((videoFile, index) => {
                return {
                    skillId: videoFile.skillId,
                    position: index + 1,
                    name: videoFile.rename,
                    aspectRatio: {
                        height: videoFile.height ? videoFile.height : 2,
                        width: videoFile.width ? videoFile.width : 1
                    }
                };
            })
        };
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        setNextClickLoader(true);
        const statusResponse = await putReq({
            url,
            payload,
            requestHeaders
        });
        const { code } = statusResponse;
        if (code === 200) {
            setSpacePoster(availableSkillsPoster[0]?.poster);
            setNextClickLoader(false);
            setCurrentStep('5');
        }
    };

    const handleCheckNameForSkills = () => {
        let nameError = false;
        for (let videoFile of videoFiles) {
            if (videoFile.rename.length === 0) {
                nameError = true;
            }
        }
        if (nameError) {
            setSkillNameError(true);
            toast.error(t('name_error'), {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        } else {
            setSkillNameError(false);
            handleSortingVidoes();
        }
    };
    const AlwaysScrollToBottom = () => {
        const elementRef = useRef();
        useEffect(() =>
            elementRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            })
        );
        return <div ref={elementRef} />;
    };

    return (
        <>
            {isDragActive ? <div className="drag-on"></div> : ''}
            <div className="upload-video-container">
                {showVideosUpload ? (
                    <>
                        <DragColumn {...dragPropsVideoUploader}>
                            <ol className="videos-wrapper">
                                {videoFiles.length > 0 &&
                                    videoFiles.map((file, index) => (
                                        <VideoUploader
                                            file={file}
                                            key={file.id}
                                            fileNumber={file.id}
                                            id={spaceId}
                                            DeleteFile={DeleteFile}
                                            videoUploaded={videoUploaded}
                                            setVideoUploaded={setVideoUploaded}
                                            availableSkillsPoster={availableSkillsPoster}
                                            setAvailableSkillsPoster={setAvailableSkillsPoster}
                                            skillNameError={skillNameError}
                                            setEditFlowUploadCheck={false}
                                            setVideoFiles={setVideoFiles}
                                            videoFiles={videoFiles}
                                        ></VideoUploader>
                                    ))}
                            </ol>
                        </DragColumn>
                        <div className="add-more-card" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <img src={UploadIcon} alt="upload icon"></img>
                            <div className="title">{t('spaces.add_space.add_videos_msg')}</div>
                        </div>
                        <AlwaysScrollToBottom />
                    </>
                ) : (
                    <div className="upload-video-icon-wrapper">
                        <div className="upload-video-icon" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <img src={UploadIcon} alt="upload icon"></img>
                            <div className="title">{t('spaces.add_space.add_videos_msg')}</div>
                        </div>
                        <AlwaysScrollToBottom />
                    </div>
                )}
                {videoFiles.length > 1 ? <div className="drag-title">{t('spaces.add_space.drag_videos')}</div> : ''}
            </div>
            <div className="bottom-wrapper">
                <button
                    disabled={!(videoFiles.length === videoUploaded) ? true : false}
                    onClick={() => {
                        if (nextClickLoader) return;
                        handleCheckNameForSkills();
                        // handleSortingVidoes();
                    }}
                    style={{
                        width: 'fit-content',
                        padding: '16px 32px'
                    }}
                >
                    {nextClickLoader ? <LoadingSpin /> : videoFiles.length > 0 ? t('continue') : t('spaces.add_space.add_videos_later')}
                </button>
            </div>
        </>
    );
}
