import React, { useState, useReducer } from 'react';
import SkillsRouter from './router';
import Amplify from 'aws-amplify';
import 'normalize.css';
import './styles/main.scss';
import { getSessionStorage } from './utils';
import globalProfileReducer from './context/globalProfileReducer';
import settingsReducer from './context/settingsReducer';
import { ToastContainer } from 'react-toastify';
import { SignupContext, ResetContext, EditMemberContext, GlobalProfile, SettingsContext } from './context';
import { S3_VIDEO_BUCKET } from './constants/base';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    const signupContextHook = useState({});
    const resetContextHook = useState({});
    const editMemberContextHook = useState({});
    const [state, dispatch] = useReducer(settingsReducer, {});

    const userDetails = getSessionStorage('ss-user-spaces') || {};

    const { name = '', avatar, id, email, role, phone, countryCode, countryName, teamExists, currentPlan, department } = userDetails;
    const admin = role === 'admin';
    const intialGlobalProfile = {
        globalProfileId: id,
        globalProfileAvatar: avatar,
        globalProfileName: name,
        globalProfileEmail: email,
        globalProfileAdmin: admin,
        globalProfilePhone: {
            phone: phone,
            countryCode: countryCode,
            countryName: countryName
        },
        globalProfilePlan: currentPlan,
        teamExists: teamExists,
        department: department
    };

    const [globalProfileState, globalProfileDispatch] = useReducer(globalProfileReducer, intialGlobalProfile);

    // Amplify.configure(awsconfig);

    Amplify.configure({
        Auth: {
            identityPoolId: 'eu-west-2:cffd2ff3-6365-44a1-99f7-7e79fd07de44', //REQUIRED - Amazon Cognito Identity Pool ID
            region: 'eu-west-2' // REQUIRED - Amazon Cognito Region
        },
        Storage: {
            AWSS3: {
                bucket: S3_VIDEO_BUCKET, //REQUIRED -  Amazon S3 bucket name
                region: 'eu-west-2' //OPTIONAL -  Amazon service region
            }
        }
    });

    return (
        <div className="App">
            <GlobalProfile.Provider value={[globalProfileState, globalProfileDispatch]}>
                <SignupContext.Provider value={signupContextHook}>
                    <ResetContext.Provider value={resetContextHook}>
                        <EditMemberContext.Provider value={editMemberContextHook}>
                            <SettingsContext.Provider value={[state, dispatch]}>
                                <SkillsRouter />
                            </SettingsContext.Provider>
                        </EditMemberContext.Provider>
                    </ResetContext.Provider>
                </SignupContext.Provider>
            </GlobalProfile.Provider>
            <ToastContainer />
        </div>
    );
}

export default App;
