import React, { useEffect, useState, useContext } from 'react';
import ClickAwayContentWrapper from '../../components/ClickAwayContentWrapper';
import Pulse from 'react-reveal/Pulse';
import { DOMAIN } from '../../constants/base';
import { getSessionStorage } from '../../utils';
import { getReq } from '../../utils/api';
import PlanUsageEstimate from '../PlanUsageEstimate';
import { GlobalProfile } from '../../context';
import LoadingSpin from '../../components/LoadingSpin';
import { Redirect } from 'react-router-dom';
import { removeSessionStorage } from '../../utils';
import { handleSubscription } from '../../utils/stripe-handler';

import './popupplantype.scss';
import '../NavBar/navBar.scss';
// Assets
import BackImg from '../../assets/back.png';
import TierStar from '../../assets/blue-start.svg';
import TierSep from '../../assets/comp-separator.svg';
import ProgressEnd from '../../assets/progress-end.svg';
import { useTranslation } from 'react-i18next';

const PopUpPlanType = ({ managePlanDetailsCheck, planTypeObj = {}, showLoader = false, setShowPlansPopUp = () => {} }) => {
    let currentProgress;
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const userDetails = getSessionStorage('ss-user-spaces') || {};
    const [globalProfile, dispatchProfile] = useContext(GlobalProfile);
    const [isFreeUser, setIsFreeUser] = useState(planTypeObj.currentTier === 'trial plan' ? true : false);
    const [subscriptionEndData, setSubscriptionEndData] = useState(undefined);
    const { t: planType } = useTranslation();
    if (!planTypeObj.enterprisePlan) {
        currentProgress =
            (716 * (planTypeObj.publishedSpaces - planTypeObj.range.starts)) / (planTypeObj.range.ends - planTypeObj.range.starts);
    } else {
        currentProgress = 716;
    }
    const [isLoading, setIsLoading] = useState(showLoader);
    const [isSignOut, setSignOut] = useState(false);
    const [shouldRedirect, setRedirect] = useState(false);

    useEffect(() => {
        document.querySelector('body').classList.add('hide-body-scroll');
        setIsFreeUser(planTypeObj.currentTier === 'trial plan' ? true : false);
    }, []);

    useEffect(() => {
        if (globalProfile?.usageDetails?.trialUp) {
            getUsageDetails();
        }
    }, []);

    const signoutHandler = () => {
        document.body.classList.add('ovf-hidden');
        setSignOut(true);
    };
    const noHandler = () => {
        document.body.classList.remove('ovf-hidden');
        setSignOut(false);
    };
    const proceedHandler = () => {
        document.body.classList.remove('ovf-hidden');
        localStorage.removeItem('ss-auth-spaces');
        removeSessionStorage('ss-user-spaces');
        localStorage.setItem('ss-lang', 'en');
        setRedirect(true);
        dispatchProfile({
            type: 'EDIT_GLOBAL_PROFILE',
            payload: {
                id: null,
                avatar: null,
                name: null,
                email: null,
                role: null,
                phone: {
                    phone: null,
                    countryCode: null,
                    countryName: null
                },
                teamExists: false,
                plan: null,
                department: null
            }
        });
        try {
            window.Intercom('shutdown');
        } catch (e) {
            console.log(e);
        }
    };

    if (shouldRedirect) {
        return <Redirect to="/signin" />;
    }

    async function getUsageDetails() {
        setIsLoading(true);
        const url = `${DOMAIN}/v1/dashboard/space/plans/subscription-end`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const currentUsage = await getReq({
            url,
            requestHeaders
        });
        const { code, data = {} } = currentUsage;

        if (code === 200) {
            setSubscriptionEndData(data);
            setIsLoading(false);
        }
    }

    // //open intercom
    // const popIntercom = () => {
    //     document.getElementsByClassName('intercom-launcher')[0].click();
    // };

    if (globalProfile?.usageDetails?.trialUp) {
        return (
            <div className="popups-wrapper">
                <>
                    <div className="plan-wrapper usage-estimate-wrapper">
                        <div className="plan-wrapper__header"></div>
                        {isLoading ? (
                            <div className="show-loader">
                                <LoadingSpin />
                            </div>
                        ) : (
                            subscriptionEndData && (
                                <PlanUsageEstimate
                                    dataUsed={subscriptionEndData}
                                    freeUser={isFreeUser}
                                    handleSubscription={handleSubscription}
                                    signoutHandler={signoutHandler}
                                    domain={DOMAIN}
                                    userToken={userToken}
                                    userDetails={userDetails}
                                ></PlanUsageEstimate>
                            )
                        )}
                    </div>
                    {isSignOut && (
                        <div className="d-flex center-align pos-fix bg-black-50 top-0 left-0 zi-100 w-100p h-100p">
                            <div className="bg-white p-20 br-10 mxw-300">
                                <div className="fs-18 fw-b mb-40">{planType('signout.message')}</div>
                                <div className="d-flex space-between fs-12">
                                    <div className="fs-12 fw-b uc c-blue p-10 cur-pointer" onClick={noHandler}>
                                        {planType('signout.back_msg')}
                                    </div>
                                    <div className="fs-12 fw-b uc bg-black c-white br-20 p-10-20 cur-pointer" onClick={proceedHandler}>
                                        {planType('signout.title')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            </div>
        );
    } else {
        return (
            <div className="popups-wrapper">
                <ClickAwayContentWrapper
                    onClose={() => {
                        document.querySelector('body').classList.remove('hide-body-scroll');
                        setShowPlansPopUp(false);
                    }}
                >
                    <Pulse duration={150}>
                        <div className="plan-wrapper">
                            <div className="plan-wrapper__header">
                                <button
                                    className="back-btn"
                                    onClick={() => {
                                        document.querySelector('body').classList.remove('hide-body-scroll');
                                        setShowPlansPopUp(false);
                                    }}
                                >
                                    <img src={BackImg} alt="" />
                                </button>
                                <div className="title">{planType('topbar.usage.title')}</div>
                            </div>
                            {showLoader ? (
                                <div className="show-loader">
                                    <LoadingSpin />
                                </div>
                            ) : (
                                <div className="plan-wrapper__content">
                                    <h2>
                                        {planType('topbar.usage.pub_space')} ({' ' + planTypeObj.publishedSpaces} /{' '}
                                        {planTypeObj.range.ends + ' '})
                                    </h2>
                                    <div className="progress-bar__wrapper">
                                        <div
                                            className={currentProgress >= 710 ? 'current-progress-bar br-100' : 'current-progress-bar'}
                                            style={{ width: `${currentProgress}px` }}
                                        >
                                            {planTypeObj?.publishedSpaces >= 1 ? (
                                                <div className="progress-end">
                                                    <div className="numbers">{planTypeObj?.publishedSpaces.toLocaleString()}</div>
                                                    <div className="img-wrap">
                                                        <img src={ProgressEnd} alt="" />
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                    <div className="progress-bar__endpts">
                                        <span className="pt">{planTypeObj?.range?.starts.toLocaleString()}</span>
                                        <span className="pt">{planTypeObj?.range?.ends.toLocaleString()}</span>
                                    </div>

                                    <div className="tier-container">
                                        <div className="tier-details">
                                            <div className="tier-name">
                                                <h2>{planType('topbar.usage.curr_plan')}</h2>
                                                <h1>
                                                    <img src={TierStar} alt="" />
                                                    {userDetails.currentPlan}
                                                </h1>
                                            </div>
                                            <div className="tier-pricing">
                                                <h2>{planType('topbar.usage.to_pay')}</h2>
                                                <div className="price">
                                                    {!isFreeUser ? (
                                                        <>
                                                            <span className="currency-type">$</span>{' '}
                                                            {planTypeObj?.priceToPay.toLocaleString()}
                                                        </>
                                                    ) : (
                                                        <span>
                                                            {planTypeObj?.priceToPay?.charAt(0).toUpperCase() +
                                                                planTypeObj?.priceToPay?.slice(1).toLowerCase()}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="tier-end-details">
                                                    {planType('topbar.usage.plan_renew')} {planTypeObj?.planEnds} <br /> (
                                                    {planTypeObj?.daysLeft}
                                                    {planType('topbar.usage.days_left')} )
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tier-sep">
                                            <img src={TierSep} alt="" />
                                        </div>
                                        <div className="tier-status payment-details">
                                            {planTypeObj?.paymentInfo ? (
                                                <>
                                                    <h1>{planType('topbar.usage.payment')}</h1>
                                                    <div className="payment-card">
                                                        <div className="payment-card__brand">{planTypeObj?.paymentInfo.brand}</div>
                                                        <div className="payment-card__num">
                                                            .... .... .... <span>{planTypeObj?.paymentInfo.last4}</span>
                                                        </div>
                                                    </div>
                                                    <div className="btn-wrapper">
                                                        <form
                                                            method="POST"
                                                            action={`${DOMAIN}/v1/dashboard/stripe/customer-portal`}
                                                            style={{ width: '100%', height: '100%' }}
                                                        >
                                                            <input
                                                                name="accessToken"
                                                                id="accessToken"
                                                                value={userToken.accessToken}
                                                                hidden
                                                            />
                                                            <input name="entity" value="space" hidden />
                                                            <button disabled={!managePlanDetailsCheck()} className="btn">
                                                                {planType('topbar.usage.manage_sub')}
                                                            </button>
                                                        </form>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="subscribe-btn__wrapper">
                                                    <button
                                                        className="subscribe-btn"
                                                        onClick={() => handleSubscription(DOMAIN, userToken, userDetails)}
                                                    >
                                                        {planType('topbar.usage.start_sub')}
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Pulse>
                </ClickAwayContentWrapper>
            </div>
        );
    }
};

export default PopUpPlanType;
