// library
import React from 'react';
import { useTranslation } from 'react-i18next';

// components

// Assets
import BackImg from '../../assets/back.png';
import './editspacedetailsheader.scss';
export default function EditSpaceDetailsHeader(props) {
    const {
        currentStep,
        isNewTemplate,
        setCurrentStep,
        setSpaceUpdated,
        setShowSpaceDetailsEditPopUp,
        cardSkills,
        setIsNewTemplate,
        setSelectedSkill,
        handleCheckNameForSkills = () => {},
        isUpdated,
        prevTemplate,
        setSelectedTemplate,
        selectedTemplate
    } = props;

    const { t: editspace } = useTranslation();

    const handleBackBtn = () => {
        if (currentStep === '1') {
            setShowSpaceDetailsEditPopUp(false);
            document.body.classList.remove('body-ovef-hdn');
        } else if (currentStep === '2') {
            setShowSpaceDetailsEditPopUp(false);
        } else if (currentStep === '3') {
            setCurrentStep('2');
            setSpaceUpdated(false);
        } else if (currentStep === '4') {
            setSelectedSkill([]);
            handleCheckNameForSkills(true);
            setCurrentStep('3');
        } else if (currentStep === '5') {
            setCurrentStep('4');
        } else if (currentStep === '6') {
            if (isNewTemplate) {
                setIsNewTemplate(false);
                if (selectedTemplate?.templateName !== 'Agent’s default contact') setSelectedTemplate(prevTemplate);
                else if (selectedTemplate?.templateName === 'Agent’s default contact') {
                    setSelectedTemplate({
                        templateName: 'Agent’s default contact',
                        templateId: null,
                        ctas: null
                    });
                }
            }
            setCurrentStep('3');
        }
    };

    return (
        <>
            <div className="top-header">
                <button
                    className="back-button"
                    onClick={() => {
                        handleBackBtn();
                    }}
                >
                    <img src={BackImg} alt="back img"></img>
                </button>
                <div className="step-indicator">
                    {currentStep === '1' ? (
                        <>
                            <div className={currentStep === '1' ? 'circle c-white active' : 'circle c-white'}></div>
                            <div className={currentStep === '2' ? 'circle c-white active' : 'circle c-white'}></div>
                            <div className={currentStep === '3' ? 'circle c-white active' : 'circle c-white'}></div>
                            <div className={currentStep === '4' ? 'circle c-white active' : 'circle c-white'}></div>
                            <div className={currentStep === '5' ? 'circle c-white active' : 'circle c-white'}></div>
                        </>
                    ) : currentStep === '6' ? (
                        ''
                    ) : (
                        <>
                            <div className={currentStep === '1' ? 'circle active' : 'circle'}></div>
                            <div className={currentStep === '2' ? 'circle active' : 'circle'}></div>
                            <div className={currentStep === '3' ? 'circle active' : 'circle'}></div>
                            <div className={currentStep === '4' ? 'circle active' : 'circle'}></div>
                            <div className={currentStep === '5' ? 'circle active' : 'circle'}></div>
                        </>
                    )}
                </div>
            </div>

            <div className={currentStep === '1' ? 'current-step-title ft-white' : 'current-step-title'}>
                {currentStep === '4' && (
                    <div className="total-videos-title">
                        {cardSkills?.length} {cardSkills?.length === 1 ? editspace('video') : editspace('videos')}
                    </div>
                )}
                {currentStep === '1' && editspace('spaces.add_space.address')}
                {currentStep === '2' && editspace('space_details.title')}
                {currentStep === '3' && editspace('agent.manage_title')}
                {currentStep === '4' && editspace('spaces.add_space.add_videos')}
                {currentStep === '6' &&
                    (isNewTemplate
                        ? editspace('cta.new_title')
                        : selectedTemplate?.templateId
                        ? editspace('cta.edit_title')
                        : 'Agent Default Contacts')}
                {currentStep === '5' && editspace('spaces.publish.title')}
            </div>
        </>
    );
}
