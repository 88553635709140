import React, { useEffect, useState } from 'react';
import './videostatscard.scss';

// Assets

import EyeIcon from '../../assets/eye-icon.png';
import RightArrIcon from '../../assets/right-arr.png';
import SortIncreasingIcon from '../../assets/sort-increase.svg';
import SortDecreasingIcon from '../../assets/sort-decreasing.svg';
import skillPosterPlaceholder from '../../assets/no-skill.svg';
import { useTranslation } from 'react-i18next';

export default function VideoStatsCard({ skills, setSkills, showCompleteStats, skillsLoading }) {
    const [sortIncrease, setSortIncrease] = useState(true);
    const [sortIcon, setSortIcon] = useState(SortIncreasingIcon);
    const { t } = useTranslation();
    useEffect(() => {
        let sortedSkills;
        if (sortIncrease) {
            setSortIcon(SortIncreasingIcon);
            sortedSkills = skills.sort((l1, l2) => l1.retentionRate - l2.retentionRate);
        } else {
            setSortIcon(SortDecreasingIcon);
            sortedSkills = skills.sort((l1, l2) => l2.retentionRate - l1.retentionRate);
        }
        setSkills(sortedSkills);
    }, [sortIncrease]);

    return (
        <div className="video-per-list-wrapper">
            <div className="videos-sort__header">
                <span>{t('videos_capi')}</span>
                <div
                    className="sort-criteria"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        setSortIncrease(!sortIncrease);
                    }}
                >
                    {t('rention_rates')}
                    <img src={sortIcon} alt="icon" />
                </div>
            </div>
            <ul className="pr-video-list">
                {skillsLoading ? (
                    <li className="pr-video">
                        <div className="skills-loading">
                            <div className="skills-header">
                                <div className="shimmerBG skills-loading-circle"></div>
                                <div className="skills-loading-rect-wrapper">
                                    <div className="shimmerBG skills-loading-rect-one"></div>
                                    <div className="shimmerBG skills-loading-rect-two"></div>
                                </div>
                            </div>
                            <div className="shimmerBG skills-loading-rect-three"></div>
                        </div>
                    </li>
                ) : skills.length === 0 ? (
                    <div className="no-skills">
                        <div className="skill">
                            <img src={skillPosterPlaceholder} alt="skill poster" />
                        </div>

                        <div className="text-content">
                            <h2>No video yet</h2>
                            <p>Start uploading content and unlock video analytics </p>
                        </div>
                    </div>
                ) : (
                    skills.map((skill) => {
                        return (
                            <li
                                className="pr-video"
                                key={skill.skillId}
                                onClick={() => {
                                    showCompleteStats(skill.skillId, skill.poster);
                                }}
                            >
                                <div className="pr-video__banner">
                                    <img src={skill.poster} alt="icon" />
                                </div>
                                <div className="pr-video__details">
                                    {skill.name ? <div className="pr-video__name">{skill.name}</div> : ''}
                                    <div className="pr-video__views">
                                        <img src={EyeIcon} alt="icon" /> {skill.viewCount}
                                    </div>
                                </div>
                                <div className="pr-video__sort-value">
                                    {skill.retentionRate} <span className="perc">%</span>
                                </div>
                                <div className="pr-video__more">
                                    <img src={RightArrIcon} alt="icon" />
                                </div>
                            </li>
                        );
                    })
                )}
            </ul>
        </div>
    );
}
