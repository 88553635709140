import React from 'react';
import HolofySpacesLogo from '../../assets/holofy-black.png';
import HolofySpacesLogoWhite from '../../assets/holofy-white.png';
import './product-logo.scss';

const ProductAtom = (props) => {
    const { isWhite, customClass } = props;
    return (
        <div className={customClass ? customClass : 'product-logo'}>
            <img src={isWhite ? HolofySpacesLogoWhite : HolofySpacesLogo} alt="Holofy Spaces" />
        </div>
    );
};

export default ProductAtom;
