import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import './select.scss';
import DownArrIcon from '../../assets/caret.svg';
import SearchIcon from '../../assets/search.svg';
import RightMarkIcon from '../../assets/check.svg';
import LinkIcon from '../../assets/link-black.png';
import { getSessionStorage } from '../../utils';
import { useTranslation } from 'react-i18next';

const Select = ({
    defaultOption,
    optionsList,
    spaceType,
    setSpaceType = null,
    name = null,
    avatar = null,
    type = null,
    agentAssigned,
    setAssigneId,
    setAssigneChanged,
    setAgentAssigned,
    canChangeAssigne,
    member,
    loggedInUserId,
    addSpace,
    setSpaceUpdated,
    setIsNewTemplate,
    setCurrentScreen,
    editFlow,
    setSelectedTemplate,
    setTemplateChanged,
    selectedTemplate,
    rentalPriceType,
    setRentalPriceType,
    setRentalPriceToShow,
    allowDropDown
}) => {
    const { t: select } = useTranslation();
    const [defaultSelect, setDefaultOption] = useState('');
    const [selectOptionsList, setOptionsList] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [optionType, setOptionType] = useState('');
    const [assigneValue, setAssignevalue] = useState('');
    const userDetails = getSessionStorage('ss-user-spaces') || {};
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        setDefaultOption(defaultOption);
        setOptionsList(optionsList);
        setOptionType(type);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            // setAssigneChanged(false);
        };
    }, []);

    useEffect(() => {
        if (avatar) {
            setDefaultOption({ avatar: avatar, name: name });
        }
        if (agentAssigned) {
            setDefaultOption({
                avatar: agentAssigned.avatar,
                name: agentAssigned.name
            });
        }
    }, []);

    const handleClickOutside = (e) => {
        if (e.target.classList.contains('assign-self')) {
            return;
        }
        if (
            !e.target.classList.contains('custom-select-option') &&
            !e.target.classList.contains('selected-text') &&
            !e.target.classList.contains('assigne-search-value') &&
            !e.target.classList.contains('assigne__name') &&
            !e.target.classList.contains('cancel')
        ) {
            setShowOptions(false);
        }
    };

    const handleShowDisplay = () => {
        setShowOptions((prev) => !prev);
    };

    const handleOptionClick = (e, option = null) => {
        let target = e.target;
        if (e.target.className === 'assigne__name') {
            target = e.target.parentElement;
        }
        if (optionType === 'assigne') {
            setDefaultOption({
                avatar: target.firstElementChild.firstElementChild.src,
                name: target.innerText
            });
            setAssigneId(e.currentTarget.dataset.id);
            setAssigneChanged(true);
            setSelectedTemplate({
                templateName: 'Agent’s default contact',
                templateId: null,
                ctas: null
            });
            setAgentAssigned({
                name: target.innerText,
                avatar: target.firstElementChild.firstElementChild.src
            });
        } else if (optionType === 'template') {
            if (!option.templateId) {
                setSelectedTemplate({
                    templateName: 'Agent’s default contact',
                    templateId: null,
                    ctas: null
                });
            } else {
                setSelectedTemplate({
                    templateId: e.currentTarget.dataset.id,
                    templateName: option.templateName,
                    ctas: option.ctas
                });
            }
            setTemplateChanged(true);
            // setDefaultSelectForTemplateAgent(null);
            setDefaultOption(option.templateName);
        } else {
            setSpaceUpdated(true);
            setDefaultOption(option.display);
            if (setSpaceType) {
                setSpaceType(option.key);
            } else if (setRentalPriceType) {
                setRentalPriceType && setRentalPriceType(option.key);
                setRentalPriceToShow && setRentalPriceToShow(option.key);
            }
        }
        setShowOptions(false);
    };

    const handleAssigneSearchValueChange = (e) => {
        setAssignevalue(e.target.value);
        setOptionsList(
            optionsList.filter((agent) => {
                if (agent.name.toLowerCase().includes(e.target.value)) {
                    return true;
                }
                return false;
            })
        );
    };
    return (
        <div className={optionType === 'assigne' ? 'custom-select-container assignee' : 'custom-select-container'}>
            <div
                className={showOptions ? 'selected-text active' : 'selected-text'}
                style={{
                    padding: defaultSelect?.avatar ? '15px 16px' : optionType !== ('assigne' || 'template') ? '15px 16px' : '9px 10px',
                    textTransform: optionType !== 'assigne' ? (optionType !== 'template' ? 'uppercase' : '') : ''
                }}
                onClick={() =>
                    member && canChangeAssigne && allowDropDown ? handleShowDisplay() : member ? '' : allowDropDown && handleShowDisplay()
                }
            >
                {optionType === 'assigne' ? (
                    defaultSelect.avatar ? (
                        <>
                            <div className="avatar-div">
                                <img
                                    src={defaultSelect.avatar}
                                    style={{ objectFit: 'cover' }}
                                    ref={(el) => {
                                        if (el) {
                                            el.style.setProperty('top', '26px', 'important');
                                        }
                                    }}
                                    className="avatar-img"
                                    alt="icon"
                                ></img>
                                <span className="assigne__name">{defaultSelect.name}</span>
                            </div>
                        </>
                    ) : (
                        <>
                            <Avatar
                                name={defaultSelect.name}
                                value=""
                                size="24"
                                round={true}
                                color="black"
                                maxInitials={1}
                                textSizeRatio={2}
                                style={{ fontWeight: '700' }}
                            ></Avatar>
                            <span className="assigne__name">{defaultSelect.name}</span>
                        </>
                    )
                ) : optionType === 'template' ? (
                    selectedTemplate?.templateName
                ) : (
                    defaultSelect
                )}
                {showOptions ? (
                    optionType === 'assigne' ? (
                        <span className="cancel-dropdown cancel">{select('cancel')}</span>
                    ) : (
                        ''
                    )
                ) : optionType === 'assigne' ? (
                    member && !canChangeAssigne ? (
                        ''
                    ) : (
                        canChangeAssigne && <span className="cancel-dropdown">{select('change')}</span>
                    )
                ) : (
                    allowDropDown && (
                        <img src={DownArrIcon} alt="arrow-down" className={optionType === 'template' ? 'template-arrow' : ''}></img>
                    )
                )}
            </div>

            {showOptions && (
                <ul className="select-options">
                    {/* Render agents */}
                    {optionType === 'assigne' && (
                        <>
                            <div
                                className="assign-self"
                                onClick={() => {
                                    setShowOptions(false);
                                    setDefaultOption({
                                        avatar: userDetails.avatar,
                                        name: userDetails.name
                                    });
                                    setAssigneId(userDetails.id);
                                    setAssigneChanged(true);
                                    setAgentAssigned({
                                        name: userDetails.name,
                                        avatar: userDetails.avatar
                                    });
                                    setSelectedTemplate({
                                        templateName: 'Agent’s default contact',
                                        templateId: null,
                                        ctas: null
                                    });
                                }}
                            >
                                {select('spaces.add_space.assign')}
                            </div>
                            <div
                                className="search-assigne"
                                onClick={() => {
                                    console.log('search clicked');
                                }}
                            >
                                <div className="search-container">
                                    <img src={SearchIcon} alt="search icon" className="search-icon"></img>
                                    <input
                                        type="text"
                                        className="assigne-search-value"
                                        autoFocus
                                        placeholder={select('spaces.managed.input_placeholder')}
                                        value={assigneValue}
                                        onChange={handleAssigneSearchValueChange}
                                    ></input>
                                </div>
                            </div>

                            {selectOptionsList.map((option) => {
                                if (option.agentId === loggedInUserId) return '';
                                else
                                    return (
                                        <li
                                            className="custom-select-option"
                                            key={option.agentId}
                                            data-id={option.agentId}
                                            onClick={handleOptionClick}
                                        >
                                            {option.avatar ? (
                                                <div className="avatar-wrapper">
                                                    <img
                                                        src={option.avatar}
                                                        style={{ objectFit: 'cover' }}
                                                        ref={(el) => {
                                                            if (el) {
                                                                el.style.setProperty('top', '8px', 'important');
                                                            }
                                                        }}
                                                        className="avatar-img"
                                                        alt="icon"
                                                    ></img>
                                                </div>
                                            ) : (
                                                <Avatar
                                                    name={option.name}
                                                    value=""
                                                    size="24"
                                                    round={true}
                                                    color="black"
                                                    maxInitials={1}
                                                    textSizeRatio={2}
                                                    style={{ fontWeight: '700' }}
                                                ></Avatar>
                                            )}

                                            <span className="assigne__name">{option.name}</span>
                                            <div class="checkmark">
                                                <img src={RightMarkIcon} alt="icon" />
                                            </div>
                                        </li>
                                    );
                            })}
                        </>
                    )}

                    {/* Render agent templates */}
                    {optionType === 'template' && (
                        <>
                            {selectOptionsList.map((template) => {
                                return (
                                    <li
                                        className="custom-select-option"
                                        style={{
                                            position: 'relative'
                                        }}
                                        key={template.templateId}
                                        data-id={template.templateId}
                                        onClick={(e) => handleOptionClick(e, template)}
                                    >
                                        <img src={LinkIcon} alt="link" className="link-icon" />
                                        <span className="assigne__name">{template.templateName}</span>
                                        <div
                                            className="checkmark"
                                            style={{
                                                top: '15px'
                                            }}
                                        >
                                            <img src={RightMarkIcon} alt="icon" />
                                        </div>
                                    </li>
                                );
                            })}

                            <div
                                className="custom-select-option add-template"
                                onClick={() => {
                                    setIsNewTemplate(true);
                                    setSelectedTemplate({
                                        templateName: '',
                                        templateId: null,
                                        ctas: null
                                    });
                                    if (editFlow) setCurrentScreen('5');
                                    else setCurrentScreen('6');
                                }}
                            >
                                NEW TEMPLATE
                            </div>
                        </>
                    )}
                    {/* Render normal options */}
                    {!(optionType === 'assigne') &&
                        optionType !== 'template' &&
                        selectOptionsList.map((option, idx) => {
                            return (
                                <li className="custom-select-option" onClick={(e) => handleOptionClick(e, option)} key={idx}>
                                    {option.display}
                                </li>
                            );
                        })}
                </ul>
            )}
        </div>
    );
};

export default Select;
