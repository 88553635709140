import React from 'react';
import './planusageestimate.scss';
import PlanUsage from '../PlanUsage';

// Assets
import EyeIcon from '../../assets/impression-stat.svg';
import RetentionIcon from '../../assets/retention-stat.svg';
import ProductIcon from '../../assets/Products-phone.svg';
import DaysIcon from '../../assets/days.svg';
import HourGlassIcon from '../../assets/hour-glass.svg';
import { useTranslation } from 'react-i18next';

export default function PlanUsageEstimate({ dataUsed, freeUser, handleSubscription, signoutHandler, domain, userToken, userDetails }) {
    const { t: usage } = useTranslation();
    return (
        <div className="plan-usage-estimate-wrapper">
            <div className="sign-out-btn">
                <button onClick={signoutHandler}>{usage('signout.title')}</button>
            </div>
            <div className="hour-glass__wrapper">
                <img src={HourGlassIcon} alt="" />
            </div>
            <div className="tier-status">
                <div className="tier-status__title">{freeUser ? usage('usage.trial_up') : usage('usage.subscription_pause')}</div>
                <div className={freeUser ? 'tier-status__msg' : 'tier-status__msg red-text'}>
                    {freeUser ? usage('usage.free_user_msg') : usage('usage.user_msg')}
                </div>
            </div>
            <div className="usage-container">
                <PlanUsage icon={DaysIcon} value={dataUsed.daysUsed} resourceType={usage('usage.days')}></PlanUsage>
                <PlanUsage icon={ProductIcon} value={dataUsed.publishedSpaces} resourceType={usage('usage.spaces_added')}></PlanUsage>
                <PlanUsage icon={EyeIcon} value={dataUsed.viewCount} resourceType={usage('usage.remote')}></PlanUsage>
                <PlanUsage icon={RetentionIcon} value={dataUsed.engagementRate} resourceType={usage('usage.eng_rate')}></PlanUsage>
            </div>
            <div className="estimate-container">
                {freeUser ? (
                    <div className="msg">{usage('usage.estimate_free_user')}</div>
                ) : (
                    <div className="msg">{usage('usage.estimate_paid_user')}</div>
                )}

                <div className="pricing-container">
                    <div className="amount-container">
                        <span span className="currency-icon">
                            $
                        </span>{' '}
                        <span className="amount">{dataUsed.priceToPay.toLocaleString()}</span>
                    </div>
                    <div className="amount-period">{usage('usage.pay_per')}</div>
                </div>
            </div>

            <div className="subscribe-wrapper">
                <div className="subscribe-msg">
                    {usage('usage.continue_service')} {freeUser ? usage('subscribe') : usage('usage.re-subscribe')} 👇
                </div>
                <div className="subscribe-btn-wrapper">
                    <button
                        className="btn"
                        onClick={() => {
                            handleSubscription(domain, userToken, userDetails);
                        }}
                    >
                        {freeUser ? usage('usage.subsc') : usage('usage.restart_sub')}
                    </button>
                </div>
            </div>
        </div>
    );
}
