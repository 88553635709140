import React, { useState, useEffect, useContext } from 'react';
import './topbar.scss';
import { DOMAIN } from '../../constants/base';
import { useLocation } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

// Modules
import { getSessionStorage, isEmpty, removeSessionStorage, setSessionStorage } from '../../utils';
import { getReq } from '../../utils/api';
import Avatar from 'react-avatar';
import { currentSpaceContext, GlobalProfile, SettingsContext } from '../../context';
import ProgressBar from '../ProgressBar';
import PopUpPlanType from '../PopUpPlanTypes';
import { handleSubscription } from '../../utils/stripe-handler';

// Assets
import HolofyProductLogo from '../../assets/holofy-black.png';
import { useTranslation } from 'react-i18next';

import UserDetailPopUp from '../UserDetailPopUp';

function useForceUpdate() {
    const [, setValue] = useState(0);
    return () => setValue((value) => value + 1);
}

const TopBar = ({ setUpdateSpaces }) => {
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    let userDetails = getSessionStorage('ss-user-spaces') || {};
    const { t: topbar, i18n: translate } = useTranslation();
    const { name = '', avatar, id, email, phone, role, countryName, countryCode } = userDetails;
    const admin = role === 'ADMIN',
        owner = role === 'OWNER';
    const [globalProfile, dispatch] = useContext(GlobalProfile);
    const [, dispatchS] = useContext(currentSpaceContext);

    const [globalSettings, dispatchSettings] = useContext(SettingsContext);
    const [shouldRedirect, setRedirect] = useState(false);
    // PopUps
    const [showPlansPopUp, setShowPlansPopUp] = useState(false);
    const [showEditPopUp, setShowEditPopUp] = useState(false);

    // states for new product usages
    const [publishedSpaces, setPublishedSpaces] = useState();
    const [rangeMax, setRangeMax] = useState();
    const [rangeMin, setRangeMin] = useState();
    const [planTypeObj, setPlanTypeObj] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    // making re render when session storage is set
    const refreshPage = useForceUpdate();

    // payment handling status
    const paymentUrl = useLocation().search;
    const status = new URLSearchParams(paymentUrl).get('payment_status');
    const [showPaymentStatus, setShowPaymentStatus] = useState(status ? true : false);
    const [showUpgradeLoader, setShowUpgradeLoader] = useState(status === 'success' ? true : false);
    const [shouldRedirectMobileBlock, setShouldRedirectMobileBlock] = useState(false);
    useEffect(() => {
        setShowUpgradeLoader(status === 'success' ? true : false);
        if (window.screen.width < 800) {
            setShouldRedirectMobileBlock(true);
        }
        //Api to get user details with access token
        if (isEmpty(userDetails)) {
            fetchUserDetails();
        }
        fetchTVProperties();
        if (userDetails && userDetails.currentPlan && userDetails.currentPlan !== 'START PLAN') {
            getUserCurrentUsage();
        } else if (showUpgradeLoader) {
            dispatchS({
                type: 'SET_SHOW_PAYMENT_STATUS_LOADER',
                payload: {
                    loader: true
                }
            });
            setTimeout(() => {
                getUserCurrentUsage();
            }, 5000);
        }
        setTimeout(() => {
            setShowPaymentStatus(false);
        }, 5000);
    }, []);

    useEffect(() => {
        if (showPlansPopUp && !showUpgradeLoader) {
            getUserCurrentUsage();
        }
    }, [showPlansPopUp]);

    useEffect(() => {
        if (typeof phone === 'string') {
            dispatch({
                type: 'EDIT_GLOBAL_PROFILE',
                payload: {
                    id: id,
                    avatar: avatar,
                    name: name,
                    email: email,
                    role: role,
                    phone: {
                        phone: phone,
                        countryCode,
                        countryName
                    }
                }
            });
        } else {
            dispatch({
                type: 'EDIT_GLOBAL_PROFILE',
                payload: {
                    id: id,
                    avatar: avatar,
                    name: name,
                    email: email,
                    role: role,
                    phone: phone
                }
            });
        }
    }, []);

    // Redirects
    if (shouldRedirectMobileBlock) {
        return <Redirect to="/mobileBlock" />;
    }

    if (shouldRedirect) {
        return <Redirect to="/signin" />;
    }

    async function fetchUserDetails() {
        const url = `${DOMAIN}/v1/dashboard/space/user/login/details`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const statusResponse = await getReq({
            url,
            requestHeaders
        });
        const { code, data } = statusResponse;
        if (statusResponse === topbar('topbar.api.max_limit')) {
            localStorage.removeItem('ss-auth-spaces');
            localStorage.setItem('ss-lang', 'en');
            removeSessionStorage('ss-user-spaces');
            setRedirect(true);
        }
        if (code === 200) {
            if (data.currentPlan) {
                dispatch({
                    type: 'EDIT_GLOBAL_PROFILE',
                    payload: {
                        plan: data.currentPlan,
                        role: data.role,
                        teamExists: data.teamExists,
                        department: data.department
                    }
                });
            }
            setSessionStorage({
                key: 'ss-user-spaces',
                value: data,
                expiry: 20160 // 2 Weeks
            });
            refreshPage();
        }
    }

    // get user details
    async function getUserCurrentUsage() {
        setShowLoader(true);
        const url = `${DOMAIN}/v1/dashboard/space/plans/usage`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const currentUsage = await getReq({
            url,
            requestHeaders
        });
        const { code, data = {} } = currentUsage;
        if (code === 200) {
            dispatch({
                type: 'SET_USER_CURRENT_USAGE',
                payload: {
                    currentTier: data.currentTier,
                    daysLeft: data.daysLeft,
                    publishedSpaces: data.publishedSpaces,
                    nextTier: data.nextTier,
                    planEnds: data.planEnds,
                    priceToPay: data.priceToPay,
                    range: data.range,
                    paymentInfo: data.paymentInfo ? data.paymentInfo : null,
                    trialUp: data.daysLeft > 0 ? false : true
                }
            });
            setPublishedSpaces(data.publishedSpaces);
            setRangeMin(data.range.starts);
            setRangeMax(data.range.ends);
            setPlanTypeObj(data);
            setShowLoader(false);
            if (isEmpty(userDetails)) {
                userDetails = getSessionStorage('ss-user-spaces');
            }
            setSessionStorage({
                key: 'ss-user-spaces',
                value: { ...userDetails, currentPlan: data.currentPlan }
            });
            dispatchS({
                type: 'SET_SHOW_PAYMENT_STATUS_LOADER',
                payload: {
                    loader: false
                }
            });
            dispatch({
                type: 'EDIT_GLOBAL_PROFILE',
                payload: {
                    plan: data.currentPlan,
                    role: role
                }
            });
        }
    }

    // fetching tv properties
    async function fetchTVProperties() {
        setShowLoader(true);
        const url = `${DOMAIN}/v1/dashboard/account/widget-settings`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const statusResponse = await getReq({
            url,
            requestHeaders
        });
        const { code, data } = statusResponse;
        // globalsettings
        if (code === 200 && data?.generalSettings) {
            const { language } = data.generalSettings;
            if (language === 'English') {
                localStorage.setItem('ss-lang', 'en');
            } else if (language === 'Portuguese') localStorage.setItem('ss-lang', 'pt');
            translate.changeLanguage(language);
        }
        if (code === 200 && data?.teamSettings) {
            setShowLoader(false);
            const { admin, member } = data.teamSettings;
            dispatchSettings({
                type: 'SET_GLOBAL_ADMIN_SETTINGS',
                payload: {
                    globalAdminSeeEntirePortfolio: admin.seeEntirePortfolio,
                    globalAdminManageTeamPortfolio: admin.manageTeamPortfolio,
                    globalAdminCreateSpace: admin.createSpace,
                    globalAdminSendInvitations: admin.sendInvitations,
                    globalAdminEditTeamMemberDetails: admin.editTeamMemberDetails,
                    globalAdminEditAccountPermissions: admin.editAccountPermissions,
                    globalAdminSeePlanDetails: admin.seePlanDetails,
                    globalAdminManagePlan: admin.managePlan,
                    globalAdminWidgetCustomisation: admin.widgetCustomisation,
                    globalInviteMoreAdmins: admin.inviteMoreAdmins,
                    globalAdminGeneralSettings: admin.generalSettings
                }
            });
            dispatchSettings({
                type: 'SET_GLOBAL_MEMBER_SETTINGS',
                payload: {
                    globalMemberSeeEntirePortfolio: member.seeEntirePortfolio,
                    globalMemberManageTeamPortfolio: member.manageTeamPortfolio,
                    globalMemberCreateSpace: member.createSpace,
                    globalMemberSendInvitations: member.sendInvitations,
                    globalMemberEditTeamMemberDetails: member.editTeamMemberDetails,
                    globalMemberSeePlanDetails: member.seePlanDetails,
                    globalMemberManagePlan: member.managePlan
                }
            });
        }
    }

    const handleEditPopUp = () => {
        setShowEditPopUp(true);
    };

    const { adminGlobalSettings, memberGlobalSettings } = globalSettings;
    //plan details conditions
    const seePlanDetailsCheck = () => {
        if (owner) return true;
        else if (admin && adminGlobalSettings?.globalAdminSeePlanDetails) return true;
        else if (!owner && !admin && memberGlobalSettings?.globalMemberSeePlanDetails) return true;
        else return false;
    };
    const managePlanDetailsCheck = () => {
        if (owner) return true;
        else if (admin && adminGlobalSettings?.globalAdminManagePlan) return true;
        else if (!owner && !admin && memberGlobalSettings?.globalMemberManagePlan) return true;
        else return false;
    };

    return (
        <>
            <div className="topbar d-flex space-between h-center pos-fix">
                {status === 'success' && showPaymentStatus ? (
                    <>
                        <div className="payment-status success">{topbar('topbar.payment.succ_msg')}</div>
                    </>
                ) : status === 'failed' && showPaymentStatus ? (
                    <>
                        <div className="payment-status failed">
                            <div className="msg">
                                <div className="title">{topbar('topbar.payment.fail_msg')}</div>
                                <div className="title-msg">{topbar('topbar.payment.fail_desc')}</div>
                            </div>
                            <div className="try-again" onClick={() => handleSubscription(DOMAIN, userToken, userDetails)}>
                                {topbar('topbar.payment.again')}
                            </div>
                        </div>
                    </>
                ) : (
                    ''
                )}
                <div className="product-logo-topbar ml-30">
                    <img src={HolofyProductLogo} alt="holofy-product-logo"></img>
                    {/* removing company as of now */}
                    {/* <span>X</span>
                    <div className="partner-company">{company}</div> */}
                </div>

                <div className="left-menu-wrap d-flex center-align">
                    {showPaymentStatus ? (
                        ''
                    ) : userDetails.currentPlan !== 'BASIC TRIAL' &&
                      userDetails.currentPlan !== 'PRO TRIAL' &&
                      userDetails.currentPlan !== 'BASIC PAID' &&
                      userDetails.currentPlan !== 'PRO PAID' &&
                      userDetails.currentPlan !== 'START PLAN' &&
                      seePlanDetailsCheck() ? (
                        <button onClick={() => setShowPlansPopUp(true)} className="dataused-wrapper">
                            <h1>{topbar('topbar.plan')}</h1>
                            <ProgressBar percentage={publishedSpaces} min={rangeMin} max={rangeMax}></ProgressBar>
                        </button>
                    ) : (
                        ''
                    )}

                    {avatar ? (
                        <div className="top-menu-item-user" onClick={handleEditPopUp} typeof="Button">
                            <img src={avatar} alt=""></img>
                        </div>
                    ) : (
                        <Avatar
                            name={name}
                            value=""
                            size="42"
                            round={true}
                            color="black"
                            maxInitials={1}
                            textSizeRatio={2}
                            style={{ fontWeight: '700' }}
                            onClick={handleEditPopUp}
                        />
                    )}
                </div>
            </div>
            {showPlansPopUp && planTypeObj ? (
                <PopUpPlanType
                    planTypeObj={planTypeObj}
                    showLoader={showLoader}
                    setShowPlansPopUp={setShowPlansPopUp}
                    managePlanDetailsCheck={managePlanDetailsCheck}
                ></PopUpPlanType>
            ) : (
                ''
            )}
            {showEditPopUp ? (
                <UserDetailPopUp setUserDetailPopUp={setShowEditPopUp} isUserProfileEdit={true} setUpdateSpaces={setUpdateSpaces} />
            ) : (
                // <PopUpEditMember setEditMemberPopUp={setShowEditPopUp} isUserProfileEdit={true} userDetails={userDetails}></PopUpEditMember>
                ''
            )}
            {globalProfile?.usageDetails?.trialUp && <PopUpPlanType planTypeObj={globalProfile.usageDetails}></PopUpPlanType>}
        </>
    );
};

export default TopBar;
