import React from 'react';
import { useTranslation } from 'react-i18next';
import AddIconBlack from '../../assets/addIconBlack.png';
import './addmore.scss';
export default function AddMore(props) {
    const { onClickFn } = props;
    const { t } = useTranslation();
    return (
        <div
            className="more-container"
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                e.nativeEvent.stopImmediatePropagation();
                onClickFn && onClickFn();
            }}
        >
            <button className="more-container__btn">
                <img src={AddIconBlack} alt="add icon"></img>
            </button>
            <div className="more-container__title">{t('add_more')}</div>
        </div>
    );
}
