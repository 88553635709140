// library
import React from 'react';
import { useTranslation } from 'react-i18next';

// components

// Assets
import BackImg from '../../assets/back.png';
// styles
import './addspaceheader.scss';
export default function AddSpaceHeader(props) {
    const {
        currentStep,
        videoFiles,
        isNewTemplate,
        setCurrentStep,
        showPopupItem,
        setSpaceUpdated,
        videoUploaded,
        setPreventUpdates,
        selectedTemplate,
        setSelectedTemplate,
        prevTemplate,
        setIsNewTemplate
    } = props;
    const { t } = useTranslation();

    const handleBackBtn = () => {
        if (currentStep === '1') {
            setCurrentStep('1');
            document.body.classList.remove('body-ovef-hdn');
            showPopupItem(false);
        } else if (currentStep === '2') {
            setCurrentStep('1');
        } else if (currentStep === '3') {
            setCurrentStep('2');
            setSpaceUpdated(false);
        } else if (currentStep === '4' && videoFiles.length === videoUploaded) {
            setCurrentStep('3');
        } else if (currentStep === '5') {
            setCurrentStep('4');
        } else if (currentStep === '6') {
            if (isNewTemplate) {
                setIsNewTemplate(false);
                if (selectedTemplate?.templateName !== 'Agent’s default contact') setSelectedTemplate(prevTemplate);
                else if (selectedTemplate?.templateName === 'Agent’s default contact') {
                    setSelectedTemplate({
                        templateName: 'Agent’s default contact',
                        templateId: null,
                        ctas: null
                    });
                }
            }
            setCurrentStep('3');
            setPreventUpdates(false);
        }
    };
    return (
        <>
            <div className="top-header">
                <button
                    className="back-button"
                    onClick={() => {
                        handleBackBtn();
                    }}
                >
                    <img src={BackImg} alt="back img"></img>
                </button>
                <div className="step-indicator">
                    {currentStep === '1' ? (
                        <>
                            <div className={currentStep === '1' ? 'circle c-white active' : 'circle c-white'}></div>
                            <div className={currentStep === '2' ? 'circle c-white active' : 'circle c-white'}></div>
                            <div className={currentStep === '3' ? 'circle c-white active' : 'circle c-white'}></div>
                            <div className={currentStep === '4' ? 'circle c-white active' : 'circle c-white'}></div>
                            <div className={currentStep === '5' ? 'circle c-white active' : 'circle c-white'}></div>
                        </>
                    ) : currentStep === '6' ? (
                        ''
                    ) : (
                        <>
                            <div className={currentStep === '1' ? 'circle active' : 'circle'}></div>
                            <div className={currentStep === '2' ? 'circle active' : 'circle'}></div>
                            <div className={currentStep === '3' ? 'circle active' : 'circle'}></div>
                            <div className={currentStep === '4' ? 'circle active' : 'circle'}></div>
                            <div className={currentStep === '5' ? 'circle active' : 'circle'}></div>
                        </>
                    )}
                </div>
            </div>
            <div className={currentStep === '1' ? 'current-step-title ft-white' : 'current-step-title'}>
                {currentStep === '4' && videoFiles.length > 0 ? (
                    <div className="total-videos-title">
                        {videoFiles.length} {videoFiles.length > 1 ? t('videos') : t('video')}
                    </div>
                ) : (
                    ''
                )}

                {currentStep === '1' && t('spaces.add_space.address')}
                {currentStep === '2' && t('space_details.title')}
                {currentStep === '3' && t('agent.manage_title')}
                {currentStep === '4' && t('spaces.add_space.add_videos')}
                {currentStep === '5' && t('spaces.publish.title')}
                {currentStep === '6' &&
                    (isNewTemplate ? t('cta.new_title') : selectedTemplate?.templateId ? t('cta.edit_title') : 'Agent Default Contacts')}
            </div>
        </>
    );
}
