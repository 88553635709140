import React, { useState, useEffect, useContext } from 'react';
import './popupemptyskill.scss';
import { Storage } from 'aws-amplify';
import TextAtom from '../TextAtom';
import ButtonAtom from '../ButtonAtom';
import Pulse from 'react-reveal/Pulse';
import Input from '../Input';
import { useDropzone } from 'react-dropzone';
import { getFormData } from '../../utils';
import { postReq, getReq, putFormReq } from '../../utils/api';
import { DOMAIN } from '../../constants/base';
import { currentSpaceContext } from '../../context';
import LoadingSpin from '../../components/LoadingSpin';
import { toast } from 'react-toastify';

// Assets
import uploadIcon from '../../assets/upload-icon.png';
import uploadBtn from '../../assets/upload-btn.png';
// import cancelBtn from "../../assets/cancel-x.png";
import BackImg from '../../assets/back.png';
import cancelBtn from '../../assets/circular.svg';
import equalDrag from '../../assets/drag-equal.svg';
import circularChecked from '../../assets/circularChecked.svg';
import { useTranslation } from 'react-i18next';

const PopUpEmptySkill = ({ setOpenEmptySkillPopUp, fetchActiveSpaces }) => {
    const [currentSpace] = useContext(currentSpaceContext);
    const { t: emptySkill } = useTranslation();
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};

    const [videoFiles, setVideoFiles] = useState([]);
    const [currentScreen, setCurrentScreen] = useState('1');

    // Form
    // const [latlng, setLatLng] = useState({ lat: 0, lng: 0 });
    // const latlng = { lat: 0, lng: 0 };

    // Current Upload Progress
    const [videoUploadIndex, setVideoUploadIndex] = useState(null);
    const [uploadStoppedIds, setUploadStoppedIds] = useState([]);
    const [loadingPercentage, setLoadingPercentage] = useState(0);

    let StoragePromise;

    useEffect(() => {
        videoDurationHandler();
    }, [videoUploadIndex]);

    // useEffect(() => {
    //   if (latlng.lat === 0 && latlng.lng === 0) {
    //     setDisabledButton(true);
    //   } else {
    //     setDisabledButton(false);
    //   }
    // }, [latlng]);

    const onDrop = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            toast.error('File Size Limit Exceeded (MAX : 500MB)', {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
            return;
        }
        setVideoFiles(acceptedFiles);
        setCurrentScreen('2');
    };

    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        onDrop,
        accept: '.mp4,.mov',
        maxSize: 524288000
    });

    const DeleteFile = (value) => {
        setVideoFiles(videoFiles.filter((item, index) => index !== value));
    };

    const handleBackBtn = () => {
        if (currentScreen === '1') {
            setCurrentScreen('1');
            setOpenEmptySkillPopUp(false);
        } else if (currentScreen === '2') {
            setCurrentScreen('1');
        } else if (currentScreen === '3') {
            setCurrentScreen('2');
        }
    };

    const renameFileHandler = (index, event) => {
        let files = [...videoFiles];
        files[index].rename = event.target.value;
        setVideoFiles(files);
    };

    const videoUploadFinishHandler = () => {
        setOpenEmptySkillPopUp(false);
        fetchActiveSpaces();
    };

    const videoDurationHandler = () => {
        let currentVideo = videoFiles[videoUploadIndex];
        if (currentVideo) {
            var video = document.createElement('video');
            video.preload = 'metadata';
            video.addEventListener('loadedmetadata', function () {
                window.URL.revokeObjectURL(video.src);
                var duration = video.duration;
                FilesUpload(Math.round(duration), currentVideo);
            });
            if (!currentVideo.videoDuration) {
                video.src = URL.createObjectURL(currentVideo);
            }
        }
    };

    const FilesUpload = (duration, currentVideo) => {
        if (videoUploadIndex === videoFiles.length) {
            videoUploadFinishHandler();
            return;
        }
        try {
            const requestHeaders = {
                authorization: userToken.accessToken
            };
            let url = `${DOMAIN}/v1/dashboard/space/skill/video-path`;
            const req = {
                url,
                requestHeaders
            };
            getReq(req).then((skillPath) => {
                if (skillPath.data) {
                    const { path } = skillPath.data;
                    let videoExt = currentVideo.type.split('/')[1];
                    if (videoExt !== 'mov' && videoExt !== 'mp4') {
                        videoExt = videoExt.includes('.mp4') ? 'mp4' : 'mov';
                    }
                    StoragePromise = Storage.put(`${path}.${videoExt}`, currentVideo, {
                        contentType: `video/${videoExt}`,
                        progressCallback(progress) {
                            // console.log(uploadStoppedIds);
                            // console.log(
                            //   `Uploaded for ${videoUploadIndex + 1} file:  ${
                            //     (progress.loaded / progress.total) * 100
                            //   } %`
                            // );
                            let lp = (progress.loaded / progress.total) * 100;
                            // console.log(lp);
                            if (setOpenEmptySkillPopUp) {
                                setLoadingPercentage(lp);
                            }
                        }
                    })
                        .then(() => {
                            url = `${DOMAIN}/v1/dashboard/space/skill/${currentSpace.selectedSkillId}/update-path`;
                            const newReq = {
                                url,
                                requestHeaders,
                                payload: {
                                    path,
                                    videoExt,
                                    videoDuration: duration
                                }
                            };
                            postReq(newReq);
                            renameFiles();
                            if (videoUploadIndex < videoFiles.length - 1) {
                                console.log('go next');
                                setLoadingPercentage(0);
                                setVideoUploadIndex(videoUploadIndex + 1);
                            } else {
                                videoUploadFinishHandler();
                            }
                        })
                        .catch((e) => {
                            toast.error('error uploading', {
                                position: 'top-center',
                                autoClose: 2000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined
                            });
                        });
                }
            });
        } catch (e) {
            console.log('some error');
        }
    };

    //   Renaming Files
    const renameFiles = async () => {
        let payload = {
            name: videoFiles[0].rename ? videoFiles[0].rename : currentSpace.selectedSkillName
        };
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const url = `${DOMAIN}/v1/dashboard/space/skill/${currentSpace.selectedSkillId}`;
        const formData = getFormData(payload);
        const editResponse = await putFormReq({
            url,
            payload: formData,
            requestHeaders
        });
        const { code } = editResponse;
        if (code === 200) {
            // console.log('Success');
        }
    };

    const CheckUpload = () => {
        setCurrentScreen('3');
        if (videoUploadIndex === null) {
            setVideoUploadIndex(0);
        }
    };

    const OnCanelUpload = (i) => {
        if (i !== videoUploadIndex) {
            const cancl = [...uploadStoppedIds];
            cancl.push(i);
            DeleteFile(i);
            setUploadStoppedIds(cancl);
        }
    };

    const OnCanelUploadAll = () => {
        setUploadStoppedIds(videoFiles.map((x, i) => (i > videoUploadIndex ? i : -1)));
        setVideoFiles([]);
        setOpenEmptySkillPopUp(false);
    };

    useEffect(() => {
        // logic here
        // setCountryCodes(cc.code())
        return () => {
            setUploadStoppedIds(videoFiles.map((x, i) => i));
        };
    }, []);

    return (
        <div className="popups-wrapper">
            <Pulse duration={150}>
                <div className="form-card-wrapper">
                    <div className="d-flex w-100p">
                        <button className="back-button" onClick={handleBackBtn}>
                            <img src={BackImg} alt="back-icon"></img>
                        </button>
                        <div className="current-step-wrapper">
                            <div className={currentScreen === '1' ? 'circle active' : 'circle'}></div>
                            <div className={currentScreen === '2' ? 'circle active' : 'circle'}></div>
                            <div className={currentScreen === '3' ? 'circle active' : 'circle'}></div>
                        </div>
                    </div>
                    {currentScreen === '1' ? (
                        <div className="form-wrapper mt-20">
                            <div className="d-flex f-col h-center">
                                <img className="w-60p" src={uploadIcon} alt="upload-icon"></img>
                                <div className="mt-40 text-center">
                                    <TextAtom
                                        textContent="Upload Videos"
                                        fontFamily="Montserrat"
                                        fontWeight="700"
                                        fontSize="1rem"
                                        color="#3A3A3A"
                                    ></TextAtom>
                                    <p className="fs-12 text-center mt-20 c-dblue fw-700">
                                        Up to 450 MB per Video File<br></br>
                                        <br></br> Upload up to 10 Files at Once <br></br>
                                        <br></br> Supported File Formats: .mov, .mp4
                                    </p>
                                </div>
                                <div className="floating-btn mt-20" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <img src={uploadBtn} alt="upload-icon"></img>
                                    <div className="hover-ani hover-ripple-out" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    {currentScreen === '2' ? (
                        <div className="form-wrapper">
                            <div className="d-flex f-col h-center">
                                <div className="text-left mt-40 w-100p">
                                    <TextAtom
                                        textContent="Upload Videos"
                                        fontFamily="Montserrat"
                                        fontWeight="700"
                                        fontSize="1rem"
                                        color="#3A3A3A"
                                    ></TextAtom>
                                </div>
                                <div className="videos-list">
                                    <ul>
                                        {videoFiles.map((file, index) => (
                                            <li key={index}>
                                                <img className="equal-drag" src={equalDrag} alt=""></img>
                                                <Input
                                                    type="text"
                                                    label="VIDEO NAME"
                                                    labelStyle="uc mb-5"
                                                    placeholder={currentSpace.selectedSkillName}
                                                    value={file.rename}
                                                    tabIndex={10 + index}
                                                    onChange={(event) => renameFileHandler(index, event)}
                                                />
                                                <button>
                                                    {index === videoUploadIndex ? (
                                                        <div className="upload-loading-spin">
                                                            <LoadingSpin />
                                                        </div>
                                                    ) : (
                                                        <img
                                                            src={index < videoUploadIndex ? circularChecked : cancelBtn}
                                                            onClick={() => {
                                                                index >= videoUploadIndex && OnCanelUpload(index);
                                                            }}
                                                            alt=""
                                                        ></img>
                                                    )}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <ButtonAtom
                                    label={emptySkill('continue')}
                                    onClick={() => CheckUpload()}
                                    disabled={videoFiles.length > 0 ? false : true}
                                ></ButtonAtom>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    {currentScreen === '3' ? (
                        <div className="form-wrapper mt-20">
                            <div className="d-flex f-col h-center">
                                <div className="text-left mt-40 w-100p">
                                    <TextAtom
                                        textContent="Videos Uploading.."
                                        fontFamily="Montserrat"
                                        fontWeight="700"
                                        fontSize="1rem"
                                        color="#3A3A3A"
                                    ></TextAtom>
                                </div>
                                <div className="videos-list-uploading">
                                    <ul>
                                        {videoFiles.map((video, i) => (
                                            <li>
                                                <div className="list-upload-container">
                                                    <div className="list-upload-container-item">
                                                        <div className="list-upload-container-item-desc">
                                                            <h4>{videoFiles[0].rename || currentSpace.selectedSkillName}</h4>
                                                            <span>
                                                                {videoUploadIndex === i
                                                                    ? parseInt(loadingPercentage || 0)
                                                                    : i < videoUploadIndex
                                                                    ? '100'
                                                                    : '0'}
                                                                %
                                                            </span>
                                                        </div>
                                                        <div className="list-upload-container-item-loader">
                                                            <div
                                                                style={{
                                                                    width: `${
                                                                        videoUploadIndex === i
                                                                            ? loadingPercentage
                                                                            : i < videoUploadIndex
                                                                            ? 100
                                                                            : 0
                                                                    }%`
                                                                }}
                                                                className="list-upload-container-item-loader-child"
                                                            />
                                                        </div>
                                                    </div>
                                                    <button>
                                                        {i === videoUploadIndex ? (
                                                            <div className="upload-loading-spin">
                                                                <LoadingSpin />
                                                            </div>
                                                        ) : (
                                                            <img
                                                                src={i < videoUploadIndex ? circularChecked : cancelBtn}
                                                                onClick={() => {
                                                                    i >= videoUploadIndex && OnCanelUpload(i);
                                                                }}
                                                                alt=""
                                                            ></img>
                                                        )}
                                                    </button>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="mt-20 video-uploading-text">
                                    <h1>Videos Uploading..</h1>
                                    <p>
                                        Please don’t close this window until upload is complete.
                                        <br></br>
                                        <br></br>
                                        Pressing the back button will stop the uploading process.
                                    </p>
                                </div>
                                <div className="mt-20">
                                    <button className="link-btn-cta c-error" onClick={OnCanelUploadAll}>
                                        CANCEL UPLOAD
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </Pulse>
        </div>
    );
};

export default PopUpEmptySkill;
