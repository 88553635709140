import React from 'react';
import AndroidTV from '../../containers/AndroidTv';

function AndroidTvPage(props) {
    return (
        <>
            <AndroidTV />
        </>
    );
}

export default AndroidTvPage;
