import React, { useContext, useState } from 'react';
import { DOMAIN } from '../../constants/base';
import Pulse from 'react-reveal/Pulse';
import { currentSpaceContext } from '../../context';
import ClickAwayContentWrapper from '../../components/ClickAwayContentWrapper';
import { useTranslation } from 'react-i18next';

const PopUpPublishUnpublish = ({
    setOpenPublishUnpublishPopUp,
    selectedCards,
    togglePublish,
    setSelectedCards,
    setPublishUnPublishNotification,
    setPageNo,
    setChangeState,
    changeState,
    setChangeCountState,
    changeCountState
}) => {
    const [, dispatch] = useContext(currentSpaceContext);
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const { t: publish } = useTranslation();
    const [isStatusChanging, setIsStatusChanging] = useState(false);
    // const [currentSpace] = useContext(currentSpaceContext);

    const togglePublishUnpublish = () => {
        setIsStatusChanging(true);
        const payload = {
            spaceIds: selectedCards,
            status: !togglePublish
        };
        const url = `${DOMAIN}/v1/dashboard/space/multiple/status-change`;
        fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                authorization: userToken.accessToken
            },
            body: JSON.stringify(payload)
        }).then((resp) => {
            setIsStatusChanging(false);
            setOpenPublishUnpublishPopUp(false);
            setPublishUnPublishNotification(true);
            setTimeout(() => {
                setPublishUnPublishNotification(false);
            }, 3000);
            setPageNo(1);
            setSelectedCards([]);
            if (payload.status === false) {
                dispatch({
                    type: 'SET_SPACE_COUNT',
                    payload: { unpublished: selectedCards.length }
                });
            } else if (payload.status === true) {
                dispatch({
                    type: 'SET_SPACE_COUNT',
                    payload: { published: selectedCards.length }
                });
            }
            setChangeState(!changeState);
            setChangeCountState(!changeCountState);
        });
    };

    return (
        <div className="popups-wrapper">
            <ClickAwayContentWrapper onClose={() => setOpenPublishUnpublishPopUp(false)}>
                <Pulse duration={150}>
                    <div className="remove-card-wrapper">
                        <div className="form-wrapper">
                            <h1 className="mt-20">
                                {publish('spaces.publish_popup.msg')}{' '}
                                {!togglePublish ? publish('spaces.publish_popup.publish') : publish('spaces.publish_popup.unpublish')}
                                {selectedCards.length}{' '}
                                {selectedCards.length > 1 ? publish('spaces.publish_popup.spaces') : publish('spaces.publish_popup.space')}?
                            </h1>
                            <p>
                                {publish('spaces.publish_popup.the')}
                                {selectedCards.length > 1 ? publish('spaces.publish_popup.spaces') : publish('spaces.publish_popup.space')}
                                {selectedCards.length > 1 ? publish('spaces.publish_popup.are') : publish('spaces.publish_popup.is')}
                                {publish('spaces.publish_popup.no_delete')}
                                {selectedCards.length > 1 ? publish('spaces.publish_popup.they') : publish('spaces.publish_popup.it')}{' '}
                                {publish('spaces.publish_popup.will')} {togglePublish ? publish('spaces.publish_popup.no_longer') : ''}{' '}
                                {publish('spaces.publish_popup.visible')}
                            </p>
                            <div className="remove-btn-wrapper mt-30 w-100p">
                                <button onClick={() => setOpenPublishUnpublishPopUp(false)} className="go-back">
                                    {publish('spaces.publish_popup.back')}
                                </button>
                                <button
                                    className="remove"
                                    onClick={() => {
                                        if (isStatusChanging) return;
                                        togglePublishUnpublish();
                                    }}
                                >
                                    {!togglePublish ? publish('spaces.publish_popup.publish') : publish('spaces.publish_popup.unpublish')}
                                </button>
                            </div>
                        </div>
                    </div>
                </Pulse>
            </ClickAwayContentWrapper>
        </div>
    );
};

export default PopUpPublishUnpublish;
