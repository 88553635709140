import React from 'react';
import classNames from 'classnames';

export default function StatusMsg(props) {
  const { type, message } = props;

  const messageClass = classNames(
    'd-flex center-align mt-20 h-20 fs-16 fw-b br-5 p-10 c-white mxw-380 pos-abs top-0',
    {
      'bg-red': type === 'error',
      'bg-blue': type === 'info',
    }
  );
  return (
    <div style={{ left: '50%' }} className={messageClass}>
      {message}
    </div>
  );
}

export function InfoBox(props) {
  const { type, message } = props;

  const messageClass = classNames(
    'c-white d-flex center-align vertical-center mt-20 h-20 fs-16 fw-b br-5 p-10 c-white ',
    {
      'bg-red': type === 'error',
      'bg-blue': type === 'info',
    }
  );
  return (
    <div
      className={messageClass}
      style={{ position: 'absolute', left: '35%', zIndex: '1' }}
    >
      {message}
    </div>
  );
}
