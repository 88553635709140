import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    fallbackLng: 'English',
    lng: 'English',
    resources: {
        English: {
            translations: require('./locales/en.json')
        },
        Portuguese: {
            translations: require('./locales/pt_BR.json')
        }
    },
    defaultLocale: 'English',
    ns: ['translations'],
    defaultNS: 'translations'
});

i18n.languages = ['English', 'Portuguese'];

export default i18n;
