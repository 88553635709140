import React from 'react';
import { useTranslation } from 'react-i18next';
import './videoTourLabel.scss';

const VideoTourLabel = (props) => {
    const { t } = useTranslation();
    const { top, left, size } = props;
    return (
        <div
            style={{ top: top, left: left, height: size === 's' ? '12px' : '15px', width: size === 's' ? '53px' : '66px' }}
            className="video-tour-label pos-abs"
        >
            <div className="dot"></div>
            <span
                style={{
                    fontSize: size === 's' ? '6px' : '7px',
                    lineHeight: size === 's' ? '2px' : '1px'
                }}
            >
                {t('videotour')}
            </span>
        </div>
    );
};

export default VideoTourLabel;
