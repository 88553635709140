import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ReactDragListView from 'react-drag-listview/lib/index.js';
import './manageskillsedit.scss';
import { DOMAIN } from '../../constants/base';
import PopUpEditSpaceSkill from '../PopUpEditSpaceSkill';
import VideoUploader from '../VideoUploader';
import Pulse from 'react-reveal/Pulse';
import UploadIcon from '../../assets/add.svg';
import { useTranslation } from 'react-i18next';
import LoadingSpin from '../LoadingSpin';
import DeleteIcon from '../../assets/delete-btn.svg';
import ReplaceIcon from '../../assets/replace-icon.png';
import { toast } from 'react-toastify';
import { putReq } from '../../utils/api';

export default function ManageSkillsEdit(props) {
    const {
        videoFiles,
        setVideoFiles,
        setSpacePoster,
        videoUploaded,
        setVideoUploaded,
        selectedSkill,
        cardSkills,
        setCardSkills,
        setSelectedSkill,
        currentspaceWidget,
        currentSpaceId,
        setCurrentStep
    } = props;
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    // const [cardSorting, setCardSorting] = useState(false);
    const [isReplace, setIsReplace] = useState(false);
    const [toggleSkillFalse, setToggleSkillFalse] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [showDeletePopUp, setShowDeletePopUp] = useState(false);
    const [skillNameError, setSkillNameError] = useState(false);
    const [isNameUpdated, setIsNameUpdated] = useState(false);
    const [scrollToUpload, setScrollToUpload] = useState(false);
    const [editFlowUploadCheck, setEditFlowUploadCheck] = useState(false);
    const { DragColumn } = ReactDragListView;
    const { t: editSpace } = useTranslation();
    let videoContainerRef = useRef(null);

    useEffect(() => {
        if (videoContainerRef?.current) {
            let el = videoContainerRef.current.parentElement;
            el.style.width = '100%';
        }
    }, []);
    useEffect(() => {
        if (videoFiles.length > 0) {
            setScrollToUpload(true);
        }
    }, [videoFiles]);

    // Handling Drag

    const dragPropsVideoUploader = {
        onDragEnd(fromIndex, toIndex) {
            const data = [...cardSkills];
            const item = data.splice(fromIndex, 1)[0];
            data.splice(toIndex, 0, item);
            setCardSkills(data);
            // setCardSorting(true);
        },
        nodeSelector: '.video-container-item',
        handleSelector: '.video-container-item',
        lineClassName: 'sort-line-video-upload'
    };

    const onDrop = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            // setErrorMsg(true);
            return;
        }
        for (let i = 0; i < acceptedFiles.length; i++) {
            acceptedFiles[i].id = videoFiles.length + i + 1;
        }
        // setCardSorting(true);
        setVideoFiles(videoFiles.concat(acceptedFiles));
        // setShowVideosUpload(true);
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        noDragEventsBubbling: true,
        onDrop,
        accept: '.mp4,.mov',
        maxSize: 524288000,
        maxFiles: selectedSkill.length === 1 ? 1 : 0
    });

    const replaceHandler = () => {
        setIsReplace(true);
        open();
    };

    const updateSkillsMethod = () => {
        let newArray = cardSkills.filter((item) => !selectedSkill.includes(item.id));
        setCardSkills(newArray);
        setSelectedSkill([]);
        setToggleSkillFalse(true);
    };

    // Delete Skill
    const deleteSkill = (skillIndex) => {
        setDeleteStatus(true);
        if (skillIndex === selectedSkill.length) {
            updateSkillsMethod();
            setDeleteStatus(false);
            setShowDeletePopUp(false);
            return;
        }
        const url = `${DOMAIN}/v1/dashboard/space/skill/${selectedSkill[skillIndex]}`;
        fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                authorization: userToken.accessToken
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.code === 200) {
                    deleteSkill(skillIndex + 1);
                }
            });
    };

    // File Utilities
    const DeleteFile = (skillId, fileNumber) => {
        const url = `${DOMAIN}/v1/dashboard/space/skill/${skillId}`;
        fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                authorization: userToken.accessToken
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setVideoUploaded(videoUploaded - 1);
                const newData = videoFiles.filter((item) => item.id !== fileNumber);
                setVideoFiles(newData);
            });
    };
    // Selecting Skill Method
    const selectSkillMethod = (id, check) => {
        let array = [...selectedSkill];
        if (check) {
            array.push(id);
        } else if (!check) {
            let index = array.indexOf(id);
            array.splice(index, 1);
        }
        setSelectedSkill(array);
    };

    // Scrolling in the last video
    const AlwaysScrollToBottom = () => {
        const elementRef = useRef();
        useEffect(() =>
            elementRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            })
        );
        return <div ref={elementRef} />;
    };

    const handleSortingVidoes = async () => {
        const url = `${DOMAIN}/v1/dashboard/space/skill/${currentSpaceId}/position`;
        let payload = {
            skillIdPosition: cardSkills.map((cardSkill, index) => {
                return {
                    skillId: cardSkill.id,
                    position: index + 1,
                    name: cardSkill.rename,
                    aspectRatio: {
                        height: cardSkill?.aspectRatio?.height ? cardSkill.aspectRatio.height : 2,
                        width: cardSkill?.aspectRatio?.width ? cardSkill.aspectRatio.width : 1
                    }
                };
            })
        };
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const statusResponse = await putReq({
            url,
            payload,
            requestHeaders
        });
        const { code } = statusResponse;
        if (code === 200) {
            setSpacePoster(cardSkills[0]?.poster);
            setCurrentStep('5');
        }
    };

    const handleCheckNameForSkills = () => {
        let nameError = false;
        if (cardSkills?.length) {
            for (let cardSkill of cardSkills) {
                if (cardSkill?.rename?.length === 0 || cardSkill?.rename === undefined) {
                    nameError = true;
                }
            }
        }
        if (videoFiles?.length) {
            for (let videoFile of videoFiles) {
                if (videoFile.rename.length === 0) {
                    nameError = true;
                }
            }
        }
        if (nameError) {
            setSkillNameError(true);
            toast.error(editSpace('name_error'), {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        } else {
            if (cardSkills?.length) handleSortingVidoes();
            else {
                setSkillNameError(false);
                setCurrentStep('5');
            }
        }
    };

    return (
        <>
            <div className="skill-edit-btn-group">
                {selectedSkill.length > 0 ? (
                    <>
                        <button className="button-delete" onClick={() => setShowDeletePopUp(true)} disabled={isReplace}>
                            {/* {deleteStatus ? <LoadingSpin /> : ""} */}
                            <img src={DeleteIcon} alt="DeleteIcon"></img>

                            {`${editSpace('delete')} ${selectedSkill.length} ${
                                selectedSkill.length === 1 ? editSpace('video') : editSpace('videos')
                            }`}
                        </button>
                        {selectedSkill.length === 1 && (
                            <button className="button-edit-link ml-15" onClick={replaceHandler}>
                                <img src={ReplaceIcon} alt="Replace"></img> {editSpace('replace_video')}
                            </button>
                        )}
                    </>
                ) : (
                    ''
                )}
            </div>
            <div
                className="upload-video-container"
                style={{
                    justifyContent: cardSkills.length === 0 ? 'center' : 'unset'
                }}
            >
                <DragColumn {...dragPropsVideoUploader} style={{ width: '100%' }}>
                    <div
                        className="video-container"
                        style={{
                            pointerEvents: deleteStatus ? 'none' : 'unset',
                            opacity: deleteStatus ? 0.7 : 1,
                            justifyContent: cardSkills?.length || videoFiles?.length ? 'unset' : 'center'
                        }}
                        ref={videoContainerRef}
                    >
                        {cardSkills.map((item) => {
                            return (
                                <PopUpEditSpaceSkill
                                    itemKey={item.id}
                                    item={item}
                                    editSpace={true}
                                    widgetLink={currentspaceWidget}
                                    selectSkillMethod={selectSkillMethod}
                                    toggleSkillFalse={toggleSkillFalse}
                                    setToggleSkillFalse={setToggleSkillFalse}
                                    skillNameError={skillNameError}
                                    skillName={item.name}
                                    setIsNameUpdated={setIsNameUpdated}
                                    isNameUpdated={isNameUpdated}
                                    cardSkills={cardSkills}
                                    setCardSkills={setCardSkills}
                                />
                            );
                        })}

                        {videoFiles.length > 0 &&
                            videoFiles.map((file) => (
                                <VideoUploader
                                    file={file}
                                    key={file.id}
                                    fileNumber={file.id}
                                    id={currentSpaceId}
                                    DeleteFile={DeleteFile}
                                    skillsLength={cardSkills.length}
                                    videoUploaded={videoUploaded}
                                    setVideoUploaded={setVideoUploaded}
                                    setCardSkills={setCardSkills}
                                    setVideoFiles={setVideoFiles}
                                    editSpace={true}
                                    isReplace={isReplace}
                                    setIsReplace={setIsReplace}
                                    replaceSkillId={selectedSkill[0]}
                                    setScrollToUpload={setScrollToUpload}
                                    setSelectedSkill={setSelectedSkill}
                                    skillNameError={skillNameError}
                                    setEditFlowUploadCheck={setEditFlowUploadCheck}
                                    videoFiles={videoFiles}
                                ></VideoUploader>
                            ))}
                        <div
                            style={{
                                marginRight: cardSkills.length === 0 ? '0px' : '30px'
                            }}
                            className="add-more-card"
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} />
                            <img src={UploadIcon} alt="UploadIcon" />
                            <div className="title">{editSpace('spaces.add_space.add_videos_msg')}</div>
                        </div>
                        <div className="drag-title">{editSpace('spaces.add_space.drag_videos')}</div>

                        {scrollToUpload && <AlwaysScrollToBottom />}
                    </div>
                </DragColumn>
            </div>
            <div className="bottom-wrapper">
                <button
                    disabled={editFlowUploadCheck || (false && skillNameError)}
                    onClick={() => {
                        handleCheckNameForSkills();
                    }}
                >
                    {editSpace('continue')}
                </button>
            </div>

            {showDeletePopUp && (
                <Pulse duration={150}>
                    <div className="delete-popup-container">
                        <div className="delete-popup">
                            <h1>
                                {editSpace('remove')} {selectedSkill.length} {editSpace('videos')}?
                            </h1>
                            <h2>
                                {editSpace('team.remove.remove_msg_one')} <br></br>the {editSpace('videos')}?
                            </h2>
                            <div className="btn-wrapper">
                                <button onClick={() => setShowDeletePopUp(false)}>{editSpace('team.remove.go_back')}</button>
                                <button
                                    onClick={() => {
                                        if (deleteStatus) return;
                                        deleteSkill(0);
                                    }}
                                    disabled={selectedSkill.length === 0}
                                >
                                    {deleteStatus ? <LoadingSpin /> : editSpace('delete')}
                                </button>
                            </div>
                        </div>
                    </div>
                </Pulse>
            )}
        </>
    );
}
