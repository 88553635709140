import React, { useContext, useEffect, useState } from 'react';
import Pulse from 'react-reveal/Pulse';
import { DOMAIN } from '../../constants/base';
import { SettingsContext } from '../../context';
import { getSessionStorage } from '../../utils';
import { getReq } from '../../utils/api';
import EditSpaceSectionHeader from '../EditSpaceSectionHeader';
import LoadingSpin from '../LoadingSpin';
import ManageAgent from '../ManageAgent';
import MapBox from '../Mapbox';
import PublishSpace from '../PublishSpace';
import SpaceDetails from '../SpaceDetails';
import './editspacesection.scss';
export default function EditSpaceSection(props) {
    const {
        currentSection,
        setEditSpaceSectionPopUp,
        currentSpaceId,
        spaceAddress,
        setSpaceAddress,
        spaceType,
        setSpaceType,
        rentalPriceType,
        setRentalPriceType,
        setRentalPriceToShow,
        flat,
        setFlat,
        assigneId,
        setAssigneId,
        price,
        setPrice,
        currency,
        setCurrency,
        bedrooms,
        setBedrooms,
        bathrooms,
        setBathrooms,
        spaceSize,
        setSpaceSize,
        measure,
        setMeasure,
        setShowSpaceDetailsEditPopUp,
        currentSpaceCoordinates,
        agentAssigned,
        setAgentAssigned,
        currentSpace,
        agentCta,
        setOpenEditProductPopUp,
        fetchActiveSpaces,
        setCurrentSection,
        setPairedLink,
        setSpaceCtasUpdated,
        currentUpdatedCta,
        userPermissionType,
        errCurrent,
        errPotential,
        setErrCurrent,
        setErrPotential,
        privateToggle,
        setPrivateToggle,
        isWidgetInstalled,
        pairedLink,
        setNoWidgetInstalled
    } = props;

    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    // step 2
    const [enableFlat, setEnableFlat] = useState(flat ? true : false);
    const [enableRooms, setEnableRooms] = useState(bathrooms || bedrooms ? true : false);
    const [enableSurface, setEnableSurface] = useState(spaceSize ? true : false);
    const [enablePrice, setEnablePrice] = useState(price ? true : false);
    const [spaceLatLng, setSpaceLatLng] = useState({ lat: 42.35, lng: -71.9 });
    const [prevSpaceLatLng, setPrevSpaceLatLng] = useState(null);
    const [nextClickLoader, setNextClickLoader] = useState(false);
    const [spacePoster, setSpacePoster] = useState(null);
    const [enableEnergyRating, setEnableEnergyRating] = useState(errCurrent > 0 || errPotential > 0);

    const [spaceUpdated, setSpaceUpdated] = useState(false);

    const userDetails = getSessionStorage('ss-user-spaces') || {};
    const { role } = userDetails;
    const admin = role === 'ADMIN',
        owner = role === 'OWNER';
    const [globalSettings] = useContext(SettingsContext);
    const member = !(admin || owner);
    const canChangeAssigne = member
        ? globalSettings.memberGlobalSettings?.globalMemberManageTeamPortfolio
            ? true
            : false
        : admin
        ? globalSettings.adminGlobalSettings?.globalAdminManageTeamPortfolio
            ? true
            : false
        : true;

    const currentSpaceType = currentSpace?.priceType === 'SALE' ? 'sale' : 'lettings';
    // agents
    const [availableAgents, setAvailableAgents] = useState([]);

    const [assigneChanged, setAssigneChanged] = useState(false);
    const [runOneTime, setRunOneTime] = useState(true);

    const [availableTemplates, setAvailableTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({
        templateName: 'Agent’s default contact',
        templateId: null,
        ctas: null
    });
    const [currentCta, setCurrentCta] = useState(currentSection !== '3' ? currentUpdatedCta : {});
    const [defaultCtas, setDefaultCtas] = useState([]);
    const [ctaTemplateName, setCtaTemplateName] = useState(null);
    const [prevTemplate, setPrevTemplate] = useState({});
    const [isUpdated, setIsUpdated] = useState(false);
    const [preventUpdates, setPreventUpdates] = useState(false);
    const [isNewTemplate, setIsNewTemplate] = useState(false);
    const [videoFiles, setVideoFiles] = useState(currentSpace.skills.length ? currentSpace.skills : []);
    const [scrollToUpload, setScrollToUpload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newTemplateCtas, setNewTemplateCtas] = useState([]);
    useEffect(() => {
        if (videoFiles.length > 0) {
            setScrollToUpload(true);
        }
    }, [videoFiles]);
    useEffect(() => {
        if (admin || owner || canChangeAssigne) {
            getAvailableAgents();
        }
        setSpacePoster(null);
        // setVideoFiles([]);
    }, []);

    useEffect(() => {
        return () => {
            fetchActiveSpaces();
        };
    }, []);

    useEffect(() => {
        setSpaceLatLng({
            lat: currentSpaceCoordinates[1],
            lng: currentSpaceCoordinates[0]
        });
    }, [currentSpaceCoordinates]);

    useEffect(() => {
        if (availableAgents) updateContactList();
    }, [availableAgents, assigneId]);

    useEffect(() => {
        if (availableTemplates.length && runOneTime) {
            let filtered = availableTemplates.filter((temp) => temp.templateId === agentCta?.templateId);
            filtered.length && setSelectedTemplate(filtered[0]);
            setRunOneTime(false);
        }
    }, [availableTemplates]);

    useEffect(() => {
        if (agentCta) {
            const arr = agentCta;
            let arr2 = [];
            const keys = Object.keys(arr);
            keys.forEach((key) => {
                let x = {};
                x[key] = arr[key];
                arr2 = [...arr2, x];
            });
            setDefaultCtas([...arr2]);
        }
    }, [agentCta]);

    useEffect(() => {
        if (setSpaceCtasUpdated) {
            setSpaceCtasUpdated(currentCta);
        }
    }, [currentCta]);
    useEffect(() => {
        if (availableAgents) updateContactList();
    }, [availableAgents, assigneId]);

    useEffect(() => {
        updateContactList();
        setCtaTemplateName(selectedTemplate?.templateName);
        if (selectedTemplate?.templateName !== '') setPrevTemplate(selectedTemplate);
    }, [selectedTemplate]);
    const updateContactList = () => {
        let selected = availableAgents.find((agent) => agent.agentId === assigneId);
        if (selectedTemplate?.templateId || isNewTemplate) {
            selected = selectedTemplate;
        }
        if (selected?.ctas) {
            const arr = selected?.ctas;
            let arr2 = [];
            const keys = Object.keys(arr);
            keys.forEach((key) => {
                let x = {};
                x[key] = arr[key];
                arr2 = [...arr2, x];
            });
            setDefaultCtas([...arr2]);
        } else {
            if (isNewTemplate) {
                setNewTemplateCtas([]);
            } else {
                setDefaultCtas([]);
            }
        }
    };
    // console.log(agentCta);
    const getAvailableAgents = async () => {
        setLoading(true);
        const url = `${DOMAIN}/v1/dashboard/space/create-space/agents`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };

        const agentsResponse = await getReq({
            url,
            requestHeaders
        });
        const { code, data } = agentsResponse;
        if (code === 200) {
            setAvailableAgents(data.docs?.managingAgent);
            setAvailableTemplates([
                {
                    templateName: 'Agent’s default contact',
                    templateId: null,
                    ctas: null
                },
                ...data.docs?.templates
            ]);
            setLoading(false);
        }
    };
    const handleMarkerClick = () => {
        setCurrentSection('2');
    };
    const handleSpaceAddressEdit = () => {
        setCurrentSection('1');
    };

    return (
        <div className="popups-wrapper">
            <Pulse duration={150}>
                <div className="space-card-wrapper">
                    <EditSpaceSectionHeader
                        currentSection={currentSection}
                        setEditSpaceSectionPopUp={setEditSpaceSectionPopUp}
                        isNewTemplate={isNewTemplate}
                        setCurrentSection={setCurrentSection}
                        setIsNewTemplate={setIsNewTemplate}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        prevTemplate={prevTemplate}
                    ></EditSpaceSectionHeader>

                    {currentSection === '1' && (
                        <MapBox
                            handleMarkerClick={handleMarkerClick}
                            spaceAddress={spaceAddress}
                            setSpaceAddress={setSpaceAddress}
                            setSpaceLatLng={setSpaceLatLng}
                            spaceLatLng={spaceLatLng}
                            setPrevSpaceLatLng={setPrevSpaceLatLng}
                            spaceUpdated={spaceUpdated}
                            setSpaceUpdated={setSpaceUpdated}
                        ></MapBox>
                    )}
                    {currentSection === '2' && (
                        <SpaceDetails
                            spaceAddress={spaceAddress}
                            spaceLatLng={spaceLatLng}
                            prevSpaceLatLng={prevSpaceLatLng}
                            handleSpaceAddressEdit={handleSpaceAddressEdit}
                            enableFlat={enableFlat}
                            setEnableFlat={setEnableFlat}
                            flatNumber={flat}
                            setFlatNumber={setFlat}
                            enablePrice={enablePrice}
                            setEnablePrice={setEnablePrice}
                            price={price}
                            setPrice={setPrice}
                            setSpaceUpdated={setSpaceUpdated}
                            spaceType={spaceType}
                            setSpaceType={setSpaceType}
                            rentalPriceType={rentalPriceType}
                            setRentalPriceType={setRentalPriceType}
                            setRentalPriceToShow={setRentalPriceToShow}
                            currency={currency}
                            setCurrency={setCurrency}
                            enableRooms={enableRooms}
                            setEnableRooms={setEnableRooms}
                            bedrooms={bedrooms}
                            setBedrooms={setBedrooms}
                            bathrooms={bathrooms}
                            setBathrooms={setBathrooms}
                            enableSurface={enableSurface}
                            setEnableSurface={setEnableSurface}
                            spaceId={currentSpaceId}
                            measure={measure}
                            setMeasure={setMeasure}
                            spaceSize={spaceSize}
                            setSpaceSize={setSpaceSize}
                            isSectionEdit={true}
                            setEditSpaceSectionPopUp={setEditSpaceSectionPopUp}
                            nextClickLoader={nextClickLoader}
                            setNextClickLoader={setNextClickLoader}
                            editSpace={true}
                            setSpaceCtasUpdated={setSpaceCtasUpdated}
                            currentCta={currentCta}
                            userPermissionType={userPermissionType}
                            errCurrent={errCurrent}
                            setErrCurrent={setErrCurrent}
                            errPotential={errPotential}
                            setErrPotential={setErrPotential}
                            setEnableEnergyRating={setEnableEnergyRating}
                            enableEnergyRating={enableEnergyRating}
                        ></SpaceDetails>
                    )}

                    {(currentSection === '3' || currentSection === '6') &&
                        (loading ? (
                            <div className="loading-container">
                                <LoadingSpin></LoadingSpin>
                            </div>
                        ) : (
                            <ManageAgent
                                canChangeAssigne={canChangeAssigne}
                                prevTemplate={prevTemplate}
                                availableAgents={availableAgents}
                                availableTemplates={availableTemplates}
                                setAvailableTemplates={setAvailableTemplates}
                                setSpaceUpdated={setSpaceUpdated}
                                setCurrentStep={setCurrentSection}
                                agentAssigned={agentAssigned}
                                setAssigneId={setAssigneId}
                                selectedTemplate={selectedTemplate}
                                setSelectedTemplate={setSelectedTemplate}
                                assigneChanged={assigneChanged}
                                setAssigneChanged={setAssigneChanged}
                                setAgentAssigned={setAgentAssigned}
                                assigneId={assigneId}
                                defaultCtas={isNewTemplate ? newTemplateCtas : defaultCtas}
                                setIsNewTemplate={setIsNewTemplate}
                                currentCta={isNewTemplate ? {} : currentCta}
                                setCurrentCta={setCurrentCta}
                                spaceId={currentSpaceId}
                                isUpdated={isUpdated}
                                setIsUpdated={setIsUpdated}
                                ctaTemplateName={ctaTemplateName}
                                setCtaTemplateName={setCtaTemplateName}
                                isNewTemplate={isNewTemplate}
                                currentStep={currentSection}
                                preventUpdates={preventUpdates}
                                setPreventUpdates={setPreventUpdates}
                                isSectionEdit={true}
                                setEditSpaceSectionPopUp={setEditSpaceSectionPopUp}
                                scrollToUpload={scrollToUpload}
                                // newTemplateCtas={newTemplateCtas}
                            ></ManageAgent>
                        ))}

                    {currentSection === '5' && (
                        <PublishSpace
                            spacePoster={currentSpace.skills?.length ? currentSpace.skills[0].poster : spacePoster}
                            videoFiles={currentSpace.skills?.length ? currentSpace.skills : []}
                            setCurrentStep={setCurrentSection}
                            spaceId={currentSpaceId}
                            showPopupItem={setShowSpaceDetailsEditPopUp}
                            setOpenEditProductPopUp={setOpenEditProductPopUp}
                            fetchActiveSpaces={fetchActiveSpaces}
                            directLink={currentSpace.widgetLink}
                            privateSpace={privateToggle}
                            setPrivateSpace={setPrivateToggle}
                            webUrl={pairedLink ? pairedLink : currentSpace.spaceURL}
                            isEdit={true}
                            isSectionEdit={true}
                            setEditSpaceSectionPopUp={setEditSpaceSectionPopUp}
                            setPairedLink={setPairedLink}
                            setSpaceCtasUpdated={setSpaceCtasUpdated}
                            currentCta={currentCta}
                            spaceAgentId={currentSpace.agentId}
                            isWidgetInstalled={isWidgetInstalled}
                            setNoWidgetInstalled={setNoWidgetInstalled}
                        ></PublishSpace>
                    )}
                </div>
            </Pulse>
        </div>
    );
}
