import React, { useState, useEffect, useContext } from 'react';
import './navBar.scss';
import { Redirect, Link } from 'react-router-dom';
import { isEmpty } from '../../utils';
import Logo from '../../components/Logo';
import { getSessionStorage } from '../../utils';

import PortfolioDeselected from '../../assets/ListingsPassive.png';
import PortfolioSelected from '../../assets/ListingsActive.png';
import TeamDeselected from '../../assets/TeamPassive.png';
import TeamSelected from '../../assets/TeamActive.png';
import settingsDeselected from '../../assets/SettingsPassive.png';
import settingsSelected from '../../assets/SettingsActive.png';
import { InfoBox } from '../../components/StatusMsg';

import statsSelected from '../../assets/StatsActive.png';
import statsDeselected from '../../assets/StatsPassive.png';
import { useTranslation } from 'react-i18next';
import { GlobalProfile } from '../../context';

function NavBar(props) {
    const { selected } = props;
    const [shouldRedirect, setRedirect] = useState(false);
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const userDetails = getSessionStorage('ss-user-spaces') || {};
    const { name = '', email } = userDetails;
    const [statusMsg, setStatusMsg] = useState({ msg: null, type: null });
    const [globalProfile] = useContext(GlobalProfile);
    const { t: navbar } = useTranslation();
    let disableLink =
        globalProfile.globalProfilePlan !== 'TRIAL' &&
        globalProfile.globalProfilePlan !== 'ENTERPRISE' &&
        globalProfile.globalProfilePlan !== 'BUSINESS TRIAL' &&
        globalProfile.globalProfilePlan !== 'BUSINESS PAID'
            ? true
            : false;

    useEffect(() => {
        if (isEmpty(userToken)) {
            setRedirect(true);
        }
        // fetchWidgetStatus();
        window.Intercom('boot', {
            app_id: process.env.REACT_APP_INTERCOM_KEY,
            name: name, // Full name
            email: email, // Email address
            created_at: new Date(), // Signup date as a Unix timestamp
            Entity: 'Space',
            plan: userDetails.currentPlan,
            custom_launcher_selector: '#holofy_intercom'
        });
    }, []);

    useEffect(() => {
        if (statusMsg) {
            setTimeout(() => {
                setStatusMsg(null);
            }, 5000);
        }
    }, [statusMsg]);

    if (shouldRedirect) {
        return <Redirect to="/signin" />;
    }

    const handleNavLink = (e) => {
        if (disableLink) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const showSettings = () => {
        if (globalProfile?.role === 'OWNER') return true;
        if (globalProfile?.role === 'ADMIN') return true;
        else return false;
    };
    return (
        <>
            {statusMsg && <InfoBox type={statusMsg.type} message={statusMsg.msg} />}
            <div className="d-flex h-center f-col pos-fix navbar-container">
                <div className="navbar-new d-flex center-align f-col bg-white b-shadow">
                    <Link className={selected === 'mySpaces' ? 'nav-item cur-pointer selected' : 'nav-item cur-pointer'} to="/mySpaces">
                        <Logo logo={selected === 'mySpaces' ? PortfolioSelected : PortfolioDeselected} width={24} height={24} />
                        <div className="nav-text mt-5">{navbar('navbar.spaces')}</div>
                    </Link>
                    <Link
                        className={
                            disableLink
                                ? 'nav-item disable-nav-item'
                                : selected === 'myTeam'
                                ? 'nav-item cur-pointer selected'
                                : 'nav-item cur-pointer'
                        }
                        to={'/myTeam'}
                        onClick={handleNavLink}
                    >
                        <Logo logo={selected === 'myTeam' ? TeamSelected : TeamDeselected} width={24} height={24} />
                        <div className="nav-text mt-5">{navbar('navbar.team')}</div>
                    </Link>

                    {/* Stats navigation start */}
                    <Link
                        className={
                            disableLink
                                ? 'nav-item disable-nav-item'
                                : selected === 'myStats'
                                ? 'nav-item cur-pointer selected'
                                : 'nav-item cur-pointer'
                        }
                        to={'/myStats'}
                        onClick={handleNavLink}
                    >
                        <Logo logo={selected === 'myStats' ? statsSelected : statsDeselected} width={24} height={24} />
                        <div className="nav-text mt-5">{navbar('navbar.stats')}</div>
                    </Link>
                    {/* Stats navigation end */}
                    {showSettings() ? (
                        <Link
                            className={
                                disableLink
                                    ? 'nav-item disable-nav-item'
                                    : selected === 'mySettings'
                                    ? 'nav-item cur-pointer selected'
                                    : 'nav-item cur-pointer'
                            }
                            onClick={handleNavLink}
                            to={'/mySettings'}
                        >
                            <Logo logo={selected === 'mySettings' ? settingsSelected : settingsDeselected} width={24} height={24} />
                            <div className="nav-text mt-5">{navbar('navbar.settings')}</div>
                        </Link>
                    ) : null}
                </div>
            </div>
        </>
    );
}

export default NavBar;
