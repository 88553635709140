import { createContext } from 'react';

export const SignupContext = createContext([{}, () => {}]);
export const ResetContext = createContext([{}, () => {}]);
// Edit Member Context
export const EditMemberContext = createContext([{}, () => {}]);
// Current Product Selected Context
export const currentSpaceContext = createContext([{}, () => {}]);
// Global Profile Context
export const GlobalProfile = createContext([{}, () => {}]);
// Stats Context
export const StatsContext = createContext([{}, () => {}]);
// Settings Context
export const SettingsContext = createContext([{}, () => {}]);
// cta context
export const CTAContext = createContext([{}, () => {}]);
