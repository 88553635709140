/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import WidgetSettings from '../../components/WidgetSettings';
import TeamSettings from '../../components/TeamSettings';
import GeneralSettings from '../../components/GeneralSettings';
import { getSessionStorage } from '../../utils';
import { getReq, putReq } from '../../utils/api';
import { isEmpty } from '../../utils';
import { DOMAIN } from '../../constants/base';
import './mySettings.scss';
import { Fade } from 'react-reveal';
import { Accordion } from 'react-accessible-accordion';
import { SettingsContext } from '../../context';
import { toast } from 'react-toastify';
//Assets

import BlockerComponent from '../../components/BlockerComponent';
import { useTranslation } from 'react-i18next';

//Core component functionality
function MySettings() {
    const [globalSettings] = useContext(SettingsContext);
    const [embedCode, setEmbedCode] = useState([]);
    const [isEmbedCodeLoading, setEmbedCodeLoading] = useState(true);
    const [isCodeCopied, setCodeCopied] = useState(false);

    const [shouldRedirect, setShouldRedirect] = useState(false);
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const userDetails = getSessionStorage('ss-user-spaces') || {};
    const { role } = userDetails;
    const admin = role === 'ADMIN',
        owner = role === 'OWNER';
    const [widgetSettings, setWidgetSettings] = useState({
        address: true,
        position: false,
        price: true,
        view: true,
        bottomPX: 70,
        sidePX: 10,
        widgetSize: 's'
    });
    // states for differenet settings
    // Widget settings states
    const [contextualWidgetDesktopSettings, setContextualWidgetDesktopSettings] = useState({});
    const [contextualWidgetMobileSettings, setContextualWidgetMobileSettings] = useState({});
    const [fullScreenSettings, setFullScreenSettings] = useState({});
    const [homePageWidgetDesktopSettings, setHomePageWidgetDesktopSettings] = useState({});
    const [homePageWidgetMobileSettings, setHomePageWidgetMobileSettings] = useState({});
    const [generalSettingsState, setGeneralSettingsState] = useState({});
    const [isAdminState, setIsAdminState] = useState(owner);
    const [teamSettingsState, setTeamSettingsState] = useState({});
    const [teamSettingsFlag, setTeamSettingsFlag] = useState(false);
    const [leadRegistrationFlag, setLeadRegistrationFlag] = useState(false);
    const [leadRegistrationSettings, setLeadRegistrationSettings] = useState({});
    // const [generalSettingsFlag, setGeneralSettingsFlag] = useState(false);
    // states for widgetStatus
    const [contextualWidgetFlag, setContextualWidgetFlag] = useState(false);
    const [homepageWidgetFlag, setHomepageWidgetFlag] = useState(false);

    //settings navigations
    const [settingsNav, setSettingsNav] = useState('widget');

    const isMountedRef = React.useRef(null);

    const { t: settings } = useTranslation();

    // loading screen
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (leadRegistrationFlag) {
            updateLeadRegistrationSettings(leadRegistrationSettings);
        }
    }, [leadRegistrationSettings]);
    useEffect(() => {
        if (contextualWidgetFlag) {
            updateContextualWidgetSettings(false, contextualWidgetDesktopSettings);
        }
    }, [contextualWidgetDesktopSettings]);

    useEffect(() => {
        if (contextualWidgetFlag) {
            updateContextualWidgetSettings(true, contextualWidgetMobileSettings);
        }
    }, [contextualWidgetMobileSettings]);

    useEffect(() => {
        if (homepageWidgetFlag) {
            updateHomePageWidgetSettings(false, homePageWidgetDesktopSettings);
        }
    }, [homePageWidgetDesktopSettings]);

    useEffect(() => {
        if (homepageWidgetFlag) {
            updateHomePageWidgetSettings(true, homePageWidgetMobileSettings);
        }
    }, [homePageWidgetMobileSettings]);

    useEffect(() => {
        if (teamSettingsFlag) updateTeamSettings();
    }, [teamSettingsState]);

    //Redirect if no userfound
    useEffect(() => {
        isMountedRef.current = true;
        if (isEmpty(userToken)) {
            setShouldRedirect(true);
        }
        fetchtEmbedCodeData();
        fetchTVProperties();
        return () => (isMountedRef.current = false);
    }, []);

    if (shouldRedirect) {
        return <Redirect to="/signin" />;
    }
    //Fetching code widget data
    async function fetchtEmbedCodeData() {
        const url = `${DOMAIN}/v1/dashboard/space/user/embed-code-space`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const embedCodeResponse = await getReq({
            url,
            requestHeaders
        });
        const { code, data } = embedCodeResponse;
        if (code === 200 && isMountedRef.current) {
            setEmbedCode(data.result);
            setEmbedCodeLoading(false);
            formatCode();
        }
    }

    // fetching tv properties
    async function fetchTVProperties(isFirst = false) {
        setShowLoader(true);
        const url = `${DOMAIN}/v1/dashboard/account/widget-settings`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const statusResponse = await getReq(
            {
                url,
                requestHeaders
            },
            setShowLoader
        );
        const { code, data } = statusResponse;
        if (code === 200 && isMountedRef.current && data) {
            if (data.desktop) setContextualWidgetDesktopSettings(data.desktop);
            if (data.mobile) setContextualWidgetMobileSettings(data.mobile);
            if (data.fullScreen) setFullScreenSettings(data.fullScreen);
            if (data.homePage && data.homePage.mobile) setHomePageWidgetMobileSettings(data.homePage.mobile);
            if (data.homePage && data.homePage.desktop) setHomePageWidgetDesktopSettings(data.homePage.desktop);
            if (data.generalSettings) setGeneralSettingsState(data.generalSettings);
            if (data.teamSettings) setTeamSettingsState(data.teamSettings);
            if (data.leadRegistration) setLeadRegistrationSettings(data.leadRegistration);
            setShowLoader(false);
        }
    }

    // Handling Number Inputs And Limiting To 3 digits
    const handleNumberChangeBottom = (e, isMobile, isContextual) => {
        // if (e.target.value.length) {
        //     setOpacityHanlderBottom(true);
        // } else {
        //     setOpacityHanlderBottom(false);
        // }
        if (isMobile && isContextual) {
            setContextualWidgetMobileSettings({
                ...contextualWidgetMobileSettings,
                bottomPX: parseInt(
                    e.target.value.length <= 2
                        ? !isNaN(e.target.value)
                            ? e.target.value === ''
                                ? 0
                                : e.target.value
                            : contextualWidgetMobileSettings.bottomPX
                        : contextualWidgetMobileSettings.bottomPX
                )
            });
        } else if (!isMobile && isContextual) {
            setContextualWidgetDesktopSettings({
                ...contextualWidgetDesktopSettings,
                bottomPX: parseInt(
                    e.target.value.length <= 2
                        ? !isNaN(e.target.value)
                            ? e.target.value === ''
                                ? 0
                                : e.target.value
                            : contextualWidgetDesktopSettings.bottomPX
                        : contextualWidgetDesktopSettings.bottomPX
                )
            });
        } else if (isMobile && !isContextual) {
            if (homePageWidgetMobileSettings.widgetSize === 'l' && homePageWidgetMobileSettings.stickyPosition) {
                setHomePageWidgetMobileSettings({
                    ...homePageWidgetMobileSettings,
                    bottomPX: parseInt(
                        e.target.value.length <= 2
                            ? !isNaN(e.target.value)
                                ? e.target.value === ''
                                    ? 0
                                    : e.target.value
                                : homePageWidgetMobileSettings.bottomPX
                            : homePageWidgetMobileSettings.bottomPX
                    )
                });
            } else {
                setHomePageWidgetMobileSettings({
                    ...homePageWidgetMobileSettings,
                    bottomPX: parseInt(
                        e.target.value.length <= 2
                            ? !isNaN(e.target.value)
                                ? e.target.value === ''
                                    ? 0
                                    : e.target.value
                                : homePageWidgetMobileSettings.bottomPX
                            : homePageWidgetMobileSettings.bottomPX
                    )
                });
            }
        } else if (!isMobile && !isContextual) {
            if (homePageWidgetDesktopSettings.widgetSize === 'l' && homePageWidgetDesktopSettings.stickyPosition) {
                setHomePageWidgetDesktopSettings({
                    ...homePageWidgetDesktopSettings,
                    bottomPX: parseInt(
                        e.target.value.length <= 2
                            ? !isNaN(e.target.value)
                                ? e.target.value === ''
                                    ? 0
                                    : e.target.value
                                : homePageWidgetDesktopSettings.bottomPX
                            : homePageWidgetDesktopSettings.bottomPX
                    )
                });
            } else if (homePageWidgetDesktopSettings.widgetSize === 'l' && !homePageWidgetDesktopSettings.stickyPosition) {
                setHomePageWidgetDesktopSettings({
                    ...homePageWidgetDesktopSettings,
                    bottomPX: parseInt(
                        e.target.value.length <= 2
                            ? !isNaN(e.target.value)
                                ? e.target.value === ''
                                    ? 0
                                    : e.target.value
                                : homePageWidgetDesktopSettings.bottomPX
                            : homePageWidgetDesktopSettings.bottomPX
                    )
                });
            } else {
                setHomePageWidgetDesktopSettings({
                    ...homePageWidgetDesktopSettings,
                    bottomPX: parseInt(
                        e.target.value.length <= 2
                            ? !isNaN(e.target.value)
                                ? e.target.value === ''
                                    ? 0
                                    : e.target.value
                                : homePageWidgetDesktopSettings.bottomPX
                            : homePageWidgetDesktopSettings.bottomPX
                    )
                });
            }
        }
    };
    const handleNumberChangeSide = (e, isMobile, isContextual) => {
        // if (e.target.value.length) {
        //     setOpacityHanlderSide(true);
        // } else {
        //     setOpacityHanlderSide(false);
        // }
        if (isMobile && isContextual) {
            setContextualWidgetMobileSettings({
                ...contextualWidgetMobileSettings,
                sidePX: parseInt(
                    e.target.value.length <= 2
                        ? !isNaN(e.target.value)
                            ? e.target.value === ''
                                ? 0
                                : e.target.value
                            : contextualWidgetMobileSettings.sidePX
                        : contextualWidgetMobileSettings.sidePX
                )
            });
        } else if (!isMobile && isContextual) {
            setContextualWidgetDesktopSettings({
                ...contextualWidgetDesktopSettings,
                sidePX: parseInt(
                    e.target.value.length <= 2
                        ? !isNaN(e.target.value)
                            ? e.target.value === ''
                                ? 0
                                : e.target.value
                            : contextualWidgetDesktopSettings.sidePX
                        : contextualWidgetDesktopSettings.sidePX
                )
            });
        } else if (isMobile && !isContextual) {
            setHomePageWidgetMobileSettings({
                ...homePageWidgetMobileSettings,
                sidePX: parseInt(
                    e.target.value.length <= 2
                        ? !isNaN(e.target.value)
                            ? e.target.value === ''
                                ? 0
                                : e.target.value
                            : homePageWidgetMobileSettings.bottomPX
                        : homePageWidgetMobileSettings.bottomPX
                )
            });
        } else if (!isMobile && !isContextual) {
            setHomePageWidgetDesktopSettings({
                ...homePageWidgetDesktopSettings,
                sidePX: parseInt(
                    e.target.value.length <= 2
                        ? !isNaN(e.target.value)
                            ? e.target.value === ''
                                ? 0
                                : e.target.value
                            : homePageWidgetDesktopSettings.bottomPX
                        : homePageWidgetDesktopSettings.bottomPX
                )
            });
        }
    };

    //Formatting the fetched response
    const formatCode = () => {
        let pre = document.getElementsByTagName('pre');
        let preLength = pre.length;
        for (let i = 0; i < preLength; i++) {
            pre[i].innerHTML = '<span class="line-number"></span>' + pre[i].innerHTML + '<span class="cl"></span>';
            let num = pre[i].innerHTML.split(/\n/).length;
            for (let j = 0; j < num; j++) {
                let lineNumber = pre[i].getElementsByTagName('span')[0];
                lineNumber.innerHTML += '<span>' + (j + 1) + '</span>';
            }
        }
    };
    //Copying the code
    const doCopyCode = (e) => {
        navigator.clipboard.writeText(embedCode).then(
            function () {
                toast.success('Link copied to clipboard', {
                    position: 'top-center',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
                setCodeCopied(true);
                setTimeout(() => {
                    setCodeCopied(false);
                }, 3000);
            },
            function (err) {
                toast.error('Could not copy text', {
                    position: 'top-center',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
            }
        );
    };

    const handleWidgetSettings = (pos, val) => {
        let newWidgetSettings = {
            ...widgetSettings
        };
        // const oldWidgetSettings = {
        //     ...widgetSettings
        // };
        switch (pos) {
            case 'position':
                const newPosition = val === 'left' ? true : false;
                newWidgetSettings.position = newPosition;
                break;
            default:
                newWidgetSettings[pos] = !newWidgetSettings[pos];
                break;
        }
        setWidgetSettings(newWidgetSettings);
    };

    const navigateSettings = (nav) => {
        if (nav === 'widget') setSettingsNav('widget');
        else if (nav === 'team') setSettingsNav('team');
        else if (nav === 'general') setSettingsNav('general');
    };

    const updateLeadRegistrationSettings = async (payloadObj) => {
        const url = `${DOMAIN}/v1/dashboard/account/widget-settings/lead-registration`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        let payload = {};
        if (payloadObj.viaEmail) {
            payload = {
                viaEmail: true
            };
        } else {
            payload = {
                viaEmail: false
            };
        }
        const statusResponse = await putReq({
            url,
            payload,
            requestHeaders
        });
        const { code } = statusResponse;
        if (code === 200) {
            setLeadRegistrationFlag(false);
            return true;
        }
        return false;
    };
    /**
     * 
     * @param {boolean} isMobile 
     * @param {object} payloadObj : {
        widgetSize: { type: 'string', enum: ['s', 'm', 'l'] },
        showViews: { type: 'boolean' },
        showLabel: { type: 'boolean' },
        allowMinimisation: { type: 'boolean' },
        position: { type: 'boolean' },
        bottomPX: { type: 'integer' },
        sidePX: { type: 'integer' },
        isMobile: { type: 'boolean' },}
     * @returns 
     */
    const updateContextualWidgetSettings = async (isMobile = false, payloadObj) => {
        const url = `${DOMAIN}/v1/dashboard/account/widget-settings/contextual`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        let payload = payloadObj;
        if (isMobile) {
            setContextualWidgetMobileSettings(payloadObj);
            payload = {
                ...payload,
                isMobile: true
            };
        } else {
            setContextualWidgetDesktopSettings(payloadObj);
            payload = {
                ...payload,
                isMobile: false
            };
        }

        const statusResponse = await putReq({
            url,
            payload,
            requestHeaders
        });
        const { code } = statusResponse;
        if (code === 200) {
            setContextualWidgetFlag(false);
            return true;
        }
        return false;
    };

    //method to update general settings
    const updateGeneralSettings = async (payloadObj) => {
        const url = `${DOMAIN}/v1/dashboard/account/widget-settings/general`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        let payload = payloadObj;
        const statusResponse = await putReq({
            url,
            payload,
            requestHeaders
        });
        const { code } = statusResponse;
        if (code === 200) {
            // setGeneralSettingsFlag(false);
            return true;
        }
        return false;
    };

    const updateTeamSettings = async () => {
        let payloadObj = {};
        if (isAdminState) {
            payloadObj = {
                ...teamSettingsState.admin,
                isAdmin: isAdminState
            };
        } else {
            payloadObj = {
                ...teamSettingsState.member,
                isAdmin: isAdminState
            };
        }
        const url = `${DOMAIN}/v1/dashboard/account/widget-settings/team`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        let payload = payloadObj;
        const statusResponse = await putReq({
            url,
            payload,
            requestHeaders
        });
        const { code } = statusResponse;
        if (code === 200) {
            setTeamSettingsFlag(false);
            return true;
        }
        return false;
    };

    /**
     * @param {object} payloadObj : {
       crossSellingExperience: { type: 'boolean' },
       showAddress: { type: 'boolean' },
       showPrice: { type: 'boolean' },
       showDetails: { type: 'boolean' },
      }
     * @returns 
     */
    const updateFullScreeenSettings = async (payloadObj) => {
        const url = `${DOMAIN}/v1/dashboard/account/widget-settings/fullScreen`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        let payload = payloadObj;
        const statusResponse = await putReq({
            url,
            payload,
            requestHeaders
        });
        const { code } = statusResponse;
        if (code === 200) {
            return true;
        }
        return false;
    };

    /**
     * 
     * @param {boolean} isMobile 
     * @param {object} payloadObj : {
        widgetSize: { type: 'string', enum: ['s', 'm', 'l'] },
        showLabel: { type: 'boolean' },
        allowMinimisation: { type: 'boolean' },
        showFilters: { type: 'boolean' },
        videoBackgroundUrl: { type: 'string' },
        webPageUrl: {type: 'string'}
        isMobile: { type: 'boolean' },}
     * @returns 
     */
    const updateHomePageWidgetSettings = async (isMobile, payloadObj) => {
        const url = `${DOMAIN}/v1/dashboard/account/widget-settings/homepage`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        let payload = payloadObj;
        if (isMobile) {
            setHomePageWidgetMobileSettings(payloadObj);
            payload = {
                ...payload,
                isMobile: true
            };
        } else {
            setHomePageWidgetDesktopSettings(payloadObj);
            payload = {
                ...payload,
                isMobile: false
            };
        }

        const statusResponse = await putReq({
            url,
            payload,
            requestHeaders
        });
        const { code } = statusResponse;
        if (code === 200) {
            setHomepageWidgetFlag(false);
            return true;
        }
        return false;
    };

    const { adminGlobalSettings } = globalSettings;
    //widget conditions
    const widgetCustomize = () => {
        if (owner) return true;
        else if (admin && adminGlobalSettings?.globalAdminWidgetCustomisation) return true;
        else return false;
    };
    const editPermission = () => {
        if (owner) return true;
        else if (admin && adminGlobalSettings?.globalAdminEditAccountPermissions) return true;
        else return false;
    };
    const generalsettingsCheck = () => {
        if (owner) return true;
        else if (admin && adminGlobalSettings?.globalAdminGeneralSettings) return true;
        else return false;
        // return false;
    };

    return (
        <>
            {userDetails.currentPlan === 'BASIC TRIAL' ||
            userDetails.currentPlan === 'PRO TRIAL' ||
            userDetails.currentPlan === 'BASIC PAID' ||
            userDetails.currentPlan === 'PRO PAID' ? (
                <BlockerComponent></BlockerComponent>
            ) : (
                <div className="d-flex main-dash-container">
                    <NavBar selected="mySettings" />
                    {!showLoader && (
                        <div className="w-100p mt-20 ml-20 mr-20 content-width">
                            <Fade duration={200}>
                                <div className="d-flex f-col space-between mb-5">
                                    <div className="products-heading d-flex f-row space-between center-align fs-18 fw-900 h-60 mb-20">
                                        <h1>{settings('settings.title')}</h1>
                                    </div>
                                    <div className="d-flex publish-heading-container">
                                        <h4
                                            onClick={() => navigateSettings('widget')}
                                            style={{
                                                color: settingsNav === 'widget' ? '#000' : '#0000003D',
                                                fontWeight: settingsNav === 'widget' ? 800 : 700
                                            }}
                                            className="mr-20"
                                        >
                                            {settings('settings.widget.title')}
                                        </h4>
                                        {editPermission() && (
                                            <h4
                                                onClick={() => navigateSettings('team')}
                                                style={{
                                                    color: settingsNav === 'team' ? '#000' : '#0000003D',
                                                    fontWeight: settingsNav === 'team' ? 800 : 700
                                                }}
                                                className="mr-20"
                                            >
                                                {settings('settings.team.title')}
                                            </h4>
                                        )}
                                        {generalsettingsCheck() && (
                                            <h4
                                                onClick={() => navigateSettings('general')}
                                                style={{
                                                    color: settingsNav === 'general' ? '#000' : '#0000003D',
                                                    fontWeight: settingsNav === 'general' ? 800 : 700
                                                }}
                                            >
                                                {settings('settings.general.title')}
                                            </h4>
                                        )}
                                    </div>
                                </div>
                                <div className="w-100p settings-mega-container h-100p ovf-y-scroll-show">
                                    {settingsNav === 'widget' ? (
                                        <Accordion preExpanded={[1]} allowZeroExpanded>
                                            <WidgetSettings
                                                isEmbedCodeLoading={isEmbedCodeLoading}
                                                embedCode={embedCode}
                                                doCopyCode={doCopyCode}
                                                isCodeCopied={isCodeCopied}
                                                contextualWidgetDesktopSettings={contextualWidgetDesktopSettings}
                                                setContextualWidgetDesktopSettings={setContextualWidgetDesktopSettings}
                                                contextualWidgetMobileSettings={contextualWidgetMobileSettings}
                                                setContextualWidgetMobileSettings={setContextualWidgetMobileSettings}
                                                setContextualWidgetFlag={setContextualWidgetFlag}
                                                fullScreenSettings={fullScreenSettings}
                                                setFullScreenSettings={setFullScreenSettings}
                                                updateFullScreeenSettings={updateFullScreeenSettings}
                                                homePageWidgetDesktopSettings={homePageWidgetDesktopSettings}
                                                setHomePageWidgetDesktopSettings={setHomePageWidgetDesktopSettings}
                                                homePageWidgetMobileSettings={homePageWidgetMobileSettings}
                                                setHomePageWidgetMobileSettings={setHomePageWidgetMobileSettings}
                                                setHomepageWidgetFlag={setHomepageWidgetFlag}
                                                handleWidgetSettings={handleWidgetSettings}
                                                handleNumberChangeSide={handleNumberChangeSide}
                                                handleNumberChangeBottom={handleNumberChangeBottom}
                                                widgetCustomize={widgetCustomize}
                                                setLeadRegistrationFlag={setLeadRegistrationFlag}
                                                leadRegistrationSettings={leadRegistrationSettings}
                                                setLeadRegistrationSettings={setLeadRegistrationSettings}
                                            />
                                        </Accordion>
                                    ) : null}
                                    {settingsNav === 'team' ? (
                                        <TeamSettings
                                            teamSettingsState={teamSettingsState}
                                            setTeamSettingsState={setTeamSettingsState}
                                            isAdminState={isAdminState}
                                            setIsAdminState={setIsAdminState}
                                            updateTeamSettings={updateTeamSettings}
                                            setTeamSettingsFlag={setTeamSettingsFlag}
                                            userDetails={userDetails}
                                        />
                                    ) : null}
                                    {settingsNav === 'general' ? (
                                        <GeneralSettings
                                            generalSettingsState={generalSettingsState}
                                            setGeneralSettingsState={setGeneralSettingsState}
                                            // setGeneralSettingsFlag={setGeneralSettingsFlag}
                                            updateGeneralSettings={updateGeneralSettings}
                                        />
                                    ) : null}
                                </div>
                            </Fade>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default MySettings;
