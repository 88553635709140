import React from "react";
import Pulse from "react-reveal/Pulse";
import "./popupcardnotification.scss";
import ClickAwayContentWrapper from "../../components/ClickAwayContentWrapper";

// Assets
import BackImg from "../../assets/back.png";

const PopUpCardNotification = ({
    setSpaceLimitNotification
}) => {

  const openIntercom = () => {
    setSpaceLimitNotification(false);
  }

  return (
    <div className="popups-wrapper">
      <ClickAwayContentWrapper onClose={() => setSpaceLimitNotification(false)}>
        <Pulse duration={150}>
            <div className="form-card-wrapper">
                <div className="d-flex w-100p">
                    <button
                        className="back-button"
                        onClick={() => setSpaceLimitNotification(false)}
                    >
                        <img src={BackImg} alt="back"></img>
                    </button>
                </div>
                <div className="">
                    <h1 className="popup-heading text-center">You have reached your monthly Plan Limit of active Spaces.</h1>
                    <p className="fs-12 text-center mt-20 fw-600 c-gray">
                        Your plan allows you and your team to have a maximum of 50 active spaces. To publish
                        more spaces either unpublish existing ones or upgrade to an enterprise plan.
                    </p>
                    <p id="holofy_intercom" onClick={openIntercom} className="fs-12 c-dblue cur-pointer mt-20 text-center w-100p fw-700"> I WANT AN ENTERPRISE PLAN</p>
                </div>
            </div>
        </Pulse>
      </ClickAwayContentWrapper>
    </div>
  );
};

export default PopUpCardNotification;
