import React, { useContext, useState } from 'react';
import './popupremovemember.scss';
import { Redirect } from 'react-router-dom';
import { DOMAIN } from '../../constants/base';
import { postReq } from '../../utils/api';
import { getSessionStorage, removeSessionStorage } from '../../utils';
import Avatar from 'react-avatar';
import { EditMemberContext } from '../../context';
import ClickAwayContentWrapper from '../../components/ClickAwayContentWrapper';
import Pulse from 'react-reveal/Pulse';
import CheckboxTick from '../../assets/checkbox-tick.svg';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const PopUpRemoveMember = ({ setRemoveMemberPopUp, fetchActiveMembers, isUserProfileEdit, setShowEditPopUp }) => {
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const userDetails = getSessionStorage('ss-user-spaces') || {};
    const [currentMember] = useContext(EditMemberContext);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const { role, avatar, name } = userDetails;
    const admin = role === 'ADMIN',
        owner = role === 'OWNER';
    const { t: remove } = useTranslation();
    const [isRemoving, setIsRemoving] = useState(false);

    const removeMemberHandler = async () => {
        setIsRemoving(true);
        const url = `${DOMAIN}/v1/dashboard/space/user/${isUserProfileEdit ? 'delete' : 'remove-member'}`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const memberPayload = {
            id: currentMember.currentEditMemberId,
            invitee: currentMember.currentEditMemberInvitee
        };
        const userPayload = {
            entity: 'space'
        };
        const editResponse = await postReq(
            {
                url,
                payload: isUserProfileEdit ? userPayload : memberPayload,
                requestHeaders
            },
            setIsRemoving
        );
        const { code } = editResponse;
        if (code === 200) {
            setIsRemoving(false);
            if (isUserProfileEdit) {
                localStorage.removeItem('ss-auth-spaces');
                localStorage.setItem('ss-lang', 'en');
                removeSessionStorage('ss-user-spaces');
                setShouldRedirect(true);
            } else {
                setRemoveMemberPopUp(false);
                fetchActiveMembers();
                toast.error('The member has been removed', {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
            }
            setShowEditPopUp(false);
        }
    };

    if (shouldRedirect) {
        return <Redirect to="/"></Redirect>;
    }

    return (
        <div className="popups-wrapper" style={{ zIndex: '9999999' }}>
            <ClickAwayContentWrapper onClose={() => setRemoveMemberPopUp(false)}>
                <Pulse duration={150}>
                    {isUserProfileEdit ? (
                        <div className="remove-card-wrapper">
                            <div className="remove-card-wrapper-photo">
                                {avatar ? (
                                    <img src={avatar} alt="avatar" />
                                ) : (
                                    <Avatar
                                        name={name}
                                        value=""
                                        size="140"
                                        round={true}
                                        color="black"
                                        maxInitials={1}
                                        textSizeRatio={2}
                                        style={{ fontWeight: '700' }}
                                    />
                                )}
                            </div>
                            <div className="form-wrapper">
                                <h1 className="mt-10">{remove('team.remove.title')}</h1>
                                <p className="mt-5">{remove('team.remove.message')}</p>
                                <div className="w-100p d-flex vertical-center pos-rel">
                                    <input id="checkmark" type="checkbox" checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
                                    {isChecked && <img className="checkbox-tick" src={CheckboxTick} alt="checkbox"></img>}
                                    <h1 className="checkbox-label">{remove('team.remove.understand')}</h1>
                                </div>
                                <div className="remove-btn-wrapper mt-70 w-100p">
                                    <button className="white" onClick={() => setRemoveMemberPopUp(false)}>
                                        {remove('team.remove.go_back')}
                                    </button>
                                    <button
                                        disabled={!isChecked}
                                        style={{
                                            opacity: isChecked ? 1 : 0.5,
                                            cursor: isChecked ? 'pointer' : 'none'
                                        }}
                                        onClick={removeMemberHandler}
                                    >
                                        {remove('team.remove.delete')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="remove-card-wrapper">
                            <div className="form-wrapper">
                                {currentMember.currentEditMemberAvatar ? (
                                    <div className="image-wrapper">
                                        <img src={currentMember.currentEditMemberAvatar} alt="avatar"></img>
                                    </div>
                                ) : (
                                    <Avatar
                                        name={currentMember.currentEditMemberName}
                                        size="142"
                                        round={true}
                                        color="black"
                                        maxInitials={1}
                                        textSizeRatio={3}
                                        textMarginRatio={0}
                                    />
                                )}

                                <h1 className="mt-30">
                                    {admin || owner
                                        ? remove('team.remove.remove_msg_one') +
                                          `${currentMember.currentEditMemberName}` +
                                          remove('team.remove.remove_msg_two')
                                        : remove('team.remove.no_perm')}
                                </h1>
                                {admin || owner ? <p className="mt-20">{remove('team.remove.admin_msg')}</p> : ''}

                                <div className="remove-btn-wrapper mt-70 w-100p">
                                    <button className="white" onClick={() => setRemoveMemberPopUp(false)}>
                                        {remove('team.remove.go_back')}
                                    </button>
                                    {admin || owner ? (
                                        <button
                                            onClick={() => {
                                                if (isRemoving) return;
                                                removeMemberHandler();
                                            }}
                                        >
                                            {remove('remove')}
                                        </button>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </Pulse>
            </ClickAwayContentWrapper>
        </div>
    );
};

export default PopUpRemoveMember;
