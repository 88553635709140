import React from 'react';
import './trafficbar.scss';
import StatOverview from '../StatOverview';
import NoStatAvailable from '../../assets/no-stats.svg';
import { useTranslation } from 'react-i18next';
export default function TrafficBar(props) {
    const { bars, hardStyle } = props;
    const { t: trafficBar } = useTranslation();
    if (bars) {
        if (bars && bars.length < 1) {
            return (
                <div className="stats-card-graph-wrapper">
                    <StatOverview
                        icon={NoStatAvailable}
                        title={trafficBar('stats.traffic.no_traffic')}
                        msg={trafficBar('stats.traffic.msg')}
                        type="product"
                    ></StatOverview>
                </div>
            );
        } else {
            return (
                <div
                    className="traffic-bar-container"
                    style={{
                        height: hardStyle ? '100%' : '',
                        marginTop: hardStyle ? '0px' : '',
                        paddingTop: hardStyle ? '22px' : ''
                    }}
                >
                    {bars.map((bar, index) => {
                        return (
                            <div
                                className="source"
                                key={index}
                                style={{
                                    marginTop: !hardStyle ? '0px' : ''
                                }}
                            >
                                <div className="icon">
                                    <img src={bar.logo} alt=""></img>
                                </div>
                                <div
                                    className="details"
                                    style={{
                                        paddingBottom: hardStyle ? '15px' : '',
                                        borderBottom: hardStyle ? '1px solid rgba(0, 0, 0, 0.12)' : ''
                                    }}
                                >
                                    <h3>{bar.source}</h3>
                                    <div className="traffic-bar">
                                        <div
                                            className="progress"
                                            style={{
                                                width: `${bar.value}%`
                                            }}
                                        ></div>
                                        <h3>{bar.value}%</h3>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        }
    }
}
