import {
    SET_OVERALL_IMPRESSION,
    SET_OVERALL_RETENTION,
    SET_OVERALL_CTAACTIONRATE,
    SET_MOST_VIEWED_TIMES,
    SET_TOP_TRAFFIC_SOURCES,
    SET_SHOW_POPUP,
    SET_LAST_UPDATED,
    SET_SPACE_SKILLS,
    SET_SPACE_IMPRESSION_CTA,
    SET_SPACE_VIDEO_PERFORMANCE,
    SET_SKILL_STATS_POPUP,
    SET_TRAFFIC_SOURCES_BY_SPACE,
    SET_ACTIVE_SPACE,
    SET_RETENTION_RATE_BY_SPACE
} from './types.js';

const setLastUpdated = ({ data }, state) => {
    const newLastUpdated = {
        ...state,
        lastUpdated: data
    };
    return {
        ...newLastUpdated
    };
};

const setOverallImpression = ({ value, graphPlot, startDate, endDate, accountCreated }, state) => {
    const newOverallImpression = {
        ...state,
        overallImpressions: {
            value: value === null ? 0 : value,
            graphPlot,
            startDate: startDate,
            endDate: endDate,
            accountCreated: accountCreated
        }
    };
    return {
        ...newOverallImpression
    };
};
const setOverallRetention = ({ value, graphPlot, startDate, endDate, accountCreated }, state) => {
    const newOverallRetention = {
        ...state,
        overallRetention: {
            value: value === null ? 0 : value,
            graphPlot,
            startDate: startDate,
            endDate: endDate,
            accountCreated: accountCreated
        }
    };
    return {
        ...newOverallRetention
    };
};

const setOverallCTAActionRate = ({ value, graphPlot, startDate, endDate, accountCreated }, state) => {
    const newCTA = {
        ...state,
        overallCTA: {
            value: value === null ? 0 : value,
            graphPlot,
            startDate: startDate,
            endDate: endDate,
            accountCreated: accountCreated
        }
    };
    return {
        ...newCTA
    };
};

const setMostViewedTimes = ({ graphData }, state) => {
    const newMostViewedTimes = {
        ...state,
        mostViewedTimes: {
            graphData
        }
    };
    return {
        ...newMostViewedTimes
    };
};

const setTopTrafficSources = ({ graph, startDate, endDate, accountCreated }, state) => {
    const newTopTrafficSources = {
        ...state,
        topTrafficSources: {
            graph,
            startDate,
            endDate,
            accountCreated: accountCreated
        }
    };
    return {
        ...newTopTrafficSources
    };
};

// Pop Up updates
const setShowPopUp = ({ showPopUp, currentGraphType, isDetail }, state) => {
    const newShowPopUp = {
        ...state,
        currentGraphType,
        showPopUp,
        isDetail
    };
    return {
        ...newShowPopUp
    };
};

// detail space stats
const setSpaceSkills = ({ skills }, state) => {
    const newSpaceSkills = {
        ...state,
        skills
    };
    return {
        ...newSpaceSkills
    };
};

const setImpressionCTARate = ({ graphPlot, impression, ctaRate, name, spaceId }, state) => {
    const newIMPCTA = {
        ...state,
        impressionCTARate: {
            graphPlot,
            impression: impression === null ? 0 : impression,
            ctaRate: ctaRate === null ? 0 : ctaRate,
            name,
            spaceId
        }
    };
    return {
        ...newIMPCTA
    };
};

const setSpaceVideoPerformance = ({ avgWatchTime, avgRetentionRate, skills }, state) => {
    const newVideoPerformance = {
        ...state,
        spaceVideoPerformance: {
            avgWatchTime: avgWatchTime === null ? 0 : avgWatchTime,
            avgRetentionRate: avgRetentionRate === null ? 0 : avgRetentionRate,
            skills
        }
    };
    return newVideoPerformance;
};

const setSkillStatsPopup = ({ name, poster, views, engagement, avgWatchTime, graphPlot, endTimeInSecond }, state) => {
    const newSkillStats = {
        ...state,
        skillStats: {
            name,
            poster,
            views,
            engagement: engagement === null ? 0 : engagement,
            avgWatchTime: avgWatchTime === null ? 0 : avgWatchTime,
            graphPlot,
            endTimeInSecond
        }
    };
    return newSkillStats;
};

const setTrafficSourcesBySpace = ({ graphPlot, startDate, endDate }, state) => {
    const newActiveSpace = {
        ...state,
        spaceTrafficSources: {
            graphPlot: graphPlot,
            startDate: startDate,
            endDate: endDate
        }
    };
    return newActiveSpace;
};

const setActiveSpaceDetails = ({ spaceName, poster, spaceId, createdDate, bedrooms, bathrooms, dimension, dimensionUnit }, state) => {
    const newActiveSpace = {
        ...state,
        activeSpace: {
            spaceName,
            poster,
            spaceId,
            createdDate,
            bedrooms,
            bathrooms,
            dimension,
            dimensionUnit
        }
    };
    return newActiveSpace;
};

const setRetentionRateBySpace = ({ value, graphPlot, startDate, endDate }, state) => {
    const newSpaceRetention = {
        ...state,
        spaceRetention: {
            value: value === null ? 0 : value,
            graphPlot,
            startDate,
            endDate
        }
    };
    return newSpaceRetention;
};

export default (state, action) => {
    switch (action.type) {
        case SET_OVERALL_IMPRESSION:
            return setOverallImpression(action.payload, state);
        case SET_OVERALL_RETENTION:
            return setOverallRetention(action.payload, state);
        case SET_OVERALL_CTAACTIONRATE:
            return setOverallCTAActionRate(action.payload, state);
        case SET_MOST_VIEWED_TIMES:
            return setMostViewedTimes(action.payload, state);
        case SET_TOP_TRAFFIC_SOURCES:
            return setTopTrafficSources(action.payload, state);
        case SET_SHOW_POPUP:
            return setShowPopUp(action.payload, state);
        case SET_LAST_UPDATED:
            return setLastUpdated(action.payload, state);
        case SET_SPACE_SKILLS:
            return setSpaceSkills(action.payload, state);
        case SET_SPACE_IMPRESSION_CTA:
            return setImpressionCTARate(action.payload, state);
        case SET_SPACE_VIDEO_PERFORMANCE:
            return setSpaceVideoPerformance(action.payload, state);
        case SET_SKILL_STATS_POPUP:
            return setSkillStatsPopup(action.payload, state);
        case SET_TRAFFIC_SOURCES_BY_SPACE:
            return setTrafficSourcesBySpace(action.payload, state);
        case SET_ACTIVE_SPACE:
            return setActiveSpaceDetails(action.payload, state);
        case SET_RETENTION_RATE_BY_SPACE:
            return setRetentionRateBySpace(action.payload, state);
        default:
            return state;
    }
};
