import React from 'react';
import { Link } from 'react-router-dom';
import './empty-state.scss';

// Assets
import EmptyTeamBanner from '../../assets/EmptyTeamBanner.png';
import StatsMac from '../../assets/stats-mac.png';

import { useTranslation } from 'react-i18next';

const EmptyState = (props) => {
    const { setInviteMemberPopup, renderComp, isDisabledAddSpace } = props;
    const { t: teamTranslate } = useTranslation();

    return (
        <div className="w-100p mt-20 ml-20 mr-20 content-width">
            <div className="products-heading d-flex f-row space-between center-align fs-18 fw-900 mt-9">
                <h1>{renderComp === 'teams' ? teamTranslate('team.title') : 'Stats'}</h1>
            </div>

            <div className="empty-state-teams">
                <div className="empty-state-image-wrapper">
                    <img className="empty-state-image" src={renderComp === 'teams' ? EmptyTeamBanner : StatsMac} alt="banner" />
                    <h1>
                        {renderComp === 'teams' ? '' : 'Stats will be functional after'} <br></br>{' '}
                        {renderComp === 'teams' ? '' : 'you publish a Space'}
                    </h1>
                    {renderComp === 'stats' ? (
                        <h4 className="text-center fw-600 fs-12 c-sub lh-16 mt-20 w-380 fw-600">
                            Here is where you can see the performance of the videos that you have created.
                        </h4>
                    ) : (
                        ''
                    )}
                </div>

                <div className="empty-state-content">
                    <h2 className="text-center fw-700 fs-24 mb-0">
                        {renderComp === 'teams' ? teamTranslate('team.team_invite') : 'Add your first Space'}
                    </h2>
                    <h4 className="text-center fw-600 fs-14 c-sub2 lh-16 mt-20 mb-20 fw-600">
                        {renderComp === 'teams'
                            ? teamTranslate('team.team_invite_msg_one')
                            : 'Upload video collections, attach custom CTA’s'}
                        <br /> {renderComp === 'teams' ? teamTranslate('team.team_invite_msg_two') : 'and pair them to your website.'}
                    </h4>

                    <div className="w-100p center-align d-flex custom-bottom">
                        {renderComp === 'teams' ? (
                            <button className="empty-state-add-new" onClick={() => setInviteMemberPopup(true)}>
                                {' '}
                                {teamTranslate('team.send_invite')}
                            </button>
                        ) : (
                            <Link to="/mySpaces">
                                <button
                                    className="empty-state-add-new"
                                    disabled={isDisabledAddSpace}
                                    style={{
                                        backgroundColor: isDisabledAddSpace ? '#808080' : ''
                                    }}
                                >
                                    {' '}
                                    Add new Space
                                </button>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmptyState;
