import React from 'react';
import Bulb from '../../assets/info-bulb.svg';
import './notecomponent.scss';
export default function NoteComponent(props) {
    const { message } = props;
    return (
        <div className="note-container">
            <img src={Bulb} alt="Bulb" />
            {message}
        </div>
    );
}
