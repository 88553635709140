import React, { useState } from 'react';
import './popupremoveproduct.scss';
import { DOMAIN } from '../../constants/base';
import Pulse from 'react-reveal/Pulse';
// import { currentSpaceContext } from "../../context";
import ClickAwayContentWrapper from '../../components/ClickAwayContentWrapper';
import { useTranslation } from 'react-i18next';

const PopUpRemoveProduct = ({
    setOpenRemoveProductPopUp,
    setPageNo,
    setChangeState,
    changeState,
    selectedCards,
    setSelectedCards,
    setDeleteNotification,
    setChangeCountState,
    changeCountState
}) => {
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const { t: deleteSpace } = useTranslation();
    // const [currentSpace] = useContext(currentSpaceContext);

    const removeProductHanlder = () => {
        setDeleteInProgress(true);
        const url = `${DOMAIN}/v1/dashboard/space/multiple/delete`;
        const payload = {
            spaceIds: selectedCards
        };
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                authorization: userToken.accessToken
            },
            body: JSON.stringify(payload)
        }).then((resp) => {
            setDeleteInProgress(false);
            setOpenRemoveProductPopUp(false);
            setDeleteNotification(true);
            setTimeout(() => {
                setDeleteNotification(false);
            }, 3000);
            setPageNo(1);
            setSelectedCards([]);
            setChangeState(!changeState);
            setChangeCountState(!changeCountState);
        });
    };

    return (
        <div className="popups-wrapper">
            <ClickAwayContentWrapper onClose={() => setOpenRemoveProductPopUp(false)}>
                <Pulse duration={150}>
                    <div className="remove-card-wrapper">
                        <div className="form-wrapper">
                            <h1 className="mt-20">
                                {deleteSpace('spaces.delete.msg')} {selectedCards.length}{' '}
                                {selectedCards.length > 1 ? deleteSpace('spaces.delete.spaces') : deleteSpace('spaces.delete.space')}?
                            </h1>
                            <p>{deleteSpace('spaces.delete.p_text')}</p>
                            <div className="remove-btn-wrapper mt-30 w-100p">
                                <button onClick={() => setOpenRemoveProductPopUp(false)} className="go-back">
                                    {deleteSpace('spaces.delete.back')}
                                </button>
                                <button
                                    className="remove"
                                    onClick={() => {
                                        if (deleteInProgress) return;
                                        removeProductHanlder();
                                    }}
                                >
                                    {deleteSpace('spaces.delete.remove')}
                                </button>
                            </div>
                        </div>
                    </div>
                </Pulse>
            </ClickAwayContentWrapper>
        </div>
    );
};

export default PopUpRemoveProduct;
