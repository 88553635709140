import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import Input from '../../components/Input';
import '../Signin/signin.scss';
import './signup.scss';
import { Link, Redirect } from 'react-router-dom';
import { isEmpty, validateEmail, getParameterByName } from '../../utils';
import { postReq } from '../../utils/api';
import { DOMAIN } from '../../constants/base';
import VideoAtom from '../../components/VideoAtom';
import TextAtom from '../../components/TextAtom';
import ButtonAtom from '../../components/ButtonAtom';
import StatusMsgAtom from '../../components/StatusMsgAtom';
import ProductAtom from '../../components/ProductAtom';
import LoginGoogle from '../../components/LoginGoogle';
import LoginApple from '../../components/LoginApple';
import Avatar from 'react-avatar';
import CheckboxAtom from '../../components/CheckboxAtom';

// Assets
import BackBtn from '../../assets/back.png';

function Signup(props) {
    const [email, setEmail] = useState('');
    const [statusMsg, setStatusMsg] = useState('');
    const [statusMsgType, setStatusMsgType] = useState('');
    const [isEmailValid, setEmailValid] = useState(true);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [shouldRedirectMobileBlock, setShouldRedirectMobileBlock] = useState(false);
    const [typeOfError, setTypeOfError] = useState('');
    const ssoLoginError = false;
    const [showLoader, setShowLoader] = useState(false);
    const [showDialogMobile, setShowDialogMobile] = useState(true);
    // SSO Datas
    const [ssoConfirmation, setSsoConfirmation] = useState(false);
    const [ssoTCChecked, setSSOTCChecked] = useState(false);
    const [TNCData, setTNCData] = useState({ name: '', email: '' });
    const mobileBlock = document.getElementById('mobileBlock');
    // Apple SSO Data
    const appleToken = getParameterByName('user');
    const appleUserName = getParameterByName('name');
    const appleEmail = getParameterByName('email');
    const appleNewUser = getParameterByName('newUser');

    // Passwordless Data
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState(false);
    const [otpErrorMsg, setOtpErrorMsg] = useState('');
    const [showOtpScreen, setShowOtpScreen] = useState(false);
    const [otptimeLeft, setOtpTimeLeft] = useState(60);
    const [otpTCCChecked, setOtpTCCChecked] = useState(false);
    // const [showFreeAccountNotification, setShowFreeAccountNotification] = useState(false);
    const [showOtpTNC, setShowOtpTNC] = useState(false);
    // const [oneTimeToken, setOneTimeToken] = useState("");

    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};

    // Use Effects
    useEffect(() => {
        window.Intercom('boot', {
            app_id: process.env.REACT_APP_INTERCOM_KEY
        });
        if (!isEmpty(userToken)) {
            setShouldRedirect(true);
        }
    }, []);

    // OTP Time
    useEffect(() => {
        if (!otptimeLeft) {
            return;
        }
        const intervalId = setInterval(() => {
            setOtpTimeLeft(otptimeLeft - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [otptimeLeft]);

    useEffect(() => {
        if (otp.length === 5) {
            handleSignin();
        }
    }, [otp]);

    function TimeFormat(duration) {
        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;
        var ret = '';
        ret += '' + mins + ':' + (secs < 10 ? '0' : '');
        ret += '' + secs;
        return ret;
    }

    const emailChangeHandler = (e) => {
        const email = e.target.value;
        setEmail(email);
    };

    const emailValidateHandler = () => {
        const isValid = validateEmail(email);
        setEmailValid(isValid);
    };

    const displayErrorMsg = (msg) => {
        setStatusMsg(msg);
        setStatusMsgType('error');
        setShowDialogMobile(true);
        return;
    };

    // Handling SSO APPLE
    useEffect(() => {
        if (appleNewUser) {
            setSsoConfirmation(true);
            return;
        }
        const ssoApple = async () => {
            const url = `${DOMAIN}/v1/dashboard/space/user/login/sso/apple`;
            const requestHeaders = {
                authorization: appleToken
            };
            let payload = { holofy: 'Space' };
            if (appleUserName !== 'undefined') {
                payload = { name: appleUserName ? appleUserName : '', holofy: 'Space' };
            }
            if (appleToken) {
                const {
                    error = '',
                    successCode = '',
                    data = {}
                } = await postReq({
                    url,
                    requestHeaders,
                    payload
                });

                if (successCode === 'SUCC200') {
                    if (data.currentPlan && data.currentPlan === 'Basic') {
                        setSsoConfirmation(false);
                        // setShowFreeAccountNotification(true);
                        setEmail(data.email);
                    } else {
                        handleSSOTAC(data);
                    }
                }
                if (error === 'ERR500') {
                    displayErrorMsg('Something went wrong. Please try after sometime.');
                }
            }
        };
        ssoApple();
    }, []);

    // Handling GOOGLE SSO
    const successCallback = (res) => {
        const url = `${DOMAIN}/v1/dashboard/space/user/login/sso/google`;
        const payload = {
            holofy: 'Space',
            tnc: true
        };
        const requestHeaders = {
            authorization: res.tokenId
        };
        postReq({
            url,
            payload,
            requestHeaders
        }).then((res) => {
            const { error = '', successCode = '', data = {} } = res;
            if (error === 'ERR404') {
                displayErrorMsg('User does not exist');
            }
            if (error === 'ERR403') {
                displayErrorMsg('Your email is incorrect');
                setTypeOfError('ERR403');
            }
            if (error === 'ERR500') {
                displayErrorMsg('Something went wrong. Please try after sometime.');
            }
            if (successCode === 'SUCC200') {
                if (data.tnc) {
                    setSsoConfirmation(true);
                    setTNCData({ name: data.name, email: data.email, token: data.token });
                } else {
                    setTNCData({ ...TNCData, token: data.token });
                    SecondSSOCall('google', data.token);
                }
            }
        });
    };

    const SecondSSOCall = (currentSSO, googleToken) => {
        const url = `${DOMAIN}/v1/dashboard/space/user/login/sso/${currentSSO}`;
        const payload = {
            holofy: 'Space',
            tnc: false
        };
        const requestHeaders = {
            authorization: appleToken ? appleToken : TNCData.token ? TNCData.token : googleToken
        };
        postReq({
            url,
            payload,
            requestHeaders
        }).then((res) => {
            const { error = '', successCode = '', data = {} } = res;
            if (error === 'ERR404') {
                displayErrorMsg('User does not exist');
            }
            if (error === 'ERR403') {
                displayErrorMsg('Your email is incorrect');
                setTypeOfError('ERR403');
            }
            if (error === 'ERR500') {
                displayErrorMsg('Something went wrong. Please try after sometime.');
            }
            if (successCode === 'SUCC200') {
                if (data.currentPlan && data.currentPlan === 'Basic') {
                    setSsoConfirmation(false);
                    // setShowFreeAccountNotification(true);
                    setEmail(data.email);
                } else {
                    handleSSOTAC(data);
                }
            }
        });
    };

    const handleSSOTAC = (data) => {
        if (window.innerWidth >= 800) {
            localStorage.setItem('ss-auth-spaces', JSON.stringify(data));
            setShouldRedirect(true);
        }
        if (window.innerWidth < 800) {
            if (mobileBlock) {
                mobileBlock.style.display = 'flex';
            }
            localStorage.setItem('ss-auth-spaces', JSON.stringify(data));
            setShouldRedirectMobileBlock(true);
        }
    };

    // Handling Sign In
    const generateOTP = async () => {
        setShowLoader(true);
        setShowDialogMobile(true);
        if (!validateEmail(email)) {
            setStatusMsg('Please input a valid email!');
            setStatusMsgType('error');
            setShowLoader(false);
            return;
        }
        const url = `${DOMAIN}/v1/dashboard/space/user/login/generate-otp`;
        let payload = {
            email: email
        };
        const generateOtpResponse = await postReq({ url, payload });
        const { error = '', successCode = '', data = {} } = generateOtpResponse;
        if (successCode === 'SUCC200') {
            setShowOtpScreen(true);
            if (data && data.newUser) {
                setShowOtpTNC(true);
            } else {
                setShowOtpTNC(false);
            }
        }
        if (error === 'ERR404') {
            setStatusMsg('Error while generating otp');
            setStatusMsgType('error');
            setShowDialogMobile(true);
            setShowLoader(false);
            return;
        }
        if (error === 'ERR403') {
            setStatusMsg('Error while generating otp');
            setStatusMsgType('error');
            setShowDialogMobile(true);
            setTypeOfError('ERR403');
            setShowLoader(false);
            return;
        }
        if (error === 'ERR500') {
            setStatusMsg('Something went wrong. Please try after sometime.');
            setStatusMsgType('error');
            setShowDialogMobile(true);
            setShowLoader(false);
            return;
        }
    };

    const handleSignin = async () => {
        const url = `${DOMAIN}/v1/dashboard/space/user/login`;
        let payload = { email: email, otp: parseInt(otp) };
        if (process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'development') {
            payload = {
                email: email,
                passcode: 'hOlOfY_tEsT_uSeR@123',
                otp: 12345
            };
        }

        const userSigninResponse = await postReq({ url, payload });
        const { error = '', successCode = '', data = {} } = userSigninResponse;

        // 401 - wrong otp.
        // 403 - expired otp.
        // 405 - app download screen.
        // 500 - Something went wrong please try after some time.
        // 404 -  user not found.

        if (error === 'ERR400') {
            setOtpError(true);
            setOtpErrorMsg('SOMETHING WENT WRONG PLEASE TRY AFTER SOME TIME');
            return;
        }
        if (error === 'ERR401') {
            setOtpError(true);
            setOtpErrorMsg('CODE IS INCORRECT');
            return;
        }
        if (error === 'ERR403') {
            setOtpError(true);
            setOtpErrorMsg('CODE IS EXPIRED');
            return;
        }

        if (error === 'ERR404') {
            setOtpError(true);
            setOtpErrorMsg('USER NOT FOUND');

            return;
        }
        if (error === 'ERR500') {
            setOtpError(true);
            setOtpErrorMsg('SOMETHING WENT WRONG PLEASE TRY AFTER SOME TIME');
            return;
        }
        if (successCode === 'SUCC200' && window.screen.width >= 800) {
            if (data.currentPlan && data.currentPlan === 'Basic') {
                // setShowFreeAccountNotification(true);/
                setEmail(data.email);
            } else {
                localStorage.setItem('ss-auth-spaces', JSON.stringify(data));
                setShouldRedirect(true);
                setShowLoader(false);
            }
        }
        if (successCode === 'SUCC200' && window.screen.width < 800) {
            localStorage.setItem('ss-auth-spaces', JSON.stringify(data));
            setShouldRedirectMobileBlock(true);
            setShowLoader(false);
        }
    };

    // Redirects
    if (shouldRedirectMobileBlock) {
        return <Redirect to="/mobileBlock" />;
    }

    if (shouldRedirect) {
        return <Redirect to="/mySpaces" />;
    }
    return (
        <>
            <div className="signin-new">
                <ProductAtom></ProductAtom>
                <VideoAtom></VideoAtom>
                <div className="sign-in">
                    <div className="btn-wrapper">
                        <Link to="/signin">
                            <button className="btn">SIGN IN</button>
                        </Link>
                    </div>
                </div>
                {statusMsg && statusMsgType && (
                    <StatusMsgAtom
                        LineOne={'We could not recognise this account'}
                        LineTwo={statusMsg}
                        linkLabel={typeOfError === 'ERR403' ? 'RESET YOUR PASSWORD' : 'SIGN UP'}
                        linkUrl={typeOfError === 'ERR403' ? '/forgetPassword' : '/signup'}
                        type={statusMsgType}
                        showDialogMobile={showDialogMobile}
                        setShowDialogMobile={setShowDialogMobile}
                    ></StatusMsgAtom>
                )}
                {ssoLoginError && (
                    <StatusMsgAtom
                        LineOne={'Something went wrong.'}
                        LineTwo={'Please try again'}
                        type="error"
                        showDialogMobile={false}
                    ></StatusMsgAtom>
                )}
                {ssoConfirmation ? (
                    <div className="form-card-wrapper custom-blur">
                        <h1 className="sso-tac-heading">
                            Hi{' '}
                            {TNCData.name
                                ? TNCData.name.length > 12
                                    ? TNCData.name.substring(0, 12) + '...'
                                    : TNCData.name
                                : appleUserName !== undefined && appleUserName !== 'undefined'
                                ? appleUserName && appleUserName.length > 12
                                    ? appleUserName.substring(0, 12) + '...'
                                    : appleUserName
                                : appleEmail && appleEmail.length > 12
                                ? appleEmail.substring(0, 12) + '...'
                                : appleEmail}
                        </h1>
                        <div className="mt-20 w-100p">
                            <div className="email-inpt d-flex f-col space-between">
                                <label className="d-ib mb-5">Email</label>
                                <div className="input-wrap">
                                    <Avatar
                                        name={
                                            TNCData.name
                                                ? TNCData.name
                                                : appleUserName !== undefined && appleUserName !== 'undefined'
                                                ? appleUserName
                                                : appleEmail
                                        }
                                        size="24"
                                        round={true}
                                        color="black"
                                        maxInitials={1}
                                        textSizeRatio={2}
                                        textMarginRatio={0}
                                        style={{ fontWeight: '700', marginLeft: '10px' }}
                                    />
                                    <h2 className="email-custom">{TNCData.email ? TNCData.email : appleEmail}</h2>
                                </div>
                            </div>
                            <div className="important-note-wrapper mt-40">
                                <p>Before you continue, you need to know about our term & policy.</p>
                                <CheckboxAtom isChecked={ssoTCChecked} setIsChecked={setSSOTCChecked}></CheckboxAtom>
                            </div>
                            <div className="d-flex f-col vertical-center mt-20">
                                <ButtonAtom
                                    label="CONTINUE"
                                    disabled={!ssoTCChecked}
                                    onClick={() => SecondSSOCall(appleToken ? 'apple' : 'google')}
                                ></ButtonAtom>
                            </div>
                        </div>
                    </div>
                ) : showOtpScreen ? (
                    <div className="form-card-wrapper custom-blur">
                        <div className="form-wrapper">
                            <button
                                onClick={() => {
                                    setShowOtpScreen(false);
                                    setShowLoader(false);
                                    setOtp('');
                                    setOtpError(false);
                                    setOtpTimeLeft(60);
                                }}
                                className="back-btn"
                            >
                                <img src={BackBtn} alt="back-btn"></img>
                            </button>
                            <h1 className="mt-60">
                                Verify your Email <br></br> Address
                            </h1>
                            <div className="mt-30">
                                <h2 className="otp-title">SECURITY CODE</h2>
                                <OtpInput
                                    value={otp}
                                    onChange={(otpText) => {
                                        setOtp(otpText);
                                    }}
                                    numInputs={5}
                                    containerStyle={'otp-container'}
                                    inputStyle={'otp-input'}
                                    errorStyle={'otp-error'}
                                    hasErrored={otpError}
                                    isDisabled={showOtpTNC ? !otpTCCChecked : false}
                                />
                                {otpError && otpErrorMsg && <p className="otp-error-text">{otpErrorMsg}</p>}
                                {showOtpTNC && (
                                    <div className="mt-20">
                                        <CheckboxAtom isChecked={otpTCCChecked} setIsChecked={setOtpTCCChecked}></CheckboxAtom>
                                    </div>
                                )}
                            </div>
                            <div className="mt-20">
                                <p className="otp-text">
                                    <span>We’ve sent a security code to your email.</span> Please allow up to a minute for the email to
                                    arrive, and check your spam folder if it may have accidentally ended up in there.
                                </p>
                            </div>

                            <div className="mt-80 w-100p">
                                {otptimeLeft <= 0 ? (
                                    <button
                                        onClick={() => {
                                            setOtpTimeLeft(60);
                                            generateOTP();
                                        }}
                                        className="otp-resend-btn"
                                    >
                                        RESEND CODE
                                    </button>
                                ) : (
                                    <button disabled className="otp-resend-btn">
                                        <span>{TimeFormat(otptimeLeft)}</span> RESEND CODE
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="form-card-wrapper custom-blur">
                        <div className="form-wrapper">
                            <TextAtom
                                textContent="Sign Up"
                                fontFamily="Montserrat"
                                fontWeight="700"
                                fontSize="17px"
                                color="#3A3A3A"
                            ></TextAtom>
                            <div className="input-wrapper mt-30">
                                <Input
                                    type="text"
                                    label="Your Email"
                                    labelStyle="uc mb-5"
                                    placeholder="your@email.com"
                                    value={email}
                                    onChange={emailChangeHandler}
                                    onBlur={emailValidateHandler}
                                    isValid={isEmailValid}
                                    handleEnter={
                                        process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'development'
                                            ? handleSignin
                                            : generateOTP
                                    }
                                />
                            </div>
                            <div className="d-flex f-col vertical-center">
                                <ButtonAtom
                                    label="CONTINUE WITH EMAIL"
                                    disabled={false}
                                    onClick={
                                        process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'development'
                                            ? handleSignin
                                            : generateOTP
                                    }
                                    showLoader={showLoader}
                                ></ButtonAtom>
                                <div className="mt-60 d-flex f-col vertical-center">
                                    <div className="separator">Or</div>
                                    <div className="mt-10">
                                        <LoginGoogle successCallback={successCallback} />
                                        <LoginApple></LoginApple>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Signup;
