import React from 'react';
import { useTranslation } from 'react-i18next';

// components

// Assets
import BackImg from '../../assets/back.png';
import './editspacesectionheader.scss';
export default function EditSpaceSectionHeader(props) {
    const {
        currentSection,
        setEditSpaceSectionPopUp,
        isNewTemplate,
        setCurrentSection,
        setIsNewTemplate,
        selectedTemplate,
        setSelectedTemplate,
        prevTemplate
    } = props;
    const { t } = useTranslation();

    return (
        <>
            <div className="top-header">
                <button
                    className="back-button"
                    onClick={() => {
                        if (currentSection === '6') {
                            setIsNewTemplate(false);
                            if (isNewTemplate) {
                                if (selectedTemplate?.templateName !== 'Agent’s default contact') setSelectedTemplate(prevTemplate);
                                else if (selectedTemplate?.templateName === 'Agent’s default contact') {
                                    setSelectedTemplate({
                                        templateName: 'Agent’s default contact',
                                        templateId: null,
                                        ctas: null
                                    });
                                }
                            }

                            setCurrentSection('3');
                        } else {
                            setEditSpaceSectionPopUp(false);
                        }
                    }}
                >
                    <img src={BackImg} alt="back img"></img>
                </button>
            </div>
            <div className={currentSection === '1' ? 'current-step-title ft-white' : 'current-step-title'}>
                {currentSection === '1' && t('spaces.add_space.address')}
                {currentSection === '2' && t('space_details.title')}
                {currentSection === '3' && t('agent.manage_title')}
                {currentSection === '4' && t('spaces.add_space.add_videos')}
                {currentSection === '5' && t('spaces.publish.title')}
                {currentSection === '6' &&
                    (isNewTemplate ? t('cta.new_title') : selectedTemplate?.templateId ? t('cta.edit_title') : 'Agent Default Contacts')}
            </div>
        </>
    );
}
