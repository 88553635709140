import React from 'react';
import './planusage.scss';
export default function PlanUsage({ icon, value, resourceType }) {
    return (
        <div className="planusage-card">
            <div className="icon-wrapper">
                <img src={icon} alt=""></img>
            </div>
            <div className="details">
                {resourceType === 'Engagement Rate' ? (
                    <div className="value-wrapper">
                        {parseFloat(value).toFixed(2)}
                        <span>%</span>
                    </div>
                ) : (
                    <div className="value-wrapper">{value.toLocaleString()}</div>
                )}
                <div className="msg">{resourceType}</div>
            </div>
        </div>
    );
}
