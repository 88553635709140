import React, { useState, useEffect } from 'react';
import './videouploader.scss';
import { postReq, getReq } from '../../utils/api';
import { DOMAIN } from '../../constants/base';
import { Storage } from 'aws-amplify';

// Assets
import dragHandleBtn from '../../assets/drag-handle.png';
import CloseBtn from '../../assets/close-btn.png';
import LoadingSpin from '../../components/LoadingSpin';
import PopUpDeleteSkill from '../PopUpDeleteSkill';
import DeleteWhite from '../../assets/delete-white-btn.svg';
import { useTranslation } from 'react-i18next';
import { accessToken } from 'mapbox-gl';
import { use } from 'i18next';

const VideoUploader = ({
    file,
    id,
    fileNumber,
    DeleteFile,
    videoUploaded,
    setVideoUploaded,
    setCardSkills,
    videoFiles,
    setVideoFiles,
    editSpace,
    isReplace,
    setIsReplace,
    replaceSkillId,
    availableSkillsPoster = [],
    setAvailableSkillsPoster = () => {},
    setSkillUpdated = () => {},
    setScrollToUpload,
    setSelectedSkill,
    skillNameError,
    setEditFlowUploadCheck,
    isHomePage = false,
    setHomePageVideoDetails
}) => {
    const { t: videoUploader } = useTranslation();
    const userDetails = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const [loadingPercentage, setLoadingPercentage] = useState(0);
    const [name, setName] = useState(file.rename ? file.rename : '');
    // const [videoId, setVideoId] = useState('');
    const [removeSkill, setRemoveSkill] = useState(false);
    let StoragePromise;
    const [homePageVideoUploaded, setHomePageVideoUploaded] = useState(false);
    const [aspectRatio, setAspectRatio] = useState({
        height: file?.height ? file?.height : 2,
        width: file?.width ? file?.width : 1
    });
    const [ratioSkillId, setRatioSkillId] = useState('');

    useEffect(() => {
        if (file && !file.uploaded) {
            videoDurationHandler();
        }
        if (file.src) {
            var video = document.getElementById(`video${fileNumber}`);
            video.src = file.src;
        }
        // if(file.uploaded) {
        //   setIsSkillPresent(false);
        // }
    }, []);

    useEffect(() => {
        if (loadingPercentage === 100) {
            if (!isHomePage) {
                setVideoUploaded(videoUploaded + 1);
            }
            file.uploaded = true;
        }
        // setIsSkillPresent(true);
    }, [loadingPercentage]);

    useEffect(() => {
        file.rename = name;
    }, [name]);

    useEffect(() => {
        if (videoFiles) {
            let newArray = [...videoFiles];
            if (newArray.length) {
                for (let i = 0; i < newArray.length; i++) {
                    if (newArray[i].skillId === ratioSkillId) {
                        newArray[i].height = aspectRatio.height;
                        newArray[i].width = aspectRatio.width;
                    }
                }
            }
            setVideoFiles(newArray);
        }
    }, [aspectRatio]);

    // GettingVideoDuration
    const videoDurationHandler = () => {
        var video = document.getElementById(`video${fileNumber}`);
        video.preload = 'metadata';
        video.addEventListener('loadedmetadata', function () {
            if (isHomePage) {
                FileUploadHomePage();
            } else {
                FileUpload(Math.round(video.duration));
            }
        });
        if (!file.videoDuration) {
            video.src = URL.createObjectURL(file);
        }
    };

    const FileUploadHomePage = () => {
        try {
            const requestHeaders = {
                authorization: userDetails.accessToken
            };
            let url = `${DOMAIN}/v1/dashboard/space/skill/video-path`;
            const req = {
                url,
                requestHeaders
            };
            getReq(req).then((fileName) => {
                if (fileName.data) {
                    const { path } = fileName.data;
                    let videoExt = file.type.split('/')[1];
                    if (videoExt !== 'mov' && videoExt !== 'mp4') {
                        videoExt = videoExt.includes('.mp4') ? 'mp4' : 'mov';
                    }
                    StoragePromise = Storage.put(`${path}.${videoExt}`, file, {
                        contentType: `video/${videoExt}`,
                        progressCallback(progress) {
                            let lp = (progress.loaded / progress.total) * 100;
                            setLoadingPercentage(lp);
                        }
                    }).then(() => {
                        setHomePageVideoDetails({
                            ext: videoExt,
                            videoPath: path
                        });
                        setHomePageVideoUploaded(true);
                    });
                }
            });
        } catch (e) {
            console.warn(e);
        }
    };

    // Uploading File
    const FileUpload = (duration) => {
        try {
            const requestHeaders = {
                authorization: userDetails.accessToken
            };
            // Replace Video
            if (editSpace && isReplace) {
                setEditFlowUploadCheck(true);
                let url = `${DOMAIN}/v1/dashboard/space/skill/video-path`;
                const req = {
                    url,
                    requestHeaders
                };
                getReq(req).then((fileName) => {
                    if (fileName.data) {
                        setCardSkills((cardSkills) => cardSkills.filter((cardSkill) => cardSkill.id !== replaceSkillId));
                        const { path } = fileName.data;
                        let videoExt = file.type.split('/')[1];
                        if (videoExt !== 'mov' && videoExt !== 'mp4') {
                            videoExt = videoExt.includes('.mp4') ? 'mp4' : 'mov';
                        }
                        StoragePromise = Storage.put(`${path}.${videoExt}`, file, {
                            contentType: `video/${videoExt}`,
                            progressCallback(progress) {
                                let lp = (progress.loaded / progress.total) * 100;
                                setLoadingPercentage(lp);
                            }
                        })
                            .then(() => {
                                url = `${DOMAIN}/v1/dashboard/space/skill/${replaceSkillId}/update-path`;
                                const newReq = {
                                    url,
                                    requestHeaders,
                                    payload: {
                                        path,
                                        videoExt,
                                        videoDuration: duration
                                    }
                                };
                                postReq(newReq).then((resp) => {
                                    setIsReplace(false);
                                    setCardSkills((cardSkills) => [...cardSkills, resp.data]);
                                    setVideoFiles((videoFiles) => videoFiles.filter((item) => item.skillId === resp.data.id));
                                    setEditFlowUploadCheck(false);
                                    setSelectedSkill([]);
                                });
                            })
                            .catch((e) => {
                                console.log('error uploading', e);
                            });
                    }
                });
            } else {
                if (editSpace) {
                    setEditFlowUploadCheck(true);
                }
                let url = `${DOMAIN}/v1/dashboard/space/skill/name`;
                if (!editSpace) {
                    setSkillUpdated(false);
                }

                let payload = {
                    position: fileNumber,
                    id: id
                };
                const requestHeaders = {
                    authorization: userDetails.accessToken
                };
                const req = {
                    url,
                    payload,
                    requestHeaders
                };
                postReq(req).then((fileName) => {
                    if (fileName.data) {
                        file.create = false;
                        const { path, skillId } = fileName.data;
                        // setVideoId(skillId);
                        file.skillId = skillId;
                        let videoExt = file.type.split('/')[1];
                        if (videoExt !== 'mov' && videoExt !== 'mp4') {
                            videoExt = videoExt.includes('.mp4') ? 'mp4' : 'mov';
                        }
                        StoragePromise = Storage.put(`${path}.${videoExt}`, file, {
                            contentType: `video/${videoExt}`,
                            progressCallback(progress) {
                                let lp = (progress.loaded / progress.total) * 100;
                                setLoadingPercentage(lp);
                            }
                        })
                            .then(() => {
                                url = `${DOMAIN}/v1/dashboard/space/skill/${skillId}/update-path`;
                                const newReq = {
                                    url,
                                    requestHeaders,
                                    payload: {
                                        path,
                                        videoExt,
                                        videoDuration: duration
                                    }
                                };
                                postReq(newReq).then((resp) => {
                                    file.src = resp.data.content.video;
                                    setAvailableSkillsPoster([
                                        ...availableSkillsPoster,
                                        { id: resp.data.id, poster: resp.data.content.poster }
                                    ]);
                                    if (!editSpace) {
                                        setSkillUpdated(true);
                                    }
                                    if (editSpace) {
                                        setEditFlowUploadCheck(false);
                                        setCardSkills((cardSkills) => [...cardSkills, resp.data]);
                                        setVideoFiles((videoFiles) => videoFiles.filter((videoFile) => videoFile.skillId !== resp.data.id));
                                        setScrollToUpload(false);
                                    }
                                });
                            })
                            .catch((e) => {
                                console.log('error uploading', e);
                            });
                    }
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    //method to set aspect ratio
    const aspectRatioSet = () => {
        if (aspectRatio.height === 2 && aspectRatio.width === 1) {
            setAspectRatio({
                height: 1,
                width: 1
            });
        } else if (aspectRatio.height === 1 && aspectRatio.width === 1) {
            setAspectRatio({
                height: 1,
                width: 3
            });
        } else if (aspectRatio.height === 1 && aspectRatio.width === 3) {
            setAspectRatio({
                height: 2,
                width: 1
            });
        }
    };
    const setVideoWidth = () => {
        if (aspectRatio.height === 2 && aspectRatio.width === 1) return '202px';
        else if (aspectRatio.height === 1 && aspectRatio.width === 1) return '360px';
        else if (aspectRatio.height === 1 && aspectRatio.width === 3) return '606px';
    };

    return (
        <>
            <li
                className={isHomePage ? 'video-upload-homepage' : 'video-uploader'}
                key={fileNumber}
                style={{
                    width: isHomePage ? '' : setVideoWidth()
                }}
            >
                {loadingPercentage !== 100 && !file.uploaded && (
                    <div className="video-upload-wrapper">
                        <div className="video-upload-content">
                            <LoadingSpin />
                            <span
                                style={{
                                    width: '40px',
                                    textAlign: 'center',
                                    fontSize: '12px'
                                }}
                            >
                                {' '}
                                {Math.round(loadingPercentage)} %
                            </span>
                        </div>
                        <div className="video-upload-progressbar">
                            <div style={{ width: `${loadingPercentage}%` }} className="video-upload-progress"></div>
                        </div>
                    </div>
                )}
                {!isHomePage && (
                    <>
                        <button className="close-btn" onClick={() => setRemoveSkill(true)}>
                            <img src={CloseBtn} alt="delete-video"></img>
                        </button>
                        <button
                            className="aspect-ratio-btn"
                            onClick={() => {
                                aspectRatioSet();
                                setRatioSkillId(file.skillId);
                            }}
                        >
                            {aspectRatio.width + ':' + aspectRatio.height}
                        </button>
                    </>
                )}

                <video id={`video${fileNumber}`}>
                    <source></source>
                </video>
                {isHomePage && homePageVideoUploaded && (
                    <div className="video-remove">
                        <img src={DeleteWhite} alt="delete"></img>
                        {videoUploader('remove')}
                    </div>
                )}
                {!isHomePage && (
                    <div
                        className="video-uploader-title-wrapper"
                        style={{
                            border: skillNameError && name.length === 0 ? '1px solid #F6655A' : ''
                        }}
                    >
                        <img src={dragHandleBtn} alt="drag-video"></img>
                        <input
                            type="text"
                            placeholder={videoUploader('download.add_video_title')}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        ></input>
                    </div>
                )}
            </li>

            {removeSkill ? (
                <PopUpDeleteSkill
                    DeleteFile={DeleteFile}
                    videoId={file.skillId}
                    fileNumber={fileNumber}
                    setRemoveSkill={setRemoveSkill}
                ></PopUpDeleteSkill>
            ) : (
                ''
            )}
        </>
    );
};

export default VideoUploader;
