import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpin from '../LoadingSpin';
import './bottomwrapper.scss';
export default function BottomWrapper(props) {
    const { onClick, onHyperClicked, hyperText, isLoading, removeMember } = props;
    const { t } = useTranslation();
    return (
        <div className="bottom-wrapper">
            {removeMember ? <a onClick={onHyperClicked}>{hyperText}</a> : ''}
            <button
                onClick={() => {
                    onClick && onClick();
                }}
            >
                {isLoading ? <LoadingSpin /> : t('continue')}
            </button>
        </div>
    );
}
