import React from "react";
function Logo(props) {
  const { logo, height = 64, width = 64, className } = props;
  return (
    <div className={className}>
      <img src={logo} height={height} width={width} alt="Logo" />
    </div>
  );
}

export default Logo;
