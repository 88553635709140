import React, { useEffect, useState } from 'react';
import VideoAtom from '../VideoAtom';
import './androidtv.scss';
import HolofyLogo from '../../assets/holofy-logo.png';
import SpaceType from '../../assets/space-type.png';
import SpaceQr from '../../assets/space-qr-code.png';
import SpaceDetails from '../../assets/fs-details-rooms.png';
import backgroundVideo from '../../assets/contextual-bg-video.mp4';
import notPairedBackgroundVideo from '../../assets/video.mp4';
export default function AndroidTVPreview(props) {
    const { isAndroidTvPaired } = props;

    return (
        <div className="android-tv-preivew">
            {/* {isAndroidTvPaired ? <VideoAtom src={backgroundVideo}></VideoAtom> : <VideoAtom></VideoAtom>} */}
            <VideoAtom src={isAndroidTvPaired ? backgroundVideo : ''}></VideoAtom>
            {isAndroidTvPaired ? (
                <div className="space-details">
                    <div className="space-type">rent</div>
                    <div className="price-container">
                        <span className="currency-type">£</span>
                        <span>4000</span>
                        <span className="price-type"> / month</span>
                    </div>
                    <div className="fs-address">Riverside Building, County Hall, South Bank, London SE1 7PB, UK</div>
                    <div className="space-props">
                        <img src={SpaceDetails} alt="rooms" />
                    </div>
                    <div className="space-qr">
                        <img src={SpaceQr} alt="qr code" />
                    </div>
                </div>
            ) : (
                <>
                    <div className="tv-header">
                        <img src={HolofyLogo} alt="holofy" />
                        <h2>TV</h2>
                    </div>
                    <div className="tv-footer">
                        <div className="otp-field">91239</div>
                        <div className="otp-verification">
                            Go to dashboard.spaces.holofy.io/android-tv and pair this TV using the code above
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
