import React, { useContext } from 'react';
import './teamsettings.scss';
import SwitchAtom from '../../components/SwitchAtom';
import { SettingsContext } from '../../context';

//Assets
import RightChevron from '../../assets/grey-right-chevron.svg';
import { useTranslation } from 'react-i18next';

function WidgetSettings(props) {
    const { teamSettingsState, isAdminState, setIsAdminState, setTeamSettingsState, setTeamSettingsFlag, userDetails } = props;

    const { role } = userDetails;
    const owner = role === 'OWNER';
    const [settings, dispatch] = useContext(SettingsContext);
    const { t: team } = useTranslation();
    const teamSwitchOptions = (type) => {
        setTeamSettingsFlag(true);
        if (isAdminState) {
            switch (type) {
                case 'seeEntirePortfolio':
                    setTeamSettingsState({
                        ...teamSettingsState,
                        admin: {
                            ...teamSettingsState.admin,
                            seeEntirePortfolio: !teamSettingsState.admin.seeEntirePortfolio
                        }
                    });
                    dispatch({
                        type: 'SET_GLOBAL_ADMIN_SETTINGS',
                        payload: {
                            ...settings.adminGlobalSettings,
                            globalAdminSeeEntirePortfolio: !teamSettingsState.admin.seeEntirePortfolio
                        }
                    });
                    return;
                case 'manageTeamPortfolio':
                    setTeamSettingsState({
                        ...teamSettingsState,
                        admin: {
                            ...teamSettingsState.admin,
                            manageTeamPortfolio: !teamSettingsState.admin.manageTeamPortfolio
                        }
                    });
                    dispatch({
                        type: 'SET_GLOBAL_ADMIN_SETTINGS',
                        payload: {
                            ...settings.adminGlobalSettings,
                            globalAdminManageTeamPortfolio: !teamSettingsState.admin.manageTeamPortfolio
                        }
                    });
                    return;
                case 'createSpace':
                    setTeamSettingsState({
                        ...teamSettingsState,
                        admin: {
                            ...teamSettingsState.admin,
                            createSpace: !teamSettingsState.admin.createSpace
                        }
                    });
                    dispatch({
                        type: 'SET_GLOBAL_ADMIN_SETTINGS',
                        payload: {
                            ...settings.adminGlobalSettings,
                            globalAdminCreateSpace: !teamSettingsState.admin.createSpace
                        }
                    });
                    return;
                case 'sendInvitations':
                    setTeamSettingsState({
                        ...teamSettingsState,
                        admin: {
                            ...teamSettingsState.admin,
                            inviteMoreAdmins:
                                !teamSettingsState.admin.sendInvitations === false ? false : teamSettingsState.admin.inviteMoreAdmins,
                            sendInvitations: !teamSettingsState.admin.sendInvitations
                        }
                    });
                    dispatch({
                        type: 'SET_GLOBAL_ADMIN_SETTINGS',
                        payload: {
                            ...settings.adminGlobalSettings,
                            globalAdminSendInvitations: !teamSettingsState.admin.sendInvitations,
                            inviteMoreAdmins:
                                !teamSettingsState.admin.sendInvitations === false ? false : teamSettingsState.admin.inviteMoreAdmins
                        }
                    });
                    return;
                case 'editTeamMemberDetails':
                    setTeamSettingsState({
                        ...teamSettingsState,
                        admin: {
                            ...teamSettingsState.admin,
                            editTeamMemberDetails: !teamSettingsState.admin.editTeamMemberDetails
                        }
                    });
                    dispatch({
                        type: 'SET_GLOBAL_ADMIN_SETTINGS',
                        payload: {
                            ...settings.adminGlobalSettings,
                            globalAdminEditTeamMemberDetails: !teamSettingsState.admin.editTeamMemberDetails
                        }
                    });
                    return;
                case 'editAccountPermissions':
                    setTeamSettingsState({
                        ...teamSettingsState,
                        admin: {
                            ...teamSettingsState.admin,
                            editAccountPermissions: !teamSettingsState.admin.editAccountPermissions
                        }
                    });
                    dispatch({
                        type: 'SET_GLOBAL_ADMIN_SETTINGS',
                        payload: {
                            ...settings.adminGlobalSettings,
                            globalAdminEditAccountPermissions: !teamSettingsState.admin.editAccountPermissions
                        }
                    });
                    return;
                case 'seePlanDetails':
                    setTeamSettingsState({
                        ...teamSettingsState,
                        admin: {
                            ...teamSettingsState.admin,
                            managePlan: !teamSettingsState.admin.seePlanDetails === false ? false : teamSettingsState.admin.managePlan,
                            seePlanDetails: !teamSettingsState.admin.seePlanDetails
                        }
                    });
                    dispatch({
                        type: 'SET_GLOBAL_ADMIN_SETTINGS',
                        payload: {
                            ...settings.adminGlobalSettings,
                            globalAdminSeePlanDetails: !teamSettingsState.admin.seePlanDetails
                        }
                    });
                    return;
                case 'managePlan':
                    setTeamSettingsState({
                        ...teamSettingsState,
                        admin: {
                            ...teamSettingsState.admin,
                            managePlan: !teamSettingsState.admin.managePlan
                        }
                    });
                    dispatch({
                        type: 'SET_GLOBAL_ADMIN_SETTINGS',
                        payload: {
                            ...settings.adminGlobalSettings,
                            globalAdminManagePlan: !teamSettingsState.admin.managePlan
                        }
                    });
                    return;
                case 'widgetCustomisation':
                    setTeamSettingsState({
                        ...teamSettingsState,
                        admin: {
                            ...teamSettingsState.admin,
                            widgetCustomisation: !teamSettingsState.admin.widgetCustomisation
                        }
                    });
                    dispatch({
                        type: 'SET_GLOBAL_ADMIN_SETTINGS',
                        payload: {
                            ...settings.adminGlobalSettings,
                            globalAdminWidgetCustomisation: !teamSettingsState.admin.widgetCustomisation
                        }
                    });
                    return;
                case 'inviteMoreAdmins':
                    setTeamSettingsState({
                        ...teamSettingsState,
                        admin: {
                            ...teamSettingsState.admin,
                            inviteMoreAdmins: !teamSettingsState.admin.inviteMoreAdmins
                        }
                    });
                    dispatch({
                        type: 'SET_GLOBAL_ADMIN_SETTINGS',
                        payload: {
                            ...settings.adminGlobalSettings,
                            globalInviteMoreAdmins: !teamSettingsState.admin.inviteMoreAdmins
                        }
                    });
                    return;
                case 'generalSettings':
                    setTeamSettingsState({
                        ...teamSettingsState,
                        admin: {
                            ...teamSettingsState.admin,
                            generalSettings: !teamSettingsState.admin.generalSettings
                        }
                    });
                    dispatch({
                        type: 'SET_GLOBAL_ADMIN_SETTINGS',
                        payload: {
                            ...settings.adminGlobalSettings,
                            globalAdminGeneralSettings: !teamSettingsState.admin.generalSettings
                        }
                    });
                    return;
                default:
                    return '';
            }
        } else {
            switch (type) {
                case 'seeEntirePortfolio':
                    setTeamSettingsState({
                        ...teamSettingsState,
                        member: {
                            ...teamSettingsState.member,
                            seeEntirePortfolio: !teamSettingsState.member.seeEntirePortfolio
                        }
                    });
                    dispatch({
                        type: 'SET_GLOBAL_MEMBER_SETTINGS',
                        payload: {
                            ...settings.memberGlobalSettings,
                            globalMemberSeeEntirePortfolio: !teamSettingsState.member.seeEntirePortfolio
                        }
                    });
                    return;
                case 'manageTeamPortfolio':
                    setTeamSettingsState({
                        ...teamSettingsState,
                        member: {
                            ...teamSettingsState.member,
                            manageTeamPortfolio: !teamSettingsState.member.manageTeamPortfolio
                        }
                    });
                    dispatch({
                        type: 'SET_GLOBAL_MEMBER_SETTINGS',
                        payload: {
                            ...settings.memberGlobalSettings,
                            globalMemberManageTeamPortfolio: !teamSettingsState.member.manageTeamPortfolio
                        }
                    });
                    return;
                case 'createSpace':
                    setTeamSettingsState({
                        ...teamSettingsState,
                        member: {
                            ...teamSettingsState.member,
                            createSpace: !teamSettingsState.member.createSpace
                        }
                    });
                    dispatch({
                        type: 'SET_GLOBAL_MEMBER_SETTINGS',
                        payload: {
                            ...settings.memberGlobalSettings,
                            globalMemberCreateSpace: !teamSettingsState.member.createSpace
                        }
                    });
                    return;
                case 'sendInvitations':
                    setTeamSettingsState({
                        ...teamSettingsState,
                        member: {
                            ...teamSettingsState.member,
                            sendInvitations: !teamSettingsState.member.sendInvitations
                        }
                    });
                    dispatch({
                        type: 'SET_GLOBAL_MEMBER_SETTINGS',
                        payload: {
                            ...settings.memberGlobalSettings,
                            globalMemberSendInvitations: !teamSettingsState.member.sendInvitations
                        }
                    });
                    return;
                case 'editTeamMemberDetails':
                    setTeamSettingsState({
                        ...teamSettingsState,
                        member: {
                            ...teamSettingsState.member,
                            editTeamMemberDetails: !teamSettingsState.member.editTeamMemberDetails
                        }
                    });
                    dispatch({
                        type: 'SET_GLOBAL_MEMBER_SETTINGS',
                        payload: {
                            ...settings.memberGlobalSettings,
                            globalMemberEditTeamMemberDetails: !teamSettingsState.member.editTeamMemberDetails
                        }
                    });
                    return;
                case 'seePlanDetails':
                    setTeamSettingsState({
                        ...teamSettingsState,
                        member: {
                            ...teamSettingsState.member,
                            managePlan: !teamSettingsState.member.seePlanDetails === false ? false : teamSettingsState.member.managePlan,
                            seePlanDetails: !teamSettingsState.member.seePlanDetails
                        }
                    });
                    dispatch({
                        type: 'SET_GLOBAL_MEMBER_SETTINGS',
                        payload: {
                            ...settings.memberGlobalSettings,
                            globalMemberSeePlanDetails: !teamSettingsState.member.seePlanDetails
                        }
                    });
                    return;
                case 'managePlan':
                    setTeamSettingsState({
                        ...teamSettingsState,
                        member: {
                            ...teamSettingsState.member,
                            managePlan: !teamSettingsState.member.managePlan
                        }
                    });
                    dispatch({
                        type: 'SET_GLOBAL_MEMBER_SETTINGS',
                        payload: {
                            ...settings.memberGlobalSettings,
                            globalMemberManagePlan: !teamSettingsState.member.managePlan
                        }
                    });
                    return;
                default:
                    return '';
            }
        }
    };

    return (
        <div className="team-settings-container">
            <h4 className="team-heading">{team('settings.team.permissions')}</h4>
            <div className="d-flex">
                <div className="team-control-container">
                    {owner ? (
                        <div
                            onClick={() => {
                                setIsAdminState(true);
                            }}
                            className={`team-control-container-wrapper ${isAdminState ? 'active' : ''}`}
                        >
                            <div className="team-control-container-wrapper-content">
                                <h4 style={{ backgroundColor: '#000' }}>{team('settings.team.admin.title')}</h4>
                                <p>{team('settings.team.admin.subtitle')}</p>
                            </div>
                            <img src={RightChevron} alt="" />
                        </div>
                    ) : null}
                    <div
                        onClick={() => {
                            setIsAdminState(false);
                        }}
                        className={`team-control-container-wrapper ${isAdminState ? '' : 'active'}`}
                    >
                        <div className="team-control-container-wrapper-content">
                            <h4>{team('settings.team.member.title')}</h4>
                            <p>{team('settings.team.member.subtitle')}</p>
                        </div>
                        <img src={RightChevron} alt="" />
                    </div>
                </div>
                <div className="team-options-container">
                    {!isAdminState ? (
                        <>
                            <h4 className="heading mt-0 mb-20">{team('settings.team.member.portfolio.title')}</h4>
                            <div className="d-flex space-between center-align team-options-container-heading">
                                <div>
                                    <h4>{team('settings.team.member.portfolio.see_portfolio.title')}</h4>
                                    <p>{team('settings.team.member.portfolio.see_portfolio.subtitle')}</p>
                                </div>
                                <SwitchAtom
                                    handleToggle={() => teamSwitchOptions('seeEntirePortfolio')}
                                    toggleVal={teamSettingsState.member.seeEntirePortfolio}
                                ></SwitchAtom>
                            </div>
                            <hr />

                            <div className="d-flex space-between center-align team-options-container-heading">
                                <div>
                                    <h4>{team('settings.team.member.portfolio.manage.title')}</h4>
                                    <p>{team('settings.team.member.portfolio.manage.subtitle')}</p>
                                </div>
                                <SwitchAtom
                                    handleToggle={() => teamSwitchOptions('manageTeamPortfolio')}
                                    toggleVal={teamSettingsState.member.manageTeamPortfolio}
                                ></SwitchAtom>
                            </div>
                            <hr />
                            <div className="d-flex space-between center-align team-options-container-heading">
                                <div>
                                    <h4>{team('settings.team.member.portfolio.create.title')}</h4>
                                    <p>{team('settings.team.member.portfolio.create.subtitle')}</p>
                                </div>
                                <SwitchAtom
                                    handleToggle={() => teamSwitchOptions('createSpace')}
                                    toggleVal={teamSettingsState.member.createSpace}
                                ></SwitchAtom>
                            </div>
                            <hr />

                            <h4 className="heading mt-60 mb-20">{team('settings.team.member.team.title')}</h4>
                            <div className="d-flex space-between center-align team-options-container-heading">
                                <div>
                                    <h4>{team('settings.team.member.team.send_invitations.title')}</h4>
                                    <p>{team('settings.team.member.team.send_invitations.subtitle')}</p>
                                </div>
                                <SwitchAtom
                                    handleToggle={() => teamSwitchOptions('sendInvitations')}
                                    toggleVal={teamSettingsState.member.sendInvitations}
                                ></SwitchAtom>
                            </div>
                            <hr />

                            <div className="d-flex space-between center-align team-options-container-heading">
                                <div>
                                    <h4>{team('settings.team.member.team.edit.title')}</h4>
                                    <p>{team('settings.team.member.team.edit.subtitle')}</p>
                                </div>
                                <SwitchAtom
                                    handleToggle={() => teamSwitchOptions('editTeamMemberDetails')}
                                    toggleVal={teamSettingsState.member.editTeamMemberDetails}
                                ></SwitchAtom>
                            </div>
                            <hr />

                            <h4 className="heading mt-60 mb-20">{team('settings.team.member.plan.title')}</h4>
                            <div className="d-flex space-between center-align team-options-container-heading">
                                <div>
                                    <h4>{team('settings.team.member.plan.see_plan.title')}</h4>
                                    <p>{team('settings.team.member.plan.see_plan.subtitle')}</p>
                                </div>
                                <SwitchAtom
                                    handleToggle={() => teamSwitchOptions('seePlanDetails')}
                                    toggleVal={teamSettingsState.member.seePlanDetails}
                                ></SwitchAtom>
                            </div>
                            <hr />

                            <div className="d-flex space-between center-align team-options-container-heading">
                                <div>
                                    <h4>{team('settings.team.member.plan.manage_plan.title')}</h4>
                                    <p>{team('settings.team.member.plan.manage_plan.title')}</p>
                                </div>
                                <SwitchAtom
                                    disabled={teamSettingsState.member.seePlanDetails === false ? true : false}
                                    handleToggle={() => {
                                        if (teamSettingsState.member.seePlanDetails) teamSwitchOptions('managePlan');
                                    }}
                                    toggleVal={teamSettingsState.member.managePlan}
                                ></SwitchAtom>
                            </div>
                        </>
                    ) : (
                        <>
                            {/* portfolio */}
                            <h4 className="heading mt-0 mb-20">{team('settings.team.admin.portfolio.title')}</h4>
                            <div className="d-flex space-between center-align team-options-container-heading">
                                <div>
                                    <h4>{team('settings.team.admin.portfolio.see_portfolio.title')}</h4>
                                    <p>{team('settings.team.admin.portfolio.see_portfolio.subtitle')}</p>
                                </div>
                                <SwitchAtom
                                    handleToggle={() => teamSwitchOptions('seeEntirePortfolio')}
                                    toggleVal={teamSettingsState.admin.seeEntirePortfolio}
                                ></SwitchAtom>
                            </div>
                            <hr />

                            <div className="d-flex space-between center-align team-options-container-heading">
                                <div>
                                    <h4>{team('settings.team.admin.portfolio.manage.title')}</h4>
                                    <p>{team('settings.team.admin.portfolio.manage.subtitle')}</p>
                                </div>
                                <SwitchAtom
                                    handleToggle={() => teamSwitchOptions('manageTeamPortfolio')}
                                    toggleVal={teamSettingsState.admin.manageTeamPortfolio}
                                ></SwitchAtom>
                            </div>
                            <hr />
                            <div className="d-flex space-between center-align team-options-container-heading">
                                <div>
                                    <h4>{team('settings.team.admin.portfolio.create.title')}</h4>
                                    <p>{team('settings.team.admin.portfolio.create.subtitle')}</p>
                                </div>
                                <SwitchAtom
                                    handleToggle={() => teamSwitchOptions('createSpace')}
                                    toggleVal={teamSettingsState.admin.createSpace}
                                ></SwitchAtom>
                            </div>
                            <hr />

                            {/* team */}
                            <h4 className="heading mt-60 mb-20">{team('settings.team.admin.team.title')}</h4>
                            <div className="d-flex space-between center-align team-options-container-heading">
                                <div>
                                    <h4>{team('settings.team.admin.team.send_invitations.title')}</h4>
                                    <p>{team('settings.team.admin.team.send_invitations.subtitle')}</p>
                                </div>
                                <SwitchAtom
                                    handleToggle={() => teamSwitchOptions('sendInvitations')}
                                    toggleVal={teamSettingsState.admin.sendInvitations}
                                ></SwitchAtom>
                            </div>
                            <hr />

                            <div className="d-flex space-between center-align team-options-container-heading">
                                <div>
                                    <h4>{team('settings.team.admin.team.edit.title')}</h4>
                                    <p>{team('settings.team.admin.team.edit.subtitle')}</p>
                                </div>
                                <SwitchAtom
                                    handleToggle={() => teamSwitchOptions('editTeamMemberDetails')}
                                    toggleVal={teamSettingsState.admin.editTeamMemberDetails}
                                ></SwitchAtom>
                            </div>
                            <hr />
                            <div className="d-flex space-between center-align team-options-container-heading">
                                <div>
                                    <h4>{team('settings.team.admin.team.invite.title')}</h4>
                                    <p>{team('settings.team.admin.team.invite.subtitle')}</p>
                                </div>
                                <SwitchAtom
                                    disabled={teamSettingsState.admin.sendInvitations === false ? true : false}
                                    handleToggle={() => {
                                        if (teamSettingsState.admin.sendInvitations) teamSwitchOptions('inviteMoreAdmins');
                                    }}
                                    toggleVal={teamSettingsState.admin.inviteMoreAdmins}
                                ></SwitchAtom>
                            </div>
                            <hr />

                            {/* plans */}
                            <h4 className="heading mt-60 mb-20">{team('settings.team.admin.plan.title')}</h4>
                            <div className="d-flex space-between center-align team-options-container-heading">
                                <div>
                                    <h4>{team('settings.team.admin.plan.see_plan.title')}</h4>
                                    <p>{team('settings.team.admin.plan.see_plan.subtitle')}</p>
                                </div>
                                <SwitchAtom
                                    handleToggle={() => teamSwitchOptions('seePlanDetails')}
                                    toggleVal={teamSettingsState.admin.seePlanDetails}
                                ></SwitchAtom>
                            </div>
                            <hr />

                            <div className="d-flex space-between center-align team-options-container-heading">
                                <div>
                                    <h4>{team('settings.team.admin.plan.manage_plan.title')}</h4>
                                    <p>{team('settings.team.admin.plan.manage_plan.subtitle')}</p>
                                </div>
                                <SwitchAtom
                                    disabled={teamSettingsState.admin.seePlanDetails === false ? true : false}
                                    handleToggle={() => {
                                        if (teamSettingsState.admin.seePlanDetails) teamSwitchOptions('managePlan');
                                    }}
                                    toggleVal={teamSettingsState.admin.managePlan}
                                ></SwitchAtom>
                            </div>

                            {/* ACCOUNT PREFERENCES */}
                            <h4 className="heading mt-60 mb-20">{team('settings.team.admin.account.title')}</h4>
                            <div className="d-flex space-between center-align team-options-container-heading">
                                <div>
                                    <h4>{team('settings.team.admin.account.widget_custom.title')}</h4>
                                    <p>{team('settings.team.admin.account.widget_custom.subtitle')}</p>
                                </div>
                                <SwitchAtom
                                    handleToggle={() => teamSwitchOptions('widgetCustomisation')}
                                    toggleVal={teamSettingsState.admin.widgetCustomisation}
                                ></SwitchAtom>
                            </div>
                            <hr />

                            <div className="d-flex space-between center-align team-options-container-heading">
                                <div>
                                    <h4>{team('settings.team.admin.account.permission.title')}</h4>
                                    <p>{team('settings.team.admin.account.permission.subtitle')}</p>
                                </div>
                                <SwitchAtom
                                    handleToggle={() => teamSwitchOptions('editAccountPermissions')}
                                    toggleVal={teamSettingsState.admin.editAccountPermissions}
                                ></SwitchAtom>
                            </div>
                            <hr />

                            <div className="d-flex space-between center-align team-options-container-heading">
                                <div>
                                    <h4>{team('settings.team.admin.account.general.title')}</h4>
                                    <p>{team('settings.team.admin.account.general.subtitle')}</p>
                                </div>
                                <SwitchAtom
                                    handleToggle={() => teamSwitchOptions('generalSettings')}
                                    toggleVal={teamSettingsState.admin.generalSettings}
                                ></SwitchAtom>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default WidgetSettings;
