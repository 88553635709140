import React, { useState, useContext } from 'react';
import BackImg from '../../assets/back.png';
import TextAtom from '../TextAtom';
import ButtonAtom from '../ButtonAtom';
import Pulse from 'react-reveal/Pulse';
import Input from '../Input';
import { isEmpty, validateCompany, validateEmail } from '../../utils';
import { DOMAIN } from '../../constants/base';
import './popupcta.scss';
import { currentSpaceContext } from '../../context';
import PhnInputAtom from '../PhnInputAtom';

// Assets
import CTALink from '../../assets/cta-link.png';
import CTAEmail from '../../assets/cta-mail.png';
import CTAPhone from '../../assets/cta-phone.png';
import CTACal from '../../assets/cta-cal.png';

const PopUpCTA = ({ setOpenCTAPopUp, fetchActiveSpaces }) => {
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const [currentSpace] = useContext(currentSpaceContext);
    // Input Handlers
    const [weblink, setWebLink] = useState(currentSpace.currentCTAValues.webLink);

    const [phone, setPhone] = useState({
        phone: currentSpace.currentCTAValues.phoneNumber ? currentSpace.currentCTAValues.phoneNumber.phone : '',
        countryCode: currentSpace.currentCTAValues.phoneNumber ? currentSpace.currentCTAValues.phoneNumber.countryCode : '',
        countryName: currentSpace.currentCTAValues.phoneNumber ? currentSpace.currentCTAValues.phoneNumber.countryName : ''
    });
    const [email, setEmail] = useState(currentSpace.currentCTAValues.email);
    const [addToCalendar, setAddToCalendar] = useState(currentSpace.currentCTAValues.calendar);
    // Error States
    const [weblinkError, setWebLinkError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [addToCalendarError, setAddToCalendarError] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    // Blur Handler
    const webLinkBlurHandler = () => {
        if (isEmpty(weblink)) {
            setWebLinkError(false);
            return;
        } else {
            const isValid = validateCompany(weblink);
            setWebLinkError(!isValid);
        }
    };

    const phoneBlurHandler = () => {
        if (isEmpty(phone.phone)) {
            setPhoneError(false);
            return;
        } else {
            setPhoneError(isEmpty(phone.phone));
        }
    };

    const emailBlurHandler = () => {
        if (isEmpty(email)) {
            setEmailError(false);
            return;
        } else {
            const isValid = validateEmail(email);
            setEmailError(!isValid);
        }
    };

    const addToCalendarBlurHandler = () => {
        if (isEmpty(addToCalendar)) {
            setAddToCalendarError(false);
            return;
        } else {
            const isValid = validateCompany(addToCalendar);
            setAddToCalendarError(!isValid);
        }
    };

    const onCTASubmit = async () => {
        let payload = {
            email: email,
            countryCode: phone.countryCode,
            countryName: phone.countryName.toUpperCase(),
            phone: phone.phone ? phone.phone.slice(phone.countryCode.length - 1) : '',
            calendar: addToCalendar,
            webLink: weblink
        };
        const url = `${DOMAIN}/v1/dashboard/spaces/${currentSpace.currentSpaceId}/cta`;
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                authorization: userToken.accessToken
            },
            body: JSON.stringify(payload)
        };
        fetch(url, requestOptions)
            .then((response) => response.json())
            .then(() => {
                fetchActiveSpaces();
                setOpenCTAPopUp(false);
            });
    };

    return (
        <div className="popups-wrapper">
            <Pulse duration={150}>
                <div className="form-card-wrapper">
                    <div className="d-flex w-100p">
                        <button className="back-button" onClick={() => setOpenCTAPopUp(false)}>
                            <img src={BackImg} alt="back"></img>
                        </button>
                        <h1 className="popup-heading ml-10">CTA's</h1>
                    </div>
                    <div className="form-wrapper mt-30">
                        <TextAtom
                            textContent={
                                currentSpace.currentProductName
                                    ? `${currentSpace.flatNumber ? currentSpace.flatNumber + ',' : ''} ${currentSpace.spaceAddress}`
                                    : "Enter CTA's"
                            }
                            fontFamily="Montserrat"
                            fontWeight="700"
                            fontSize="1rem"
                            color="#3A3A3A"
                        ></TextAtom>
                        <div className="input-wrapper mt-20">
                            <div className="d-flex space-between center-align">
                                <img className="cta-icons mr-20" src={CTALink} alt="cta icon"></img>

                                <Input
                                    type="text"
                                    label="Web Link"
                                    labelStyle="uc mb-5"
                                    placeholder="www.apple.com"
                                    value={weblink}
                                    onChange={(e) => setWebLink(e.target.value)}
                                    onBlur={webLinkBlurHandler}
                                    info={weblinkError ? 'INVALID LINK' : ''}
                                ></Input>
                            </div>
                            <div className="d-flex space-between center-align">
                                <img className="cta-icons mr-20" src={CTAPhone} alt="phone"></img>

                                <PhnInputAtom
                                    phone={phone}
                                    customMargin={true}
                                    customWidth="75%"
                                    setPhone={setPhone}
                                    onBlur={phoneBlurHandler}
                                    info={phoneError ? 'BLANK NO' : ''}
                                    setIsUpdated={setIsUpdated}
                                    isUpdated={isUpdated}
                                ></PhnInputAtom>
                            </div>
                            <div className="d-flex space-between center-align">
                                <img className="cta-icons mr-20" src={CTAEmail} alt="email icon"></img>

                                <Input
                                    type="text"
                                    label="Email Address"
                                    labelStyle="uc mb-5"
                                    placeholder="demo@email.com"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    onBlur={emailBlurHandler}
                                    info={emailError ? 'Invalid Email' : ''}
                                ></Input>
                            </div>
                            <div className="d-flex space-between center-align">
                                <img className="cta-icons mr-20" src={CTACal} alt="cal-icon"></img>

                                <Input
                                    type="text"
                                    label="Add To Calendar Link"
                                    labelStyle="uc mb-5"
                                    placeholder="www.calendly.com/myuser"
                                    value={addToCalendar}
                                    onChange={(e) => setAddToCalendar(e.target.value)}
                                    onBlur={addToCalendarBlurHandler}
                                    info={addToCalendarError ? 'INVALID LINK' : ''}
                                ></Input>
                            </div>

                            <div className="mt-20">
                                <ButtonAtom label="SAVE" onClick={onCTASubmit}></ButtonAtom>
                            </div>
                        </div>
                    </div>
                </div>
            </Pulse>
        </div>
    );
};

export default PopUpCTA;
