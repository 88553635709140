import React from 'react';
import { useTranslation } from 'react-i18next';
import Pulse from 'react-reveal/Pulse';
import ClickAwayContentWrapper from '../../components/ClickAwayContentWrapper';
import './agentTemplatePopup.scss';
export default function AgentTemplateSavePopUp(props) {
    const { isDeleteTemplate, setTemplateSavePopUp, handleSaveTemplate, deleteTemplate } = props;
    const { t: templateSave } = useTranslation();
    return (
        <div className="popups-wrapper" style={{ zIndex: '101' }}>
            <ClickAwayContentWrapper onClose={() => setTemplateSavePopUp(false)}>
                <Pulse duration={150}>
                    <div className="template-save-wrapper">
                        <div className="form-wrapper" style={{ alignItems: 'flex-start' }}>
                            <h1>
                                {!isDeleteTemplate
                                    ? templateSave('agent.templates.save_popup_title')
                                    : templateSave('agent.templates.delete_popup_title')}
                            </h1>
                            <p>
                                {!isDeleteTemplate
                                    ? templateSave('agent.templates.save_popup_msg')
                                    : templateSave('agent.templates.delete_popup_msg')}
                            </p>
                            {isDeleteTemplate ? (
                                <div className="remove-btn-wrapper  w-100p">
                                    <button
                                        onClick={() => {
                                            setTemplateSavePopUp(false);
                                        }}
                                        className="active"
                                    >
                                        {templateSave('agent.templates.go_back')}
                                    </button>
                                    <button className="not-active delete" onClick={() => deleteTemplate()}>
                                        {templateSave('agent.templates.del')}
                                    </button>
                                </div>
                            ) : (
                                <div className="remove-btn-wrapper w-100p ">
                                    <button
                                        className="active"
                                        onClick={() => {
                                            handleSaveTemplate();
                                        }}
                                    >
                                        {templateSave('agent.templates.save')}
                                    </button>
                                    <button className="not-active" onClick={() => setTemplateSavePopUp(false)}>
                                        {templateSave('agent.templates.go_back')}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </Pulse>
            </ClickAwayContentWrapper>
        </div>
    );
}
