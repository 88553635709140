import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import './firsttimepopup.scss';
import { postReq, getReq } from '../../utils/api';
import { getSessionStorage, isEmpty, removeSessionStorage, setSessionStorage } from '../../utils/';
import { DOMAIN } from '../../constants/base';
import { toast } from 'react-toastify';
import { GlobalProfile, SettingsContext } from '../../context';
import { useState } from 'react';
import ButtonAtom from '../ButtonAtom';
// Assets
import EmptyBannerBackground from '../../assets/empty-banner-bg.png';
import SpencerImage from '../../assets/spencer-empty-banner.png';
import QuoteImage from '../../assets/empty-banner-quote.png';
import DownloadApple from '../../assets/apple-download-black.png';
import DownloadGoogle from '../../assets/googleplay-download-black.png';

const FirstTimePopUp = ({ setNewItemPopUp, setEditMode, editMode, showProgress, setShowProgress }) => {
    // const history = useHistory();
    const [, dispatch] = useContext(GlobalProfile);
    const [globalSettings] = useContext(SettingsContext);
    const [shouldRedirect, setRedirect] = useState(false);
    const userDetails = getSessionStorage('ss-user-spaces') || {};
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const [isUserDetails, setIsUserDetails] = useState(isEmpty(userDetails) ? false : true);
    const { role } = userDetails;
    const admin = role === 'ADMIN',
        owner = role === 'OWNER';
    const { adminGlobalSettings, memberGlobalSettings } = globalSettings;

    const createSpaceCheck = () => {
        if (owner) return true;
        else if (admin && adminGlobalSettings?.globalAdminCreateSpace) return true;
        else if (!owner && !admin && memberGlobalSettings?.globalMemberCreateSpace) return true;
        else return false;
    };
    useEffect(() => {
        //Api to get user details with access token
        if (!isUserDetails && isEmpty(userDetails)) {
            fetchUserDetails();
        }
    }, []);

    if (shouldRedirect) {
        return <Redirect to="/signin" />;
    }
    const handleSendEmail = async () => {
        const url = `${DOMAIN}/v1/dashboard/account/send-email/download-space-app`;
        let payload = { email: userDetails.email };
        const EmailSentReponse = await postReq({
            url,
            payload
        });
        const { code } = EmailSentReponse;
        if (code === 200) {
            toast.success('Email Sent Successfully', {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        }
    };

    async function fetchUserDetails() {
        const url = `${DOMAIN}/v1/dashboard/space/user/login/details`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const statusResponse = await getReq({
            url,
            requestHeaders
        });
        const { code, data } = statusResponse;
        if (statusResponse === 'You have exceeded the requests limit! Please try again after few minutes') {
            localStorage.removeItem('ss-auth-spaces');
            localStorage.setItem('ss-lang', 'en');
            removeSessionStorage('ss-user-spaces');
            setRedirect(true);
        }
        if (code === 200) {
            if (data.currentPlan) {
                dispatch({
                    type: 'EDIT_GLOBAL_PROFILE',
                    payload: {
                        plan: data.currentPlan,
                        teamExists: data.teamExists,
                        role: data.role
                    }
                });
            }
            setSessionStorage({
                key: 'ss-user-spaces',
                value: data,
                expiry: 20160 // 2 Weeks
            });
            setIsUserDetails(true);
        }
    }

    // const BeginTrial = async () => {
    //     setShowProgress(true);
    //     const url = `${DOMAIN}/v1/dashboard/space/plans/begin-trial`;
    //     const requestHeaders = {
    //         authorization: userToken.accessToken
    //     };

    //     const response = await getReq({
    //         url,
    //         requestHeaders
    //     });
    //     const { successCode, data } = response;
    //     setSessionStorage({
    //         key: 'ss-user-spaces',
    //         value: {
    //             ...userDetails,
    //             currentPlan: data.currentPlan
    //         }
    //     });
    //     setShowProgress(false);
    //     history.go(0);
    // };

    const appStore = () => {
        const URL = 'https://apps.apple.com/gb/app/holofy-spaces/id1518492855';
        window.open(URL, '_blank');
    };

    const playStore = () => {
        const URL = 'https://play.google.com/store/apps/details?id=com.holofy.store';
        window.open(URL, '_blank');
    };

    return (
        <>
            <div className="w-100p mt-20 pl-20 pr-20" style={{ height: 'calc(100vh - 68px)' }}>
                <div className="products-heading d-flex f-row space-between center-align fs-18 fw-900 mt-9">
                    <h1>Your Spaces</h1>
                </div>
                <div className="empty-state-wrapper">
                    <div className="section-one">
                        <img className="bg" src={EmptyBannerBackground} alt=""></img>
                        <div className="content-wrapper">
                            <img className="testimonial-image" alt="spencer" src={SpencerImage}></img>
                            <h1>Spencer Lawrence</h1>
                            <p>Managing Director of Lettings, Paramount Properties</p>
                            <img className="testimonial-quote" alt="quote" src={QuoteImage}></img>
                            <p className="testimonial-text">
                                “Holofy Spaces helped Paramount exponentially grow the business in just 8 weeks”
                            </p>
                        </div>
                    </div>
                    <div className="section-two">
                        <div className="section-two-partone">
                            <h1>
                                Use the agent App to <br /> create on the go{' '}
                            </h1>
                            <div className="section-two-partone-button-wrapper">
                                <button onClick={playStore}>
                                    <img src={DownloadGoogle} alt=""></img>
                                </button>
                                <button onClick={appStore}>
                                    <img src={DownloadApple} alt=""></img>
                                </button>
                            </div>
                            <a
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleSendEmail();
                                }}
                                href="!#"
                            >
                                Send link to apps on my Email
                            </a>
                        </div>
                        <div className="section-two-parttwo">
                            <div className="section-two-line"></div>
                            <p>Or</p>
                            <div className="section-two-line"></div>
                        </div>
                        <div className="section-two-partthree">
                            <h1>Create your first space</h1>
                            <p>
                                Upload video collections, attach custom <br /> CTA’s and pair them to your website.
                            </p>
                            <ButtonAtom
                                label="ADD NEW SPACE"
                                buttonStyle="empty-state-add-new"
                                onClick={() => setNewItemPopUp(true)}
                                disabled={!createSpaceCheck()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FirstTimePopUp;
