import React, { useState } from 'react';
import CompanyField from '../CompanyField';
import InviteByEmail from '../InviteEmail';
import MultiStepPopup from '../MultiStepPopUp';
import './setupteam.scss';
export default function SetUpTeam(props) {
    const { setisBeginSetup, currentStep, notSetupTeam, title, fetchActiveMembers, beginSetUp } = props;
    const [activeStep, setActiveStep] = useState(currentStep ? currentStep : 1);
    const [companyName, setCompanyName] = useState('');
    const [steps] = useState([1, 2]);

    const handleBackButton = () => {
        if (activeStep === 1) {
            setisBeginSetup(false);
        } else if (activeStep === 2) {
            if (notSetupTeam) {
                setisBeginSetup(false);
            } else {
                setActiveStep(activeStep - 1);
            }
        }
    };

    const renderDots = () => {
        if (notSetupTeam) {
            return;
        }
        return steps.map((step, idx) => {
            if (idx + 1 === activeStep) {
                return <div className="circle active" key={step.name}></div>;
            } else {
                return <div className="circle" key={step.name}></div>;
            }
        });
    };

    return (
        <MultiStepPopup setMultiStepPopUp={setisBeginSetup} handleBackButton={handleBackButton} renderDots={renderDots}>
            <div className="edit-profile-container">
                <div className="wrapper">
                    {activeStep === 1 && (
                        <CompanyField activeStep={activeStep} setCompanyName={setCompanyName} setActiveStep={setActiveStep} />
                    )}
                    {activeStep === 2 && (
                        <InviteByEmail
                            setInvitePopUp={setisBeginSetup}
                            setupteam={notSetupTeam ? notSetupTeam : true}
                            title={title}
                            fetchActiveMembers={fetchActiveMembers}
                            beginSetUp={beginSetUp}
                        />
                    )}
                </div>
            </div>
        </MultiStepPopup>
    );
}
