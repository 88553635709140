import React from 'react';
import BackEndIssues from '../../containers/BackEndIssues';

export default function BackEndIssuePage() {
    return (
        <>
            <BackEndIssues />
        </>
    );
}
