import React, { useState, useEffect } from 'react';
import './inviteLogin.scss';
import { isEmpty, getSessionStorage, getParameterByName, setSessionStorage } from '../../utils';
import VideoAtom from '../../components/VideoAtom';
import TextAtom from '../../components/TextAtom';
import ButtonAtom from '../../components/ButtonAtom';
import ProductAtom from '../../components/ProductAtom';
import Avatar from 'react-avatar';
import LoadingSpin from '../../components/LoadingSpin';
import { Bounce } from 'react-reveal';
import { Redirect } from 'react-router-dom';
import { DOMAIN } from '../../constants/base';
import { getReq } from '../../utils/api';

function InviteLoginContainer(props) {
    const [teamInfoData, setTeamInfoData] = useState({});
    const [loading, setLoading] = useState(true);
    const [shouldRedirect, setRedirect] = useState(false);
    const [shouldRedirectMobileBlock, setShouldRedirectMobileBlock] = useState(false);

    const mobileBlock = document.getElementById('mobileBlock');

    const sessionData = getSessionStorage('ss-auth');
    const userToken = getParameterByName('userToken');

    useEffect(() => {
        window.Intercom('boot', {
            app_id: process.env.REACT_APP_INTERCOM_KEY
        });
    }, []);

    useEffect(() => {
        (async () => {
            const url = `${DOMAIN}/v1/dashboard/space/user/v2/invite-team/team-info`;
            const requestHeaders = {
                authorization: userToken
            };
            const userInviteResponse = await getReq({ url, requestHeaders });
            const { successCode = '', data = {} } = userInviteResponse;
            if (successCode === 'SUCC200') {
                setTeamInfoData(data);
                setLoading(false);
            }
        })();
    }, []);

    const joinTeamHandler = async () => {
        const url = `${DOMAIN}/v1/dashboard/space/user/v2/invite-team/join-team/${userToken}`;
        const requestHeaders = {
            authorization: userToken
        };
        const userInviteResponse = await getReq({ url, requestHeaders });
        const { successCode = '', data = {} } = userInviteResponse;
        if (successCode === 'SUCC200' && window.innerWidth >= 800) {
            localStorage.setItem('ss-auth-spaces', JSON.stringify(data));
            setSessionStorage({
                key: 'ss-user-spaces',
                value: {
                    ...data,
                    teamExists: true
                },
                expiry: 20160 // 2 Weeks
            });
            setRedirect(true);
        }
        if (successCode === 'SUCC200' && window.innerWidth < 800) {
            localStorage.setItem('ss-auth-spaces', JSON.stringify(data));
            if (mobileBlock) {
                mobileBlock.style.display = 'flex';
            }
            setSessionStorage({
                key: 'ss-user-spaces',
                value: {
                    ...data,
                    teamExists: true
                },
                expiry: 20160 // 2 Weeks
            });
            setShouldRedirectMobileBlock(true);
        }
    };

    if (isEmpty(userToken)) {
        if (sessionData) {
            return <Redirect to="/myTeam" />;
        }
        return <Redirect to="/signin" />;
    }

    if (shouldRedirect) {
        return <Redirect to="/myTeam" />;
    }

    if (shouldRedirectMobileBlock) {
        return <Redirect to="/mobileBlock" />;
    }

    return (
        <>
            <div className="invite">
                <ProductAtom></ProductAtom>
                <VideoAtom></VideoAtom>
                <Bounce>
                    <div className="form-card-wrapper-invite custom-blur">
                        {loading ? (
                            <LoadingSpin></LoadingSpin>
                        ) : (
                            <div>
                                <TextAtom
                                    textContent={`Hi ${teamInfoData.name ? teamInfoData.name : ''}`}
                                    className="w-100p"
                                    fontWeight="700"
                                    fontSize="20px"
                                    lineHeight="25px"
                                ></TextAtom>
                                <div className="d-flex center-align align-middle mt-10">
                                    {teamInfoData.avatars && teamInfoData.avatars[0].length > 1 ? (
                                        <div className="image-wrapper-main">
                                            <img src={teamInfoData.avatars && teamInfoData.avatars[0]} alt=""></img>
                                        </div>
                                    ) : (
                                        <Avatar
                                            name={teamInfoData.avatars && teamInfoData.avatars[0]}
                                            size="56"
                                            round={true}
                                            color="black"
                                            maxInitials={1}
                                            textSizeRatio={2}
                                            textMarginRatio={0}
                                            style={{ fontWeight: '700', marginLeft: '5px' }}
                                        />
                                    )}

                                    <p className="ml-10">
                                        {teamInfoData.adminName ? teamInfoData.adminName : ''}, your teammate has invited you to join Holofy
                                        Spaces
                                    </p>
                                </div>
                                <div className="mt-10 w-100p">
                                    <p>
                                        {teamInfoData.memberCount && teamInfoData.memberCount}
                                        {teamInfoData.memberCount > 1 ? ' others are' : ' other is'} waiting for you
                                    </p>
                                    <div className="d-flex vertical-center">
                                        {teamInfoData.avatars &&
                                            teamInfoData.avatars.map((avatar) => {
                                                return avatar.length > 1 ? (
                                                    <div className="image-wrapper-invite ml-5">
                                                        <img src={avatar} alt="avatar"></img>
                                                    </div>
                                                ) : (
                                                    <Avatar
                                                        name={avatar}
                                                        size="32"
                                                        round={true}
                                                        color="black"
                                                        maxInitials={1}
                                                        textSizeRatio={2}
                                                        textMarginRatio={0}
                                                        style={{ fontWeight: '700', marginLeft: '5px' }}
                                                    />
                                                );
                                            })}
                                        {teamInfoData.memberCount && teamInfoData.memberCount > 5 ? (
                                            <button className="more-invite-btn ml-5">
                                                {`+${teamInfoData.memberCount && teamInfoData.memberCount - teamInfoData.avatars.length}`}
                                            </button>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                                <div className="line mt-20"></div>
                                <div className="mt-20 w-100p">
                                    <div className="email-inpt d-flex f-col space-between">
                                        <label className="d-ib mb-5">Email</label>
                                        <div className="input-wrap">
                                            <Avatar
                                                name={teamInfoData.name && teamInfoData.name}
                                                size="24"
                                                round={true}
                                                color="black"
                                                maxInitials={1}
                                                textSizeRatio={2}
                                                textMarginRatio={0}
                                                style={{ fontWeight: '700', marginLeft: '10px' }}
                                            />
                                            <h2 className="email-custom">{teamInfoData.email ? teamInfoData.email : 'email@email.com'}</h2>
                                        </div>
                                    </div>
                                    <div className="d-flex f-col vertical-center mt-20">
                                        <ButtonAtom label="JOIN TEAM" onClick={joinTeamHandler}></ButtonAtom>
                                    </div>
                                    <div className="important-note-wrapper mt-20">
                                        <h2>Important Note:</h2>
                                        <p>Any spaces created on your account will be included to this team.</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </Bounce>
            </div>
        </>
    );
}

export default InviteLoginContainer;
