import React, { useEffect, useState, useContext } from 'react';
import './statspopup.scss';
import Pulse from 'react-reveal/Pulse';
import { postReq } from '../../utils/api';
import { toolTipDateFormat, humanFormatDate, getDateFormat } from '../../utils';
import { DOMAIN } from '../../constants/base';
import { StatsContext } from '../../context';
import AreaChartComponent from '../../components/AreaChart';
// React Date Range

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

// Assets
import BackBtn from '../../assets/back.png';
import EyeIcon from '../../assets/eye-icon.png';
import StatsCalendar from '../../assets/stats-calendar.png';
import RetentionIcon from '../../assets/retention-stat.svg';
import StopWatch from '../../assets/stopwatch.svg';
import BedIcon from '../../assets/bed-icon.svg';
import BathIcon from '../../assets/bath-icon.svg';
import AreaIcon from '../../assets/area-icon.svg';
import TrafficBar from '../TrafficBar';
import { useTranslation } from 'react-i18next';
const StatsPopUpGraph = ({ onBack }) => {
    const [statsValue] = useContext(StatsContext);
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const [graphData, setGraphData] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    // prevent user to select date beyond creation of space
    const [minimumDate, setMinimumDate] = useState();

    // const [emptyGraph, setEmptyGraph] = useState(false);
    const [trafficData, setTrafficData] = useState([]);
    const [graphDateMillis, setGraphDateMillis] = useState([]);
    const endDateI = new Date(new Date().setHours(0, 0, 0, 0));
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const [range, setRange] = useState({
        startDate: today.getTime() - 7 * 24 * 60 * 60 * 1000,
        endDate: endDateI.getTime() + (24 * 60 * 60 * 1000 - 1000)
    });
    const [rangesInMillis, setRangesInMillis] = useState({
        startDate: today.getTime() - 7 * 24 * 60 * 60 * 1000,
        endDate: endDateI.getTime() + (24 * 60 * 60 * 1000 - 1000)
    });
    const [validRange, setValidRange] = useState(null);
    const { t: statsGraph } = useTranslation();
    let graphType = '';

    useEffect(() => {
        if (graphData.trafficRes) {
            let sortedData = graphData.trafficRes.sort((s1, s2) => (s1.value > s2.value ? -1 : 1));
            setTrafficData(sortedData);
        }
    }, [graphData]);

    // Title
    const generateTitle = () => {
        switch (statsValue.currentGraphType) {
            case 'overall-impressions':
                graphType = 'overall';
                return statsGraph('stats.overall_impressions');
            case 'overall-retention-rate':
                graphType = 'overall';
                return statsGraph('stats.avg_rtn_rate');
            case 'cta-action-rates':
                graphType = 'overall';
                return statsGraph('stats.overall_cta_action_rate');
            case 'top-traffic-sources':
                graphType = 'top-traffic-sources';
                return statsGraph('stats.traffic_sources');
            case 'video-performance':
                graphType = 'skill';
                return statsGraph('stats.video_performance');
            case 'space-retention-rate':
                graphType = 'space-retention-rate';
                return statsGraph('stats.avg_rtn_rate');
            case 'traffic-source':
                graphType = 'traffic-source';
                return statsGraph('stats.traffic_sources');
            default:
                break;
        }
    };

    // Generating Value
    const generateValue = () => {
        switch (statsValue.currentGraphType) {
            case 'overall-impressions':
                return graphData?.totalViews ? Number(graphData?.totalViews).toLocaleString() : 0;
            case 'overall-retention-rate':
                return graphData.retentionRate >= 0 ? graphData.retentionRate : '';
            case 'cta-action-rates':
                return graphData.avgConversion >= 0 ? graphData.avgConversion : '';
            case 'space-retention-rate':
                return graphData.retentionRate >= 0 ? graphData.retentionRate : '';
            default:
                break;
        }
    };

    // Tagline
    const generateTagline = () => {
        if (graphData.overview) {
            switch (statsValue.currentGraphType) {
                case 'overall-impressions':
                    return statsGraph('stats.Impressions');
                case 'overall-retention-rate':
                    return statsGraph('stats.avg_rtn_rate');
                case 'cta-action-rates':
                    return statsGraph('stats.cta_action_rate');
                case 'space-retention-rate':
                    return statsGraph('stats.avg_rtn_rate');
                default:
                    break;
            }
        }
    };

    // Date Range
    const handleDateSelect = (ranges) => {
        let startMillis = new Date(ranges.selection.startDate);
        startMillis = startMillis.getTime();
        let endMillis = new Date(ranges.selection.endDate);
        endMillis.setTime(
            endMillis.getTime() - endMillis.getHours() * 3600 * 1000 - endMillis.getMinutes() * 60 * 1000 - endMillis.getSeconds() * 1000
        );
        endMillis = endMillis.getTime();
        endMillis = endMillis + (24 * 60 * 60 * 1000 - 1000);
        setRangesInMillis({
            startDate: startMillis,
            endDate: endMillis
        });
        setRange(ranges.selection);
    };
    const selectionRange = {
        startDate: range.startDate,
        endDate: range.endDate,
        key: 'selection'
    };

    function formatDate(dateVal, inString) {
        const newDate = new Date(dateVal);
        var month = '' + (newDate.getMonth() + 1);
        var day = '' + newDate.getDate();
        var year = newDate.getFullYear();
        if (inString) {
            switch (month) {
                case '1':
                    return 'JAN ' + day + ', ' + year;
                case '2':
                    return 'FEb ' + day + ', ' + year;
                case '3':
                    return 'MAR ' + day + ', ' + year;
                case '4':
                    return 'APR ' + day + ', ' + year;
                case '5':
                    return 'MAY ' + day + ', ' + year;
                case '6':
                    return 'JUN ' + day + ', ' + year;
                case '7':
                    return 'JUL ' + day + ', ' + year;
                case '8':
                    return 'AUG ' + day + ', ' + year;
                case '9':
                    return 'SEP ' + day + ', ' + year;
                case '10':
                    return 'OCT ' + day + ', ' + year;
                case '11':
                    return 'NOV ' + day + ', ' + year;
                case '12':
                    return 'DEC ' + day + ', ' + year;
                default:
                    break;
            }
        } else {
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        }
    }

    // Graph Custom
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <div className="custom-tooltip-date">{toolTipDateFormat(payload[0].payload.dateInMillis)}</div>
                    {statsValue.currentGraphType === 'overall-impressions' && <h1>{statsGraph('stats.Impressions')}</h1>}
                    {statsValue.currentGraphType === 'overall-retention-rate' && <h1>{statsGraph('stats.avg_rtn_rate')}</h1>}
                    {statsValue.currentGraphType === 'cta-action-rates' && <h1>{statsGraph('stats.cta_action_rate')}</h1>}
                    {statsValue.currentGraphType === 'space-retention-rate' && <h1>{statsGraph('stats.avg_rtn_rate')}</h1>}
                    {statsValue.currentGraphType === 'space-retention-rate' ? (
                        <p className="label">{payload[0].payload.count}%</p>
                    ) : (
                        <p className="label">{payload[0].payload.count}</p>
                    )}
                </div>
            );
        }

        return null;
    };

    function formatXAxis(tickItem) {
        if (typeof tickItem === 'string') {
            return tickItem.slice(8);
        }
    }

    // const CustomXAxis = (props) => {
    //     const { x, y, width, height } = props.viewBox;
    //     return (
    //         <g marginTop="5px">
    //             <text
    //                 x={x + width}
    //                 y={350}
    //                 dy={16}
    //                 fontSize="10px"
    //                 fontWeight="600"
    //                 color="rgba(0,0,0,0.5)"
    //                 lineHeight="14px"
    //                 position="insideBottomRight"
    //                 textAnchor="end"
    //             >
    //                 (Date)
    //             </text>
    //         </g>
    //     );
    // };
    async function getGraphData(startDate, endDate, currentGraphType) {
        let payload = {};
        setShowSpinner(true);
        let url = `${DOMAIN}/v1/dashboard/space/stats/v3/`;
        if (currentGraphType === 'traffic-source') {
            url = url + `${currentGraphType}/${statsValue?.activeSpace?.spaceId}`;
        } else if (currentGraphType === 'space-retention-rate') {
            currentGraphType = 'retention-rate';
            url = url + `${currentGraphType}/${statsValue?.activeSpace?.spaceId}`;
        } else if (currentGraphType === 'top-traffic-sources') {
            url = url + `${currentGraphType}`;
        } else {
            url = url + `${currentGraphType}`;
        }

        payload = {
            startMillis: startDate,
            endMillis: endDate
        };
        const requestHeaders = {
            authorization: userToken.accessToken
        };

        const activeSpacesResponse = await postReq(
            {
                url,
                requestHeaders,
                payload
            },
            setShowSpinner
        );
        const { code, data = {} } = activeSpacesResponse;

        if (code === 200) {
            setGraphData(data);
            if (data.length < 1) {
                // setEmptyGraph(true);
            } else {
                convertIntoMillis(data.overview);
                // setEmptyGraph(false);
            }
            setShowSpinner(false);
        }
    }

    const convertIntoMillis = (data) => {
        if (data?.length) {
            let newData = [...data];
            newData.forEach(function (item, index) {
                item.dateInMillis = humanFormatDate(item.dateInMillis);
            });
            setGraphDateMillis(newData);
        }
    };

    useEffect(() => {
        if (statsValue.currentGraphType === 'overall-impressions') {
            setRange({
                startDate: statsValue.overallImpressions.startDate,
                endDate: statsValue.overallImpressions.endDate
            });
            let createdDate = new Date(statsValue.overallImpressions.accountCreated);
            setMinimumDate(getDateFormat(createdDate));
            getGraphData(statsValue.overallImpressions.startDate, statsValue.overallImpressions.endDate, statsValue.currentGraphType);
        } else if (statsValue.currentGraphType === 'overall-retention-rate') {
            setRange({
                startDate: statsValue.overallRetention.startDate,
                endDate: statsValue.overallRetention.endDate
            });
            let createdDate = new Date(statsValue.overallRetention.accountCreated);
            setMinimumDate(getDateFormat(createdDate));
            getGraphData(statsValue.overallRetention.startDate, statsValue.overallRetention.endDate, statsValue.currentGraphType);
        } else if (statsValue.currentGraphType === 'cta-action-rates') {
            setRange({
                startDate: statsValue.overallCTA.startDate,
                endDate: statsValue.overallCTA.endDate
            });
            let createdDate = new Date(statsValue.overallRetention.accountCreated);
            setMinimumDate(getDateFormat(createdDate));
            getGraphData(statsValue.overallCTA.startDate, statsValue.overallCTA.endDate, statsValue.currentGraphType);
        } else if (statsValue.currentGraphType === 'space-retention-rate') {
            setRange({
                startDate: statsValue.spaceRetention.startDate,
                endDate: statsValue.spaceRetention.endDate
            });
            let createdDate = new Date(statsValue.activeSpace.createdDate);
            setMinimumDate(getDateFormat(createdDate));
            getGraphData(statsValue.spaceRetention.startDate, statsValue.spaceRetention.endDate, statsValue.currentGraphType);
        } else if (statsValue.currentGraphType === 'traffic-source') {
            setRange({
                startDate: statsValue.spaceTrafficSources.startDate,
                endDate: statsValue.spaceTrafficSources.endDate
            });
            let createdDate = new Date(statsValue.activeSpace.createdDate);
            setMinimumDate(getDateFormat(createdDate));
            getGraphData(statsValue.spaceTrafficSources.startDate, statsValue.spaceTrafficSources.endDate, statsValue.currentGraphType);
        } else if (statsValue.currentGraphType === 'top-traffic-sources') {
            setRange({
                startDate: statsValue.topTrafficSources.startDate,
                endDate: statsValue.topTrafficSources.endDate
            });
            let createdDate = new Date(statsValue.topTrafficSources.accountCreated);

            setMinimumDate(getDateFormat(createdDate));
            getGraphData(statsValue.topTrafficSources.startDate, statsValue.topTrafficSources.endDate, statsValue.currentGraphType);
        }
    }, []);

    const handleApplyFilter = () => {
        getGraphData(rangesInMillis.startDate, rangesInMillis.endDate, statsValue.currentGraphType);
        setShowCalendar(false);
    };

    return (
        <div className="statspopup-container">
            {showSpinner && (
                <div
                    className="pos-abs z-2"
                    style={{
                        marginTop: statsValue.currentGraphType === 'space-retention-rate' ? '50px' : '70px'
                    }}
                >
                    {statsValue.currentGraphType === 'traffic-source' || statsValue.currentGraphType === 'top-traffic-sources' ? (
                        <div className="loading-wrapper-graph">
                            <div className="shimmerBG loading-traffic-source-wrapper"></div>
                        </div>
                    ) : statsValue.currentGraphType === 'space-retention-rate' ? (
                        <div className="loading-wrapper-graph-videoperformance">
                            <div className="loading-top-wrapper-videoperformance one-videoperformance">
                                <div className="shimmerBG loading-square"></div>
                                <div className="loading-right">
                                    <div className="shimmerBG loading-right-top"></div>
                                    <div className="shimmerBG loading-right-bottom"></div>
                                </div>
                            </div>
                            <div className="loading-videoperformance-border"></div>
                            <div className="loading-top-wrapper-videoperformance two-videoperformance">
                                <div className="shimmerBG loading-circle"></div>
                                <div className="loading-right">
                                    <div className="shimmerBG loading-right-top"></div>
                                    <div className="shimmerBG loading-right-bottom"></div>
                                </div>
                            </div>
                            <div className="shimmerBG loading-bottom-wrapper-videoperformance"></div>
                        </div>
                    ) : (
                        <div className="loading-wrapper-graph">
                            <div className="loading-top-wrapper">
                                <div className="shimmerBG loading-circle"></div>
                                <div className="loading-right">
                                    <div className="shimmerBG loading-right-top"></div>
                                    <div className="shimmerBG loading-right-bottom"></div>
                                </div>
                            </div>
                            <div className="shimmerBG loading-bottom-wrapper"></div>
                        </div>
                    )}
                </div>
            )}
            <Pulse duration={150}>
                <div className="stats-popup">
                    {showCalendar && (
                        <div className="stats-calendar-popup">
                            <DateRangePicker
                                maxDate={new Date()}
                                minDate={new Date(minimumDate)}
                                disabledDates={[new Date(minimumDate)]}
                                ranges={[selectionRange]}
                                onChange={handleDateSelect}
                                className="stats-calendar"
                                rangeColors={['black']}
                                color="black"
                            />
                            <div className="stats-calendar-apply">
                                <div className="stats-calendar-apply-btn-wrapper">
                                    <button
                                        onClick={() => {
                                            setShowCalendar(false);
                                            if (validRange) {
                                                setRange(validRange);
                                                setValidRange(null);
                                            }
                                        }}
                                        className="stats-calendar-apply-btn"
                                    >
                                        {statsGraph('close')}
                                    </button>
                                    <button onClick={handleApplyFilter} className="stats-calendar-apply-btn-fill">
                                        {statsGraph('apply_filter')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="stats-popup-header">
                        <div className="stats-popup-title">
                            <button onClick={onBack}>
                                <img src={BackBtn} alt="icon"></img>
                            </button>
                            <h1>{generateTitle()}</h1>
                        </div>
                        {graphType === 'skill' ? (
                            ''
                        ) : (
                            <button onClick={() => setShowCalendar(!showCalendar)} className="stats-popup-calendar-btn">
                                <img src={StatsCalendar} alt="icon"></img>
                                {range.startDate &&
                                    range.endDate &&
                                    `${formatDate(range.startDate, true)} - ${formatDate(range.endDate, true)}`}
                            </button>
                        )}
                    </div>
                    {/* <div className="stats-popup-last-updated">
            Last Updated: {
              statsValue.currentGraphType === 'overall-impressions' ? statsValue?.lastUpdated?.overAllSpaceImpressions :
              (statsValue.currentGraphType === 'overall-retention-rate') ? statsValue?.lastUpdated?.overAllRetentionRate  : 
              (statsValue.currentGraphType === 'cta-action-rates') ? statsValue?.lastUpdated?.overAllCtaActionRate :
              statsValue?.lastUpdated?.overAllCtaActionRate
            }
          </div> */}
                    {graphType === 'skill' ? (
                        <>
                            <div className="skill-stat-wrapper">
                                {!showSpinner && (
                                    <div className="skill-stat-wrapper-content-main">
                                        <div className="stats-details">
                                            <div className="skill-poster">
                                                <img src={statsValue?.skillStats?.poster} alt="icon" />
                                            </div>
                                            <div className="skill-stats">
                                                {statsValue?.skillStats?.name.length > 0 ? (
                                                    <div className="skill-name">{statsValue?.skillStats?.name}</div>
                                                ) : (
                                                    ''
                                                )}
                                                <div className="skill-counts">
                                                    <img src={EyeIcon} alt="icon" /> {statsValue?.skillStats?.views}
                                                </div>
                                            </div>
                                            <div className="sep"></div>
                                        </div>

                                        <div className="stats-details">
                                            <div className="skill-stat-icon">
                                                <img src={RetentionIcon} alt="icon" />
                                            </div>
                                            <div className="skill-stats">
                                                <div className="stat-value">
                                                    {statsValue?.skillStats?.engagement}
                                                    <span className="perc">%</span>
                                                </div>
                                                <div className="stat-name">{statsGraph('stats.engagement')}</div>
                                            </div>
                                            <div className="sep"></div>
                                        </div>

                                        <div className="stats-details">
                                            <div className="skill-stat-icon">
                                                <img src={StopWatch} alt="icon" />
                                            </div>
                                            <div className="skill-stats">
                                                <div className="stat-value">
                                                    {statsValue?.skillStats?.avgWatchTime}
                                                    <span className="perc">sec</span>
                                                </div>
                                                <div className="stat-name">{statsGraph('stats.avg_watch_time')}</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {!showSpinner && (
                                <div className="stats-popup-content-wrapper" style={{ display: 'block', marginTop: '60px' }}>
                                    <div
                                        className="skill-stats-graph-wrapper"
                                        style={{
                                            display: 'block',
                                            borderTop: 'none',
                                            marginTop: '0px',
                                            paddingTop: '0px'
                                        }}
                                    >
                                        <h1>{statsGraph('stats.video_engagment')}</h1>
                                        <div className="graph-wrapper">
                                            <AreaChartComponent graph={statsValue?.skillStats?.graphPlot}></AreaChartComponent>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            {graphType === 'space-retention-rate' || graphType === 'traffic-source'
                                ? !showSpinner && (
                                      <>
                                          <div className="product-details">
                                              <div className="product-img">
                                                  <img src={statsValue?.activeSpace?.poster} alt="space poster" />
                                              </div>
                                              <div className="product-name">
                                                  <div className="value">{statsValue?.activeSpace?.spaceName}</div>
                                                  <div className="space-props">
                                                      {statsValue?.activeSpace?.bedrooms !== 0 && (
                                                          <div className="space-comp">
                                                              <img src={BedIcon} alt="Bed" />
                                                              <span className="text">{statsValue?.activeSpace?.bedrooms}</span>
                                                          </div>
                                                      )}
                                                      {statsValue?.activeSpace?.bathrooms !== 0 && (
                                                          <div className="space-comp">
                                                              <img src={BathIcon} alt="Bath" />
                                                              <span className="text">{statsValue?.activeSpace?.bathrooms}</span>
                                                          </div>
                                                      )}
                                                      {statsValue?.activeSpace?.dimension !== 0 && (
                                                          <div className="space-comp">
                                                              <img src={AreaIcon} alt="Area" />
                                                              <span className="text">
                                                                  {statsValue?.activeSpace?.dimension?.toLocaleString()}{' '}
                                                              </span>
                                                              <span className="text ml-2">
                                                                  {statsValue?.activeSpace?.dimensionUnit &&
                                                                  statsValue?.activeSpace?.dimensionUnit.toLowerCase() === 'sqft'
                                                                      ? 'ft2'
                                                                      : 'mt2'}
                                                              </span>
                                                          </div>
                                                      )}
                                                  </div>
                                              </div>
                                          </div>
                                          {/* <div className="hr-break"></div> */}
                                      </>
                                  )
                                : ''}

                            {graphType === 'traffic-source' || graphType === 'top-traffic-sources' ? (
                                ''
                            ) : showSpinner ? (
                                <div className="loading-wrapper-graph">
                                    <div className="loading-top-wrapper">
                                        <div className="shimmerBG loading-circle"></div>
                                        <div className="loading-right">
                                            <div className="shimmerBG loading-right-top"></div>
                                            <div className="shimmerBG loading-right-bottom"></div>
                                        </div>
                                    </div>
                                    <div className="shimmerBG loading-bottom-wrapper"></div>
                                </div>
                            ) : (
                                <div
                                    className="stats-popup-content-wrapper"
                                    style={{
                                        marginTop: graphType === 'space-retention-rate' ? '24px' : '50px'
                                    }}
                                >
                                    <div className="stats-popup-content-main">
                                        <div className="icon-wrapper">
                                            {graphType === 'space-retention-rate' ? (
                                                <img src={RetentionIcon} alt="icon" />
                                            ) : (
                                                <img src={EyeIcon} alt="icon"></img>
                                            )}
                                        </div>
                                        <div className="stats-popup-content">
                                            <h1>
                                                {generateValue()}
                                                {statsValue.currentGraphType !== 'overall-impressions' && <span>%</span>}
                                            </h1>
                                            <h2>{generateTagline()}</h2>
                                        </div>
                                    </div>
                                    {graphData.overview && (
                                        <div className="stats-popup-content-tagline">
                                            <div className="circle"></div>
                                            <h2>{generateTagline()}</h2>
                                        </div>
                                    )}
                                </div>
                            )}
                            {graphType === 'traffic-source' || graphType === 'top-traffic-sources' ? (
                                showSpinner ? (
                                    <div className="loading-wrapper-graph">
                                        <div className="shimmerBG loading-traffic-source-wrapper"></div>
                                    </div>
                                ) : (
                                    <div className="traffic-source-wrapper">
                                        <TrafficBar bars={trafficData} hardStyle={true}></TrafficBar>
                                    </div>
                                )
                            ) : (
                                <div
                                    className={
                                        graphType === 'space-retention-rate'
                                            ? 'stats-popup-graph-wrapper product'
                                            : 'stats-popup-graph-wrapper'
                                    }
                                    style={{
                                        height: graphType === 'space-retention-rate' ? '50%' : '60%'
                                    }}
                                >
                                    {showSpinner !== true && (
                                        <ResponsiveContainer width="100%" height="100%">
                                            <LineChart width={500} height={300} data={graphDateMillis}>
                                                <CartesianGrid strokeDasharray="0" vertical={false} />
                                                <XAxis
                                                    dataKey="dateInMillis"
                                                    strokeWidth={0}
                                                    tick={{ fontSize: 10, fontWeight: '600' }}
                                                    tickFormatter={formatXAxis}
                                                    allowDataOverflow={true}
                                                    interval="preserveStartEnd"
                                                    // label={{
                                                    //   value: '(Date)',
                                                    //   fontSize: '10px',
                                                    //   fontWeight: '600',
                                                    //   lineHeight: '14px',
                                                    //   position: 'insideBottomRight',
                                                    //   marginTop: '2px',
                                                    //   fill:'#00000080'
                                                    //   // offset: '20px'
                                                    // }}
                                                    // label={<CustomXAxis/>}
                                                ></XAxis>
                                                <YAxis strokeWidth={0} tick={{ fontSize: 10, fontWeight: '600' }} />
                                                <Tooltip
                                                    cursor={{ fill: 'transparent' }}
                                                    offset={0}
                                                    animationEasing={'ease-in-out'}
                                                    content={<CustomTooltip />}
                                                />
                                                <Line type="monotone" dataKey="count" stroke="#000" activeDot={{ r: 4 }} />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    )}

                                    {!showSpinner && <div className="date-legend">(Date)</div>}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </Pulse>
        </div>
    );
};

export default StatsPopUpGraph;
