// library
import React, { useState, useContext, useEffect } from 'react';
import Pulse from 'react-reveal/Pulse';

// components
import AddSpaceHeader from '../AddSpaceHeader';
import MapBox from '../Mapbox';
import SpaceDetails from '../SpaceDetails';
import ManageAgent from '../ManageAgent';
import PopUpCardNotification from '../PopUpCardNotification';
import DragTip from '../DragTip';
// utility
import { getSessionStorage } from '../../utils';

// context
import { SettingsContext } from '../../context';
// styles
import './addspace.scss';
import { DOMAIN } from '../../constants/base';
import { getReq } from '../../utils/api';
import ManageSkills from '../ManageSkills';
import PublishSpace from '../PublishSpace';

export default function AddSpace({ showPopupItem, fetchActiveSpaces }) {
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const userDetails = getSessionStorage('ss-user-spaces') || {};
    const [globalSettings] = useContext(SettingsContext);
    const { name, avatar, id, role, department } = userDetails;
    const admin = role === 'ADMIN',
        owner = role === 'OWNER';
    const member = !(admin || owner);
    const canChangeAssigne = member
        ? globalSettings.memberGlobalSettings?.globalMemberManageTeamPortfolio
            ? true
            : false
        : admin
        ? globalSettings.adminGlobalSettings?.globalAdminManageTeamPortfolio
            ? true
            : false
        : true;

    const [currentStep, setCurrentStep] = useState('1');
    const [isNewTemplate, setIsNewTemplate] = useState(false);

    // general states
    const [videoFiles, setVideoFiles] = useState([]);
    const [spaceUpdated, setSpaceUpdated] = useState(false);
    const [nextClickLoader, setNextClickLoader] = useState(false);
    const [spaceLimitNotification, setSpaceLimitNotification] = useState(false);
    const [preventUpdates, setPreventUpdates] = useState(false);
    const [showDragTip, setShowDragTip] = useState(true);
    // first step states
    const [spaceId, setSpaceId] = useState(null);
    const [spaceAddress, setSpaceAddress] = useState('Initial Address');
    const [spaceLatLng, setSpaceLatLng] = useState({
        lat: 51.50508,
        lng: -0.08751
    });
    const [prevSpaceLatLng, setPrevSpaceLatLng] = useState(null);

    // second step states - space details
    const [enableFlat, setEnableFlat] = useState(false);
    const [flatNumber, setFlatNumber] = useState('');
    const [spaceType, setSpaceType] = useState('RENTAL');
    const [rentalPriceType, setRentalPriceType] = useState('PER MONTH');
    const [enableEnergyRating, setEnableEnergyRating] = useState(false);

    // Price
    const [enablePrice, setEnablePrice] = useState(false);
    const [price, setPrice] = useState(null);
    const [currency, setCurrency] = useState('USD');
    // Rooms
    const [enableRooms, setEnableRooms] = useState(false);
    const [bathrooms, setBathrooms] = useState(null);
    const [bedrooms, setBedrooms] = useState(null);
    // Surface
    const [enableSurface, setEnableSurface] = useState(false);
    const [spaceSize, setSpaceSize] = useState(null);
    const [measure, setMeasure] = useState('SQFT');
    const [directLink, setDirectLink] = useState(null);

    // managing agent states
    const [availableAgents, setAvailableAgents] = useState([]);
    const [assigneId, setAssigneId] = useState(id);
    const [assigneChanged, setAssigneChanged] = useState(false);
    const [agentAssigned, setAgentAssigned] = useState({
        name: name,
        avatar: avatar,
        id: id
    });
    const [availableTemplates, setAvailableTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({
        templateName: 'Agent’s default contact',
        templateId: null,
        ctas: null
    });
    const [currentCta, setCurrentCta] = useState({});
    const [defaultCtas, setDefaultCtas] = useState([]);
    const [ctaTemplateName, setCtaTemplateName] = useState(null);
    const [prevTemplate, setPrevTemplate] = useState({});
    const [isUpdated, setIsUpdated] = useState(false);
    const [privateToggle, setPrivateToggle] = useState(false);
    //Energy Ratings
    const [errCurrent, setErrCurrent] = useState(null);
    const [errPotential, setErrPotential] = useState(null);
    // template changes

    // manage skills
    const [availableSkillsPoster, setAvailableSkillsPoster] = useState([]);
    const [spacePoster, setSpacePoster] = useState(null);
    const [videoUploaded, setVideoUploaded] = useState(0);
    const [newTemplateCtas, setNewTemplateCtas] = useState([]);

    useEffect(() => {
        document.body.classList.add('body-ovef-hdn');
        if (admin || owner || canChangeAssigne) {
            getAvailableAgents();
        }
    }, []);

    useEffect(() => {
        if (availableAgents) updateContactList();
    }, [availableAgents, assigneId]);

    useEffect(() => {
        updateContactList();
        setCtaTemplateName(selectedTemplate?.templateName);
        if (selectedTemplate?.templateName !== '') setPrevTemplate(selectedTemplate);
    }, [selectedTemplate]);

    const getAvailableAgents = async () => {
        const url = `${DOMAIN}/v1/dashboard/space/create-space/agents`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const agentsResponse = await getReq({
            url,
            requestHeaders
        });
        const { code, data } = agentsResponse;
        if (code === 200) {
            setAvailableAgents(data.docs?.managingAgent);
            setAvailableTemplates([
                {
                    templateName: 'Agent’s default contact',
                    templateId: null,
                    ctas: null
                },
                ...data.docs?.templates
            ]);
        }
    };

    const updateContactList = () => {
        let selected = availableAgents.find((agent) => agent.agentId === assigneId);
        if (selectedTemplate?.templateId || isNewTemplate) {
            selected = selectedTemplate;
        }
        if (selected?.ctas) {
            const arr = selected?.ctas;
            let arr2 = [];
            const keys = Object.keys(arr);
            keys.forEach((key) => {
                let x = {};
                x[key] = arr[key];
                arr2 = [...arr2, x];
            });
            setDefaultCtas([...arr2]);
        } else {
            if (isNewTemplate) {
                setNewTemplateCtas([]);
            } else {
                setDefaultCtas([]);
            }
        }
    };
    // go to next step from map on marker click
    const handleMarkerClick = () => {
        setCurrentStep('2');
    };
    // current Screen === 2, functions
    const handleSpaceAddressEdit = () => {
        setCurrentStep('1');
    };

    return (
        <div className="popups-wrapper">
            <Pulse duration={150}>
                <div className="space-card-wrapper">
                    <AddSpaceHeader
                        currentStep={currentStep}
                        videoFiles={videoFiles}
                        isNewTemplate={isNewTemplate}
                        setCurrentStep={setCurrentStep}
                        showPopupItem={showPopupItem}
                        setSpaceUpdated={setSpaceUpdated}
                        videoUploaded={videoUploaded}
                        setPreventUpdates={setPreventUpdates}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        prevTemplate={prevTemplate}
                        setIsNewTemplate={setIsNewTemplate}
                    />
                    {currentStep === '1' && (
                        <MapBox
                            handleMarkerClick={handleMarkerClick}
                            spaceAddress={spaceAddress}
                            setSpaceAddress={setSpaceAddress}
                            setSpaceLatLng={setSpaceLatLng}
                            spaceLatLng={spaceLatLng}
                            setPrevSpaceLatLng={setPrevSpaceLatLng}
                            setSpaceUpdated={setSpaceUpdated}
                        ></MapBox>
                    )}
                    {currentStep === '2' && (
                        <SpaceDetails
                            spaceAddress={spaceAddress}
                            spaceLatLng={spaceLatLng}
                            prevSpaceLatLng={prevSpaceLatLng}
                            handleSpaceAddressEdit={handleSpaceAddressEdit}
                            enableFlat={enableFlat}
                            setEnableFlat={setEnableFlat}
                            flatNumber={flatNumber}
                            setFlatNumber={setFlatNumber}
                            enablePrice={enablePrice}
                            setEnablePrice={setEnablePrice}
                            price={price}
                            setPrice={setPrice}
                            setSpaceUpdated={setSpaceUpdated}
                            spaceType={spaceType}
                            setSpaceType={setSpaceType}
                            rentalPriceType={rentalPriceType}
                            setRentalPriceType={setRentalPriceType}
                            currency={currency}
                            setCurrency={setCurrency}
                            enableRooms={enableRooms}
                            setEnableRooms={setEnableRooms}
                            bedrooms={bedrooms}
                            setBedrooms={setBedrooms}
                            bathrooms={bathrooms}
                            setBathrooms={setBathrooms}
                            enableSurface={enableSurface}
                            setEnableSurface={setEnableSurface}
                            measure={measure}
                            setMeasure={setMeasure}
                            spaceSize={spaceSize}
                            setSpaceSize={setSpaceSize}
                            nextClickLoader={nextClickLoader}
                            setNextClickLoader={setNextClickLoader}
                            setSpaceLimitNotification={setSpaceLimitNotification}
                            spaceId={spaceId}
                            setSpaceId={setSpaceId}
                            setPrevSpaceLatLng={setPrevSpaceLatLng}
                            setCurrentStep={setCurrentStep}
                            spaceUpdated={spaceUpdated}
                            setDirectLink={setDirectLink}
                            setDefaultCtas={setDefaultCtas}
                            member={member}
                            userPermissionType={department}
                            errCurrent={errCurrent}
                            setErrCurrent={setErrCurrent}
                            errPotential={errPotential}
                            setErrPotential={setErrPotential}
                            setEnableEnergyRating={setEnableEnergyRating}
                            enableEnergyRating={enableEnergyRating}
                        ></SpaceDetails>
                    )}
                    {(currentStep === '3' || currentStep === '6') && (
                        <ManageAgent
                            canChangeAssigne={canChangeAssigne}
                            availableAgents={availableAgents}
                            availableTemplates={availableTemplates}
                            setAvailableTemplates={setAvailableTemplates}
                            setSpaceUpdated={setSpaceUpdated}
                            setCurrentStep={setCurrentStep}
                            agentAssigned={agentAssigned}
                            setAssigneId={setAssigneId}
                            selectedTemplate={selectedTemplate}
                            setSelectedTemplate={setSelectedTemplate}
                            assigneChanged={assigneChanged}
                            setAssigneChanged={setAssigneChanged}
                            setAgentAssigned={setAgentAssigned}
                            assigneId={assigneId}
                            defaultCtas={isNewTemplate ? newTemplateCtas : defaultCtas}
                            setIsNewTemplate={setIsNewTemplate}
                            currentCta={isNewTemplate ? {} : currentCta}
                            setCurrentCta={setCurrentCta}
                            spaceId={spaceId}
                            isUpdated={isUpdated}
                            setIsUpdated={setIsUpdated}
                            ctaTemplateName={ctaTemplateName}
                            setCtaTemplateName={setCtaTemplateName}
                            isNewTemplate={isNewTemplate}
                            currentStep={currentStep}
                            preventUpdates={preventUpdates}
                            setPreventUpdates={setPreventUpdates}
                            prevTemplate={prevTemplate}
                        ></ManageAgent>
                    )}

                    {currentStep === '4' && (
                        <ManageSkills
                            videoFiles={videoFiles}
                            setVideoFiles={setVideoFiles}
                            availableSkillsPoster={availableSkillsPoster}
                            setAvailableSkillsPoster={setAvailableSkillsPoster}
                            spaceId={spaceId}
                            nextClickLoader={nextClickLoader}
                            setNextClickLoader={setNextClickLoader}
                            setCurrentStep={setCurrentStep}
                            spacePoster={spacePoster}
                            setSpacePoster={setSpacePoster}
                            videoUploaded={videoUploaded}
                            setVideoUploaded={setVideoUploaded}
                        ></ManageSkills>
                    )}

                    {currentStep === '5' && (
                        <PublishSpace
                            spacePoster={spacePoster}
                            videoFiles={videoFiles}
                            setCurrentStep={setCurrentStep}
                            spaceId={spaceId}
                            showPopupItem={showPopupItem}
                            setAvailableSkillsPoster={setAvailableSkillsPoster}
                            fetchActiveSpaces={fetchActiveSpaces}
                            directLink={directLink}
                            privateSpace={privateToggle}
                            setPrivateSpace={setPrivateToggle}
                            spaceAgentId={assigneId}
                            isWidgetInstalled={true}
                        ></PublishSpace>
                    )}
                </div>
            </Pulse>
            {spaceLimitNotification && <PopUpCardNotification setSpaceLimitNotification={setSpaceLimitNotification} />}
            {videoFiles.length >= 2 && showDragTip && <DragTip setShowDragTip={setShowDragTip}></DragTip>}
        </div>
    );
}
