import React from 'react';
import IssuesPage from '../../containers/IssuesPage';

export default function TVIssues() {
    return (
        <>
            <IssuesPage />
        </>
    );
}
