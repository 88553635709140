import React, { useReducer, useState } from 'react';
import TopBar from '../../components/TopBar';
import MySpacesComponent from '../../containers/MySpaces';
// State Management
import { currentSpaceContext } from '../../context';
import spaceReducer from '../../context/spaceReducer';

function MySpaces() {
    const initialState = {
        currentMagicLink: '',
        flatNumber: '',
        spaceAddress: '',
        priceType: '',
        currentSpaceId: '',
        currentHashtagValues: [],
        currentCTAValues: [],
        currentSkillValues: [],
        currentBrandName: '',
        currentCurrency: '',
        currentPrice: ''
    };
    const [state, dispatch] = useReducer(spaceReducer, initialState);
    const [updateSpaces, setUpdateSpaces] = useState(false);
    return (
        <>
            <currentSpaceContext.Provider value={[state, dispatch]}>
                <TopBar setUpdateSpaces={setUpdateSpaces}></TopBar>
                <MySpacesComponent setUpdateSpaces={setUpdateSpaces} updateSpaces={updateSpaces} />
            </currentSpaceContext.Provider>
        </>
    );
}

export default MySpaces;
