import React, { useCallback, useEffect, useRef } from 'react';

const ClickAwayContentWrapper = ({ onClose, children }) => {
    const ref = useRef(null);
    const escapeListener = useCallback((e) => {
        if (e.key === 'Escape') {
            onClose();
        }
    }, []);
    const clickListener = useCallback(
        (e) => {
            if (!ref.current.contains(e.target)) {
                onClose && onClose();
            }
        },
        [ref.current]
    );
    useEffect(() => {
        document.addEventListener('click', clickListener);
        document.addEventListener('keyup', escapeListener);
        return () => {
            document.removeEventListener('click', clickListener);
            document.removeEventListener('keyup', escapeListener);
        };
    }, []);
    return <div ref={ref}>{children}</div>;
};

export default ClickAwayContentWrapper;
