import React from 'react';
import './headertag.scss';
export default function HeaderTag(props) {
    const { headerText, tagText, customTagClass, isLoading } = props;
    return (
        <div className="ht-container">
            {isLoading ? <div className="shimmer-effect header"> </div> : <div className="header-text">{headerText}</div>}
            {isLoading ? (
                <div className="shimmer-effect tag"></div>
            ) : (
                <div className={customTagClass ? `${customTagClass}` : 'tag-text'}>{tagText}</div>
            )}
        </div>
    );
}
