import React, { useContext, useEffect, useState } from 'react';
import SwitchAtom from '../../components/SwitchAtom';
import CurrentSpace from '../../assets/current-space.png';
import PrevSpace from '../../assets/previous-space.png';
import NextSpace from '../../assets/next-space.png';
import PrivateSpaceLock from '../../assets/private-lock.svg';
import { DOMAIN } from '../../constants/base';
import { putReq } from '../../utils/api';
import NoteComponent from '../../components/NoteComponent';
import { useTranslation } from 'react-i18next';
import { GlobalProfile, SettingsContext } from '../../context';
import { getSessionStorage } from '../../utils';

import './publishspace.scss';
export default function TogglePrivateSpace(props) {
    const { privateSpace, setPrivateSpace, fetchActiveSpaces, spaceId, customWidth, spaceAgentId } = props;
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const userDetails = getSessionStorage('ss-user-spaces') || {};
    const [globalProfile] = useContext(GlobalProfile);
    const { role } = userDetails;
    const admin = role === 'ADMIN' || globalProfile?.role === 'ADMIN',
        owner = role === 'OWNER' || globalProfile?.role === 'OWNER';
    const [isPrivate, setIsPrivate] = useState(false);
    const { t } = useTranslation();
    const [globalSettings] = useContext(SettingsContext);
    const { adminGlobalSettings, memberGlobalSettings } = globalSettings;

    useEffect(() => {
        setIsPrivate(privateSpace);
    }, [privateSpace]);

    const managePortfolioCheck = () => {
        if (userDetails?.id === spaceAgentId) {
            return true;
        } else {
            if (owner) return true;
            else if (admin && adminGlobalSettings?.globalAdminManageTeamPortfolio) return true;
            else if (!owner && !admin && memberGlobalSettings?.globalMemberManageTeamPortfolio) return true;
            else return false;
        }
    };

    const handlePrivateToggle = async (isPrivateValue) => {
        const url = `${DOMAIN}/v1/dashboard/space/${spaceId}`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        let payload = {
            isPrivate: isPrivateValue
        };
        const updateResponse = await putReq({ url, payload, requestHeaders });
        const { code } = updateResponse;
        if (code === 200) {
            setPrivateSpace(payload.isPrivate);
            fetchActiveSpaces();
        }
    };
    return (
        <div
            className="link-container flex-col"
            style={{
                width: customWidth ? customWidth : ''
            }}
        >
            <div className="header">
                <div className="title">{t('spaces.private.title')}</div>
                <SwitchAtom
                    disabled={!managePortfolioCheck()}
                    toggleVal={isPrivate}
                    handleToggle={() => {
                        isPrivate ? handlePrivateToggle(false) : handlePrivateToggle(true);
                        setIsPrivate(!isPrivate);
                    }}
                />
            </div>
            <div className="subtitle">
                {t('spaces.private.subtitle')} <span>{t('spaces.private.register')}</span>
                {t('spaces.private.register_next')}
            </div>
            <div className="space-card">
                <img src={PrevSpace} alt="prev-space" />
                <div className="current-space">
                    <img src={CurrentSpace} alt="curr-space" />
                    <div className="overalay">
                        <img src={PrivateSpaceLock} alt="private space indicator" />
                        <div className="email-container">
                            <label>{t('agent.email.label')}</label>
                            <input type="text" disabled={true} placeholder={t('team.invite.placeholder_email')} />
                        </div>
                    </div>
                </div>

                <img src={NextSpace} alt="next-space" />
            </div>

            <div className="note">
                <NoteComponent message={t('spaces.private.note')}></NoteComponent>
            </div>
        </div>
    );
}
