import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import Search from '../../components/Search';
import PropCard from '../../components/PropCard';
import NavBar from '../../components/NavBar';
import { isEmpty, setSessionStorage } from '../../utils';
import { useLocation } from 'react-router-dom';
import { getReq, postReq } from '../../utils/api';
import { DOMAIN } from '../../constants/base';
import './index.scss';
import PopUpMagicPairing from '../../components/PopUpMagicPairing';
import PopUpCTA from '../../components/PopUpCTA';
import PopUpRemoveProduct from '../../components/PopUpRemoveProduct';
import { currentSpaceContext, GlobalProfile, SettingsContext } from '../../context';
import PopUpPublishUnpublish from '../../components/PopUpPublishUnpublish';
import { Fade } from 'react-reveal';
import ClickAwayListener from 'react-click-away-listener';
import Avatar from 'react-avatar';
import { getSessionStorage } from '../../utils';
import FirstTimePopUp from '../../components/FirstTimePopUp';
import { InView } from 'react-intersection-observer';
import BlockerComponent from '../../components/BlockerComponent';
import LoadingSpin from '../../components/LoadingSpin';
import { useTranslation } from 'react-i18next';
import AddSpace from '../../components/AddSpace';

// Assets
import RemoveTextImg from '../../assets/ClearText.png';
import AddIcon from '../../assets/addIconBlack.png';
import PopUpEditSpace from '../../components/PopUpEditSpace';
import PopUpEmptySkill from '../../components/PopUpEmptySkill';
import SearchIcon from '../../assets/search.svg';
import StatsUpDownIcon from '../../assets/stats-up-down.png';
import StatsUpIcon from '../../assets/stats-up.png';
import StatsDownIcon from '../../assets/stats-down.png';
import EditIcon from '../../assets/editnew.svg';
import DeleteIcon from '../../assets/delete-btn.svg';
import CheckIcon from '../../assets/check-btn.svg';
import Check from '../../assets/check.svg';
import UnpublishIcon from '../../assets/eye-slash-outline.svg';
import PublishIcon from '../../assets/eye-outline.svg';
import CaretTop from '../../assets/caret-top.svg';
import CaretBot from '../../assets/caret-bot.svg';

let allAgents = [];
function MySpaces({ setUpdateSpaces, updateSpaces }) {
    const [spaceContext, dispatch] = useContext(currentSpaceContext);
    const [globalProfile] = useContext(GlobalProfile);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [isActiveSpacesLoading, setActiveSpacesLoading] = useState(false);
    const [toggleActiveIndex, setToggleActiveIndex] = useState('');

    const [noData, setNoData] = useState(false);
    const [changeState, setChangeState] = useState(true);
    const [changeCountState, setChangeCountState] = useState(true);
    const [changeStateAgents, setChangeStateAgents] = useState(true);
    const [selectAgentIdArray, setSelectAgentIdArray] = useState([]);

    // Pop Up States
    const [openMagicLinkPopUp, setMagicLinkPopUp] = useState(false);
    const [openNewItemPopUp, setNewItemPopUp] = useState(false);
    const [openCTAPopUp, setOpenCTAPopUp] = useState(false);
    const [openRemoveProductPopUp, setOpenRemoveProductPopUp] = useState(false);
    const [openEditProductPopUp, setOpenEditProductPopUp] = useState(false);
    const [openEmptySkillPopUp, setOpenEmptySkillPopUp] = useState(false);
    const [addSkill, setAddSkill] = useState(false);
    const [openPublishUnpublishPopUp, setOpenPublishUnpublishPopUp] = useState(false);
    const [publishUnPublishNotification, setPublishUnPublishNotification] = useState(false);
    const [deleteNotification, setDeleteNotification] = useState(false);

    // Pagination States
    const [activeSpaces, setActiveSpaces] = useState([]);
    const [serachParam, setSearchParam] = useState('');
    const [pageNo, setPageNo] = useState(1);
    const [pageMetadata, setPageMetadata] = useState({
        totalDocs: null,
        totalPages: 0
    });
    const [totalDocs, setTotalDocs] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(false);

    const [isSettingMenuOpen, setSettingMenuOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedCards, setSelectedCards] = useState([]);
    const [togglePublish, setTogglePublish] = useState(true);
    const [toggleFilters, setToggleFilters] = useState({
        rentSale: false,
        webPaired: false,
        searchAgent: false
    });
    const [rentSaleType, setRentSaleType] = useState(null);
    const [priceAscDesc, setPriceAscDesc] = useState(null);
    const [isPaired, setIsPaired] = useState(null);
    const [activeMemberList, setActiveMemberList] = useState(null);
    const [filteredAgent, setFilteredAgent] = useState([]);
    const [globalSettings] = useContext(SettingsContext);

    const isMountedRef = React.useRef(null);

    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const userDetails = getSessionStorage('ss-user-spaces') || {};

    const { department } = userDetails;

    const admin = globalProfile?.role === 'ADMIN',
        owner = globalProfile?.role === 'OWNER';
    // payment handling status
    const paymentUrl = useLocation().search;
    const status = new URLSearchParams(paymentUrl).get('payment_status');
    const [showUpgradeLoader, setShowUpgradeLoader] = useState(status === 'success' ? true : false);

    const [showTrailProgress, setShowTrailProgress] = useState(false);
    const [isPlanUpdated, setIsPlanUpdated] = useState(false);
    var prevPlan = '';
    var currPlan = '';
    const showBlocker =
        globalProfile.globalProfilePlan === 'BASIC TRIAL' ||
        globalProfile.globalProfilePlan === 'PRO TRIAL' ||
        globalProfile.globalProfilePlan === 'BASIC PAID' ||
        globalProfile.globalProfilePlan === 'PRO PAID';

    // translations
    const { t: spaceHomePage } = useTranslation();
    // publish and unpublish count is 0
    useEffect(() => {
        if (showUpgradeLoader) {
            prevPlan = userDetails.currentPlan;
            setTimeout(() => {
                getUserCurrentUsage();
            }, 5000);
        }
        setShowUpgradeLoader(status === 'success' ? true : false);
    }, []);

    useEffect(() => {
        if (updateSpaces) {
            setPageNo(1);
            fetchActiveSpaces('', false);
            setUpdateSpaces(false);
        }
    }, [updateSpaces]);

    useEffect(() => {
        getTotalDocs();
    }, [changeCountState]);

    useEffect(() => {
        isMountedRef.current = true;
        if (isEmpty(userToken)) {
            setShouldRedirect(true);
            return;
        }
        setPageNo(1);
        fetchActiveSpaces('', false);

        return () => (isMountedRef.current = false);
    }, [changeState]);

    useEffect(() => {
        if (pageNo > 1) {
            fetchActiveSpaces('', true);
        }
    }, [pageNo]);

    useEffect(() => {
        if (
            totalDocs === 0 &&
            pageMetadata.totalDocs === 0 &&
            serachParam?.length === 0 &&
            !toggleFilters.rentSale &&
            !toggleFilters.webPaired &&
            !toggleFilters.searchAgent &&
            rentSaleType === null &&
            isPaired === null &&
            filteredAgent.length === 0
        ) {
            setNoData(true);
        } else {
            setNoData(false);
        }
    }, [totalDocs, pageMetadata.totalDocs]);

    useEffect(() => {
        isMountedRef.current = true;
        if (isEmpty(userToken)) {
            setShouldRedirect(true);
            return;
        }

        if (toggleFilters.searchAgent) getAvailableAgents();

        return () => (isMountedRef.current = false);
    }, [changeStateAgents]);

    useEffect(() => {
        if (priceAscDesc !== null || isPaired !== null || rentSaleType !== null || toggleFilters.searchAgent) {
            setChangeState(!changeState);
        }
    }, [priceAscDesc, isPaired, rentSaleType, filteredAgent.length]);

    if (shouldRedirect) {
        return <Redirect to="/signin" />;
    }

    // get user details
    async function getUserCurrentUsage() {
        const url = `${DOMAIN}/v1/dashboard/space/plans/usage`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const currentUsage = await getReq({
            url,
            requestHeaders
        });
        const { code, data = {} } = currentUsage;
        if (code === 200) {
            dispatch({
                type: 'SET_USER_CURRENT_USAGE',
                payload: {
                    currentTier: data.currentTier,
                    daysLeft: data.daysLeft,
                    publishedSpaces: data.publishedSpaces,
                    nextTier: data.nextTier,
                    planEnds: data.planEnds,
                    priceToPay: data.priceToPay,
                    range: data.range,
                    paymentInfo: data.paymentInfo ? data.paymentInfo : null,
                    trialUp: data.daysLeft > 0 ? false : true
                }
            });
            dispatch({
                type: 'EDIT_GLOBAL_PROFILE',
                payload: {
                    plan: data.currentPlan
                }
            });
            currPlan = data.currentPlan;
            if (prevPlan !== currPlan) {
                setIsPlanUpdated(true);
            }
            setSessionStorage({
                key: 'ss-user-spaces',
                value: { ...userDetails, currentPlan: data.currentPlan }
            });
        }
    }

    async function getTotalDocs() {
        const url = `${DOMAIN}/v1/dashboard/space/page/${pageNo}?published=${!togglePublish}&searchStr=${serachParam}`;

        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const activeSpacesResponse = await postReq(
            filteredAgent.length > 0
                ? {
                      url,
                      requestHeaders
                  }
                : {
                      url,
                      requestHeaders
                  }
        );
        const { code, data = {} } = activeSpacesResponse;
        if (code === 200) {
            setTotalDocs(data.totalDocs);
            dispatch({
                type: 'SET_SPACE_COUNT',
                payload: { unpublished: data.totalDocs }
            });
        }
    }

    async function fetchActiveSpaces(addNewSpace = false, scroller) {
        let agents = [];
        if (filteredAgent.length > 0) {
            for (let i = 0; i < filteredAgent.length; i++) {
                agents.push(filteredAgent[i].agentId);
            }
        }
        let payload = {
            agentIds: agents
        };
        setActiveSpacesLoading(true);
        let url = `${DOMAIN}/v1/dashboard/space/page/${scroller ? pageNo : 1}?published=${
            addNewSpace ? true : togglePublish
        }&searchStr=${serachParam}`;
        if (
            toggleFilters.rentSale ||
            toggleFilters.webPaired ||
            toggleFilters.searchAgent ||
            isPaired === false ||
            isPaired ||
            rentSaleType === 'rent' ||
            rentSaleType === 'sale' ||
            priceAscDesc !== null
        ) {
            url = `${DOMAIN}/v1/dashboard/space/page/${scroller ? pageNo : 1}?published=${
                addNewSpace ? true : togglePublish
            }&searchStr=${serachParam}&type=${rentSaleType}&sortByPrice=${priceAscDesc}&isPaired=${isPaired}`;
        }
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const activeSpacesResponse = await postReq(
            filteredAgent.length > 0
                ? {
                      url,
                      payload,
                      requestHeaders
                  }
                : {
                      url,
                      requestHeaders
                  }
        );
        const { code, data = {} } = activeSpacesResponse;
        if (code === 200) {
            setActiveSpacesLoading(false);
            if (data && data.docs[0] && data.docs[0].currency) {
                dispatch({
                    type: 'SET_CURRENCY',
                    payload: { currency: data.docs[0].currency }
                });
            }
            setPageMetadata({
                totalDocs: data.totalDocs,
                totalPages: data.totalPages
            });
            setHasNextPage(data.hasNextPage);
            if (isMountedRef.current && data.totalDocs > 0 && scroller) {
                setActiveSpaces([...activeSpaces, ...data.docs]);
            } else {
                setActiveSpaces(data.docs);
                setPageNo(1);
            }
            dispatch({
                type: 'SET_SPACE_COUNT',
                payload: { published: data.totalDocs }
            });
        }
    }

    const searchActiveMembers = (value) => {
        if (value === '') {
            setActiveMemberList(allAgents);
            return;
        }
        setActiveMemberList(
            allAgents.filter((agent) => {
                if (agent.name.toLowerCase().includes(value)) {
                    return true;
                }
                return false;
            })
        );
    };

    let timerSpaces;
    const searchActiveSpaces = (value) => {
        if (!isActiveSpacesLoading) {
            clearTimeout(timerSpaces);
            timerSpaces = setTimeout(() => {
                setSearchParam(value);
                setChangeState(!changeState);
            }, 1000);
        }
    };

    // Rendering Active Spaces
    const renderActiveSpaces = () => {
        let propArr = [];
        propArr = activeSpaces.map((prop, index) => (
            <PropCard
                key={index}
                property={prop}
                index={index}
                setMenuState={false}
                toggleCopy={toggleActiveCopy}
                isTogggle={index === toggleActiveIndex}
                setMagicLinkPopUp={setMagicLinkPopUp}
                setOpenCTAPopUp={setOpenCTAPopUp}
                setOpenEditProductPopUp={setOpenEditProductPopUp}
                setSettingMenuOpen={setSettingMenuOpen}
                isSettingMenuOpen={isSettingMenuOpen}
                setOpenEmptySkillPopUp={setOpenEmptySkillPopUp}
                addSkill={addSkill}
                setAddSkill={setAddSkill}
                editMode={editMode}
                selectCardMethod={selectCardMethod}
                selectedCards={selectedCards}
            />
        ));
        if (activeSpaces.length > 0) {
            return propArr;
        } else if (pageMetadata?.totalDocs === 0) {
            return (
                <div className="loading-space">
                    {spaceHomePage('spaces.search_msg.msg_find')}{' '}
                    {togglePublish ? spaceHomePage('spaces.space_status.published') : spaceHomePage('spaces.space_status.unpublished')}{' '}
                    {spaceHomePage('spaces.search_msg.msg_try')}
                </div>
            );
        }
    };

    const toggleActiveCopy = (index) => {
        if (index === toggleActiveIndex) {
            setToggleActiveIndex('');
            return;
        }
        setToggleActiveIndex(index);
    };

    //toggle edit mode
    const toggleEdit = () => {
        setEditMode(!editMode);
    };

    // select cards method
    const selectCardMethod = (selectedId, isSelected, selectedAgentId) => {
        if (editMode) {
            let cards = [...selectedCards];
            let agentIds = [...selectAgentIdArray];
            let index = cards.indexOf(selectedId);
            if (isSelected) {
                cards.push(selectedId);
                agentIds.push(selectedAgentId);
            } else if (!isSelected) {
                cards.splice(index, 1);
                agentIds.splice(index, 1);
            }
            setSelectedCards(cards);
            setSelectAgentIdArray(agentIds);
        }
        return;
    };

    // clear selected cards while clicking done
    const clearCards = () => {
        setSelectedCards([]);
        toggleEdit();
    };

    const togglePublishMethod = (bool) => {
        setChangeState(!changeState);
        setTogglePublish(bool);
        setSelectedCards([]);
        setChangeCountState(!changeCountState);
    };

    const toggleFiltersMethod = (type) => {
        if (type === 'rentSale') {
            if (toggleFilters.rentSale) {
                setToggleFilters({
                    ...toggleFilters,
                    rentSale: false
                });
            } else {
                setToggleFilters({
                    rentSale: true,
                    webPaired: false,
                    searchAgent: false
                });
            }
        }
        if (type === 'webPaired') {
            if (toggleFilters.webPaired) {
                setToggleFilters({
                    ...toggleFilters,
                    webPaired: false
                });
            } else {
                setToggleFilters({
                    rentSale: false,
                    webPaired: true,
                    searchAgent: false
                });
            }
        }
        if (type === 'agent') {
            if (toggleFilters.searchAgent) {
                setToggleFilters({
                    ...toggleFilters,
                    searchAgent: false
                });
            } else {
                setToggleFilters({
                    rentSale: false,
                    webPaired: false,
                    searchAgent: true
                });
                setChangeStateAgents(!changeStateAgents);
            }
        }
    };

    const handleClickAway = () => {
        setToggleFilters({
            rentSale: false,
            webPaired: false,
            searchAgent: false
        });
    };

    const toggleRentSaleMethod = (e, val) => {
        e.stopPropagation();
        setRentSaleType(val);
        handleClickAway();
    };

    const togglePriceSortMethod = (e) => {
        e.stopPropagation();
        if (priceAscDesc === null || priceAscDesc === '') {
            setPriceAscDesc('asc');
        } else if (priceAscDesc === 'asc') {
            setPriceAscDesc('desc');
        } else if (priceAscDesc === 'desc') {
            setPriceAscDesc('');
        }
    };

    const toggleIsPairedMethod = (e, bool) => {
        e.stopPropagation();
        setIsPaired(bool);
        handleClickAway();
    };

    const filterAgentMethod = (props) => {
        let agents = [...filteredAgent];
        if (!agents.some((i) => i.agentId === props.agentId)) agents.push(props);
        setFilteredAgent(agents);
    };
    const removeFilteredAgents = (index) => {
        let array = [...filteredAgent];
        array.splice(index, 1);
        setFilteredAgent(array);
    };

    const saleRentBtnText = () => {
        if (rentSaleType !== 'rent' && rentSaleType !== 'sale') return spaceHomePage('rent_sale');
        if (rentSaleType === 'rent') return spaceHomePage('rent');
        if (rentSaleType === 'sale') return spaceHomePage('sale');
    };

    const webPairedBtnText = () => {
        if (isPaired === null || isPaired === '') return spaceHomePage('web_paired');
        if (isPaired) return spaceHomePage('paired');
        if (isPaired === false) return spaceHomePage('not_paired');
    };

    const searchAgentBtnText = () => {
        if (filteredAgent.length === 0) return spaceHomePage('spaces.managed.title');
        if (filteredAgent.length === 1) {
            return (
                <div className="filtered-agents-1">
                    {spaceHomePage('spaces.managed.title')} {filteredAgent[0].name}
                </div>
            );
        }
        if (filteredAgent.length > 1) {
            return (
                <>
                    <div className="filtered-agents-1">
                        {spaceHomePage('spaces.managed.title')} {filteredAgent[0].name}
                    </div>
                    <span className="filtered-agents-other">
                        +{filteredAgent.length - 1} {filteredAgent.length > 2 ? 'others' : 'other'}
                    </span>
                </>
            );
        }
    };

    const { adminGlobalSettings, memberGlobalSettings } = globalSettings;
    //portfolio conditions
    const createSpaceCheck = () => {
        if (owner) return true;
        else if (admin && adminGlobalSettings?.globalAdminCreateSpace) return true;
        else if (!owner && !admin && memberGlobalSettings?.globalMemberCreateSpace) return true;
        else return false;
    };

    const seePortfolioCheck = () => {
        if (owner) return true;
        else if (admin && adminGlobalSettings?.globalAdminSeeEntirePortfolio) return true;
        else if (!owner && !admin && memberGlobalSettings?.globalMemberSeeEntirePortfolio) return true;
        else return false;
    };

    const managePortfolioCheck = () => {
        let array = [...new Set(selectAgentIdArray)];
        if (owner) return true;
        if (array.includes(userDetails.id) && array.length === 1) return true;
        else if (admin && adminGlobalSettings?.globalAdminManageTeamPortfolio) return true;
        else if (!owner && !admin && memberGlobalSettings?.globalMemberManageTeamPortfolio) return true;
        else return false;
    };

    const checkForValidSpaceTypeFilter = (value) => {
        if (department === 'ALL' || globalProfile?.department === 'ALL') {
            return true;
        } else {
            return false;
        }
        // if (value === 'sale' && value === 'rent' && department === 'ALL') {
        //     return true;
        // } else if (value === 'sale' && department === 'SALE') {
        //     return true;
        // } else if (value === 'rent' && department === 'LETTINGS') {
        //     return true;
        // } else return false;
    };

    const getAvailableAgents = async () => {
        const url = `${DOMAIN}/v1/dashboard/space/create-space/agents`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };

        const agentsResponse = await getReq({
            url,
            requestHeaders
        });
        const { code, data } = agentsResponse;
        if (code === 200) {
            allAgents = [...data.docs?.managingAgent];
            setActiveMemberList(data.docs?.managingAgent);
        }
    };

    return (
        <>
            {spaceContext?.paymentLoader ? (
                <div className="d-flex main-dash-container">
                    <div className="w-100p mt-20 pl-20 pr-20 payment-loader" style={{ height: 'calc(100vh - 68px)' }}>
                        <LoadingSpin />
                    </div>
                </div>
            ) : globalProfile.plan === 'START PLAN' || userDetails.currentPlan === 'START PLAN' ? (
                <BlockerComponent isPlanStarted={false}></BlockerComponent>
            ) : showBlocker && !isPlanUpdated ? (
                <BlockerComponent></BlockerComponent>
            ) : (
                <div className="d-flex main-dash-container">
                    <NavBar selected="mySpaces" />
                    {activeSpaces &&
                        (noData && spaceContext?.countPublished === 0 && spaceContext?.countUnpublished === 0 ? (
                            <FirstTimePopUp
                                setNewItemPopUp={setNewItemPopUp}
                                setEditMode={setEditMode}
                                editMode={editMode}
                                showProgress={showTrailProgress}
                                setShowProgress={setShowTrailProgress}
                            ></FirstTimePopUp>
                        ) : (
                            <div className="w-100p mt-20 ml-20 mr-20 content-width">
                                <div className="d-flex f-col space-between mb-5">
                                    <div className="products-heading d-flex f-row space-between center-align fs-18 fw-900 h-60 mb-20">
                                        <h1>{spaceHomePage('spaces.title')}</h1>
                                        <button
                                            disabled={!createSpaceCheck()}
                                            className="button-link mt-5"
                                            onClick={() => setNewItemPopUp(true)}
                                        >
                                            {' '}
                                            <img src={AddIcon} alt="" /> {spaceHomePage('spaces.add_space.add_btn')}
                                        </button>
                                    </div>
                                    <div className="d-flex publish-heading-container">
                                        <h4
                                            onClick={() => togglePublishMethod(true)}
                                            style={{ color: togglePublish ? '#000' : '#0000003D', fontWeight: togglePublish ? 700 : 600 }}
                                            className="mr-20"
                                        >
                                            {spaceHomePage('spaces.space_status.published')}
                                            <span className="ml-2" style={{ fontWeight: togglePublish ? 700 : 600 }}>
                                                ({togglePublish ? pageMetadata.totalDocs : totalDocs})
                                            </span>
                                        </h4>
                                        <h4
                                            onClick={() => togglePublishMethod(false)}
                                            style={{ color: !togglePublish ? '#000' : '#0000003D', fontWeight: !togglePublish ? 700 : 600 }}
                                        >
                                            {spaceHomePage('spaces.space_status.unpublished')}
                                            <span className="ml-2" style={{ fontWeight: !togglePublish ? 700 : 600 }}>
                                                ({togglePublish ? totalDocs : pageMetadata.totalDocs})
                                            </span>
                                        </h4>
                                    </div>
                                </div>

                                <div className="active-list">
                                    <div className="active-item-header">
                                        <div className="active-item-header-search">
                                            <img src={SearchIcon} alt="Search" />
                                            <Search
                                                type="text"
                                                labelStyle="mb-10"
                                                placeholder={spaceHomePage('spaces.search.placeholder')}
                                                onChange={searchActiveSpaces}
                                                appendImg={RemoveTextImg}
                                                setCurrentPage={() => {
                                                    setPageNo(1);
                                                }}
                                            />
                                        </div>
                                        {!editMode && (
                                            <>
                                                <div
                                                    onClick={() => {
                                                        if (checkForValidSpaceTypeFilter()) toggleFiltersMethod('rentSale');
                                                        else return;
                                                    }}
                                                    className="active-item-header-search-btn"
                                                    style={{
                                                        color:
                                                            rentSaleType === 'rent' || rentSaleType === 'sale'
                                                                ? '#000'
                                                                : 'rgba(0, 0, 0, 0.5)',
                                                        cursor: checkForValidSpaceTypeFilter() ? '' : 'not-allowed'
                                                    }}
                                                >
                                                    {saleRentBtnText()}
                                                    <img
                                                        onClick={(e) => toggleRentSaleMethod(e, '')}
                                                        src={
                                                            toggleFilters.rentSale
                                                                ? CaretTop
                                                                : rentSaleType === 'rent' || rentSaleType === 'sale'
                                                                ? RemoveTextImg
                                                                : CaretBot
                                                        }
                                                        alt=""
                                                    />
                                                    {toggleFilters.rentSale && (
                                                        <Fade duration={200}>
                                                            <div className="dropdown-filter-wrapper">
                                                                <ClickAwayListener onClickAway={handleClickAway}>
                                                                    <ul>
                                                                        <li
                                                                            className="li-rent-sale"
                                                                            onClick={(e) => toggleRentSaleMethod(e, '')}
                                                                        >
                                                                            {spaceHomePage('spaces.space_type.rent_sale')}
                                                                            {rentSaleType !== 'rent' && rentSaleType !== 'sale' && (
                                                                                <img src={Check} alt="" />
                                                                            )}
                                                                        </li>
                                                                        <li
                                                                            className="li-rent-sale"
                                                                            onClick={(e) => toggleRentSaleMethod(e, 'rent')}
                                                                        >
                                                                            {spaceHomePage('spaces.space_type.rent')}
                                                                            {rentSaleType === 'rent' && <img src={Check} alt="" />}
                                                                        </li>

                                                                        <li
                                                                            className="li-rent-sale"
                                                                            onClick={(e) => toggleRentSaleMethod(e, 'sale')}
                                                                        >
                                                                            {spaceHomePage('spaces.space_type.sale')}
                                                                            {rentSaleType === 'sale' && <img src={Check} alt="" />}
                                                                        </li>
                                                                    </ul>
                                                                </ClickAwayListener>
                                                            </div>
                                                        </Fade>
                                                    )}
                                                </div>
                                                <div
                                                    className="active-item-header-search-btn"
                                                    onClick={(e) => togglePriceSortMethod(e)}
                                                    style={{
                                                        color:
                                                            priceAscDesc === 'asc' || priceAscDesc === 'desc'
                                                                ? '#000'
                                                                : 'rgba(0, 0, 0, 0.5)'
                                                    }}
                                                >
                                                    {spaceHomePage('spaces.price')}
                                                    <img
                                                        style={{ height: '10px' }}
                                                        src={
                                                            priceAscDesc === null || priceAscDesc === ''
                                                                ? StatsUpDownIcon
                                                                : priceAscDesc === 'asc'
                                                                ? StatsUpIcon
                                                                : StatsDownIcon
                                                        }
                                                        alt=""
                                                    ></img>
                                                </div>
                                                <div
                                                    className="active-item-header-search-btn"
                                                    onClick={() => toggleFiltersMethod('webPaired')}
                                                    style={{
                                                        color: isPaired || isPaired === false ? '#000' : 'rgba(0, 0, 0, 0.5)'
                                                    }}
                                                >
                                                    {webPairedBtnText()}
                                                    <img
                                                        onClick={(e) => toggleIsPairedMethod(e, '')}
                                                        src={
                                                            toggleFilters.webPaired
                                                                ? CaretTop
                                                                : isPaired || isPaired === false
                                                                ? RemoveTextImg
                                                                : CaretBot
                                                        }
                                                        alt=""
                                                    />
                                                    {toggleFilters.webPaired && (
                                                        <Fade duration={200}>
                                                            <div className="dropdown-filter-wrapper">
                                                                <ClickAwayListener onClickAway={handleClickAway}>
                                                                    <ul>
                                                                        <li
                                                                            className="li-web-paired"
                                                                            onClick={(e) => toggleIsPairedMethod(e, '')}
                                                                        >
                                                                            {spaceHomePage('spaces.web_staus.all')}
                                                                            {isPaired === null && <img src={Check} alt="" />}
                                                                        </li>
                                                                        <li
                                                                            className="li-web-paired"
                                                                            onClick={(e) => toggleIsPairedMethod(e, false)}
                                                                        >
                                                                            {spaceHomePage('spaces.web_staus.not_paired')}
                                                                            {isPaired === false && <img src={Check} alt="" />}
                                                                        </li>
                                                                        <li
                                                                            className="li-web-paired"
                                                                            onClick={(e) => toggleIsPairedMethod(e, true)}
                                                                        >
                                                                            {spaceHomePage('spaces.web_staus.paired')}
                                                                            {isPaired && <img src={Check} alt="" />}
                                                                        </li>
                                                                    </ul>
                                                                </ClickAwayListener>
                                                            </div>
                                                        </Fade>
                                                    )}
                                                </div>
                                                <div
                                                    className="active-item-header-search-btn"
                                                    onClick={() => {
                                                        if (seePortfolioCheck()) toggleFiltersMethod('agent');
                                                    }}
                                                    style={{
                                                        color: filteredAgent.length > 0 ? '#000' : 'rgba(0, 0, 0, 0.5)',
                                                        cursor: seePortfolioCheck() ? 'pointer' : 'not-allowed'
                                                    }}
                                                >
                                                    {searchAgentBtnText()}
                                                    <img
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setFilteredAgent([]);
                                                            setChangeState(!changeState);
                                                        }}
                                                        src={
                                                            toggleFilters.searchAgent
                                                                ? CaretTop
                                                                : filteredAgent.length > 0
                                                                ? RemoveTextImg
                                                                : CaretBot
                                                        }
                                                        alt=""
                                                    />
                                                    {toggleFilters.searchAgent && (
                                                        <Fade duration={200}>
                                                            <div className="dropdown-filter-wrapper" onClick={(e) => e.stopPropagation()}>
                                                                <ClickAwayListener onClickAway={handleClickAway}>
                                                                    <div className="dropdown-filter-wrapper-search">
                                                                        {filteredAgent.length > 0 && (
                                                                            <ul
                                                                                className="mt-10 agent-list-overflow pb-4"
                                                                                style={{
                                                                                    maxHeight: '100px',
                                                                                    borderBottom: '0.5px solid rgba(0, 0, 0, 0.15)'
                                                                                }}
                                                                            >
                                                                                {filteredAgent?.map((props, index) => (
                                                                                    <li key={index}>
                                                                                        <div className="filter-agent-img-container">
                                                                                            {props?.avatar?.length > 0 ? (
                                                                                                <img src={props.avatar} alt="" />
                                                                                            ) : (
                                                                                                <Avatar
                                                                                                    name={props.name}
                                                                                                    value=""
                                                                                                    size="24"
                                                                                                    round={true}
                                                                                                    color="black"
                                                                                                    maxInitials={1}
                                                                                                    textSizeRatio={2}
                                                                                                    style={{
                                                                                                        fontWeight: '700',
                                                                                                        fontSize: '10px'
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        </div>
                                                                                        <span className="filter-agent-img-container">
                                                                                            {props.name}
                                                                                        </span>
                                                                                        <img
                                                                                            className="filtered-agent-list"
                                                                                            src={RemoveTextImg}
                                                                                            onClick={() => removeFilteredAgents(index)}
                                                                                            alt=""
                                                                                        />
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        )}
                                                                        <div className="d-flex vertical-center dropdown-filter-wrapper-search-container">
                                                                            <img
                                                                                className="filer-search-icon"
                                                                                src={SearchIcon}
                                                                                alt="Search"
                                                                            />
                                                                            <Search
                                                                                type="text"
                                                                                labelStyle="mb-10"
                                                                                placeholder={spaceHomePage(
                                                                                    'spaces.managed.input_placeholder'
                                                                                )}
                                                                                onChange={searchActiveMembers}
                                                                                setCurrentPage={() => {
                                                                                    setPageNo(1);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <ul className="mt-10 mb-10 agent-list-overflow">
                                                                            {activeMemberList?.map((props, index) => {
                                                                                return (
                                                                                    <li
                                                                                        className="active-search-agents"
                                                                                        key={index}
                                                                                        onClick={() => filterAgentMethod(props)}
                                                                                    >
                                                                                        <div className="filter-agent-img-container">
                                                                                            {props?.avatar?.length > 0 ? (
                                                                                                <img src={props.avatar} alt="" />
                                                                                            ) : (
                                                                                                <Avatar
                                                                                                    name={props.name}
                                                                                                    value=""
                                                                                                    size="24"
                                                                                                    round={true}
                                                                                                    color="black"
                                                                                                    maxInitials={1}
                                                                                                    textSizeRatio={2}
                                                                                                    style={{
                                                                                                        fontWeight: '700',
                                                                                                        fontSize: '10px'
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        </div>
                                                                                        <span className="filter-agent-img-container">
                                                                                            {props.name}
                                                                                        </span>
                                                                                    </li>
                                                                                );
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                </ClickAwayListener>
                                                            </div>
                                                        </Fade>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                        <div className="edit-btn-wrapper">
                                            {editMode ? (
                                                <div className="edit-btn-wrapper-on d-flex center-align">
                                                    <div className="edit-btn-wrapper-on-selected mr-15">
                                                        {selectedCards.length} {spaceHomePage('selected')}
                                                    </div>
                                                    <button
                                                        className="button-delete-link"
                                                        onClick={() => setOpenRemoveProductPopUp(true)}
                                                        disabled={selectedCards.length === 0 || !managePortfolioCheck()}
                                                    >
                                                        {' '}
                                                        <img src={DeleteIcon} alt="" /> {spaceHomePage('delete')}
                                                    </button>
                                                    <button
                                                        className="button-edit-link ml-15"
                                                        onClick={() => setOpenPublishUnpublishPopUp(true)}
                                                        disabled={selectedCards.length === 0}
                                                    >
                                                        {' '}
                                                        <img src={togglePublish ? UnpublishIcon : PublishIcon} alt="" />{' '}
                                                        {togglePublish ? spaceHomePage('unpublish') : spaceHomePage('publish')}
                                                    </button>
                                                    <div className="edit-btn-wrapper-on-pipe mr-15 ml-15" />
                                                    <button className="button-done-link" onClick={clearCards}>
                                                        {' '}
                                                        <img src={CheckIcon} alt="" /> {spaceHomePage('done')}
                                                    </button>
                                                </div>
                                            ) : (
                                                <button className="button-edit-link" onClick={toggleEdit}>
                                                    {' '}
                                                    <img src={EditIcon} alt="" /> {spaceHomePage('edit')}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    <div className="pt-15 h-100p ovf-y-scroll-show">
                                        {renderActiveSpaces()}
                                        {isActiveSpacesLoading ? (
                                            <div className="prop-card pos-rel d-flex center-align ">
                                                <div className="prop-card-wrapper w-100p d-grid">
                                                    <div className="heading-wrapper d-flex w-100p">
                                                        <div className="w-64 h-100 br-8 shimmerBG" />
                                                        <div className="ml-20 d-flex flex-col h-100 center-align">
                                                            <div className="w-80 br-4 h-15 shimmerBG align-self-start" />
                                                            <div className="w-200 br-4 h-15 shimmerBG mt-10" />
                                                        </div>
                                                    </div>
                                                    <div className="w-50 h-15 shimmerBG br-4" />
                                                    <div className="w-70 h-15 shimmerBG br-4" />
                                                    <div className="w-30 h-15 shimmerBG br-4" />
                                                    <div className="w-100 h-15 shimmerBG br-4" />
                                                </div>
                                            </div>
                                        ) : (
                                            <InView
                                                as="div"
                                                onChange={(inView) => {
                                                    if (inView && hasNextPage) {
                                                        setPageNo(pageNo + 1);
                                                    }
                                                }}
                                                className="prop-card pos-rel d-flex center-align o-00"
                                            ></InView>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    {openMagicLinkPopUp ? (
                        <PopUpMagicPairing setMagicLinkPopUp={setMagicLinkPopUp} fetchActiveSpaces={fetchActiveSpaces}></PopUpMagicPairing>
                    ) : (
                        ''
                    )}

                    {openNewItemPopUp ? (
                        <AddSpace
                            showPopupItem={setNewItemPopUp}
                            fetchActiveSpaces={fetchActiveSpaces}
                            setNewItemPopUp={setNewItemPopUp}
                        ></AddSpace>
                    ) : (
                        ''
                    )}
                    {openCTAPopUp ? <PopUpCTA setOpenCTAPopUp={setOpenCTAPopUp} fetchActiveSpaces={fetchActiveSpaces}></PopUpCTA> : ''}
                    {openRemoveProductPopUp ? (
                        <PopUpRemoveProduct
                            setOpenRemoveProductPopUp={setOpenRemoveProductPopUp}
                            selectedCards={selectedCards}
                            setSelectedCards={setSelectedCards}
                            deleteNotification={deleteNotification}
                            setDeleteNotification={setDeleteNotification}
                            setPageNo={setPageNo}
                            setChangeState={setChangeState}
                            changeState={changeState}
                            setChangeCountState={setChangeCountState}
                            changeCountState={changeCountState}
                        ></PopUpRemoveProduct>
                    ) : (
                        ''
                    )}
                    {openPublishUnpublishPopUp ? (
                        <PopUpPublishUnpublish
                            setOpenPublishUnpublishPopUp={setOpenPublishUnpublishPopUp}
                            selectedCards={selectedCards}
                            togglePublish={togglePublish}
                            setSelectedCards={setSelectedCards}
                            publishUnPublishNotification={publishUnPublishNotification}
                            setPublishUnPublishNotification={setPublishUnPublishNotification}
                            setPageNo={setPageNo}
                            setChangeState={setChangeState}
                            changeState={changeState}
                            setChangeCountState={setChangeCountState}
                            changeCountState={changeCountState}
                        ></PopUpPublishUnpublish>
                    ) : (
                        ''
                    )}
                    {openEditProductPopUp ? (
                        <PopUpEditSpace
                            setOpenEditProductPopUp={setOpenEditProductPopUp}
                            fetchActiveSpaces={fetchActiveSpaces}
                        ></PopUpEditSpace>
                    ) : (
                        ''
                    )}
                    {openEmptySkillPopUp && (
                        <PopUpEmptySkill
                            setOpenEmptySkillPopUp={setOpenEmptySkillPopUp}
                            fetchActiveSpaces={fetchActiveSpaces}
                        ></PopUpEmptySkill>
                    )}
                </div>
            )}
        </>
    );
}

export default MySpaces;
