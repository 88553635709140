import React, { useEffect, useState, useRef } from 'react';
import InviteEmailInput from './EmailInput';
import AddMore from '../AddMore';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import NoteComponent from '../NoteComponent';
import MinusIcon from '../../assets/minus.png';
import './inviteemail.scss';
import ButtonAtom from '../ButtonAtom';
import { DOMAIN } from '../../constants/base';
import { postReq } from '../../utils/api';
import { getSessionStorage, setSessionStorage } from '../../utils';

export default function InviteByEmail(props) {
    const { setInvitePopUp, setupteam, title, fetchActiveMembers, beginSetUp } = props;
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const userDetails = getSessionStorage('ss-user-spaces') || {};
    const [invites, setInvites] = useState([{ email: '', uid: 1, teamRole: 'AGENT', department: 'ALL', role: title ? 'MEMBER' : 'ADMIN' }]);
    const [scrollInvites, setScrollInvites] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const [isBottomBtnDisabled, setisBottomBtnDisabled] = useState(true);
    const [duplicatesIdx, setDuplicateIdx] = useState([]);
    const [totalInvitesClicked, setTotalInvitesClicked] = useState(1);
    useEffect(() => {
        if (invites.length && isBottomBtnDisabled) {
            if (invites[0]?.email !== '') {
                if (isBottomBtnDisabled) {
                    setisBottomBtnDisabled(false);
                }
            }
        }
    }, [invites]);

    const addInvite = () => {
        setInvites([...invites, { email: '', role: 'MEMBER', teamRole: 'AGENT', department: 'ALL', uid: totalInvitesClicked + 1 }]);
        setTotalInvitesClicked(totalInvitesClicked + 1);
        setScrollInvites(true);
    };

    const removeInvite = (id) => {
        let filteredArr = invites.filter((_, idx) => idx !== id);
        setDuplicateIdx([]);
        setInvites(() => filteredArr);
    };

    const AlwaysScrollToBottom = () => {
        const elementRef = useRef();
        useEffect(() =>
            elementRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            })
        );
        return <div ref={elementRef} />;
    };
    const sendInvites = async () => {
        setIsLoading(true);
        let url = `${DOMAIN}/v1/dashboard/space/user/v2/invite-team`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        if (invites[0]?.email) {
            let validInvites = [];
            for (let inviting of invites) {
                if (inviting.email !== '') {
                    validInvites = [...validInvites, inviting];
                }
            }
            setInvites(validInvites);
            let ds = [];
            let payloadData = [];
            validInvites
                .map((e) => e['email'])
                .map((e, i, final) => final.indexOf(e) === i && i)
                .filter(function (e, i) {
                    if (e === false && e !== '') {
                        ds = [...ds, i];
                    }
                    return i;
                });
            if (ds.length) {
                setDuplicateIdx(ds);
                setIsLoading(false);
                return;
            }
            payloadData = validInvites.map((inmail) => ({
                email: inmail.email,
                teamRole: inmail.teamRole,
                department: inmail.department,
                role: inmail.role
            }));

            const payload = {
                invitees: payloadData
            };
            const res = await postReq({ url, requestHeaders, payload }, setIsLoading);
            const { code, message } = res;
            if (code === 200) {
                setIsLoading(false);
                setInvitePopUp(false);
                if (beginSetUp) {
                    setSessionStorage({
                        key: 'ss-user-spaces',
                        value: {
                            ...userDetails,
                            teamExists: true
                        }
                    });
                }
                if (message?.length) {
                    toast.success(message, {
                        position: 'top-center',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined
                    });
                }
                fetchActiveMembers && fetchActiveMembers();
            }
        }
    };
    const calcZindex = (i) => {
        return parseInt(999999 - i);
    };
    return (
        <>
            <h1 className="component-title"> {title ? title : 'Let’s invite your Admin Team'} </h1>
            <div className="component-container">
                <div className="invite-email-field">
                    <div className="invite-container">
                        {invites.map((invitingObj, index) => {
                            return (
                                <li
                                    style={{
                                        zIndex: calcZindex(index)
                                    }}
                                    key={invitingObj.uid.toString()}
                                    className="invite-li"
                                >
                                    <InviteEmailInput
                                        currentIndex={index}
                                        setInvites={setInvites}
                                        invites={invites}
                                        duplicatesIdx={duplicatesIdx}
                                    />
                                    {index !== 0 && (
                                        <button className="close-btn" onClick={() => removeInvite(index)}>
                                            <img src={MinusIcon} alt="minimize icon"></img>
                                        </button>
                                    )}
                                </li>
                            );
                        })}
                        {setupteam && !title && <NoteComponent message={t('team.invite.note')} />}
                        {scrollInvites && <AlwaysScrollToBottom />}
                        <div className="add-more-field">
                            <AddMore onClickFn={addInvite} />
                        </div>
                    </div>
                </div>
                <div className="bottom-step-wrapper">
                    <div className="btn-container">
                        <ButtonAtom
                            label="send invitations"
                            buttonStyle="custom-btn"
                            onClick={sendInvites}
                            showLoader={isLoading}
                            disabled={isBottomBtnDisabled}
                        ></ButtonAtom>
                    </div>
                </div>
            </div>
        </>
    );
}
