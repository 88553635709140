import React from 'react';
import Input from '../Input';
import CurrencyFormat from 'react-currency-format';
import cc from 'currency-codes';
import './spacefield.scss';

import MinusIcon from '../../assets/minus.png';
import Bed from '../../assets/bed-bg.svg';
import Bath from '../../assets/bath-bg.svg';
import DownArrIcon from '../../assets/caret.svg';
import AddIconBlack from '../../assets/addIconBlack.png';
import { useTranslation } from 'react-i18next';
import Select from '../Select';
export default function SpaceField(props) {
    const {
        isEnabled,
        setIsEnabled,
        spacefieldValue,
        setSpaceFieldValue,
        labelString,
        placeholderString,
        title,
        secSpaceFieldValue,
        setSecSpaceFieldValue,
        labelStringSec,
        type,
        setSpaceUpdated,
        spaceType,
        rentalPriceType,
        setRentalPriceType,
        setRentalPriceToShow,
        userPermissionType
    } = props;
    const { t } = useTranslation();

    const renderPriceField = () => {
        return isEnabled ? (
            <>
                <div className="price-container">
                    <div className="price-inpt-wrapper">
                        <div className="input-main">
                            <div className="input-val-error d-flex space-between">
                                <div>
                                    <label stye={{ color: '#3A3A3A' }} className="d-ib fs-8 fw-900 uc mb-5">
                                        {labelString}
                                    </label>
                                </div>
                            </div>
                            <div className="custom-price">
                                <CurrencyFormat
                                    placeholder="1000"
                                    value={spacefieldValue ? spacefieldValue : ''}
                                    thousandSeparator={true}
                                    onValueChange={(values) => {
                                        setSpaceFieldValue(values.value);
                                        setSpaceUpdated(true);
                                    }}
                                    isAllowed={(values) => {
                                        if (values.value === '0') return false;
                                        else return true;
                                    }}
                                    allowNegative={false}
                                    decimalScale={0}
                                ></CurrencyFormat>
                            </div>
                        </div>
                        <div
                            className="select-input"
                            style={{
                                marginLeft: '30px',
                                height: '44px',
                                position: 'relative',
                                bottom: '5px',
                                width: '88px'
                            }}
                        >
                            {/* <label className="d-ib fs-8 fw-900 uc">{labelStringSec}</label> */}
                            <select
                                name="currency"
                                id="currency"
                                value={secSpaceFieldValue}
                                onChange={(e) => {
                                    setSecSpaceFieldValue(e.target.value);
                                    setSpaceUpdated(true);
                                }}
                            >
                                {cc.codes().map((code) => (
                                    <option key={code} value={code}>
                                        {code}
                                    </option>
                                ))}
                            </select>
                            <img
                                src={DownArrIcon}
                                alt="arrow-down"
                                style={{
                                    position: 'absolute',
                                    right: '15px',
                                    bottom: '32%'
                                }}
                            />
                        </div>
                        {(spaceType === 'RENTAL' || spaceType === 'Rental') && userPermissionType !== 'SALE' && (
                            <div className="rental-selection">
                                <Select
                                    defaultOption={rentalPriceType ? rentalPriceType : 'Per Month'}
                                    optionsList={[
                                        { display: 'Per Month', key: 'MONTH' },
                                        { display: 'Per Year', key: 'YEAR' }
                                    ]}
                                    rentalPriceType={rentalPriceType}
                                    setRentalPriceType={setRentalPriceType}
                                    setSpaceUpdated={setSpaceUpdated}
                                    allowDropDown={userPermissionType !== 'SALE' ? true : false}
                                    setRentalPriceToShow={setRentalPriceToShow}
                                ></Select>
                            </div>
                        )}
                    </div>
                    <button
                        className="optional-btn"
                        style={{
                            position: 'relative',
                            bottom: '50%',
                            transform: 'translateY(50%)'
                        }}
                        onClick={() => {
                            if (rentalPriceType) {
                                setRentalPriceType('');
                                setRentalPriceToShow && setRentalPriceToShow('');
                            }
                            setSpaceFieldValue('');
                            setSecSpaceFieldValue('');
                            setIsEnabled(false);
                            setSpaceUpdated(true);
                        }}
                    >
                        {' '}
                        <img src={MinusIcon} alt="minimize icon"></img>
                    </button>
                </div>
            </>
        ) : (
            <div className="optional-field" style={{ cursor: 'pointer' }} onClick={() => setIsEnabled(true)}>
                <button className="optional-btn">
                    <img src={AddIconBlack} alt="add icon"></img>
                </button>
                <div className="title">{title}</div>
            </div>
        );
    };

    const renderFlatField = () => {
        return isEnabled ? (
            <>
                <div className="flat-container">
                    <div className="flat-inpt-wrapper">
                        <Input
                            type="text"
                            label={labelString}
                            labelStyle="uc mb-5"
                            placeholder={placeholderString}
                            value={spacefieldValue}
                            onChange={(e) => {
                                setSpaceFieldValue(e.target.value);
                                setSpaceUpdated(true);
                            }}
                            customHeight="44px"
                        />
                    </div>
                    <button
                        className="optional-btn"
                        onClick={() => {
                            setSpaceFieldValue('');
                            setIsEnabled(false);
                            setSpaceUpdated(true);
                        }}
                    >
                        {' '}
                        <img src={MinusIcon} alt="minimize icon"></img>
                    </button>
                </div>
            </>
        ) : (
            <div className="optional-field" style={{ cursor: 'pointer' }} onClick={() => setIsEnabled(true)}>
                <button className="optional-btn">
                    <img src={AddIconBlack} alt="add icon"></img>
                </button>
                <div className="title">{title}</div>
            </div>
        );
    };

    const renderRoomsField = () => {
        return isEnabled ? (
            <div className="price-container">
                <div className="price-inpt-wrapper" style={{ width: '318px' }}>
                    <Input
                        // customWidth={'50%'}
                        type="text"
                        label={labelString}
                        labelStyle="uc mb-5"
                        placeholder="2"
                        value={spacefieldValue}
                        onChange={(e) => {
                            if (e.target.value.length > 2 && !e.target.value.includes('.')) {
                                return;
                            }
                            !isNaN(e.currentTarget.value) && setSpaceFieldValue(e.target.value);
                            setSpaceUpdated(true);
                        }}
                        customHeight="44px"
                        maxLength={4}
                        icon={Bed}
                    />
                    <Input
                        icon={Bath}
                        // customWidth={'50%'}
                        type="text"
                        label={labelStringSec}
                        labelStyle="uc mb-5"
                        placeholder="2"
                        value={secSpaceFieldValue}
                        onChange={(e) => {
                            if (e.target.value.length > 2 && !e.target.value.includes('.')) {
                                return;
                            }
                            !isNaN(e.currentTarget.value) && setSecSpaceFieldValue(e.target.value);
                            setSpaceUpdated(true);
                        }}
                        customHeight="44px"
                        customMl="30px"
                        maxLength={4}
                    />
                    <button
                        className="optional-btn"
                        style={{ position: 'absolute', top: '50%', right: '0px', transform: 'translateY(-50%)' }}
                        onClick={() => {
                            setIsEnabled(false);
                            setSpaceFieldValue('');
                            setSecSpaceFieldValue('');
                            setSpaceUpdated(true);
                        }}
                    >
                        {' '}
                        <img src={MinusIcon} alt="minimize icon"></img>
                    </button>
                </div>
            </div>
        ) : (
            <div className="optional-field" style={{ cursor: 'pointer' }} onClick={() => setIsEnabled(true)}>
                <button className="optional-btn">
                    <img src={AddIconBlack} alt="add icon"></img>
                </button>
                <div className="title">{title}</div>
            </div>
        );
    };

    const renderRatings = () => {
        return isEnabled ? (
            <div className="price-container">
                <div className="price-inpt-wrapper" style={{ width: '318px' }}>
                    <Input
                        type="number"
                        label={labelString}
                        labelStyle="uc mb-5"
                        placeholder="90"
                        value={spacefieldValue}
                        onChange={(e) => {
                            if (e.target.value.length > 2 && !e.target.value.includes('.')) {
                                return;
                            }
                            !isNaN(e.currentTarget.value) && setSpaceFieldValue(e.target.value);
                            setSpaceUpdated(true);
                        }}
                        customHeight="44px"
                        maxLength={2}
                    />
                    <Input
                        type="number"
                        label={labelStringSec}
                        labelStyle="uc mb-5"
                        placeholder="90"
                        value={secSpaceFieldValue}
                        onChange={(e) => {
                            if (e.target.value.length > 2 && !e.target.value.includes('.')) {
                                return;
                            }
                            !isNaN(e.currentTarget.value) && setSecSpaceFieldValue(e.target.value);
                            setSpaceUpdated(true);
                        }}
                        customHeight="44px"
                        customMl="30px"
                        maxLength={2}
                    />
                </div>
                <button
                    className="optional-btn"
                    onClick={() => {
                        setSpaceFieldValue('');
                        setSecSpaceFieldValue('');
                        setIsEnabled(false);
                        setSpaceUpdated(true);
                    }}
                >
                    {' '}
                    <img src={MinusIcon} alt="minimize icon"></img>
                </button>
            </div>
        ) : (
            <div className="optional-field" style={{ cursor: 'pointer' }} onClick={() => setIsEnabled(true)}>
                <button className="optional-btn">
                    <img src={AddIconBlack} alt="add icon"></img>
                </button>
                <div className="title">{title}</div>
            </div>
        );
    };

    const renderSizeField = () => {
        return isEnabled ? (
            <div className="price-container">
                <div
                    className="price-inpt-wrapper"
                    style={{
                        alignItems: 'flex-start'
                    }}
                >
                    <Input
                        customWidth={'200px'}
                        type="text"
                        label={labelString}
                        labelStyle="uc mb-5"
                        placeholder="120"
                        value={spacefieldValue}
                        onChange={(e) => {
                            !isNaN(e.currentTarget.value) && setSpaceFieldValue(e.target.value);
                            setSpaceUpdated(true);
                        }}
                        customHeight="44px"
                        maxLength={7}
                    />
                    <div className="select-input pos-rel ml-30">
                        <label className="d-ib fs-8 fw-900 uc">{labelStringSec}</label>
                        <select
                            name="measure"
                            id="measure"
                            value={secSpaceFieldValue}
                            onChange={(e) => setSecSpaceFieldValue(e.target.value)}
                        >
                            <option value={'SQFT'}>{t('space_details.size.option_sqft')}</option>
                            <option value={'SQMT'}>{t('space_details.size.option_sqmt')}</option>
                        </select>
                        <img
                            src={DownArrIcon}
                            alt="arrow-down"
                            style={{
                                position: 'absolute',
                                right: '15px',
                                bottom: '32%'
                            }}
                        />
                    </div>
                </div>
                <button
                    className="optional-btn"
                    onClick={() => {
                        setIsEnabled(false);
                        setSpaceFieldValue('');
                        setSecSpaceFieldValue('');
                        setSpaceUpdated(true);
                    }}
                >
                    {' '}
                    <img src={MinusIcon} alt="minimize icon"></img>
                </button>
            </div>
        ) : (
            <div className="optional-field" style={{ cursor: 'pointer' }} onClick={() => setIsEnabled(true)}>
                <button className="optional-btn">
                    <img src={AddIconBlack} alt="add icon"></img>
                </button>
                <div className="title">{title}</div>
            </div>
        );
    };
    const renderFields = (type) => {
        switch (type) {
            case 'price':
                return renderPriceField();
            case 'rooms':
                return renderRoomsField();
            case 'size':
                return renderSizeField();
            case 'ratings':
                return renderRatings();
            default:
                return renderFlatField();
        }
    };
    return <div className="space-field">{renderFields(type)}</div>;
}
