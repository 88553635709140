import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
/* eslint import/no-webpack-loader-syntax: off */
import mapboxgl from '!mapbox-gl';
import ButtonAtom from '../ButtonAtom';
import MarkerComponent from './marker';

// styles
import 'mapbox-gl/dist/mapbox-gl.css';
import './mapbox.scss';

// assets
import ClearText from '../../assets/ClearText.png';
import CancelWhite from '../../assets/cancel-white.svg';
import SearchListingPassive from '../../assets/ListingsPassive.svg';
import SearchListingActive from '../../assets/ListingsActive.svg';
import { useTranslation } from 'react-i18next';

export default function MapBox({
    handleMarkerClick,
    spaceAddress,
    setSpaceAddress,
    setSpaceLatLng,
    spaceLatLng,
    setPrevSpaceLatLng,
    setSpaceUpdated
}) {
    const { t: mapBox } = useTranslation();
    const [searchData, setSearchData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [errorSearchData, setErrorSearchData] = useState(false);
    const [currentZoom, setCurrentZoom] = useState(10);
    const [showRecommendationSpace, setShowRecommendationSpace] = useState(true);
    // space lat and lng
    const [spaceCoordinates, setSpaceCoordinates] = useState(spaceLatLng);
    const [markerMoving, setMarkerMoving] = useState(false);

    const mapKey = process.env.REACT_APP_MAP;
    const mapContainer = useRef(null);
    const map = useRef(null);

    const [validAddress, setValidAddress] = useState(false);

    const [startFetchMarker, setStartFetchMarker] = useState(false);
    const [rcItemClicked, setRcItemClicked] = useState(false);
    //marker Ids
    let loadingMarkerId = 'loadingMarker';
    let renderedMarkerId = 'renderedMarker';
    let markerMovingEl = document.getElementById(loadingMarkerId);
    let markerEl = document.getElementById(renderedMarkerId);

    mapboxgl.accessToken = mapKey;

    let markerNode = document.createElement('div');
    ReactDOM.render(
        <MarkerComponent loadingMarkerId={loadingMarkerId} renderedMarkerId={renderedMarkerId} handleMarkerClick={handleMarkerClick} />,
        markerNode
    );
    const markerRef = useRef(
        new mapboxgl.Marker({
            element: markerNode,
            draggable: true
        })
    );

    useEffect(() => {
        if (searchText === '') return;
        setSpaceCoordinates(spaceLatLng);
        let coordinates = { lng: spaceLatLng.lng, lat: spaceLatLng.lat };
        if (map.current) {
            map.current.flyTo({
                center: [spaceLatLng.lng, spaceLatLng.lat],
                zoom: currentZoom
            });
            handleMarkerMove(coordinates);
        }
    }, [spaceLatLng]);
    // render initial map
    useEffect(() => {
        if (map.current) return;
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/holofy-mapbox/ckqhqp55d0o7217pi2b3k3zp8',
            center: [spaceCoordinates.lng, spaceCoordinates.lat],
            zoom: currentZoom,
            maxZoom: 20,
            minZoom: 0,
            attributionControl: false,
            logoPosition: 'top-right'
        });
        map.current.addControl(new mapboxgl.NavigationControl());
        setCurrentZoom(Math.round(map.current?.getZoom()));

        if (map.current) {
            map.current.on('load', () => {
                handleMarkerMove();
                markerRef.current.setLngLat([spaceCoordinates.lng, spaceCoordinates.lat]).addTo(map.current);
                // disable map rotation using touch rotation gesture
                map.current.touchZoomRotate.disableRotation();
                markerRef.current.on('dragstart', onDragStart);
                markerRef.current.on('dragend', onDragEnd);
                markerMovingEl = document.getElementById(loadingMarkerId);
                markerEl = document.getElementById(renderedMarkerId);
                map.current.on('move', () => {
                    setSpaceCoordinates({
                        lat: parseFloat(map.current.getCenter().lat.toFixed(4)),
                        lng: parseFloat(map.current.getCenter().lng.toFixed(4))
                    });
                    setPrevSpaceLatLng({ lat: parseFloat(map.current.getCenter().lat), lng: parseFloat(map.current.getCenter().lng) });
                    setShowRecommendationSpace(true);
                    setSpaceUpdated(true);
                    setCurrentZoom(map.current.getZoom().toFixed(2));
                });
                //click event
                map.current.on('click', (e) => {
                    if (markerRef.current) {
                        let coordinates = e.lngLat;
                        setSpaceCoordinates({ lat: coordinates.lat, lng: coordinates.lng });
                        markerRef.current.setLngLat(coordinates).addTo(map.current);
                        handleMarkerMove(coordinates);
                    }
                });
            });
        }
    });

    useEffect(() => {
        setSpaceAddress(searchText);
        if (spaceCoordinates) {
            setPrevSpaceLatLng(null);
            setSpaceLatLng(spaceCoordinates);
        }
    }, [searchText]);

    useEffect(() => {
        if (!markerMovingEl || !markerEl) return;
        if (markerMoving) {
            markerMovingEl.style.display = 'block';
            markerEl.style.display = 'none';
        } else {
            markerMovingEl.style.display = 'none';
            markerEl.style.display = 'flex';
        }
    }, [markerMoving]);

    useEffect(() => {
        if (startFetchMarker) {
            handleMarkerMove();
        }
    }, [startFetchMarker]);
    // Search text handlers
    /* clear search text */
    const clearSearchValue = () => {
        // setSpaceCoordinates({lat: 0, lng: 0});
        setSearchText('');
        setValidAddress(false);
        // setRcItemClicked(true);
    };

    const calcZoomValue = (place_type) => {
        switch (place_type) {
            case 'country':
                return 4;
            case 'poi':
                return 14;
            case 'region':
                return 10;
            case 'neighborhood':
                return 16;
            case 'place':
                return 15;
            case 'locality':
                return 15;
            case 'postcode':
                return 16;
            default:
                return 16;
        }
    };

    /* handle API for search text */
    const handleMapSearch = async (e) => {
        let placeSearch = encodeURIComponent(e.target.value);
        await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${placeSearch}.json?types=country,region,postcode,district,place,locality,neighborhood,address,poi&access_token=${mapKey}&limit=4`
        )
            .then((response) => response.json())
            .then((data) => {
                if (data && data?.features?.length > 0) {
                    setSearchData(data);
                    setShowRecommendationSpace(false);
                    setErrorSearchData(false);
                } else {
                    setErrorSearchData(true);
                    setShowRecommendationSpace(true);
                    setPrevSpaceLatLng({ lat: -1, lng: -1 });
                }
            });
    };

    // marker handlers
    function onDragStart() {
        setMarkerMoving(true);
    }
    function onDragEnd() {
        setMarkerMoving(false);
        const lngLat = markerRef.current.getLngLat();
        setSpaceCoordinates({ lat: lngLat.lat, lng: lngLat.lng });
        setStartFetchMarker(true);
    }

    // find location when marker is moving or clicked on map
    const handleMarkerMove = async (coordinates = null) => {
        if (spaceCoordinates) {
            let latLngParam;
            if (coordinates) {
                latLngParam = coordinates.lng + ',' + coordinates.lat;
            } else {
                latLngParam = spaceCoordinates.lng + ',' + spaceCoordinates.lat;
            }
            await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${latLngParam}.json?limit=1&access_token=${mapKey}`)
                .then((response) => response.json())
                .then((data) => {
                    const address = data.features[0].place_name;
                    setSearchText(address);
                    setValidAddress(true);
                    setStartFetchMarker(false);
                })
                .catch((err) => console.error(err));
        }
        return null;
    };

    // set input value on search data item click
    const handleSearchDataItemClick = async (searchVal) => {
        setRcItemClicked(true);
        if (searchVal) {
            // setCurrentSearchData(searchVal);
            setSearchText(searchVal.place_name);
        }
        setSearchData([]);
        setShowRecommendationSpace(true);
        map.current.flyTo({
            center: searchVal.center,
            zoom: calcZoomValue(searchVal.place_type[0])
        });
        if (markerRef.current) {
            markerRef.current.setLngLat(searchVal.center).addTo(map.current);
        }
        setValidAddress(true);
    };

    return (
        <div className="map-container">
            <div className="map-wrapper" ref={mapContainer}>
                {errorSearchData ? (
                    <div className="error-box">
                        <img
                            src={CancelWhite}
                            alt="cancel-btn"
                            onClick={() => {
                                setErrorSearchData(false);
                                setSearchText('');
                            }}
                        />
                        We couldn’t find that address. Try without unit, suite or floor numbers.
                    </div>
                ) : (
                    ''
                )}
                {searchText.length > 0 && !showRecommendationSpace && (
                    <div className="search-results">
                        <ul>
                            {searchData &&
                                searchData.features &&
                                searchData.features.map((data) => {
                                    return (
                                        <li onClick={() => handleSearchDataItemClick(data)}>
                                            <img src={SearchListingPassive} alt="space-not-hovered" />
                                            <img src={SearchListingActive} alt="space-hovered" />
                                            <span>{data.place_name}</span>
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                )}
                <div className="map-search-box">
                    {markerMoving ? (
                        <>
                            <div
                                className="shimmer-bg"
                                style={{
                                    width: rcItemClicked && searchText.length ? '100%' : ''
                                }}
                            ></div>
                        </>
                    ) : (
                        <div
                            className="input-wrapper"
                            style={{
                                width: rcItemClicked && searchText.length ? '100%' : ''
                            }}
                        >
                            <input
                                type="text"
                                placeholder={mapBox('spaces.add_space.map.placeholder')}
                                value={searchText}
                                disabled={validAddress ? true : false}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                    // setRcItemClicked(false);
                                    handleMapSearch(e);
                                }}
                            />
                            {searchText.length > 0 ? (
                                <div className="clear-text" onClick={clearSearchValue}>
                                    <img src={ClearText} alt="clear-icon" />
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    )}
                    {rcItemClicked && searchText.length ? (
                        ''
                    ) : (
                        <ButtonAtom label={mapBox('spaces.add_space.map.search')} showLoader={false} buttonStyle="search-map"></ButtonAtom>
                    )}
                </div>
            </div>
        </div>
    );
}
