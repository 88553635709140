import { EDIT_GLOBAL_PROFILE, SET_USER_CURRENT_USAGE, SET_FREE_SUBSCRIPTION } from './types.js';

const editGlobalProfile = ({ id, avatar, name, email, phone, role, plan, teamExists, department }, state) => {
    const newProfile = {
        ...state,
        ...(id !== undefined && { globalProfileId: id }),
        ...(avatar !== undefined && { globalProfileAvatar: avatar }),
        ...(name !== undefined && { globalProfileName: name }),
        ...(email !== undefined && { globalProfileEmail: email }),
        ...(phone !== undefined && { globalProfilePhone: phone }),
        ...(role !== undefined && { role }),
        ...(plan !== undefined && { globalProfilePlan: plan }),
        ...((teamExists === true || teamExists === false) && { teamExists }),
        ...(department !== undefined && { department })
    };
    return {
        ...newProfile
    };
};

const setUserUsageDetails = (
    { currentTier, daysLeft, publishedSpaces, nextTier, planEnds, priceToPay, range, paymentInfo, trialUp },
    state
) => {
    const newState = {
        ...state,
        usageDetails: {
            currentTier,
            daysLeft,
            publishedSpaces,
            nextTier,
            planEnds,
            priceToPay,
            range,
            paymentInfo,
            trialUp
        }
    };
    return newState;
};

const setFreeSubscription = ({ subscribe }, state) => {
    const newState = {
        ...state,
        subscribe
    };
    return newState;
};

export default (state, action) => {
    switch (action.type) {
        case EDIT_GLOBAL_PROFILE:
            return editGlobalProfile(action.payload, state);
        case SET_USER_CURRENT_USAGE:
            return setUserUsageDetails(action.payload, state);
        case SET_FREE_SUBSCRIPTION:
            return setFreeSubscription(action.payload, state);
        default:
            return state;
    }
};
