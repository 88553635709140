import React, { useEffect } from 'react';
import { useState } from 'react';
import './ctalist.scss';
export default function CTAList(props) {
    const { ctas, excludeCTA } = props;
    const [exactCtas, setExactCtas] = useState([]);
    useEffect(() => {
        if (ctas) {
            const keys = Object.keys(ctas);
            let x = {};
            for (let ctaKey of keys) {
                switch (ctaKey) {
                    case 'email':
                        if (ctas[ctaKey]?.value) {
                            x = { ...x, [ctaKey]: ctas[ctaKey] };
                        }
                        break;
                    case 'phone':
                        if (ctas[ctaKey]?.value?.phone) {
                            x = { ...x, [ctaKey]: ctas[ctaKey] };
                        }
                        break;
                    case 'whatsapp':
                        if (ctas[ctaKey]?.value?.phone) {
                            x = { ...x, [ctaKey]: ctas[ctaKey] };
                        }
                        break;
                    case 'license':
                        if (excludeCTA) break;
                        if (ctas[ctaKey]?.value) {
                            x = { ...x, [ctaKey]: ctas[ctaKey] };
                        }
                        break;
                    case 'message':
                        if (excludeCTA) break;
                        if (ctas[ctaKey]?.value) {
                            x = { ...x, [ctaKey]: ctas[ctaKey] };
                        }
                        break;
                    case 'telegram':
                        if (ctas[ctaKey]?.value) {
                            x = { ...x, [ctaKey]: ctas[ctaKey] };
                        }
                        break;
                    case 'messenger':
                        if (ctas[ctaKey]?.value) {
                            x = { ...x, [ctaKey]: ctas[ctaKey] };
                        }
                        break;
                    case 'calendar':
                        if (ctas[ctaKey]?.value) {
                            x = { ...x, [ctaKey]: ctas[ctaKey] };
                        }
                        break;
                    case 'customlink':
                        if (ctas[ctaKey]?.value && ctas[ctaKey]?.name) {
                            x = { ...x, [ctaKey]: ctas[ctaKey] };
                        }
                        break;
                    case 'typeform':
                        if (ctas[ctaKey]?.buttonText && ctas[ctaKey]?.formId) {
                            x = { ...x, [ctaKey]: ctas[ctaKey] };
                        }
                        break;
                    default:
                        break;
                }
            }
            const sortable = Object.fromEntries(Object.entries(x).sort(([, a], [, b]) => a.order - b.order));
            setExactCtas(sortable);
        }
    }, [ctas]);

    const generateClassName = (type) => {
        switch (type) {
            case 'phone':
                return ' phone';
            case 'email':
                return ' email';
            case 'telegram':
                return ' telegran';
            case 'customlink':
                return ' custom-link';
            case 'calendar':
                return ' cal';
            case 'typeform':
                return ' typeform';
            default:
                return '';
        }
    };
    if (exactCtas) {
        const keys = Object.keys(exactCtas);
        if (keys.length === 1) {
            return (
                <div className="cta-single-container">
                    <div className={'single-cta' + generateClassName(keys[0])}>
                        <img src={ctas[keys[0]]?.icon} alt="mail icon" />
                        <div className="cta_info">
                            {keys[0] === 'phone' || keys[0] === 'whatsapp'
                                ? ctas[keys[0]]?.value?.countryCode + ' ' + ctas[keys[0]]?.value?.phone
                                : keys[0] === 'typeform'
                                ? ctas[keys[0]]?.buttonText
                                : ctas[keys[0]]?.value}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="cta-box">
                    {keys.map((key, idx) => {
                        if (key === 'message' || key === 'license') return undefined;
                        return ctas[key]?.value?.phone ? (
                            <div className={`cta ${generateClassName(key)}`} key={idx}>
                                <img src={ctas[key]?.icon} alt="mail icon" />
                                <div className="cta_tooltip">{ctas[key]?.value?.countryCode + ctas[key]?.value?.phone}</div>
                            </div>
                        ) : ctas[key]?.buttonText && ctas[key]?.formId ? (
                            <div className={`cta ${generateClassName(key)}`} key={idx}>
                                <img src={ctas[key]?.icon} alt="mail icon" />
                                <div className="cta_tooltip">{ctas[key]?.buttonText}</div>
                            </div>
                        ) : ctas[key]?.value && ctas[key].name ? (
                            <div className={`cta ${generateClassName(key)}`} key={idx}>
                                <img src={ctas[key]?.icon} alt="mail icon" />
                                <div className="cta_tooltip">{ctas[key]?.name}</div>
                            </div>
                        ) : ctas[key]?.icon && ctas[key]?.value && typeof ctas[key]?.value !== 'object' ? (
                            <div className={`cta ${generateClassName(key)}`} key={idx}>
                                <img src={ctas[key]?.icon} alt="mail icon" />
                                <div className="cta_tooltip">{ctas[key]?.value}</div>
                            </div>
                        ) : null;
                    })}
                </div>
            );
        }
    }
    return;
}
