import React, { useContext, useEffect, useState } from 'react';
import Pulse from 'react-reveal/Pulse';
import { DOMAIN } from '../../constants/base';
import { SettingsContext } from '../../context';
import { getSessionStorage } from '../../utils';
import { getReq } from '../../utils/api';
import EditSpaceDetailsHeader from '../EditSpaceDetailsHeader';
import ManageAgent from '../ManageAgent';
import ManageSkillsEdit from '../ManageSkillsEdit';
import MapBox from '../Mapbox';
import PublishSpace from '../PublishSpace';
import SpaceDetails from '../SpaceDetails';
import './editspacedetails.scss';
export default function EditSpaceDetails({
    currentSpaceId,
    spaceAddress,
    setSpaceAddress,
    spaceType,
    setSpaceType,
    rentalPriceType,
    setRentalPriceType,
    setRentalPriceToShow,
    flat,
    setFlat,
    assigneId,
    setAssigneId,
    price,
    setPrice,
    currency,
    setCurrency,
    bedrooms,
    setBedrooms,
    bathrooms,
    setBathrooms,
    spaceSize,
    setSpaceSize,
    measure,
    setMeasure,
    setShowSpaceDetailsEditPopUp,
    currentSpaceCoordinates,
    agentAssigned,
    setAgentAssigned,
    currentSpace,
    agentCta,
    setOpenEditProductPopUp,
    fetchActiveSpaces,
    userPermissionType,
    errCurrent,
    errPotential,
    setErrCurrent,
    setErrPotential,
    privateToggle,
    setPrivateToggle,
    isWidgetInstalled,
    pairedLink,
    setPairedLink,
    setNoWidgetInstalled
}) {
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const userDetails = getSessionStorage('ss-user-spaces') || {};
    const { role } = userDetails;
    const admin = role === 'ADMIN',
        owner = role === 'OWNER';
    const [globalSettings] = useContext(SettingsContext);
    const member = !(admin || owner);
    const canChangeAssigne = member
        ? globalSettings.memberGlobalSettings?.globalMemberManageTeamPortfolio
            ? true
            : false
        : admin
        ? globalSettings.adminGlobalSettings?.globalAdminManageTeamPortfolio
            ? true
            : false
        : true;

    const [currentStep, setCurrentStep] = useState('2');
    const [isNewTemplate, setIsNewTemplate] = useState(false);
    const [preventUpdates, setPreventUpdates] = useState(false);
    // general states
    const [scrollToUpload, setScrollToUpload] = useState(false);
    const [videoFiles, setVideoFiles] = useState([]);
    const [spaceUpdated, setSpaceUpdated] = useState(false);
    const [nextClickLoader, setNextClickLoader] = useState(false);
    const [spaceLatLng, setSpaceLatLng] = useState({ lat: 42.35, lng: -71.9 });
    const [prevSpaceLatLng, setPrevSpaceLatLng] = useState(null);
    const [selectedSkill, setSelectedSkill] = useState([]);
    const [showDeletePopUp, setShowDeletePopUp] = useState(false);
    const [cardSkills, setCardSkills] = useState(currentSpace.skills);
    const currentSpaceType = currentSpace?.priceType === 'SALE' ? 'sale' : 'lettings';
    // step 2
    const [enableFlat, setEnableFlat] = useState(flat ? true : false);
    const [enableRooms, setEnableRooms] = useState(bathrooms || bedrooms ? true : false);
    const [enableSurface, setEnableSurface] = useState(spaceSize ? true : false);
    const [enablePrice, setEnablePrice] = useState(price ? true : false);
    const [enableEnergyRating, setEnableEnergyRating] = useState(errCurrent > 0 || errPotential > 0);

    const [spacePoster, setSpacePoster] = useState(null);
    const [videoUploaded, setVideoUploaded] = useState(0);

    // agents
    const [availableAgents, setAvailableAgents] = useState([]);

    const [assigneChanged, setAssigneChanged] = useState(false);
    const [runOneTime, setRunOneTime] = useState(true);

    const [availableTemplates, setAvailableTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({
        templateName: 'Agent’s default contact',
        templateId: null,
        ctas: null
    });
    const [currentCta, setCurrentCta] = useState({});
    const [defaultCtas, setDefaultCtas] = useState([]);
    const [ctaTemplateName, setCtaTemplateName] = useState(null);
    const [prevTemplate, setPrevTemplate] = useState({});
    const [isUpdated, setIsUpdated] = useState(false);
    const [newTemplateCtas, setNewTemplateCtas] = useState([]);

    const spaceAddressDisabled = true;

    useEffect(() => {
        setSpaceLatLng({
            lat: currentSpaceCoordinates[1],
            lng: currentSpaceCoordinates[0]
        });
    }, [currentSpaceCoordinates]);

    useEffect(() => {
        if (videoFiles.length > 0) {
            setScrollToUpload(true);
        }
    }, [videoFiles]);

    useEffect(() => {
        if (admin || owner || canChangeAssigne) {
            getAvailableAgents();
        }
    }, []);

    useEffect(() => {
        if (availableAgents) updateContactList();
    }, [availableAgents, assigneId]);

    useEffect(() => {
        updateContactList();
        setCtaTemplateName(selectedTemplate?.templateName);
        if (selectedTemplate?.templateName !== '') setPrevTemplate(selectedTemplate);
    }, [selectedTemplate]);

    useEffect(() => {
        if (availableTemplates.length && runOneTime) {
            let filtered = availableTemplates.filter((temp) => temp.templateId === agentCta?.templateId);
            filtered.length && setSelectedTemplate(filtered[0]);
            setRunOneTime(false);
        }
    }, [availableTemplates]);

    useEffect(() => {
        if (agentCta) {
            const arr = agentCta;
            let arr2 = [];
            const keys = Object.keys(arr);
            keys.forEach((key) => {
                let x = {};
                x[key] = arr[key];
                arr2 = [...arr2, x];
            });
            setDefaultCtas([...arr2]);
        }
    }, [agentCta]);
    useEffect(() => {
        if (availableAgents) updateContactList();
    }, [availableAgents, assigneId]);

    const updateContactList = () => {
        let selected = availableAgents.find((agent) => agent.agentId === assigneId);
        if (selectedTemplate?.templateId || isNewTemplate) {
            selected = selectedTemplate;
        }
        if (selected?.ctas) {
            const arr = selected?.ctas;
            let arr2 = [];
            const keys = Object.keys(arr);
            keys.forEach((key) => {
                let x = {};
                x[key] = arr[key];
                arr2 = [...arr2, x];
            });
            setDefaultCtas([...arr2]);
        } else {
            if (isNewTemplate) {
                setNewTemplateCtas([]);
            } else {
                setDefaultCtas([]);
            }
        }
    };

    const handleMarkerClick = () => {
        setCurrentStep('2');
    };
    const handleSpaceAddressEdit = () => {
        if (spaceAddressDisabled) {
            setCurrentStep('1');
        }
    };

    const getAvailableAgents = async () => {
        const url = `${DOMAIN}/v1/dashboard/space/create-space/agents`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };

        const agentsResponse = await getReq({
            url,
            requestHeaders
        });
        const { code, data } = agentsResponse;

        if (code === 200) {
            setAvailableAgents(data.docs?.managingAgent);
            setAvailableTemplates([
                {
                    templateName: 'Agent’s default contact',
                    templateId: null,
                    ctas: null
                },
                ...data.docs?.templates
            ]);
        }
    };

    return (
        <div className="popups-wrapper">
            <Pulse duration={150}>
                <div className="space-card-wrapper">
                    <EditSpaceDetailsHeader
                        currentStep={currentStep}
                        scrollToUpload={scrollToUpload}
                        videoFiles={videoFiles}
                        isNewTemplate={isNewTemplate}
                        setCurrentStep={setCurrentStep}
                        selectedSkill={selectedSkill}
                        setShowDeletePopUp={setShowDeletePopUp}
                        showDeletePopUp={showDeletePopUp}
                        cardSkills={cardSkills}
                        setSpaceUpdated={setSpaceUpdated}
                        setSelectedSkill={setSelectedSkill}
                        setIsNewTemplate={setIsNewTemplate}
                        isUpdated={isUpdated}
                        setShowSpaceDetailsEditPopUp={setShowSpaceDetailsEditPopUp}
                        prevTemplate={prevTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        selectedTemplate={selectedTemplate}
                    ></EditSpaceDetailsHeader>

                    {currentStep === '1' && (
                        <MapBox
                            handleMarkerClick={handleMarkerClick}
                            spaceAddress={spaceAddress}
                            setSpaceAddress={setSpaceAddress}
                            setSpaceLatLng={setSpaceLatLng}
                            spaceLatLng={spaceLatLng}
                            setPrevSpaceLatLng={setPrevSpaceLatLng}
                            setSpaceUpdated={setSpaceUpdated}
                        ></MapBox>
                    )}

                    {currentStep === '2' && (
                        <SpaceDetails
                            spaceAddress={spaceAddress}
                            spaceLatLng={spaceLatLng}
                            prevSpaceLatLng={prevSpaceLatLng}
                            handleSpaceAddressEdit={handleSpaceAddressEdit}
                            enableFlat={enableFlat}
                            setEnableFlat={setEnableFlat}
                            flatNumber={flat}
                            setFlatNumber={setFlat}
                            enablePrice={enablePrice}
                            setEnablePrice={setEnablePrice}
                            price={price}
                            setPrice={setPrice}
                            setSpaceUpdated={setSpaceUpdated}
                            spaceType={spaceType}
                            setSpaceType={setSpaceType}
                            rentalPriceType={rentalPriceType}
                            setRentalPriceType={setRentalPriceType}
                            setRentalPriceToShow={setRentalPriceToShow}
                            currency={currency}
                            setCurrency={setCurrency}
                            enableRooms={enableRooms}
                            setEnableRooms={setEnableRooms}
                            bedrooms={bedrooms}
                            setBedrooms={setBedrooms}
                            bathrooms={bathrooms}
                            setBathrooms={setBathrooms}
                            enableSurface={enableSurface}
                            setEnableSurface={setEnableSurface}
                            measure={measure}
                            setMeasure={setMeasure}
                            spaceSize={spaceSize}
                            setSpaceSize={setSpaceSize}
                            nextClickLoader={nextClickLoader}
                            setNextClickLoader={setNextClickLoader}
                            spaceId={currentSpaceId}
                            // setSpaceId={setSpaceId}
                            setPrevSpaceLatLng={setPrevSpaceLatLng}
                            setCurrentStep={setCurrentStep}
                            spaceUpdated={spaceUpdated}
                            // setDirectLink={setDirectLink}
                            // setDefaultCtas={setDefaultCtas}
                            // member={member}
                            editSpace={true}
                            userPermissionType={userPermissionType}
                            errCurrent={errCurrent}
                            setErrCurrent={setErrCurrent}
                            errPotential={errPotential}
                            setErrPotential={setErrPotential}
                            enableEnergyRating={enableEnergyRating}
                            setEnableEnergyRating={setEnableEnergyRating}
                        ></SpaceDetails>
                    )}

                    {(currentStep === '3' || currentStep === '6') && (
                        <ManageAgent
                            canChangeAssigne={canChangeAssigne}
                            availableAgents={availableAgents}
                            availableTemplates={availableTemplates}
                            setAvailableTemplates={setAvailableTemplates}
                            setSpaceUpdated={setSpaceUpdated}
                            setCurrentStep={setCurrentStep}
                            agentAssigned={agentAssigned}
                            setAssigneId={setAssigneId}
                            selectedTemplate={selectedTemplate}
                            setSelectedTemplate={setSelectedTemplate}
                            assigneChanged={assigneChanged}
                            setAssigneChanged={setAssigneChanged}
                            setAgentAssigned={setAgentAssigned}
                            assigneId={assigneId}
                            defaultCtas={isNewTemplate ? newTemplateCtas : defaultCtas}
                            setIsNewTemplate={setIsNewTemplate}
                            currentCta={isNewTemplate ? {} : currentCta}
                            setCurrentCta={setCurrentCta}
                            spaceId={currentSpaceId}
                            setIsUpdated={setIsUpdated}
                            ctaTemplateName={ctaTemplateName}
                            setCtaTemplateName={setCtaTemplateName}
                            isNewTemplate={isNewTemplate}
                            currentStep={currentStep}
                            scrollToUpload={scrollToUpload}
                            videoFiles={videoFiles}
                            selectedSkill={selectedSkill}
                            setShowDeletePopUp={setShowDeletePopUp}
                            showDeletePopUp={showDeletePopUp}
                            cardSkills={cardSkills}
                            setSelectedSkill={setSelectedSkill}
                            isUpdated={isUpdated}
                            setShowSpaceDetailsEditPopUp={setShowSpaceDetailsEditPopUp}
                            prevTemplate={prevTemplate}
                            preventUpdates={preventUpdates}
                            setPreventUpdates={setPreventUpdates}
                        ></ManageAgent>
                    )}

                    {currentStep === '4' && (
                        <ManageSkillsEdit
                            videoFiles={videoFiles}
                            setVideoFiles={setVideoFiles}
                            setSpacePoster={setSpacePoster}
                            videoUploaded={videoUploaded}
                            setVideoUploaded={setVideoUploaded}
                            selectedSkill={selectedSkill}
                            cardSkills={cardSkills}
                            setCardSkills={setCardSkills}
                            setSelectedSkill={setSelectedSkill}
                            currentspaceWidget={currentSpace.widgetLink}
                            currentSpaceId={currentSpaceId}
                            setCurrentStep={setCurrentStep}
                        ></ManageSkillsEdit>
                    )}

                    {currentStep === '5' && (
                        <PublishSpace
                            spacePoster={currentSpace.skills?.length ? currentSpace.skills[0].poster : spacePoster}
                            videoFiles={currentSpace.skills?.length ? currentSpace.skills : []}
                            setCurrentStep={setCurrentStep}
                            spaceId={currentSpaceId}
                            showPopupItem={setShowSpaceDetailsEditPopUp}
                            setOpenEditProductPopUp={setOpenEditProductPopUp}
                            fetchActiveSpaces={fetchActiveSpaces}
                            directLink={currentSpace.widgetLink}
                            privateSpace={privateToggle}
                            setPrivateSpace={setPrivateToggle}
                            webUrl={pairedLink ? pairedLink : currentSpace.spaceURL}
                            setPairedLink={setPairedLink}
                            isEdit={true}
                            spaceAgentId={currentSpace.agentId}
                            isWidgetInstalled={isWidgetInstalled}
                            setNoWidgetInstalled={setNoWidgetInstalled}
                        ></PublishSpace>
                    )}
                </div>
            </Pulse>
        </div>
    );
}
