import React, { useState } from 'react';
import './dropdown.scss';
import Down from '../../assets/caret-down.svg';
import Up from '../../assets/caret-up.svg';
import ClickAwayListener from 'react-click-away-listener';
import { useTranslation } from 'react-i18next';

function Dropdown(props) {
    const { onChange, renderData, defaultValue, isLang } = props;
    const [toggleFilter, setToggleFilter] = useState(false);
    const [selected, setSelected] = useState(defaultValue);
    const { i18n: translate } = useTranslation();
    const renderPropType = () => {
        let propArr = [];
        propArr = renderData.map((type, index) => {
            if (type !== selected) {
                return (
                    <div index={index} className="dropdown-container-options-items" onClick={() => handleSelect(type)}>
                        <span className="dropdown-container-options-items-name">{type}</span>
                    </div>
                );
            }
            return '';
        });
        return propArr;
    };

    const handleSelect = (type) => {
        setSelected(type);
        if (isLang) {
            translate.changeLanguage(type);
            if (type === 'English') {
                localStorage.setItem('ss-lang', 'en');
            } else if (type === 'Portuguese') {
                localStorage.setItem('ss-lang', 'pt');
            }
        }
        onChange(type);
    };

    const filterClick = () => {
        setToggleFilter(!toggleFilter);
    };

    const handleClickAway = () => {
        setToggleFilter(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="dropdown-container" onClick={filterClick}>
                <span className="dropdown-container-selected">{selected}</span>
                <img className="ml-10" src={toggleFilter ? Up : Down} alt="" />

                {toggleFilter && <div className="dropdown-container-options">{renderPropType()}</div>}
            </div>
        </ClickAwayListener>
    );
}

export default Dropdown;
