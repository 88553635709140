import React from 'react';

export default function EnergyRatings(props) {
    const { errCurrentVal, errPotentialVal, errCurrent, errPotential } = props;
    return (
        <div className="space-field">
            <div className="err-container">
                <div className="err-container-title">
                    <h4>Rating</h4>
                    <h4>current</h4>
                    <h4>potential</h4>
                </div>
                <div className="err-container-heading">
                    <div className="err-container-heading-rating">
                        <div
                            className={`err-container-heading-rating-content ${
                                errCurrentVal === 'bg-a' || errPotentialVal === 'bg-a' ? 'bg-a-gradient' : ''
                            }`}
                        >
                            <div className="err-container-heading-rating-content-details">
                                <span className="bg-a">A</span> 92+
                            </div>
                            {errCurrentVal === 'bg-a' ? (
                                <div className={`err-container-heading-rating-content-cur ${errCurrentVal} ${errCurrentVal}-shadow`}>
                                    <div className={`${errCurrentVal}-border`} />
                                    <span>{errCurrent}</span>
                                </div>
                            ) : (
                                <div />
                            )}
                            {errPotentialVal === 'bg-a' ? (
                                <div className={`err-container-heading-rating-content-cur ${errPotentialVal} ${errPotentialVal}-shadow`}>
                                    <div className={`${errPotentialVal}-border`} />
                                    <span>{errPotential}</span>
                                </div>
                            ) : (
                                <div />
                            )}
                        </div>
                        <div
                            className={`err-container-heading-rating-content ${
                                errCurrentVal === 'bg-b' || errPotentialVal === 'bg-b' ? 'bg-b-gradient' : ''
                            }`}
                        >
                            <div className="err-container-heading-rating-content-details">
                                <span className="bg-b">B</span> 81-91
                            </div>
                            {errCurrentVal === 'bg-b' ? (
                                <div className={`err-container-heading-rating-content-cur ${errCurrentVal} ${errCurrentVal}-shadow`}>
                                    <div className={`${errCurrentVal}-border`} />
                                    <span>{errCurrent}</span>
                                </div>
                            ) : (
                                <div />
                            )}
                            {errPotentialVal === 'bg-b' ? (
                                <div className={`err-container-heading-rating-content-cur ${errPotentialVal} ${errPotentialVal}-shadow`}>
                                    <div className={`${errPotentialVal}-border`} />
                                    <span>{errPotential}</span>
                                </div>
                            ) : (
                                <div />
                            )}
                        </div>
                        <div
                            className={`err-container-heading-rating-content ${
                                errCurrentVal === 'bg-c' || errPotentialVal === 'bg-c' ? 'bg-c-gradient' : ''
                            }`}
                        >
                            <div className="err-container-heading-rating-content-details">
                                <span className="bg-c">c</span> 69-80
                            </div>
                            {errCurrentVal === 'bg-c' ? (
                                <div className={`err-container-heading-rating-content-cur ${errCurrentVal} ${errCurrentVal}-shadow`}>
                                    <div className={`${errCurrentVal}-border`} />
                                    <span>{errCurrent}</span>
                                </div>
                            ) : (
                                <div />
                            )}
                            {errPotentialVal === 'bg-c' ? (
                                <div className={`err-container-heading-rating-content-cur ${errPotentialVal} ${errPotentialVal}-shadow`}>
                                    <div className={`${errPotentialVal}-border`} />
                                    <span>{errPotential}</span>
                                </div>
                            ) : (
                                <div />
                            )}
                        </div>
                        <div
                            className={`err-container-heading-rating-content ${
                                errCurrentVal === 'bg-d' || errPotentialVal === 'bg-d' ? 'bg-d-gradient' : ''
                            }`}
                        >
                            <div className="err-container-heading-rating-content-details">
                                <span className="bg-d">d</span> 55-68
                            </div>
                            {errCurrentVal === 'bg-d' ? (
                                <div className={`err-container-heading-rating-content-cur ${errCurrentVal} ${errCurrentVal}-shadow`}>
                                    <div className={`${errCurrentVal}-border`} />
                                    <span>{errCurrent}</span>
                                </div>
                            ) : (
                                <div />
                            )}
                            {errPotentialVal === 'bg-d' ? (
                                <div className={`err-container-heading-rating-content-cur ${errPotentialVal} ${errPotentialVal}-shadow`}>
                                    <div className={`${errPotentialVal}-border`} />
                                    <span>{errPotential}</span>
                                </div>
                            ) : (
                                <div />
                            )}
                        </div>
                        <div
                            className={`err-container-heading-rating-content ${
                                errCurrentVal === 'bg-e' || errPotentialVal === 'bg-e' ? 'bg-e-gradient' : ''
                            }`}
                        >
                            <div className="err-container-heading-rating-content-details">
                                <span className="bg-e">e</span> 39-54
                            </div>
                            {errCurrentVal === 'bg-e' ? (
                                <div className={`err-container-heading-rating-content-cur ${errCurrentVal} ${errCurrentVal}-shadow`}>
                                    <div className={`${errCurrentVal}-border`} />
                                    <span>{errCurrent}</span>
                                </div>
                            ) : (
                                <div />
                            )}
                            {errPotentialVal === 'bg-e' ? (
                                <div className={`err-container-heading-rating-content-cur ${errPotentialVal} ${errPotentialVal}-shadow`}>
                                    <div className={`${errPotentialVal}-border`} />
                                    <span>{errPotential}</span>
                                </div>
                            ) : (
                                <div />
                            )}
                        </div>
                        <div
                            className={`err-container-heading-rating-content ${
                                errCurrentVal === 'bg-f' || errPotentialVal === 'bg-f' ? 'bg-f-gradient' : ''
                            }`}
                        >
                            <div className="err-container-heading-rating-content-details">
                                <span className="bg-f">f</span> 21-38
                            </div>
                            {errCurrentVal === 'bg-f' ? (
                                <div className={`err-container-heading-rating-content-cur ${errCurrentVal} ${errCurrentVal}-shadow`}>
                                    <div className={`${errCurrentVal}-border`} />
                                    <span>{errCurrent}</span>
                                </div>
                            ) : (
                                <div />
                            )}
                            {errPotentialVal === 'bg-f' ? (
                                <div className={`err-container-heading-rating-content-cur ${errPotentialVal} ${errPotentialVal}-shadow`}>
                                    <div className={`${errPotentialVal}-border`} />
                                    <span>{errPotential}</span>
                                </div>
                            ) : (
                                <div />
                            )}
                        </div>
                        <div
                            className={`err-container-heading-rating-content ${
                                errCurrentVal === 'bg-g' || errPotentialVal === 'bg-g' ? 'bg-g-gradient' : ''
                            }`}
                        >
                            <div className="err-container-heading-rating-content-details">
                                <span className="bg-g">g</span> 20
                            </div>
                            {errCurrentVal === 'bg-g' ? (
                                <div className={`err-container-heading-rating-content-cur ${errCurrentVal} ${errCurrentVal}-shadow`}>
                                    <div className={`${errCurrentVal}-border`} />
                                    <span>{errCurrent}</span>
                                </div>
                            ) : (
                                <div />
                            )}
                            {errPotentialVal === 'bg-g' ? (
                                <div className={`err-container-heading-rating-content-cur ${errPotentialVal} ${errPotentialVal}-shadow`}>
                                    <div className={`${errPotentialVal}-border`} />
                                    <span>{errPotential}</span>
                                </div>
                            ) : (
                                <div />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
