import React from 'react';
import classnames from 'classnames';
import { isEmpty } from '../../utils';
import { Fade } from 'react-reveal';
import './styles.scss';
function InputField(props) {
    const {
        type,
        inputStyle,
        label,
        labelStyle,
        placeholder,
        value,
        onChange,
        onBlur,
        info,
        infoStyle,
        customWidth,
        customHeight,
        disabled,
        isValid = true,
        handleEnter = () => {},
        maxLength,
        agent,
        icon,
        iconType,
        customMl,
        onDownArrowClick,
        iconClass,
        onPaste
    } = props;

    const labelClass = classnames('d-ib fs-8 fw-900 ', labelStyle);
    const inputClass = classnames(
        'd-ib',
        {
            'mb-20': isEmpty(info),
            'mb-15': !isEmpty(info),
            'b-error': !isValid
        },
        inputStyle
    );
    const infoClass = classnames('d-ib fs-8 fw-900 c-error', infoStyle);

    const onEnterKey = (e) => {
        if (e.keyCode === 13) {
            handleEnter(e);
        }
    };
    return (
        <div className="input-main w-100p" style={{ marginLeft: customMl ? customMl : '' }}>
            <div className="input-val-error d-flex space-between" style={{ width: customWidth ? customWidth : '100%' }}>
                {label && (
                    <div>
                        <label
                            className={labelClass}
                            stye={{
                                color: '#3A3A3A'
                            }}
                        >
                            {label}
                        </label>
                    </div>
                )}
                {info && (
                    <div className="mb-5">
                        <Fade bottom duration={150}>
                            <label className={infoClass}>{info}</label>
                        </Fade>
                    </div>
                )}
            </div>
            <div
                style={{
                    position: 'relative'
                }}
            >
                <input
                    style={{
                        width: customWidth ? customWidth : '100%',
                        height: customHeight ? customHeight : '40px',
                        marginBottom: '20px',
                        padding: agent ? '14px 16px' : '',
                        overflowWrap: agent ? 'break-word' : ''
                    }}
                    type={type}
                    className={inputClass}
                    placeholder={placeholder}
                    onChange={(e) => onChange && onChange(e)}
                    value={value}
                    onBlur={onBlur}
                    onKeyDown={onEnterKey}
                    disabled={disabled}
                    maxLength={maxLength}
                    onPaste={onPaste}
                ></input>

                {icon ? (
                    <div
                        // eslint-disable-next-line no-useless-concat
                        className={'cta ' + `${iconType}`}
                        style={{
                            position: 'absolute',
                            right: '16px',
                            top: '10px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        onClick={() => {
                            onDownArrowClick && onDownArrowClick();
                        }}
                    >
                        <img src={icon} alt="icon" className={iconClass ? iconClass : ''} />
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
}

export default InputField;
