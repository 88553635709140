import React, { useState, useEffect } from 'react';
import { isEmpty } from '../../utils';
import { Link, Redirect } from 'react-router-dom';
import './homepage.scss';
import { PLAN_DETAILS } from '../../constants/base';
import TierSlider from '../../components/TierSlider';
import VideoAtom from '../../components/VideoAtom';
import ProductAtom from '../../components/ProductAtom';

// Assets
import TierSep from '../../assets/comp-separator.svg';
import FeatureCta from '../../assets/feature-cta.png';
import FeatureSpaceAnalytics from '../../assets/feature-space-analytics.png';
import FeatureShareableLink from '../../assets/feature-box-sharable.png';
import FeatureCurateAndShare from '../../assets/feature-curate-share.png';
import FeatureQualityLeads from '../../assets/feature-quality-leads.png';
import FeatureApiAccess from '../../assets/feature-api-access.png';
import FeatureDedicatedSupport from '../../assets/feature-dedicated-support.png';
import FeatureEmbedVideo from '../../assets/feature-embed-video.png';
import FeatureDashboard from '../../assets/feature-dashboard-management.png';
import FeatureCollaborativeTeams from '../../assets/feature-collaborative-teams.png';
import FeatureBrandedLinks from '../../assets/feature-custom-brand.png';

const HomePage = () => {
    const [tierPlans, setTierPlans] = useState({});
    const [tierPlansPrice, setTierPlansPrice] = useState([]);
    const [currentProgressedTier, setCurrentProgressTier] = useState();
    const [redirectToSignUp, setRedirectToSignUp] = useState(false);
    const [redirectToSignIn, setRedirectToSignIn] = useState(false);
    const [redirectToMySpaces, setRedirectToMySpaces] = useState(false);

    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};

    useEffect(() => {
        if (!isEmpty(userToken)) {
            setRedirectToMySpaces(true);
        } else {
            // preventing to sign in page
            setRedirectToSignIn(false);
        }
        let objTiers = {};
        let objTierPrice = {};
        let idx = 0;
        for (let arr of PLAN_DETAILS) {
            objTiers[idx] = arr.spaces;
            objTierPrice[idx] = arr.price;
            idx += 1;
        }
        setTierPlans(objTiers);
        setTierPlansPrice(objTierPrice);
    }, []);

    if (redirectToSignUp) {
        return <Redirect to="/signup" />;
    }

    if (redirectToSignIn) {
        return <Redirect to="/signin" />;
    }

    if (redirectToMySpaces) {
        return <Redirect to="/mySpaces" />;
    }

    return (
        <div className="homepage">
            <ProductAtom></ProductAtom>
            <VideoAtom></VideoAtom>
            <div className="sign-in">
                <div>Already an existing user?</div>
                <div className="btn-wrapper">
                    <Link to="/signin">
                        <button className="btn" onClick={() => setRedirectToSignIn(true)}>
                            SIGN IN
                        </button>
                    </Link>
                </div>
            </div>
            <div className="landing-container">
                <div className="plan-details">
                    <div className="current-plan">
                        <h1>Welcome!</h1>
                        <div className="tagline">
                            Market your real estate assets through video and extract new levels of customer insights with deep sales
                            analytics.
                        </div>
                        <div className="btn-wrapper">
                            <Link to="/signup">
                                <button className="btn" onClick={() => setRedirectToSignUp(true)}>
                                    GET STARTED
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="tier-sep">
                        <img src={TierSep} alt="" />
                    </div>
                    <div className="plan-comparator">
                        <h2>Pay as you go!</h2>
                        <div className="slider-tier">
                            <TierSlider tierPlans={tierPlans} setCurrentProgressTier={setCurrentProgressTier} type="spaces"></TierSlider>
                        </div>
                        <div className="current-tier">
                            {currentProgressedTier >= 5 ? (
                                <>
                                    <div className="price">
                                        <span>$ </span>
                                        {tierPlansPrice[currentProgressedTier]}
                                        <span>/mo</span>
                                    </div>
                                    <div className="visitors">
                                        with up to <span>500</span> spaces <span>+ $5 /space added</span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="price">
                                        <span>$ </span>
                                        {tierPlansPrice[currentProgressedTier]}
                                        <span>/mo</span>
                                    </div>
                                    <div className="visitors">
                                        with up to <span>{tierPlans[currentProgressedTier]}</span> spaces
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="feature-wrapper">
                    <h1>Plan's Feature:</h1>
                    <div className="features-grid">
                        <div className="row-one">
                            <div className="column-wrapper">
                                <div className="custom-cta">
                                    <img className="custom-cta-image" src={FeatureCta} alt=""></img>
                                    <h1>Custom CTA’s</h1>
                                </div>
                                <div className="space-analytics">
                                    <h1>Space Analytics</h1>
                                    <img className="space-analytics-image" src={FeatureSpaceAnalytics} alt=""></img>
                                </div>
                            </div>
                            <div className="create-shareable-links">
                                <h1>Create Shareable Links</h1>
                                <img className="create-shareable-links-image" src={FeatureShareableLink} alt=""></img>
                            </div>
                            <div className="curate-and-share">
                                <h1>Curate And Share Collections</h1>
                                <img className="curate-and-share-image" src={FeatureCurateAndShare} alt=""></img>
                            </div>
                        </div>
                        <div className="row-two">
                            <div className="quality-leads">
                                <h1>Qualify Leads through Analytics</h1>
                                <img className="quality-leads-image" src={FeatureQualityLeads} alt=""></img>
                            </div>

                            <div className="column-wrapper">
                                <div className="api-access">
                                    <img className="api-access-image" src={FeatureApiAccess} alt=""></img>
                                    <h1>API Access</h1>
                                </div>
                                <div className="dedicated-support">
                                    <img className="dedicated-support-image" src={FeatureDedicatedSupport} alt=""></img>
                                    <h1>Dedicated Support</h1>
                                </div>
                            </div>
                        </div>
                        <div className="row-three">
                            <div className="column-wrapper">
                                <div className="embed-videos">
                                    <img className="embed-videos-image" src={FeatureEmbedVideo} alt=""></img>
                                    <h1>Embed videos on your website</h1>
                                </div>
                                <div className="dashboard-management">
                                    <h1>Dashboard Management</h1>
                                    <img className="dashboard-management-image" src={FeatureDashboard} alt=""></img>
                                </div>
                            </div>
                            <div className="collaborative-teams">
                                <img className="collaborative-teams-image" src={FeatureCollaborativeTeams} alt=""></img>
                                <h1>Collaborative Teams</h1>
                            </div>
                            <div className="custom-branded-links">
                                <img className="custom-branded-links-image" src={FeatureBrandedLinks} alt=""></img>
                                <h1>Custom Branded Links</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
