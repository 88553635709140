import React, { useContext, useEffect, useState } from 'react';
import ButtonAtom from '../ButtonAtom';
import TierSlider from '../TierSlider';
import { Redirect } from 'react-router-dom';
import './blocker.scss';

// assets
import { DOMAIN, PLAN_DETAILS } from '../../constants/base';
import UpgradeBg from '../../assets/upgrade-bg.png';
import FeatureAPI from '../../assets/feature-api-access.png';
import FeatureDedicateSupport from '../../assets/feature-dedicated-support.png';
import FeatureEmbed from '../../assets/feature-embed-video.png';
import FeatureDashboard from '../../assets/feature-dashboard-management.png';
import FeatureCustomLink from '../../assets/feature-custom-brand.png';
import FeatureTeam from '../../assets/feature-collaborative-teams.png';
import LogoutDeselected from '../../assets/sign-out-black.svg';

import { handleSubscription } from '../../utils/stripe-handler';
import { getSessionStorage, removeSessionStorage } from '../../utils';
import { useTranslation } from 'react-i18next';
import { GlobalProfile } from '../../context';
export default function BlockerComponent({ isPlanStarted = true }) {
    const [tierPlans, setTierPlans] = useState({});
    const [tierPlansPrice, setTierPlansPrice] = useState([]);
    const [tierPlanName, setTierPlanName] = useState([]);
    const [currentProgressedTier, setCurrentProgressTier] = useState();
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const userDetails = getSessionStorage('ss-user-spaces') || {};
    const [isSignOut, setSignOut] = useState(false);
    const [shouldRedirect, setRedirect] = useState(false);
    const [, dispatchProfile] = useContext(GlobalProfile);
    const { t: blocker } = useTranslation();
    const signoutHandler = () => {
        document.body.classList.add('ovf-hidden');
        setSignOut(true);
    };
    const noHandler = () => {
        document.body.classList.remove('ovf-hidden');
        setSignOut(false);
    };
    const proceedHandler = () => {
        document.body.classList.remove('ovf-hidden');
        localStorage.removeItem('ss-auth-spaces');
        removeSessionStorage('ss-user-spaces');
        localStorage.setItem('ss-lang', 'en');
        dispatchProfile({
            type: 'EDIT_GLOBAL_PROFILE',
            payload: {
                id: null,
                avatar: null,
                name: null,
                email: null,
                role: null,
                phone: {
                    phone: null,
                    countryCode: null,
                    countryName: null
                },
                teamExists: false,
                plan: null,
                department: null
            }
        });
        setRedirect(true);
        try {
            window.Intercom('shutdown');
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        let objTiers = {};
        let objTierPrice = {};
        let objTierName = {};
        let idx = 0;
        for (let arr of PLAN_DETAILS) {
            objTiers[idx] = arr.spaces;
            objTierPrice[idx] = arr.price;
            objTierName[idx] = arr.name;
            idx += 1;
        }
        setTierPlans(objTiers);
        setTierPlansPrice(objTierPrice);
        setTierPlanName(objTierName);
    }, []);

    const upgradePlan = () => {
        handleSubscription(DOMAIN, userToken, userDetails);
    };
    if (shouldRedirect) {
        return <Redirect to="/signin" />;
    }

    return (
        <>
            <div className="d-flex blocker-container">
                <div className="upgrade-container">
                    <div className="title">{isPlanStarted ? blocker('blocker.no_access') : blocker('blocker.subscribe_plan')}</div>
                    <img src={UpgradeBg} alt="bg"></img>
                    <ButtonAtom
                        label={isPlanStarted ? blocker('blocker.upgrade') : blocker('subscribe')}
                        buttonStyle="upgrade-btn"
                        onClick={upgradePlan}
                    ></ButtonAtom>
                    <div className="access-phone">
                        {blocker('blocker.app_msg') + ' '}
                        <a href="https://apps.apple.com/in/app/holofy-spaces/id1518492855">{' ' + blocker('ios')} </a> {blocker('or') + ' '}
                        <a href="https://play.google.com/store/apps/details?id=com.holofy.store">{blocker('android')}</a>
                    </div>
                </div>
                <div className="feature-container">
                    <div className="slider">
                        <div className="plan-name">{tierPlanName[currentProgressedTier]}</div>
                        <div className="slider-tier">
                            <TierSlider tierPlans={tierPlans} setCurrentProgressTier={setCurrentProgressTier} type="spaces"></TierSlider>
                        </div>
                        <div className="plan-info">
                            {currentProgressedTier >= 5 ? (
                                <>
                                    <div className="price">
                                        <span>$ </span>
                                        {tierPlansPrice[currentProgressedTier]}
                                        <span>{blocker('blocker.per_month')}</span>
                                    </div>
                                    <div className="visitors">
                                        {blocker('blocker.with_upto')} <span>3000</span> {blocker('blocker.spaces')}
                                        <span>+ $5 /{blocker('blocker.spaces_add')}</span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="price">
                                        <span>$ </span>
                                        {tierPlansPrice[currentProgressedTier]}
                                        <span>{blocker('blocker.per_month')}</span>
                                    </div>
                                    <div className="visitors">
                                        {blocker('blocker.with_upto')} <span>{tierPlans[currentProgressedTier]}</span>
                                        {blocker('blocker.spaces')}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="features">
                        <div className="title"> {blocker('blocker.features.title')}</div>
                        <div className="feature-box">
                            <div className="container one">
                                <div className="row one">
                                    <div className="feature api-access">
                                        <img src={FeatureAPI} alt="features" />
                                        <div className="feature-name">{blocker('blocker.features.api')}</div>
                                    </div>
                                    <div className="feature dedicated-support">
                                        <img src={FeatureDedicateSupport} alt="features" />
                                        <div className="feature-name">{blocker('blocker.features.support')}</div>
                                    </div>
                                </div>
                                <div className="row two">
                                    <div className="feature teams">
                                        <img src={FeatureTeam} alt="features" />
                                        <div className="feature-name">{blocker('blocker.features.team')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="container two">
                                <div className="row one">
                                    <div className="feature embed">
                                        <img src={FeatureEmbed} alt="features" />
                                        <div className="feature-name">{blocker('blocker.features.embed')}</div>
                                    </div>
                                    <div className="feature dashboard">
                                        <div className="feature-name">{blocker('blocker.features.dashboard')}</div>
                                        <img src={FeatureDashboard} alt="features" />
                                    </div>
                                </div>
                                <div className="row two">
                                    <div className="feature custom-link">
                                        <img src={FeatureCustomLink} alt="features" />
                                        <div className="feature-name">{blocker('blocker.features.links')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="more-text">{blocker('blocker.features.more')}</div>
                </div>
            </div>
            <div className="sign-out-handle" onClick={signoutHandler}>
                <img src={LogoutDeselected} alt="log out button" />
                {blocker('signout.title')}
            </div>
            {isSignOut && (
                <div className="d-flex center-align pos-fix w-100p h-100p bg-black-50 top-0 left-0 zi-100" style={{ zIndex: 100000 }}>
                    <div className="bg-white p-20 br-10 mxw-300">
                        <div className="fs-18 fw-b mb-40"> {blocker('signout.message')}</div>
                        <div className="d-flex space-between fs-12">
                            <div className="fs-12 fw-b uc c-blue p-10 cur-pointer" onClick={noHandler}>
                                {blocker('signout.back_msg')}
                            </div>
                            <div className="fs-12 fw-b uc bg-black c-white br-20 p-10-20 cur-pointer" onClick={proceedHandler}>
                                {blocker('signout.title')}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
