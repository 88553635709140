import React from 'react';
import classnames from 'classnames';
import LoadingSpin from '../../components/LoadingSpin';
import './button-atom.scss';

const ButtonAtom = ({ label, disabled, onClick, buttonStyle, showLoader }) => {
    const buttonClass = classnames(
        `btn-atom b-none br-40 fs-12 fw-900 mt-5`,
        {
            'bg-lightgray': disabled
        },
        buttonStyle
    );
    return (
        <button disabled={disabled} onClick={onClick} className={buttonClass}>
            {showLoader ? <LoadingSpin /> : label}
        </button>
    );
};

export default ButtonAtom;
