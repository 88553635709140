import React, { useState, useContext, Suspense, useEffect } from 'react';
import { Fade } from 'react-reveal';
import { currentSpaceContext } from '../../context';
import getSymbolFromCurrency from 'currency-symbol-map';
import Avatar from 'react-avatar';
import { getEnergyRatings } from '../../utils';

// Assets
import Tick from '../../assets/check-outline.svg';
import Close from '../../assets/close-outline.svg';
import RightCaret from '../../assets/right-outline.svg';
import PrivateSpaceLock from '../../assets/publish-private.svg';
// import { DOMAIN } from "../../constants/base";
import VideoPlaceholder from '../../assets/video-placeholder.png';
import Checked from '../../assets/Vector 316checked.svg';
import BedIcon from '../../assets/bed-icon.svg';
import BathIcon from '../../assets/bath-icon.svg';
import AreaIcon from '../../assets/area-icon.svg';
import EnergyRatings from '../../assets/energy-ratings.svg';

import './propCard.scss';
import { useTranslation } from 'react-i18next';

function PropCard(props) {
    // const userToken = JSON.parse(localStorage.getItem("ss-auth-spaces")) || {};
    const [, dispatch] = useContext(currentSpaceContext);
    const { t: propCard } = useTranslation();
    const {
        property: {
            flatAddress = '',
            status,
            widgetLink: url,
            skills = [],
            spaceURL,
            flat,
            priceType,
            cta,
            id,
            currency,
            brand,
            price,
            address,
            dimensions,
            rooms,
            createdAtInMillis,
            name,
            avatar,
            coordinates,
            agentId,
            isPrivate,
            energyMeter
        },
        // setMagicLinkPopUp,
        // setOpenCTAPopUp,
        setOpenEditProductPopUp,
        setSettingMenuOpen,
        isSettingMenuOpen,
        // setOpenEditVideosPopUp,
        // setOpenEmptySkillPopUp,
        // setAddSkill,
        // setSpaceNotification,
        editMode,
        selectCardMethod,
        selectedCards
    } = props;
    // const [toggleVal, setToggleVal] = useState(status);
    // const [openMenu, setOpenMenu] = useState(false);
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        checkedCards();
    }, [selectedCards.length]);

    const checkedCards = () => {
        if (selectedCards.find((i) => i === id)) {
            setIsSelected(true);
        } else {
            setIsSelected(false);
        }
    };

    const openEditProductPopUp = () => {
        dispatch({
            type: 'EDIT_SPACE',
            payload: {
                flatNumber: flat,
                spaceAddress: address,
                id,
                currency,
                brand,
                price,
                priceType,
                skills,
                widgetLink: url,
                cta,
                dimensions,
                rooms,
                createdAtInMillis,
                spaceURL,
                name,
                avatar,
                coordinates,
                agentId,
                isPrivate,
                status,
                energyMeter
            }
        });
        setOpenEditProductPopUp(true);
    };

    const handleOpenSettingMenu = () => {
        if (!isSettingMenuOpen) {
            // If setting menu is not already open
            // setOpenMenu(true);
            setSettingMenuOpen(true);
        }
    };

    const handleBrokenImage = (e) => {
        e.target.src = VideoPlaceholder;
    };

    const handleSelect = () => {
        if (editMode) {
            setIsSelected(!isSelected);
            if (isSelected) {
                selectCardMethod(id, false, agentId);
            } else if (!isSelected) {
                selectCardMethod(id, true, agentId);
            }
        } else if (!editMode) {
            // toggleEditProducts();
        }
        return;
    };

    return (
        <Suspense>
            <Fade duration={200}>
                <div className="prop-card pos-rel d-flex center-align" onClick={editMode ? handleSelect : openEditProductPopUp}>
                    <div className="prop-card-wrapper w-100p d-grid">
                        <div className="heading-wrapper">
                            <div className="heading-wrapper-img">
                                <img
                                    className="thumbnail"
                                    onError={handleBrokenImage}
                                    src={skills[0] ? (skills[0].poster === '' ? VideoPlaceholder : skills[0].poster) : ''}
                                    alt=""
                                />
                            </div>
                            <div className="heading-wrapper-content">
                                <div className="heading-status">
                                    <span className={status ? 'heading-title-pub heading-title' : 'heading-title-un heading-title'}>
                                        {status ? propCard('spaces.space_status.published') : propCard('spaces.space_status.unpublished')}
                                    </span>
                                    {isPrivate ? (
                                        <span className="private-status">
                                            <img src={PrivateSpaceLock} alt="private" />
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="heading">
                                    {flatAddress.length > 65 ? flatAddress.substring(0, 65) + '...' : flatAddress}
                                </div>
                                <div className="space-details-bed-bath">
                                    {rooms?.bed ? (
                                        <div className="space-comp">
                                            <img src={BedIcon} alt="Bed" />
                                            <span className="text">{rooms.bed}</span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {rooms?.bath ? (
                                        <div className="space-comp">
                                            <img src={BathIcon} alt="Bath" />
                                            <span className="text">{rooms.bath}</span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {dimensions?.size ? (
                                        <div className="space-comp">
                                            <img src={AreaIcon} alt="Area" />
                                            <span className="text">{dimensions.size} </span>
                                            <span className="text ml-2">
                                                {dimensions.measure && dimensions.measure.toLowerCase() === 'sqft' ? 'ft2' : 'mt2'}
                                            </span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {energyMeter?.current > 0 ? (
                                        <div className="space-comp">
                                            <img src={EnergyRatings} alt="ratings" />
                                            <span className="text uppercase">
                                                {getEnergyRatings(energyMeter.current)} ({energyMeter.current})
                                            </span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="sale-rent-wrapper">
                            <div className={priceType.toLowerCase() === 'sale' ? 'sale' : 'rent'}>
                                {priceType.toLowerCase() === 'sale'
                                    ? propCard('space_details.type.sale')
                                    : propCard('space_details.type.rent')}
                            </div>
                        </div>

                        <div className="price-wrapper">
                            {price !== 0 && price !== undefined ? (
                                <>
                                    <span>{getSymbolFromCurrency(currency)}</span>
                                    {price?.toLocaleString()} {priceType.toLowerCase() !== 'sale' ? `/ ${priceType}` : ''}
                                </>
                            ) : (
                                <span className="no-price">{propCard('no_price')}</span>
                            )}
                        </div>
                        <div className="cta-wrapper d-flex">
                            <span className={spaceURL?.length > 0 ? 'paired' : 'not-paired'}>
                                <img src={spaceURL?.length > 0 ? Tick : Close} alt="icon" />
                                {spaceURL?.length > 0 ? 'PAIRED' : 'NOT PAIRED'}
                            </span>
                        </div>
                        <div className="managed-btn">
                            <div className="managed-btn-wrapper">
                                <div className="managed-btn-wrapper-img center-align d-flex">
                                    {avatar.length > 0 ? (
                                        <img src={avatar} alt="avatar" />
                                    ) : (
                                        <Avatar
                                            name={name}
                                            value=""
                                            size="24"
                                            round={true}
                                            color="black"
                                            maxInitials={1}
                                            textSizeRatio={2}
                                            style={{ fontWeight: '700', fontSize: '10px' }}
                                        />
                                    )}
                                </div>
                                <span className="avatar-name">{name}</span>
                            </div>
                        </div>
                        <div className="setting-btn">
                            {editMode ? (
                                <div className="products-custom-checkbox">
                                    {isSelected && (
                                        <div>
                                            <img src={Checked} alt="Checked" />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <button className="d-flex" onClick={handleOpenSettingMenu}>
                                    <img typeof="button" src={RightCaret} alt=""></img>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </Fade>
        </Suspense>
    );
}

export default PropCard;
