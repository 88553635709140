import React from 'react';
import './checkbox.scss';

const CheckboxAtom = ({ isBlack, labelText, isChecked, setIsChecked }) => {
    return (
        <>
            <label className="label-checkbox">
                <input type="checkbox" checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
                <span className="fake-checkbox"></span>
                <span className="text">
                    I agree with the{' '}
                    <a href="https://www.holofy.io/terms-of-service" target="_blank" rel="noopener noreferrer">
                        Terms & Conditions
                    </a>
                    <br></br> and{' '}
                    <div
                        className="c-dblue d-ib"
                        onClick={(e) => {
                            e.preventDefault();
                            window.open('https://www.holofy.io/privacy-policy', '_blank');
                        }}
                    >
                        Privacy Policy
                    </div>
                </span>
            </label>
        </>
    );
};

export default CheckboxAtom;
