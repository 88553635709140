import {
    SET_CURRENT_MAGIC_LINK,
    SET_CURRENT_CTA,
    REMOVE_SPACE,
    EDIT_SPACE,
    EDIT_VIDEOS,
    EDIT_EMPTY_SKILL,
    SET_CURRENCY,
    SET_SPACE_COUNT,
    SET_SHOW_PAYMENT_STATUS_LOADER
} from './types.js';

const setCurrentMagicLink = ({ magicLink, spaceAddress, flatNumber, id }, state) => {
    const newCurrentProduct = {
        ...state,
        currentMagicLink: magicLink,
        spaceAddress,
        flatNumber,
        currentSpaceId: id
    };
    return {
        ...newCurrentProduct
    };
};

const setCurrentCTA = ({ cta, id, flatNumber, spaceAddress }, state) => {
    const newCurrentProduct = {
        ...state,
        currentSpaceId: id,
        currentCTAValues: cta,
        spaceAddress,
        flatNumber
    };
    return {
        ...newCurrentProduct
    };
};

const editSpace = (
    {
        flatNumber,
        spaceAddress,
        id,
        currency,
        brand,
        price,
        priceType,
        skills,
        widgetLink,
        cta,
        dimensions,
        rooms,
        createdAtInMillis,
        spaceURL,
        name,
        avatar,
        coordinates,
        agentId,
        isPrivate,
        status,
        energyMeter
    },
    state
) => {
    const newCurrentProduct = {
        ...state,
        flatNumber,
        spaceAddress,
        currentSpaceId: id,
        currentBrandName: brand,
        currency,
        price,
        priceType,
        skills,
        widgetLink,
        cta,
        dimensions,
        rooms,
        createdAtInMillis,
        spaceURL,
        name,
        avatar,
        coordinates,
        agentId,
        isPrivate,
        status,
        energyMeter
    };
    return {
        ...newCurrentProduct
    };
};

const removeSpace = ({ spaceAddress, flatNumber, id }, state) => {
    const newCurrentProduct = {
        ...state,
        spaceAddress,
        flatNumber,
        currentSpaceId: id
    };
    return {
        ...newCurrentProduct
    };
};

const editVideos = ({ skills, id }, state) => {
    const newCurrentProduct = {
        ...state,
        currentSkillValues: skills,
        currentSpaceId: id
    };
    return {
        ...newCurrentProduct
    };
};

const emptyVideoSkill = ({ skillId, skillName }, state) => {
    const newCurrentProduct = {
        ...state,
        selectedSkillId: skillId,
        selectedSkillName: skillName
    };
    return {
        ...newCurrentProduct
    };
};

const setCurrencyValue = ({ currency }, state) => {
    const newCurrentProduct = {
        ...state,
        currentCurrency: currency
    };
    return {
        ...newCurrentProduct
    };
};

const setSpaceCount = ({ published, unpublished }, state) => {
    const newSpaceCount = {
        ...state,
        countPublished: published >= 0 ? published : state.countPublished,
        countUnpublished: unpublished >= 0 ? unpublished : state.countUnpublished
    };
    return {
        ...newSpaceCount
    };
};

const setPaymentLoader = ({ loader }, state) => {
    const newCurrentProduct = {
        ...state,
        paymentLoader: loader
    };
    return {
        ...newCurrentProduct
    };
};
export default (state, action) => {
    switch (action.type) {
        case SET_CURRENT_MAGIC_LINK:
            return setCurrentMagicLink(action.payload, state);
        case SET_CURRENT_CTA:
            return setCurrentCTA(action.payload, state);
        case REMOVE_SPACE:
            return removeSpace(action.payload, state);
        case EDIT_SPACE:
            return editSpace(action.payload, state);
        case EDIT_VIDEOS:
            return editVideos(action.payload, state);
        case EDIT_EMPTY_SKILL:
            return emptyVideoSkill(action.payload, state);
        case SET_CURRENCY:
            return setCurrencyValue(action.payload, state);
        case SET_SPACE_COUNT:
            return setSpaceCount(action.payload, state);
        case SET_SHOW_PAYMENT_STATUS_LOADER:
            return setPaymentLoader(action.payload, state);
        default:
            return state;
    }
};
