/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from 'react';

// Style
import './popupeditspace.scss';

// Components
import Pulse from 'react-reveal/Pulse';
import Avatar from 'react-avatar';

// Helpers
import { DOMAIN } from '../../constants/base';
import { currentSpaceContext, SettingsContext } from '../../context';
import getSymbolFromCurrency from 'currency-symbol-map';
import { postReq } from '../../utils/api';
import { getSessionStorage, getEnergyRatings } from '../../utils';
import { useTranslation } from 'react-i18next';

//   Assets
import BackImg from '../../assets/back.png';
import EditIcon from '../../assets/editnew.svg';
import CTAPhone from '../../assets/phone_number.png';
import CTAMail from '../../assets/mail.png';
import CTACal from '../../assets/calendar-circle.svg';
import CTAWhatsapp from '../../assets/whatsapp-circle.svg';
import CTATelegram from '../../assets/telegram-circle.svg';
import CTAMessenger from '../../assets/messager-circle.svg';
import CTATypeForm from '../../assets/typeform-icon.svg';
import CTACustomLink from '../../assets/custom-link.png';
import BedIcon from '../../assets/bed-icon.svg';
import BathIcon from '../../assets/bath-icon.svg';
import AreaIcon from '../../assets/area-icon.svg';
import Check from '../../assets/check-grey.svg';
import Warning from '../../assets/warning-triangle.svg';
import VideoPlaceholder from '../../assets/video-placeholder.png';
import PrivateSpaceLock from '../../assets/publish-private.svg';
import EditSpaceDetails from '../EditSpaceDetails';
import EditSpaceSection from '../EditSpaceSection';
import CTAList from '../CTAList';
import { toast } from 'react-toastify';
import EnergyRatings from '../../assets/energy-ratings.svg';
import TogglePrivateSpace from '../PublishSpace/togglePrivateSpace';

const PopUpEditSpace = ({ setOpenEditProductPopUp, fetchActiveSpaces }) => {
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const userDetails = getSessionStorage('ss-user-spaces') || {};
    const { department, role } = userDetails;
    const admin = role === 'ADMIN',
        owner = role === 'OWNER';
    const [currentSpace] = useContext(currentSpaceContext);
    const [globalSettings] = useContext(SettingsContext);
    const { t: editSpacePopUp } = useTranslation();
    const [cardSkills] = useState(currentSpace.skills);

    const [pairedLink, setPairedLink] = useState(currentSpace.spaceURL || '');
    const [copyDirectLink, setCopyDirectLink] = useState(false);
    const [showSpaceDetailsEditPopUp, setShowSpaceDetailsEditPopUp] = useState(false);

    const [noWidgetInstalled, setNoWidgetInstalled] = useState(true);
    const [currentCta, setCurrentCta] = useState({});
    const [defaultCtas, setDefaultCtasSpace] = useState([]);

    // Address & Type
    const [spaceAddress, setSpaceAddress] = useState(currentSpace.spaceAddress);
    const [spaceType, setSpaceType] = useState(currentSpace.priceType === 'SALE' ? 'SALE' : 'RENTAL');
    const [privateToggle, setPrivateToggle] = useState(currentSpace?.isPrivate);
    const [rentalPriceType, setRentalPriceType] = useState(currentSpace.priceType === 'YEAR' ? 'Per Year' : 'Per Month');
    const [rentaPriceToShow, setRentalPriceToShow] = useState(currentSpace.priceType === 'YEAR' ? 'YEAR' : 'MONTH');

    const [flat, setFlat] = useState(currentSpace.flatNumber);
    // Price
    const [price, setPrice] = useState(currentSpace.price ? currentSpace.price : 0);
    const [currency, setCurrency] = useState(currentSpace.currency);
    // Rooms
    const [bathrooms, setBathrooms] = useState(currentSpace?.rooms?.bath);
    const [bedrooms, setBedrooms] = useState(currentSpace?.rooms?.bed);
    // Surface
    const [spaceSize, setSpaceSize] = useState(currentSpace.dimensions?.size);
    const [measure, setMeasure] = useState(currentSpace.dimensions?.measure ? currentSpace.dimensions?.measure : 'SQFT');
    //   Assignee
    const [assigneId, setAssigneId] = useState(currentSpace.agentId);
    // additing details
    const [editSpaceSectionPopup, setEditSpaceSectionPopup] = useState(false);
    const [currentSection, setCurrentSection] = useState('');
    const [agentAssigned, setAgentAssigned] = useState({
        name: currentSpace.name,
        avatar: currentSpace.avatar,
        id: currentSpace.agentId
    });

    //Energy Ratings
    const [errCurrent, setErrCurrent] = useState(currentSpace.energyMeter?.current);
    const [errPotential, setErrPotential] = useState(currentSpace.energyMeter?.potential);

    const [ctaLicenseNo, setCtaLicenseNo] = useState({
        value: '',
        enable: false
    });
    const [ctaWelcomeMsg, setCtaWelcomeMsg] = useState({
        value: '',
        enable: false
    });
    const [ctaEmail, setCtaEmail] = useState({
        icon: CTAMail,
        value: '',
        enable: false,
        order: 8
    });
    const [ctaPhone, setCtaPhone] = useState({
        icon: CTAPhone,
        value: {},
        enable: false,
        order: 1
    });
    const [ctaWhatsapp, setCtaWhatsapp] = useState({
        icon: CTAWhatsapp,
        value: {},
        enable: false,
        order: 2
    });
    const [ctaMessenger, setCtaMessenger] = useState({
        icon: CTAMessenger,
        value: '',
        enable: false,
        order: 4
    });
    const [ctaTelegram, setCtaTelegram] = useState({
        icon: CTATelegram,
        value: '',
        enable: false,
        order: 3
    });
    const [ctaCustomButton, setCtaCustomButton] = useState({
        icon: CTACustomLink,
        value: '',
        name: '',
        enable: false,
        order: 6
    });

    const [ctaTypeform, setCtaTypeform] = useState({
        icon: CTATypeForm,
        formId: '',
        buttonText: '',
        enable: false,
        order: 7
    });

    const [ctaCal, setCtaCal] = useState({
        icon: CTACal,
        value: '',
        enable: false,
        order: 5
    });

    useEffect(() => {
        for (let cta of defaultCtas) {
            let ctaKey = Object.keys(cta)[0];
            switch (ctaKey) {
                case 'email':
                    if (cta[ctaKey] !== null) setCtaEmail({ ...ctaEmail, value: cta[ctaKey], enable: true });
                    break;
                case 'phoneNumber':
                    if (cta[ctaKey].phone !== null && cta[ctaKey].phone !== undefined)
                        setCtaPhone({ ...ctaPhone, value: { ...cta[ctaKey] }, enable: true });
                    break;
                case 'whatsAppPhoneNumber':
                    if (cta[ctaKey].phone !== null && cta[ctaKey].phone !== undefined)
                        setCtaWhatsapp({ ...ctaWhatsapp, value: { ...cta[ctaKey] }, enable: true });
                    break;
                case 'licenceNo':
                    if (cta[ctaKey] !== null) setCtaLicenseNo({ value: cta[ctaKey], enable: true });
                    break;
                case 'message':
                    if (cta[ctaKey]) setCtaWelcomeMsg({ value: cta[ctaKey], enable: true });
                    break;
                case 'telegram':
                    if (cta[ctaKey]) setCtaTelegram({ ...ctaTelegram, value: cta[ctaKey], enable: true });
                    break;
                case 'facebookMessenger':
                    if (cta[ctaKey]) setCtaMessenger({ ...ctaMessenger, value: cta[ctaKey], enable: true });
                    break;
                case 'calendar':
                    if (cta[ctaKey]) setCtaCal({ ...ctaCal, value: cta[ctaKey], enable: true });
                    break;
                case 'customButton':
                    if (cta[ctaKey]?.value && cta[ctaKey]?.name) {
                        setCtaCustomButton({ ...ctaCustomButton, value: cta[ctaKey].value, name: cta[ctaKey].name, enable: true });
                    }
                    break;
                case 'typeForm':
                    if (cta[ctaKey]?.buttonText && cta[ctaKey]?.formId) {
                        setCtaTypeform({ ...ctaTypeform, buttonText: cta[ctaKey].buttonText, formId: cta[ctaKey].formId, enable: true });
                    }
                    break;
                default:
                    break;
            }
        }
    }, [defaultCtas]);

    useEffect(() => {
        if (ctaTelegram.value !== null) updateCtas('telegram', ctaTelegram);
        if (ctaEmail.value !== null) updateCtas('email', ctaEmail);
        if (ctaCal.value !== null) updateCtas('calendar', ctaCal);
        if (ctaMessenger.value !== null) updateCtas('messenger', ctaMessenger);
        if (ctaTypeform.buttonText !== null && ctaTypeform.formId !== null) updateCtas('typeform', ctaTypeform);
        if (ctaCustomButton.value !== null && ctaCustomButton.name !== null) updateCtas('customlink', ctaCustomButton);
        if (ctaWhatsapp.value?.phone !== null) updateCtas('whatsapp', ctaWhatsapp);
        if (ctaPhone.value?.phone !== null) updateCtas('phone', ctaPhone);
        if (ctaWelcomeMsg.value !== null) updateCtas('message', ctaWelcomeMsg);
        if (ctaLicenseNo.value !== null) updateCtas('license', ctaLicenseNo);
    }, [ctaEmail, ctaTelegram, ctaMessenger, ctaCal, ctaLicenseNo, ctaWelcomeMsg, ctaCustomButton, ctaTypeform, ctaWhatsapp, ctaPhone]);

    const updateCtas = (key, value) => {
        setCurrentCta((prevState) => {
            return {
                ...prevState,
                [key]: { ...value }
            };
        });
    };
    const handleBackBtn = () => {
        setOpenEditProductPopUp(false);
    };

    useEffect(() => {
        if (currentSpace?.cta) {
            const arr = currentSpace?.cta;
            let arr2 = [];
            const keys = Object.keys(arr);
            keys.forEach((key) => {
                let x = {};
                x[key] = arr[key];
                arr2 = [...arr2, x];
            });
            setDefaultCtasSpace([...arr2]);
        }
    }, []);

    useEffect(() => {
        const checkForSpaceUrl = async () => {
            if (currentSpace?.spaceURL?.length) {
                const url = `${DOMAIN}/v1/dashboard/space/create-space/spaceURL/search`;
                let payload = {
                    spaceURL: currentSpace.spaceURL
                };
                const requestHeaders = {
                    authorization: userToken.accessToken
                };
                const statusResponse = await postReq({ url, payload, requestHeaders });
                const { data } = statusResponse;
                setNoWidgetInstalled(data?.isWidgetInstalled);
            }
        };
        checkForSpaceUrl();
    }, []);

    // Copying the Direct Link
    const doCopyDirectLink = (e) => {
        navigator.clipboard.writeText(currentSpace?.widgetLink).then(
            function () {
                setCopyDirectLink(true);
                toast.success('Link copied to clipboard', {
                    position: 'top-center',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
                setTimeout(() => {
                    setCopyDirectLink(false);
                }, 3000);
            },
            function (err) {
                console.error('Async: Could not copy text: ', err);
            }
        );
    };

    const handleBrokenImage = (e) => {
        e.target.src = VideoPlaceholder;
    };

    // Handling Session And Local Storage
    const handlePersistenStorage = () => {
        localStorage.setItem('ss-auth', JSON.stringify(userDetails));
        window.open('mySettings', '_blank');
    };

    const handleOpenSpacePairedWebPage = () => {
        if (!currentSpace?.widgetLink.length) {
            return;
        }
        if (currentSpace?.widgetLink.includes('http://') || currentSpace?.widgetLink.includes('https://')) {
            window.open(currentSpace?.widgetLink, '_blank');
        } else {
            window.open(`//${currentSpace?.widgetLink}`, '_blank');
        }
    };

    const { adminGlobalSettings, memberGlobalSettings } = globalSettings;

    const managePortfolioCheck = () => {
        if (userDetails?.id === currentSpace?.agentId) {
            return true;
        } else {
            if (owner) return true;
            else if (admin && adminGlobalSettings?.globalAdminManageTeamPortfolio) return true;
            else if (!owner && !admin && memberGlobalSettings?.globalMemberManageTeamPortfolio) return true;
            else return false;
        }
    };
    return (
        <div className="popups-wrapper">
            <Pulse duration={150}>
                <div className="space-card-wrapper">
                    <div className="top-wrapper-edit">
                        <button className="back-button" onClick={handleBackBtn}>
                            <img src={BackImg} alt="back-icon"></img>
                        </button>
                        <div className="edit-btn-group">
                            <button
                                disabled={!managePortfolioCheck()}
                                className="button-edit-link"
                                onClick={() => setShowSpaceDetailsEditPopUp(true)}
                            >
                                <img src={EditIcon} alt="edit"></img> {editSpacePopUp('edit')}
                            </button>
                        </div>
                    </div>
                    <div className="mid-wrapper-edit">
                        <div className="mid-wrapper-edit-extra" />
                        <div className="mid-wrapper-edit-space">
                            <div className="mid-wrapper-edit-space-image" onClick={() => handleOpenSpacePairedWebPage()}>
                                <img
                                    onError={handleBrokenImage}
                                    src={cardSkills[0]?.poster?.length ? cardSkills[0].poster : VideoPlaceholder}
                                    alt="Space"
                                />
                                {cardSkills?.length > 0 && (
                                    <span className="video-skill">
                                        {cardSkills?.length} {cardSkills?.length === 1 ? editSpacePopUp('video') : editSpacePopUp('videos')}
                                    </span>
                                )}
                            </div>
                            <div className="section-title">
                                <h4>{editSpacePopUp('space_details.title')}</h4>
                                {managePortfolioCheck() && (
                                    <h6
                                        onClick={() => {
                                            setEditSpaceSectionPopup(true);
                                            setCurrentSection('2');
                                        }}
                                    >
                                        {editSpacePopUp('edit_section')}
                                    </h6>
                                )}
                            </div>
                            <div className="space-type-status">
                                <div className="space-type">
                                    {spaceType.toLowerCase() === 'sale'
                                        ? editSpacePopUp('space_details.type.sale')
                                        : editSpacePopUp('space_details.type.rent')}
                                </div>
                                {currentSpace.status ? (
                                    <div className="space-type">{editSpacePopUp('spaces.space_status.published')}</div>
                                ) : (
                                    <div className="space-type unpublished">{editSpacePopUp('spaces.space_status.unpublished')}</div>
                                )}
                                {privateToggle ? (
                                    <span className="private-status">
                                        <img src={PrivateSpaceLock} alt="private" />
                                    </span>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="mid-wrapper-edit-space-price">
                                {price ? (
                                    currency === 'USN' ? (
                                        <>
                                            <span className="currency">$ </span>
                                            <span className="price">{price?.toLocaleString()}</span>
                                            {spaceType.toLowerCase() !== 'sale' && rentaPriceToShow && (
                                                <span className="month"> / {rentaPriceToShow}</span>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <span className="currency">{getSymbolFromCurrency(currency) + ' '}</span>
                                            <span className="price">{price?.toLocaleString()}</span>
                                            {spaceType.toLowerCase() !== 'sale' && rentaPriceToShow && (
                                                <span className="month"> / {rentaPriceToShow}</span>
                                            )}
                                        </>
                                    )
                                ) : (
                                    <span className="no-price">{editSpacePopUp('no_price')}</span>
                                )}
                            </div>
                            <div className="mid-wrapper-edit-space-details">{spaceAddress}</div>
                            <div className="mid-wrapper-edit-space-comp">
                                {/* <div className="space-type">
                                    {spaceType.toLowerCase() === 'sale'
                                        ? editSpacePopUp('space_details.type.sale')
                                        : editSpacePopUp('space_details.type.rent')}
                                </div> */}
                                {bedrooms ? (
                                    <div className="space-comp">
                                        <img src={BedIcon} alt="Bed" />
                                        <span className="text">{bedrooms}</span>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {bathrooms ? (
                                    <div className="space-comp">
                                        <img src={BathIcon} alt="Bath" />
                                        <span className="text">{bathrooms}</span>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {spaceSize ? (
                                    <div className="space-comp">
                                        <img src={AreaIcon} alt="Area" />
                                        <span className="text">{spaceSize?.toLocaleString()} </span>
                                        <span className="text ml-2">{measure && measure.toLowerCase() === 'sqft' ? 'ft2' : 'mt2'}</span>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {errCurrent > 0 ? (
                                    <div className="space-comp">
                                        <img src={EnergyRatings} alt="ratings" />
                                        <span className="text uppercase">
                                            {getEnergyRatings(errCurrent)} ({errCurrent})
                                        </span>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className="mid-wrapper-edit-agent">
                            {/* <div onClick={() => setToggleAgent(!toggleAgent)} className="toggler-space" /> */}
                            <div className="section-title">
                                <h4>{editSpacePopUp('agent.manage_title')}</h4>
                                {managePortfolioCheck() && (
                                    <h6
                                        onClick={() => {
                                            setEditSpaceSectionPopup(true);
                                            setCurrentSection('3');
                                        }}
                                    >
                                        {editSpacePopUp('edit_section')}
                                    </h6>
                                )}
                            </div>
                            <div className="mid-wrapper-edit-agent-container">
                                <div className="mid-wrapper-edit-agent-container-details">
                                    <div className="mid-wrapper-edit-agent-container-details-profile">
                                        <div className="agent-img">
                                            {agentAssigned?.avatar?.length ? (
                                                <img src={agentAssigned.avatar} alt="Agent" />
                                            ) : (
                                                <Avatar
                                                    size="40"
                                                    name={agentAssigned.name}
                                                    value="86%"
                                                    color="black"
                                                    round={true}
                                                    maxInitials={1}
                                                    textSizeRatio={2}
                                                    style={{
                                                        fontWeight: '700'
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div className="agent-name">
                                            <h4>{agentAssigned?.name}</h4>
                                            {currentCta?.license?.value?.length ? <span>{currentCta?.license?.value}</span> : ''}
                                        </div>
                                    </div>
                                    <div className="mid-wrapper-edit-agent-container-details-contacts">
                                        <CTAList ctas={currentCta} excludeCTA={true}></CTAList>
                                    </div>
                                </div>
                                {currentCta?.message?.value?.length ? (
                                    <div className="mid-wrapper-edit-agent-container-message">
                                        <h4>{editSpacePopUp('agent.wl_msg.label')}</h4>
                                        <span>{currentCta?.message?.value}</span>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <div className="mid-wrapper-edit-pair">
                            <div className="section-title">
                                <h4>{editSpacePopUp('spaces.publish.pair.title')}</h4>
                                {managePortfolioCheck() && (
                                    <h6
                                        onClick={() => {
                                            setEditSpaceSectionPopup(true);
                                            setCurrentSection('5');
                                        }}
                                    >
                                        {editSpacePopUp('edit_section')}
                                    </h6>
                                )}
                            </div>
                            <>
                                <div className="mid-wrapper-edit-pair-container">
                                    <span>{editSpacePopUp('spaces.publish.pair.subtitle')}</span>
                                    <div className="mid-wrapper-edit-pair-container-input">
                                        <input
                                            type="text"
                                            disabled={true}
                                            value={pairedLink.length ? pairedLink : editSpacePopUp('spaces.publish.pair.not_paired')}
                                            onChange={(e) => setPairedLink(e.target.value)}
                                            style={{
                                                color: pairedLink.length ? '#000' : '#0000003D'
                                            }}
                                        />
                                        {pairedLink?.length > 0 && noWidgetInstalled ? (
                                            <div className="success-paired">
                                                <img src={Check} alt="Tick" />
                                                <span>{editSpacePopUp('spaces.publish.pair.success.title')}</span>
                                                <a onClick={() => handleOpenSpacePairedWebPage()}>
                                                    {editSpacePopUp('spaces.publish.pair.success.title')}
                                                </a>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        {pairedLink?.length > 0 && !noWidgetInstalled && (
                                            <div
                                                className="success-paired"
                                                style={{
                                                    backgroundColor: '#FFCF88'
                                                }}
                                            >
                                                <img src={Warning} alt="Tick" />
                                                <div className="fail-paired">
                                                    <h4>{editSpacePopUp('spaces.publish.pair.warning.title')}</h4>
                                                    <span>{editSpacePopUp('spaces.publish.pair.warning.subtitle')}</span>
                                                </div>
                                                <a onClick={() => handlePersistenStorage()}>
                                                    {editSpacePopUp('spaces.publish.pair.warning.install')}
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        </div>
                        <div className="mid-wrapper-edit-direct">
                            <div className="section-title">
                                <h4>{editSpacePopUp('spaces.share.title')}</h4>
                            </div>

                            <div className="mid-wrapper-edit-direct-container">
                                <span>{editSpacePopUp('spaces.share.subtitle')}</span>
                                <div className="mid-wrapper-edit-direct-container-input">
                                    <input type="text" value={currentSpace?.widgetLink} disabled={true}></input>
                                    <button onClick={doCopyDirectLink}>
                                        {copyDirectLink ? editSpacePopUp('copied') : editSpacePopUp('copy')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="mid-wrapper-edit-private">
                            <TogglePrivateSpace
                                privateSpace={privateToggle}
                                setPrivateSpace={setPrivateToggle}
                                fetchActiveSpaces={fetchActiveSpaces}
                                spaceId={currentSpace.currentSpaceId}
                                spaceAgentId={currentSpace.agentId}
                                customWidth="100%"
                            ></TogglePrivateSpace>
                        </div>
                    </div>
                </div>
            </Pulse>

            {editSpaceSectionPopup && (
                <EditSpaceSection
                    currentSection={currentSection}
                    setEditSpaceSectionPopUp={setEditSpaceSectionPopup}
                    currentSpace={currentSpace}
                    currentSpaceId={currentSpace.currentSpaceId}
                    spaceAddress={spaceAddress}
                    setSpaceAddress={setSpaceAddress}
                    spaceType={spaceType}
                    setSpaceType={setSpaceType}
                    rentalPriceType={rentalPriceType}
                    setRentalPriceType={setRentalPriceType}
                    setRentalPriceToShow={setRentalPriceToShow}
                    flat={flat}
                    setFlat={setFlat}
                    assigneId={assigneId}
                    setAssigneId={setAssigneId}
                    price={price}
                    setPrice={setPrice}
                    currency={currency}
                    setCurrency={setCurrency}
                    bedrooms={bedrooms}
                    setBedrooms={setBedrooms}
                    bathrooms={bathrooms}
                    setBathrooms={setBathrooms}
                    spaceSize={spaceSize}
                    setSpaceSize={setSpaceSize}
                    measure={measure}
                    setMeasure={setMeasure}
                    setShowSpaceDetailsEditPopUp={setShowSpaceDetailsEditPopUp}
                    currentSpaceCoordinates={currentSpace.coordinates ? currentSpace.coordinates : [0, 0]}
                    agentAssigned={agentAssigned}
                    setAgentAssigned={setAgentAssigned}
                    agentCta={currentSpace.cta}
                    setOpenEditProductPopUp={setOpenEditProductPopUp}
                    fetchActiveSpaces={fetchActiveSpaces}
                    setCurrentSection={setCurrentSection}
                    pairedLink={pairedLink}
                    setPairedLink={setPairedLink}
                    setSpaceCtasUpdated={setCurrentCta}
                    currentUpdatedCta={currentCta}
                    userPermissionType={department}
                    errCurrent={errCurrent}
                    setErrCurrent={setErrCurrent}
                    errPotential={errPotential}
                    setErrPotential={setErrPotential}
                    privateToggle={privateToggle}
                    setPrivateToggle={setPrivateToggle}
                    isWidgetInstalled={noWidgetInstalled}
                    setNoWidgetInstalled={setNoWidgetInstalled}
                ></EditSpaceSection>
            )}
            {showSpaceDetailsEditPopUp && (
                <EditSpaceDetails
                    currentSpace={currentSpace}
                    currentSpaceId={currentSpace.currentSpaceId}
                    spaceAddress={spaceAddress}
                    setSpaceAddress={setSpaceAddress}
                    spaceType={spaceType}
                    setSpaceType={setSpaceType}
                    rentalPriceType={rentalPriceType}
                    setRentalPriceType={setRentalPriceType}
                    setRentalPriceToShow={setRentalPriceToShow}
                    flat={flat}
                    setFlat={setFlat}
                    assigneId={assigneId}
                    setAssigneId={setAssigneId}
                    price={price}
                    setPrice={setPrice}
                    currency={currency}
                    setCurrency={setCurrency}
                    bedrooms={bedrooms}
                    setBedrooms={setBedrooms}
                    bathrooms={bathrooms}
                    setBathrooms={setBathrooms}
                    spaceSize={spaceSize}
                    setSpaceSize={setSpaceSize}
                    measure={measure}
                    setMeasure={setMeasure}
                    setShowSpaceDetailsEditPopUp={setShowSpaceDetailsEditPopUp}
                    currentSpaceCoordinates={currentSpace.coordinates ? currentSpace.coordinates : [0, 0]}
                    agentAssigned={agentAssigned}
                    setAgentAssigned={setAgentAssigned}
                    agentCta={currentSpace.cta}
                    setOpenEditProductPopUp={setOpenEditProductPopUp}
                    fetchActiveSpaces={fetchActiveSpaces}
                    userPermissionType={department}
                    errCurrent={errCurrent}
                    setErrCurrent={setErrCurrent}
                    errPotential={errPotential}
                    setErrPotential={setErrPotential}
                    privateToggle={privateToggle}
                    setPrivateToggle={setPrivateToggle}
                    isWidgetInstalled={noWidgetInstalled}
                    setNoWidgetInstalled={setNoWidgetInstalled}
                    pairedLink={pairedLink}
                    setPairedLink={setPairedLink}
                ></EditSpaceDetails>
            )}
        </div>
    );
};

export default PopUpEditSpace;
