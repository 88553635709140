import React, { useContext, useEffect, useState } from 'react';
import MultiStepPopup from '../MultiStepPopUp';
import Avatar from 'react-avatar';
import { Redirect } from 'react-router-dom';

import CTAList from '../CTAList';
import HeaderTag from '../HeaderTag';
import { getSessionStorage, removeSessionStorage } from '../../utils';
import { EditMemberContext, GlobalProfile, SettingsContext } from '../../context';
import { useTranslation } from 'react-i18next';
import { getReq, postReq } from '../../utils/api';
import { DOMAIN } from '../../constants/base';
import { toast } from 'react-toastify';
import EditProfileDetails from '../EditProfileDetails';

import EditIcon from '../../assets/editnew.svg';
import EmailOutline from '../../assets/email-outline.svg';
import CalOutline from '../../assets/cal-grey-outline.svg';
import VideoPlaceholder from '../../assets/video-placeholder.png';
import CTAPhone from '../../assets/phone_number.png';
import CTAMail from '../../assets/mail.png';
import CTACal from '../../assets/calendar-circle.svg';
import CTAWhatsapp from '../../assets/whatsapp-circle.svg';
import CTATelegram from '../../assets/telegram-circle.svg';
import CTAMessenger from '../../assets/messager-circle.svg';
import CTATypeForm from '../../assets/typeform-icon.svg';
import CTACustomLink from '../../assets/custom-link.png';
import './userdetailpop.scss';

export default function UserDetailPopUp(props) {
    const { setUserDetailPopUp, isMemberEdit, isUserProfileEdit, fetchActiveMembers, memberProfile, setUpdateSpaces } = props;
    const [showShimmer, setShowShimmer] = useState(false);
    const [currentCta, setCurrentCta] = useState({});
    const [currentStep, setCurrentStep] = useState(1);
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const userDetails = getSessionStorage('ss-user-spaces') || {};

    const [currentMember] = useContext(EditMemberContext);
    const [globalSettings] = useContext(SettingsContext);
    const [, dispatchProfile] = useContext(GlobalProfile);
    const { adminGlobalSettings, memberGlobalSettings } = globalSettings;

    const { name = '', avatar, email, role, id } = userDetails;
    const admin = role === 'ADMIN',
        owner = role === 'OWNER';
    const [agentAdmin] = useState(isMemberEdit ? currentMember?.currentEditMemberAdmin : admin);
    const { t: userProfile } = useTranslation();
    const [profileName, setProfileName] = useState(isUserProfileEdit ? name : currentMember?.currentEditMemberName);

    const [profileAvatar, setProfileAvatar] = useState(isUserProfileEdit ? avatar : currentMember?.currentEditMemberAvatar);
    const profileEmail = isUserProfileEdit ? email : currentMember?.currentEditMemberEmail;
    const [lastActiveTime, setLastActiveTime] = useState(
        memberProfile ? (currentMember?.lastActiveInMillis ? currentMember?.lastActiveInMillis : false) : null
    );
    const [userPermission, setUserPermission] = useState(isMemberEdit ? currentMember?.currentEditMemberRole : '');
    const [userTeamRole, setUserTeamRole] = useState(isMemberEdit ? currentMember?.currentEditMemberTeamRole : '');
    const [userDepartment, setUserDepartment] = useState(isMemberEdit ? currentMember?.currentEditMemberDepartment : '');
    const [isSignOut, setSignOut] = useState(false);
    const [activeDays, setActiveDays] = useState(null);
    const [shouldRedirect, setRedirect] = useState(false);
    // ctas

    const [defaultCtas, setDefaultCtasSpace] = useState([]);
    const [ctaLicenseNo, setCtaLicenseNo] = useState({
        value: '',
        enable: false,
        order: 10
    });
    const [ctaWelcomeMsg, setCtaWelcomeMsg] = useState({
        value: '',
        enable: false,
        order: 9
    });
    const [ctaEmail, setCtaEmail] = useState({
        icon: CTAMail,
        value: '',
        enable: false,
        order: 8
    });
    const [ctaPhone, setCtaPhone] = useState({
        icon: CTAPhone,
        value: {},
        enable: false,
        order: 1
    });
    const [ctaWhatsapp, setCtaWhatsapp] = useState({
        icon: CTAWhatsapp,
        value: {},
        enable: false,
        order: 2
    });
    const [ctaMessenger, setCtaMessenger] = useState({
        icon: CTAMessenger,
        value: '',
        enable: false,
        order: 4
    });
    const [ctaTelegram, setCtaTelegram] = useState({
        icon: CTATelegram,
        value: '',
        enable: false,
        order: 3
    });
    const [ctaCustomButton, setCtaCustomButton] = useState({
        icon: CTACustomLink,
        value: '',
        name: '',
        enable: false,
        order: 6
    });

    const [ctaTypeform, setCtaTypeform] = useState({
        icon: CTATypeForm,
        formId: '',
        buttonText: '',
        enable: false,
        order: 7
    });

    const [ctaCal, setCtaCal] = useState({
        icon: CTACal,
        value: '',
        enable: false,
        order: 5
    });

    const [editAllDetails, setEditAllDetails] = useState(false);
    const [editSectionDetails, setEditSectionDetails] = useState(false);

    useEffect(() => {
        if (lastActiveTime) {
            let dateValue = `/Date(${lastActiveTime})/`;
            let lastActiveDate = new Date(parseFloat(dateValue.substr(6)));
            let today = new Date();
            let lastActiveDay = lastActiveDate.getDate();
            let todayDate = today.getDate();
            setActiveDays(todayDate - lastActiveDay);
        }
    }, [lastActiveTime]);
    useEffect(() => {
        if (isUserProfileEdit) {
            getUserDetails();
        } else if (currentMember?.currentEditMemberCta) {
            const arr = currentMember?.currentEditMemberCta;
            let arr2 = [];
            const keys = Object.keys(arr);
            keys.forEach((key) => {
                let x = {};
                x[key] = arr[key];
                arr2 = [...arr2, x];
            });
            setDefaultCtasSpace([...arr2]);
        }
    }, []);

    useEffect(() => {
        for (let cta of defaultCtas) {
            let ctaKey = Object.keys(cta)[0];
            switch (ctaKey) {
                case 'email':
                    if (cta[ctaKey] !== null) setCtaEmail({ ...ctaEmail, value: cta[ctaKey], enable: true });
                    break;
                case 'phoneNumber':
                    if (cta[ctaKey].phone !== null && cta[ctaKey].phone !== undefined)
                        setCtaPhone({ ...ctaPhone, value: { ...cta[ctaKey] }, enable: true });
                    break;
                case 'whatsAppPhoneNumber':
                    if (cta[ctaKey].phone !== null && cta[ctaKey].phone !== undefined)
                        setCtaWhatsapp({ ...ctaWhatsapp, value: { ...cta[ctaKey] }, enable: true });
                    break;
                case 'licenceNo':
                    if (cta[ctaKey] !== null) setCtaLicenseNo({ value: cta[ctaKey], enable: true });
                    break;
                case 'message':
                    if (cta[ctaKey] !== null) setCtaWelcomeMsg({ value: cta[ctaKey], enable: true });
                    break;
                case 'telegram':
                    if (cta[ctaKey] !== null) setCtaTelegram({ ...ctaTelegram, value: cta[ctaKey], enable: true });
                    break;
                case 'facebookMessenger':
                    if (cta[ctaKey] !== null) setCtaMessenger({ ...ctaMessenger, value: cta[ctaKey], enable: true });
                    break;
                case 'calendar':
                    if (cta[ctaKey] !== null) setCtaCal({ ...ctaCal, value: cta[ctaKey], enable: true });
                    break;
                case 'customButton':
                    if (cta[ctaKey]?.value && cta[ctaKey]?.name) {
                        setCtaCustomButton({ ...ctaCustomButton, value: cta[ctaKey].value, name: cta[ctaKey].name, enable: true });
                    }
                    break;
                case 'typeForm':
                    if (cta[ctaKey]?.buttonText && cta[ctaKey]?.formId) {
                        setCtaTypeform({ ...ctaTypeform, buttonText: cta[ctaKey].buttonText, formId: cta[ctaKey].formId, enable: true });
                    }
                    break;
                default:
                    break;
            }
        }
    }, [defaultCtas]);
    useEffect(() => {
        if (ctaTelegram.value !== null) updateCtas('telegram', ctaTelegram);
        if (ctaEmail.value !== null) updateCtas('email', ctaEmail);
        if (ctaCal.value !== null) updateCtas('calendar', ctaCal);
        if (ctaMessenger.value !== null) updateCtas('messenger', ctaMessenger);
        if (ctaTypeform.buttonText !== null && ctaTypeform.formId !== null) updateCtas('typeform', ctaTypeform);
        if (ctaCustomButton.value !== null && ctaCustomButton.name !== null) updateCtas('customlink', ctaCustomButton);
        if (ctaWhatsapp.value?.phone !== null) updateCtas('whatsapp', ctaWhatsapp);
        if (ctaPhone.value?.phone !== null) updateCtas('phone', ctaPhone);
        if (ctaWelcomeMsg.value !== null) updateCtas('message', ctaWelcomeMsg);
        if (ctaLicenseNo.value !== null) updateCtas('license', ctaLicenseNo);
    }, [ctaEmail, ctaTelegram, ctaMessenger, ctaCal, ctaLicenseNo, ctaWelcomeMsg, ctaCustomButton, ctaTypeform, ctaWhatsapp, ctaPhone]);

    const updateCtas = (key, value) => {
        setCurrentCta((prevState) => {
            return {
                ...prevState,
                [key]: { ...value }
            };
        });
    };

    if (shouldRedirect) {
        return <Redirect to="/signin" />;
    }

    const revokeInvitation = async () => {
        const url = `${DOMAIN}/v1/dashboard/space/user/remove-member`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const memberPayload = {
            id: currentMember.currentEditMemberId,
            invitee: currentMember.currentEditMemberInvitee
        };
        const userPayload = {
            entity: 'space'
        };
        const revokeInvResp = await postReq({
            url,
            payload: isUserProfileEdit ? userPayload : memberPayload,
            requestHeaders
        });
        const { code } = revokeInvResp;
        if (code === 200) {
            toast.error(
                userProfile('userdetail.toast.revoke_inv') +
                    currentMember.currentEditMemberEmail +
                    userProfile('userdetail.toast.revoke_inv_two'),
                {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                }
            );
            setUserDetailPopUp(false);
            fetchActiveMembers();
        }
    };
    // console.log(currentMember);
    const getUserDetails = async () => {
        setShowShimmer(true);
        const url = `${DOMAIN}/v1/dashboard/space/user/profile`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const statusResponse = await getReq({
            url,
            requestHeaders
        });
        const { code, data } = statusResponse;
        if (code === 200) {
            const arr = data?.cta;
            let arr2 = [];
            const keys = Object.keys(arr);
            keys.forEach((key) => {
                let x = {};
                x[key] = arr[key];
                arr2 = [...arr2, x];
            });
            setDefaultCtasSpace([...arr2]);
            setLastActiveTime(data?.lastActiveInMillis);
            setUserPermission(data?.role);
            setUserDepartment(data?.department);
            setUserTeamRole(data?.teamRole);
            setShowShimmer(false);
        }
    };
    const handleBackButton = () => {
        setUserDetailPopUp(false);
    };

    const renderDots = () => {
        //  return steps.map((step, idx) => {
        //      if (idx + 1 === activeStep) {
        //          return <div className="circle active" key={step.name}></div>;
        //      } else {
        //          return <div className="circle" key={step.name}></div>;
        //      }
        //  });
    };

    const resendInvite = async () => {
        const url = `${DOMAIN}/v1/dashboard/space/user/v2/invite-team`;
        const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
        const requestHeaders = {
            authorization: userToken.accessToken
        };

        const payload = {
            invitees: [
                {
                    email: currentMember?.currentEditMemberEmail,
                    role: currentMember?.currentEditMemberRole,
                    department: currentMember?.currentEditMemberDepartment,
                    teamRole: currentMember?.currentEditMemberTeamRole
                }
            ]
        };
        const invitationResponse = await postReq({
            url,
            payload,
            requestHeaders
        });
        const { code } = invitationResponse;

        if (code === 200) {
            toast.success(userProfile('userdetail.toast.sent_inv') + currentMember.currentEditMemberEmail, {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        }
    };

    const signoutHandler = () => {
        document.body.classList.add('ovf-hidden');
        setSignOut(true);
    };
    const noHandler = () => {
        document.body.classList.remove('ovf-hidden');
        setSignOut(false);
    };
    const proceedHandler = () => {
        document.body.classList.remove('ovf-hidden');
        localStorage.removeItem('ss-auth-spaces');
        removeSessionStorage('ss-user-spaces');
        localStorage.setItem('ss-lang', 'en');
        dispatchProfile({
            type: 'EDIT_GLOBAL_PROFILE',
            payload: {
                id: null,
                avatar: null,
                name: null,
                email: null,
                role: null,
                phone: {
                    phone: null,
                    countryCode: null,
                    countryName: null
                },
                teamExists: false,
                plan: null,
                department: null
            }
        });
        setRedirect(true);
        try {
            window.Intercom('shutdown');
        } catch (e) {
            console.log(e);
        }
    };
    const isEmptyCtas = () => {
        let keys = Object.keys(currentCta);
        for (let k of keys) {
            if (typeof currentCta[k].value !== 'object') {
                if (currentCta[k].value && currentCta[k].value !== '') {
                    return false;
                } else if (currentCta[k].buttonText && currentCta[k].buttonText !== '') {
                    return false;
                }
            } else {
                if (currentCta[k].value?.phone && currentCta[k].value?.phone !== '') {
                    return false;
                }
            }
        }
        return true;
    };
    const additionalBtnToWrapper = () => {
        return editTeamMemberDetailsCheck() ? (
            <div className="btn-container">
                <button
                    className="button-edit"
                    onClick={() => {
                        setEditAllDetails(true);
                        setCurrentStep(1);
                        setEditSectionDetails(false);
                    }}
                >
                    <img src={EditIcon} alt="edit"></img> {userProfile('edit')}
                </button>
            </div>
        ) : (
            !currentMember?.currentEditMemberVerified && (
                <button className="button-resend" disabled={invitePermissionCheck() ? false : true} onClick={resendInvite}>
                    {userProfile('userdetail.inv.resend')}
                </button>
            )
        );
    };

    const handleBrokenImage = (e) => {
        e.target.src = VideoPlaceholder;
    };
    const editTeamMemberDetailsCheck = (type) => {
        if (currentMember?.currentEditMemberRole === 'OWNER') return false;
        // checking for verified
        if (isUserProfileEdit) return true;
        if (!isUserProfileEdit && !currentMember?.currentEditMemberVerified && type !== 'team') return false;
        if (owner) return true;
        else if (admin && adminGlobalSettings?.globalAdminEditTeamMemberDetails) return true;
        else if (!owner && !admin && memberGlobalSettings?.globalMemberEditTeamMemberDetails && !agentAdmin) return true;
        else return false;
    };

    const invitePermissionCheck = () => {
        if (owner) return true;
        else if (admin && adminGlobalSettings?.globalAdminSendInvitations) return true;
        else if (!admin && !owner && memberGlobalSettings?.globalMemberSendInvitations) return true;
        else return false;
    };

    const departementText = () => {
        if (userDepartment === 'ALL') return userProfile('team.departement.all');
        if (userDepartment === 'SALE') return userProfile('team.departement.sale');
        if (userDepartment === 'LETTINGS') return userProfile('team.departement.lettings');
    };

    const userPermissionText = () => {
        if (userPermission === 'ADMIN') return userProfile('team.admin');
        else if (userPermission === 'OWNER') return userProfile('team.owner');
        else return userProfile('team.member');
    };
    return editAllDetails ? (
        <EditProfileDetails
            setEditAllDetails={setEditAllDetails}
            owner={owner}
            admin={admin}
            profileId={id}
            isMemberEdit={isMemberEdit}
            profileAvatar={profileAvatar}
            setProfileAvatar={setProfileAvatar}
            handleBrokenImage={handleBrokenImage}
            profileName={profileName}
            setProfileName={setProfileName}
            isUserProfileEdit={isUserProfileEdit}
            currentMember={currentMember}
            profileEmail={profileEmail}
            currentCta={currentCta}
            setCurrentCta={setCurrentCta}
            agentAdmin={agentAdmin}
            fetchActiveMembers={fetchActiveMembers}
            currentStep={currentStep}
            editSectionDetails={editSectionDetails}
            setEditSectionDetails={setEditSectionDetails}
            userDepartment={userDepartment}
            setUserDepartment={setUserDepartment}
            userTeamRole={userTeamRole}
            setUserTeamRole={setUserTeamRole}
            userPermission={userPermission}
            setUserPermission={setUserPermission}
            ctaEmail={ctaEmail}
            setCtaEmail={setCtaEmail}
            ctaLicenseNo={ctaLicenseNo}
            setCtaLicenseNo={setCtaLicenseNo}
            ctaWelcomeMsg={ctaWelcomeMsg}
            setCtaWelcomeMsg={setCtaWelcomeMsg}
            ctaWhatsapp={ctaWhatsapp}
            setCtaWhatsapp={setCtaWhatsapp}
            ctaPhone={ctaPhone}
            setCtaPhone={setCtaPhone}
            ctaTelegram={ctaTelegram}
            setCtaTelegram={setCtaTelegram}
            ctaMessenger={ctaMessenger}
            setCtaMessenger={setCtaMessenger}
            ctaCustomButton={ctaCustomButton}
            setCtaCustomButton={setCtaCustomButton}
            ctaTypeform={ctaTypeform}
            ctaCal={ctaCal}
            setCtaCal={setCtaCal}
            setCtaTypeform={setCtaTypeform}
            setUserDetailPopUp={setUserDetailPopUp}
        ></EditProfileDetails>
    ) : (
        <>
            <MultiStepPopup
                setMultiStepPopUp={setUserDetailPopUp}
                handleBackButton={handleBackButton}
                renderDots={renderDots}
                additionalBtnToWrapper={additionalBtnToWrapper}
            >
                <div className="detail-container">
                    <div className="profile">
                        <div className="pbox profile__info">
                            {showShimmer ? (
                                <div className="profile-image shimmer-effect"></div>
                            ) : (
                                <div className="profile-image">
                                    {profileAvatar?.length ? (
                                        <img onError={handleBrokenImage} src={profileAvatar} alt="profile" />
                                    ) : (
                                        <Avatar
                                            name={profileName}
                                            value=""
                                            size="140"
                                            round={true}
                                            color="black"
                                            maxInitials={1}
                                            textSizeRatio={2}
                                            style={{ fontWeight: '700' }}
                                        />
                                    )}
                                </div>
                            )}

                            {showShimmer ? (
                                <div className="shimmer-effect name"></div>
                            ) : (
                                <div className="pbox__header">
                                    <h4 style={{ marginTop: '20px' }}>{profileName}</h4>
                                    {editTeamMemberDetailsCheck() && (
                                        <h6
                                            onClick={() => {
                                                setEditSectionDetails(true);
                                                setEditAllDetails(true);
                                                setCurrentStep(1);
                                            }}
                                        >
                                            {userProfile('edit_section')}
                                        </h6>
                                    )}
                                </div>
                            )}
                            <h1>{userProfile('profile.acc_info')}</h1>
                            <div className="profile__info--email">
                                <img src={EmailOutline} alt="mail" />
                                {showShimmer ? (
                                    <div className="shimmer-effect mail"> </div>
                                ) : (
                                    <p>{isMemberEdit ? currentMember?.currentEditMemberEmail : email}</p>
                                )}
                            </div>
                            {(currentCta?.message?.value || currentCta?.license?.value) && (
                                <div className="add-ctas">
                                    {currentCta?.message?.value && (
                                        <HeaderTag
                                            headerText={userProfile('agent.wl_msg.label')}
                                            tagText={currentCta?.message?.value}
                                            isLoading={showShimmer}
                                        />
                                    )}
                                    {currentCta?.license?.value && (
                                        <HeaderTag
                                            headerText={userProfile('agent.lc_no.label')}
                                            tagText={currentCta?.license?.value}
                                            isLoading={showShimmer}
                                        />
                                    )}
                                </div>
                            )}
                            <hr />
                            <div className="profile__info--lastactive">
                                <img src={CalOutline} alt="people" />
                                <div className="lastactive-day">
                                    <div>
                                        {userProfile('profile.last_active')}
                                        {lastActiveTime === false
                                            ? userProfile('userdetail.last_active.never')
                                            : activeDays === 0
                                            ? userProfile('userdetail.last_active.today')
                                            : activeDays === 1
                                            ? userProfile('userdetail.last_active.one_day')
                                            : ` ${activeDays} ${userProfile('userdetail.last_active.days')}`}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pbox profile__ctas">
                            <div className="pbox__header">
                                <h4>{userProfile('userdetail.contacts')}</h4>
                                {editTeamMemberDetailsCheck() && !showShimmer && (
                                    <h6
                                        onClick={() => {
                                            setEditSectionDetails(true);
                                            setEditAllDetails(true);
                                            setCurrentStep(2);
                                        }}
                                    >
                                        {userProfile('edit_section')}
                                    </h6>
                                )}
                            </div>
                            <h1>{userProfile('contacts')}</h1>
                            {showShimmer ? (
                                <div className="shimmer-effect ctas"></div>
                            ) : (
                                !isEmptyCtas() &&
                                !showShimmer && (
                                    <div className="contacts-list">
                                        <CTAList ctas={currentCta} excludeCTA={true}></CTAList>
                                    </div>
                                )
                            )}
                        </div>
                        {userPermission.toUpperCase() !== 'OWNER' && (
                            <div className="pbox profile__role">
                                <div className="pbox__header">
                                    <h4>{userProfile('team.title')}</h4>
                                    {editTeamMemberDetailsCheck('team') && !isUserProfileEdit && (
                                        <h6
                                            onClick={() => {
                                                setEditSectionDetails(true);
                                                setEditAllDetails(true);
                                                setCurrentStep(3);
                                            }}
                                        >
                                            {userProfile('edit_section')}
                                        </h6>
                                    )}
                                </div>
                                {/* Commenting below code as we are not implementing branches */}
                                {/* <h1>Part of</h1>
                            <div className="headquarter">
                                <img src={HeadQuarter} alt="" />
                                <div className="details">
                                    <h1>Paramount Properties London</h1>
                                    <p>Joined Dec 23th, 2021</p>
                                </div>
                            </div> */}
                                <div className="permissions">
                                    <HeaderTag
                                        headerText={userProfile('settings.team.permissions')}
                                        tagText={userPermissionText()}
                                        customTagClass={
                                            userPermission && userPermission !== 'MEMBER'
                                                ? userPermission === 'OWNER'
                                                    ? 'member owner'
                                                    : 'member not-member'
                                                : 'member'
                                        }
                                        isLoading={showShimmer}
                                    />
                                    <HeaderTag
                                        headerText={userProfile('team_role')}
                                        tagText={userTeamRole}
                                        customTagClass="member"
                                        isLoading={showShimmer}
                                    />
                                    <HeaderTag
                                        headerText={userProfile('department')}
                                        tagText={departementText()}
                                        customTagClass="member"
                                        isLoading={showShimmer}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {isUserProfileEdit && (
                        <div className="sign-out-btn-profile" onClick={signoutHandler}>
                            {userProfile('signout.title')}
                        </div>
                    )}
                    {!isUserProfileEdit && !currentMember?.currentEditMemberVerified && (
                        <div
                            className="sign-out-btn-profile revoke-invitation"
                            style={{
                                cursor: invitePermissionCheck() ? '' : 'not-allowed'
                            }}
                            onClick={() => {
                                if (invitePermissionCheck()) revokeInvitation();
                                else {
                                    return;
                                }
                            }}
                        >
                            {userProfile('userdetail.inv.revoke')}
                        </div>
                    )}
                </div>
            </MultiStepPopup>
            {isSignOut && (
                <div className="d-flex center-align pos-fix w-100p h-100p bg-black-50 top-0 left-0 zi-100" style={{ zIndex: 100000 }}>
                    <div className="bg-white p-20 br-10 mxw-300">
                        <div className="fs-18 fw-b mb-40">{userProfile('signout.message')}</div>
                        <div className="d-flex space-between fs-12">
                            <div className="fs-12 fw-b uc c-blue p-10 cur-pointer" onClick={noHandler}>
                                {userProfile('signout.back_msg')}
                            </div>
                            <div className="fs-12 fw-b uc bg-black c-white br-20 p-10-20 cur-pointer" onClick={proceedHandler}>
                                {userProfile('signout.title')}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
