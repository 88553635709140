import React from 'react';
import './memberitem.scss';
import { Slide } from 'react-reveal';
import Avatar from 'react-avatar';

// Assets
import RightArrIcon from '../../assets/caret-right.svg';

import { useTranslation } from 'react-i18next';

const MemberItem = ({ onClick, setMemberProfile, teamsNav, memberData }) => {
    const { t: member } = useTranslation();
    const { t } = useTranslation();
    const { verified, name, avatar, email, spaceCount, leadsCount, role, department } = memberData;
    const owner = role === 'OWNER';
    //Team conditions
    // const editTeamMemberDetailsCheck = () => {
    //     if (!verified) return false;
    //     if (userDetails?.owner) return true;
    //     else if (userDetails?.admin && adminGlobalSettings?.globalAdminEditTeamMemberDetails) return true;
    //     else if (!userDetails?.owner && !userDetails?.admin && memberGlobalSettings?.globalMemberEditTeamMemberDetails && !isMemberAdmin)
    //         return true;
    //     else return false;
    // };

    const bgColor = () => {
        if (owner) return 'rgb(68, 37, 245)';
        else if (!verified) return 'rgba(0, 0, 0, 0.3)';
        else return '#fff';
    };

    const memberType = () => {
        if (owner) return member('team.owner');
        if (!verified) return member('team.inv_pending');
        else if (department.toLowerCase() === 'all') return member('team.departement.sale_lettings');
        else if (department.toLowerCase() === 'sale') return member('team.departement.sale');
        else if (department.toLowerCase() === 'lettings') return member('team.departement.lettings');
    };

    return (
        <div
            className="member-item-wrapper"
            onClick={() => {
                setMemberProfile(true);
                onClick();
            }}
            style={{ backgroundColor: verified ? '#fff' : '#EDEDED' }}
        >
            <button className="setting-btn">
                <img src={RightArrIcon} alt="setting-icon"></img>
            </button>
            <div
                className="member-item"
                typeof="button"
                style={{ backgroundColor: verified ? 'rgba(0, 0, 0, 0.04)' : 'rgba(0, 0, 0, 0.04)' }}
            >
                <div className="member-item-avatar">
                    {avatar ? (
                        <img src={avatar} style={{ objectFit: 'cover' }} alt="avatar"></img>
                    ) : (
                        <Avatar
                            name={name}
                            value=""
                            size="56"
                            round={true}
                            color="black"
                            maxInitials={1}
                            textSizeRatio={2}
                            style={{ fontWeight: '700' }}
                        />
                    )}
                </div>
                <div className="member-item-details">
                    <div className="member-item-details-cont">
                        <h1>{name}</h1>
                        <span>{email}</span>
                    </div>
                    {/* className={isMemberAdmin && verified ? (owner ? 'bg-blue' : 'bg-black') : !verified ? 'bg-black' : 'bg-lgray'} */}
                    <span
                        className="member-item-details-type"
                        style={{
                            backgroundColor: bgColor(),
                            color: !verified || role.toLowerCase() === 'owner' ? '#fff' : 'rgba(0, 0, 0, 0.5)'
                        }}
                    >
                        {memberType()}
                        {/* {isMemberAdmin
                            ? verified
                                ? owner
                                    ? member('team.owner')
                                    : member('team.admin')
                                : member('team.inv_pending')
                            : verified
                            ? member('team.member')
                            : member('team.inv_pending')} */}
                    </span>
                </div>
                {teamsNav === 'AGENT' ? (
                    <Slide top duration={100}>
                        <div className="member-item-details-leads">
                            <div className="member-item-details-leads-item">
                                <h4>{spaceCount > 0 ? spaceCount : '-'}</h4>
                                <span>{member('spaces_title')}</span>
                            </div>
                            <div className="member-item-details-leads-div" />
                            <div className="member-item-details-leads-item">
                                <h4>{leadsCount > 0 ? leadsCount : '-'}</h4>
                                <span>{member('leads')}</span>
                            </div>
                        </div>
                    </Slide>
                ) : null}
            </div>
        </div>
    );
};

export default MemberItem;
