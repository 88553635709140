import React from 'react';
import { useTranslation } from 'react-i18next';
import Pulse from 'react-reveal/Pulse';
import ClickAwayContentWrapper from '../../components/ClickAwayContentWrapper';
import './popupdeleteskill.scss';
export default function PopUpDeleteSkill({ DeleteFile, videoId, fileNumber, setRemoveSkill }) {
    const { t: skillRemove } = useTranslation();
    return (
        <div className="popups-wrapper" style={{ zIndex: '101' }}>
            <ClickAwayContentWrapper onClose={() => setRemoveSkill(false)}>
                <Pulse duration={150}>
                    <div className="remove-card-wrapper">
                        <div className="form-wrapper" style={{ alignItems: 'flex-start' }}>
                            <h1 className="mt-20">{skillRemove('remove_skill.title')}</h1>
                            <p>{skillRemove('remove_skill.sub_title')}</p>
                            <div className="remove-btn-wrapper mt-30 w-100p">
                                <button onClick={() => setRemoveSkill(false)} className="go-back">
                                    {skillRemove('remove_skill.back')}
                                </button>
                                <button className="remove" onClick={() => DeleteFile(videoId, fileNumber)}>
                                    {skillRemove('remove_skill.delete')}
                                </button>
                            </div>
                        </div>
                    </div>
                </Pulse>
            </ClickAwayContentWrapper>
        </div>
    );
}
