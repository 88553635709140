import React from 'react';
import './dragtip.scss';
// Assets
import DragTipIcon from '../../assets/dragTip.png';
import CloseIcon from '../../assets/close-btn.png';
import { useTranslation } from 'react-i18next';
export default function DragTip({ setShowDragTip }) {
    const { t: dragTur } = useTranslation();
    return (
        <div className="dragtip-container">
            <div className="img-wrapper">
                <img src={DragTipIcon} className="drag-bg" alt="drag-icon" />
                <div className="drag-close" onClick={() => setShowDragTip(false)}>
                    <img src={CloseIcon} alt="close-icon" />
                </div>
            </div>
            <div className="content-wrapper">
                <p>{dragTur('video_drag.msg')}</p>
                <button className="drag-gt" onClick={() => setShowDragTip(false)}>
                    {dragTur('video_drag.btn_text')}
                </button>
            </div>
        </div>
    );
}
