import React, { useEffect, useContext, useState } from 'react';
import './statsdetail.scss';
import { postReq } from '../../utils/api';
import StatsPopUpGraph from '../../components/StatsPopUpGraph';
import { StatsContext } from '../../context';
import { DOMAIN } from '../../constants/base';
import EyeIcon from '../../assets/eye-icon.png';
import DollarIcon from '../../assets/dollar-action.png';
import RetentionIcon from '../../assets/stats-retention.png';
import RightArrIcon from '../../assets/right-arr.png';
import StopWatch from '../../assets/stopwatch.svg';
import BackImg from '../../assets/back.png';
import FallBackImg from '../../assets/skillPlaceholder.png';
import VideoStatsCard from '../../components/VideoStatsCard';
import { toolTipDateFormat, humanFormatDate, formatDate, getDateFormat } from '../../utils';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { DateRangePicker } from 'react-date-range';
import BedIcon from '../../assets/bed-icon.svg';
import BathIcon from '../../assets/bath-icon.svg';
import AreaIcon from '../../assets/area-icon.svg';
import StatsCalendar from '../../assets/stats-calendar.png';
import TrafficBar from '../../components/TrafficBar';
import { useTranslation } from 'react-i18next';
const DetailStats = ({ setShowDetailStats, openPopUp, closePopUp }) => {
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const [statsValue, dispatch] = useContext(StatsContext);
    const [skillList, setSkillList] = useState([]);
    const [topProductTraffic, setTopProductTraffic] = useState([]);
    const { t: detailStat } = useTranslation();

    let createdDate = new Date(statsValue.activeSpace.createdDate);

    const [minimumDate, setMinimumDate] = useState(getDateFormat(createdDate));
    // Loading
    const [loadingProductPerformance, setLoadingProductPerformance] = useState(false);
    const [loadingVideoPerformance, setLoadingVideoPerformance] = useState(false);
    const [loadingTrafficPerformance, setLoadingTrafficPerformance] = useState(false);
    const [loadingSkills, setLoadingSkills] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const endDateI = new Date(new Date().setHours(0, 0, 0, 0));
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const [range, setRange] = useState({
        startDate: today.getTime() - 7 * 24 * 60 * 60 * 1000,
        endDate: endDateI.getTime() + (24 * 60 * 60 * 1000 - 1000)
    });
    const [rangesInMillis, setRangesInMillis] = useState({
        startDate: today.getTime() - 7 * 24 * 60 * 60 * 1000,
        endDate: endDateI.getTime() + (24 * 60 * 60 * 1000 - 1000)
    });

    const [validRange, setValidRange] = useState(null);

    useEffect(() => {
        setMinimumDate(getDateFormat(createdDate));
        getProductPerformance();
        getProductSkillsList();
        getTrafficSourcesForProduct();
        getRetentionRate();
    }, []);

    function formatXAxis(tickItem) {
        if (typeof tickItem === 'string') {
            return tickItem.slice(8);
        }
    }

    // Graph Custom
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <div className="custom-tooltip-date">{toolTipDateFormat(payload[0].payload.date)}</div>
                    <h1>{detailStat('stats.Impressions')}</h1>
                    <p className="label">{payload[0].payload.count}</p>
                    <h1 style={{ color: '#00DC58' }}>{detailStat('stats.cta_action_rate')}</h1>
                    <p className="label" style={{ color: '#00DC58' }}>
                        {payload[0].payload.ctaClick}
                    </p>
                </div>
            );
        }

        return null;
    };

    const handleApplyFilter = () => {
        getProductPerformance();
        getProductSkillsList();
        getTrafficSourcesForProduct();
        getRetentionRate();
        setShowCalendar(false);
    };
    const selectionRange = {
        startDate: range.startDate,
        endDate: range.endDate
    };
    const handleDateSelect = (ranges) => {
        let startMillis = new Date(ranges.range1.startDate);
        startMillis = startMillis.getTime();
        let endMillis = new Date(ranges.range1.endDate);
        endMillis.setTime(
            endMillis.getTime() - endMillis.getHours() * 3600 * 1000 - endMillis.getMinutes() * 60 * 1000 - endMillis.getSeconds() * 1000
        );
        endMillis = endMillis.getTime();
        endMillis = endMillis + (24 * 60 * 60 * 1000 - 1000);
        setRangesInMillis({
            startDate: startMillis,
            endDate: endMillis
        });
        setRange(ranges.range1);
    };
    // click on video performance card
    const handleVideoPerformance = (skillId) => {
        openPopUp('video-performance', undefined);
        getSkillStatsByProduct(skillId);
    };

    async function getProductSkillsList() {
        if (rangesInMillis?.startDate > 1) {
            setLoadingVideoPerformance(true);
            setLoadingSkills(true);
            const url = `${DOMAIN}/v1/dashboard/space/stats/v3/video-performance/${statsValue?.activeSpace?.spaceId}`;
            const requestHeaders = {
                authorization: userToken.accessToken
            };
            const payload = {
                startMillis: rangesInMillis.startDate,
                endMillis: rangesInMillis.endDate
            };
            const skillsResponse = await postReq(
                {
                    url,
                    payload,
                    requestHeaders
                },
                setLoadingSkills
            );
            const { code, data = {} } = skillsResponse;
            if (code === 200) {
                setLoadingSkills(false);
                setLoadingVideoPerformance(false);
                dispatch({
                    type: 'SET_SPACE_VIDEO_PERFORMANCE',
                    payload: {
                        avgWatchTime: data.overallAvgWatchTime,
                        avgRetentionRate: data.overallRetentionRate,
                        skills: data.videoPerformance
                    }
                });
                setSkillList(data.videoPerformance);
            }
        }
    }

    // Product Performance for impression and cta action rate
    async function getProductPerformance() {
        if (rangesInMillis?.startDate > 1) {
            setLoadingProductPerformance(true);
            const url = `${DOMAIN}/v1/dashboard/space/stats/v3/space-performance/${statsValue?.activeSpace?.spaceId}`;
            const requestHeaders = {
                authorization: userToken.accessToken
            };
            const payload = {
                startMillis: rangesInMillis.startDate,
                endMillis: rangesInMillis.endDate
            };
            const productPerformanceResponse = await postReq({
                url,
                payload,
                requestHeaders
            });
            const { code, data = {} } = productPerformanceResponse;
            if (code === 200) {
                convertIntoMillis(data);
            }
        }
    }

    const convertIntoMillis = (data) => {
        let newData = data;
        if (data) {
            if (data?.overview?.length) {
                newData.overview.forEach(function (item, index) {
                    item.dateInMillis = humanFormatDate(item.dateInMillis);
                });
            }
            setLoadingProductPerformance(false);
            dispatch({
                type: 'SET_SPACE_IMPRESSION_CTA',
                payload: {
                    graphPlot: newData.overview,
                    impression: newData.totalViews,
                    ctaRate: newData.avgConversion,
                    name: newData.name
                }
            });
        }
    };

    // get skill details of the product to render graph
    async function getSkillStatsByProduct(skillId) {
        if (rangesInMillis?.startDate > 1) {
            const url = `${DOMAIN}/v1/dashboard/space/stats/v3/video-performance/${statsValue?.activeSpace?.spaceId}/skill/${skillId}`;
            const requestHeaders = {
                authorization: userToken.accessToken
            };
            const payload = {
                startMillis: rangesInMillis.startDate,
                endMillis: rangesInMillis.endDate
            };
            const skillStats = await postReq({
                url,
                payload,
                requestHeaders
            });
            const { code, data = {} } = skillStats;

            if (code === 200) {
                dispatch({
                    type: 'SET_SKILL_STATS_POPUP',
                    payload: {
                        name: data.videoPerformance[0].name,
                        poster: data.videoPerformance[0].poster,
                        views: data.videoPerformance[0].viewCount,
                        engagement: data.videoPerformance[0].engagement,
                        avgWatchTime: data.videoPerformance[0].avgWatchTime,
                        graphPlot: data.videoPerformance[0].secondPercentageArray,
                        endTimeInSecond: data.videoPerformance[0].endTimeInSecond
                    }
                });
            }
        }
    }
    // Traffic Sources
    async function getTrafficSourcesForProduct() {
        if (rangesInMillis?.startDate > 1) {
            setLoadingTrafficPerformance(true);
            const url = `${DOMAIN}/v1/dashboard/space/stats/v3/traffic-source/${statsValue?.activeSpace?.spaceId}`;
            const requestHeaders = {
                authorization: userToken.accessToken
            };
            const payload = {
                startMillis: rangesInMillis.startDate,
                endMillis: rangesInMillis.endDate
            };
            const trafficSources = await postReq({
                url,
                payload,
                requestHeaders
            });
            const { code, data = {} } = trafficSources;
            if (code === 200) {
                setLoadingTrafficPerformance(false);
                let sortedData = data.trafficRes.sort((s1, s2) => (s1.value > s2.value ? -1 : 1));
                dispatch({
                    type: 'SET_TRAFFIC_SOURCES_BY_SPACE',
                    payload: {
                        startDate: data.startMillis,
                        endDate: data.endMillis,
                        graphPlot: sortedData
                    }
                });
                if (data.trafficRes.length >= 4) {
                    setTopProductTraffic(sortedData.slice(0, 3));
                } else {
                    setTopProductTraffic(sortedData);
                }
            }
        }
    }

    // get retention rate stats
    async function getRetentionRate() {
        if (rangesInMillis?.startDate > 1) {
            const url = `${DOMAIN}/v1/dashboard/space/stats/v3/retention-rate/${statsValue?.activeSpace?.spaceId}`;
            const requestHeaders = {
                authorization: userToken.accessToken
            };
            const payload = {
                startMillis: rangesInMillis.startDate,
                endMillis: rangesInMillis.endDate
            };
            const retentionRateByProduct = await postReq({
                url,
                payload,
                requestHeaders
            });
            const { code, data = {} } = retentionRateByProduct;
            if (code === 200) {
                dispatch({
                    type: 'SET_RETENTION_RATE_BY_SPACE',
                    payload: {
                        value: data.retentionRate === null ? 0 : data.retentionRate,
                        graphPlot: data.overview,
                        startDate: data.startMillis,
                        endDate: data.endMillis
                    }
                });
            }
        }
    }

    return (
        <div className="d_stats-container">
            <div className="d_stats-container__header">
                <div className="details">
                    <div
                        className="d_stats-hide"
                        onClick={() => {
                            setShowDetailStats(false);
                        }}
                    >
                        <img src={BackImg} alt="" />
                    </div>
                    <div className="product-img">
                        {statsValue?.activeSpace?.poster.length > 0 ? (
                            <img src={statsValue?.activeSpace?.poster} alt="" />
                        ) : (
                            <img src={FallBackImg} alt="" />
                        )}
                    </div>
                    <div className="product-name">
                        <div className="value">{statsValue?.activeSpace?.spaceName}</div>
                        <div className="space-props">
                            {statsValue?.activeSpace?.bedrooms !== 0 && (
                                <div className="space-comp">
                                    <img src={BedIcon} alt="Bed" />
                                    <span className="text">{statsValue?.activeSpace?.bedrooms}</span>
                                </div>
                            )}
                            {statsValue?.activeSpace?.bathrooms !== 0 && (
                                <div className="space-comp">
                                    <img src={BathIcon} alt="Bath" />
                                    <span className="text">{statsValue?.activeSpace?.bathrooms}</span>
                                </div>
                            )}
                            {statsValue?.activeSpace?.dimension !== 0 && (
                                <div className="space-comp">
                                    <img src={AreaIcon} alt="Area" />
                                    <span className="text">{statsValue?.activeSpace?.dimension?.toLocaleString()} </span>
                                    <span className="text ml-2">
                                        {statsValue?.activeSpace?.dimensionUnit &&
                                        statsValue?.activeSpace?.dimensionUnit.toLowerCase() === 'sqft'
                                            ? 'ft2'
                                            : 'mt2'}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <button onClick={() => setShowCalendar(!showCalendar)} className="stats-popup-calendar-btn">
                    <img src={StatsCalendar} alt="icon"></img>
                    {range.startDate && range.endDate && `${formatDate(range.startDate, true)} - ${formatDate(range.endDate, true)}`}
                </button>
                {showCalendar && (
                    <div className="stats-calendar-popup">
                        <DateRangePicker
                            maxDate={new Date()}
                            minDate={new Date(minimumDate)}
                            disabledDates={[new Date(minimumDate)]}
                            ranges={[selectionRange]}
                            onChange={handleDateSelect}
                            className="stats-calendar"
                            rangeColors={['black']}
                            color="black"
                        />
                        <div className="stats-calendar-apply">
                            <div className="stats-calendar-apply-btn-wrapper">
                                <button
                                    onClick={() => {
                                        setShowCalendar(false);
                                        if (validRange) {
                                            setRange(validRange);
                                            setValidRange(null);
                                        }
                                    }}
                                    className="stats-calendar-apply-btn"
                                >
                                    {detailStat('close')}
                                </button>
                                <button onClick={handleApplyFilter} className="stats-calendar-apply-btn-fill">
                                    {detailStat('apply_filter')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="d_stats-container__grid">
                <div className="card-chart performance">
                    {loadingProductPerformance ? (
                        <div className="loading-product-performance">
                            <div className="shimmerBG loading-product-performance-topbar"></div>
                            <div className="loading-product-performance-items-wrapper">
                                <div className="loading-product-performance-item">
                                    <div className="shimmerBG loading-product-performance-circle"></div>
                                    <div className="loading-product-performance-rect-wrapper">
                                        <div className="shimmerBG loading-product-performance-rect-one"></div>
                                        <div className="shimmerBG loading-product-performance-rect-two"></div>
                                    </div>
                                </div>
                                <div className="loading-product-performance-item">
                                    <div className="shimmerBG loading-product-performance-circle"></div>
                                    <div className="loading-product-performance-rect-wrapper">
                                        <div className="shimmerBG loading-product-performance-rect-one"></div>
                                        <div className="shimmerBG loading-product-performance-rect-two"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="shimmerBG loading-product-performance-bottombar"></div>
                        </div>
                    ) : (
                        <>
                            {' '}
                            <div className="title-one">{detailStat('stats.space.space_performance')}</div>
                            <div className="card-chart__params">
                                <div className="param">
                                    <div className="param-icon">
                                        <img src={EyeIcon} alt="icon" />
                                    </div>
                                    <div className="param-details">
                                        <div className="param-stat">{statsValue?.impressionCTARate?.impression}</div>
                                        <div className="param-name">{detailStat('stats.Impressions')}</div>
                                    </div>
                                </div>
                                <div className="param">
                                    <div className="param-icon">
                                        <img src={DollarIcon} style={{ width: '20px', height: '20px' }} alt="icon" />
                                    </div>
                                    <div className="param-details">
                                        <div className="param-stat">
                                            {statsValue?.impressionCTARate?.ctaRate}
                                            <span className="perc">%</span>
                                        </div>
                                        <div className="param-name">{detailStat('stats.cta_action_rate')}</div>
                                    </div>
                                </div>
                                <div className="legend-wrap">
                                    <div className="legend one">{detailStat('stats.Impressions')}</div>
                                    <div className="legend two">{detailStat('stats.cta_action_rate')}</div>
                                </div>
                            </div>
                            <div className="line-graph-wrapper">
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={statsValue?.impressionCTARate?.graphPlot}>
                                        <CartesianGrid strokeDasharray="0" vertical={false} />
                                        <XAxis
                                            dataKey="dateInMillis"
                                            strokeWidth={0}
                                            tick={{ fontSize: 10, fontWeight: '600' }}
                                            tickFormatter={formatXAxis}
                                            allowDataOverflow={true}
                                            interval="preserveStartEnd"
                                        />
                                        <YAxis strokeWidth={0} dataKey="count" tick={{ fontSize: 10, fontWeight: '600' }} />
                                        {statsValue?.impressionCTARate?.ctaRate > 0 || statsValue?.impressionCTARate?.impression > 0 ? (
                                            <Tooltip
                                                cursor={{ fill: 'transparent' }}
                                                offset={0}
                                                animationEasing={'ease-in-out'}
                                                content={<CustomTooltip />}
                                            />
                                        ) : (
                                            ''
                                        )}
                                        {statsValue?.impressionCTARate?.ctaRate > 0 || statsValue?.impressionCTARate?.impression > 0 ? (
                                            <Line type="monotone" dataKey="count" stroke="#000" activeDot={{ r: 4 }} />
                                        ) : (
                                            ''
                                        )}
                                        {statsValue?.impressionCTARate?.ctaRate > 0 || statsValue?.impressionCTARate?.impression > 0 ? (
                                            <Line type="monotone" dataKey="ctaClick" stroke="#00DC58" activeDot={{ r: 4 }} />
                                        ) : (
                                            ''
                                        )}
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </>
                    )}
                </div>
                <div className="card-chart video-performance">
                    <div className="title-one">{detailStat('stats.video_performance')}</div>
                    <div className="dstats-card-wrapper">
                        <div
                            className="dstats-card"
                            onClick={() => openPopUp('space-retention-rate', undefined)}
                            style={{ cursor: 'pointer' }}
                        >
                            {loadingVideoPerformance ? (
                                <div className="video-performance-loading">
                                    <div className="shimmerBG video-performance-loading-circle"></div>
                                    <div>
                                        <div className="shimmerBG video-performance-loading-rect-one"></div>
                                        <div className="shimmerBG video-performance-loading-rect-two"></div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {' '}
                                    <div className="dstats-card__header">
                                        <div className="icon">
                                            <img src={RetentionIcon} alt="icon" />
                                        </div>
                                        <div className="icon more-icon">
                                            <img src={RightArrIcon} alt="icon" />
                                        </div>
                                    </div>
                                    <div className="param-details">
                                        <div className="param-stat">
                                            {statsValue?.spaceVideoPerformance?.avgRetentionRate}
                                            <span className="perc">%</span>
                                        </div>
                                        <div className="param-name">{detailStat('stats.avg_rtn_rate')}</div>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="dstats-card">
                            {loadingVideoPerformance ? (
                                <div className="video-performance-loading">
                                    <div className="shimmerBG video-performance-loading-circle"></div>
                                    <div>
                                        <div className="shimmerBG video-performance-loading-rect-one"></div>
                                        <div className="shimmerBG video-performance-loading-rect-two"></div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {' '}
                                    <div className="dstats-card__header">
                                        <div className="icon">
                                            <img src={StopWatch} alt="icon" />
                                        </div>
                                    </div>
                                    <div className="param-details">
                                        <div className="param-stat">{statsValue?.spaceVideoPerformance?.avgWatchTime} sec</div>
                                        <div className="param-name">{detailStat('stats.avg_watch_time')}</div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <VideoStatsCard
                        skillsLoading={loadingSkills}
                        skills={skillList.sort((l1, l2) => l1.retentionRate - l2.retentionRate)}
                        setSkills={setSkillList}
                        showCompleteStats={handleVideoPerformance}
                    ></VideoStatsCard>
                </div>
                <div
                    className="card-chart traffic-sources"
                    onClick={() => {
                        openPopUp('traffic-source', true);
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    {loadingTrafficPerformance ? (
                        <div className="traffic-performance-loading">
                            <div className="shimmerBG traffic-performance-loading-rect-one"></div>
                            <div className="shimmerBG traffic-performance-loading-rect-two"></div>
                        </div>
                    ) : (
                        <>
                            {' '}
                            <div className="title-one">{detailStat('stats.traffic_sources')}</div>
                            <div className="traffic-sources__more">
                                <img src={RightArrIcon} alt="icon" />
                            </div>
                            <div className="graph-wrapper">
                                <TrafficBar bars={topProductTraffic} />
                            </div>
                        </>
                    )}
                </div>
                {statsValue.showPopUp && <StatsPopUpGraph onBack={closePopUp}></StatsPopUpGraph>}
            </div>
        </div>
    );
};

export default DetailStats;
