import React from "react";
import TopBar from "../../components/TopBar";
import MySettings from "../../containers/MySettings";

function Settings(props) {
  return (
    <>
      <TopBar></TopBar>
      <MySettings />
    </>
  );
}

export default Settings;
