import React from 'react';
import SigninComponent from '../../containers/Signin';

function Signin(props) {
  return (
    <>
      <SigninComponent />
    </>
  );
}

export default Signin;
