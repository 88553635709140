import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ButtonAtom from '../ButtonAtom';
import Input from '../Input';
import NoteComponent from '../NoteComponent';
import { DOMAIN } from '../../constants/base';
import { putReq } from '../../utils/api';

import './companyfield.scss';
import { getSessionStorage, setSessionStorage } from '../../utils';

export default function CompanyField(props) {
    const { setCompanyName, activeStep, setActiveStep } = props;
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const userDetails = getSessionStorage('ss-user-spaces') || {};
    const [value, setValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    // const [validEmail, setValidEmail] = useState(true);
    const { t } = useTranslation();

    const updateCompanyName = async (type) => {
        setIsLoading(true);
        let url = `${DOMAIN}/v1/dashboard/account`;
        if (type === 'invite') {
        }
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        if (value) {
            const payload = {
                company: value
            };

            const res = await putReq({ url, requestHeaders, payload }, setIsLoading);
            const { code, data } = res;
            if (code === 200) {
                setIsLoading(false);
                setActiveStep(activeStep + 1);
                setSessionStorage({
                    key: 'ss-user-spaces',
                    value: {
                        ...userDetails,
                        company: value
                    }
                });
            }
        }
    };

    return (
        <>
            <h1 className="component-title">Name your team</h1>
            <div className="company-name-container">
                <Input
                    type="text"
                    label={t('team.company.label')}
                    labelStyle="uc mb-5"
                    placeholder={t('team.company.placeholder')}
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value);
                        setCompanyName((prev) => value);
                    }}
                    customHeight="44px"
                />
            </div>
            <NoteComponent message={t('team.company.note')} />
            <div className="bottom-step-wrapper">
                <div className="btn-container">
                    <ButtonAtom
                        label="continue"
                        buttonStyle="custom-btn"
                        onClick={updateCompanyName}
                        disabled={value.length ? false : true}
                        showLoader={isLoading}
                    ></ButtonAtom>
                </div>
            </div>
        </>
    );
}
