import { SET_GLOBAL_ADMIN_SETTINGS, SET_GLOBAL_MEMBER_SETTINGS } from './types.js';

const editGlobalAdminSettings = (
    {
        globalAdminSeeEntirePortfolio,
        globalAdminManageTeamPortfolio,
        globalAdminCreateSpace,
        globalAdminSendInvitations,
        globalAdminEditTeamMemberDetails,
        globalAdminEditAccountPermissions,
        globalAdminSeePlanDetails,
        globalAdminManagePlan,
        globalAdminWidgetCustomisation,
        globalInviteMoreAdmins,
        globalAdminGeneralSettings
    },
    state
) => {
    const newAdminSettings = {
        ...state,
        adminGlobalSettings: {
            globalAdminSeeEntirePortfolio,
            globalAdminManageTeamPortfolio,
            globalAdminCreateSpace,
            globalAdminSendInvitations,
            globalAdminEditTeamMemberDetails,
            globalAdminEditAccountPermissions,
            globalAdminSeePlanDetails,
            globalAdminManagePlan,
            globalAdminWidgetCustomisation,
            globalInviteMoreAdmins,
            globalAdminGeneralSettings
        }
    };
    return {
        ...newAdminSettings
    };
};

const editGlobalMemberSettings = (
    {
        globalMemberSeeEntirePortfolio,
        globalMemberManageTeamPortfolio,
        globalMemberCreateSpace,
        globalMemberSendInvitations,
        globalMemberEditTeamMemberDetails,
        globalMemberSeePlanDetails,
        globalMemberManagePlan
    },
    state
) => {
    const newMemberSettings = {
        ...state,
        memberGlobalSettings: {
            globalMemberSeeEntirePortfolio,
            globalMemberManageTeamPortfolio,
            globalMemberCreateSpace,
            globalMemberSendInvitations,
            globalMemberEditTeamMemberDetails,
            globalMemberSeePlanDetails,
            globalMemberManagePlan
        }
    };
    return {
        ...newMemberSettings
    };
};

export default (state, action) => {
    switch (action.type) {
        case SET_GLOBAL_ADMIN_SETTINGS:
            return editGlobalAdminSettings(action.payload, state);
        case SET_GLOBAL_MEMBER_SETTINGS:
            return editGlobalMemberSettings(action.payload, state);
        default:
            return state;
    }
};
