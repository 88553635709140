import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getSessionStorage } from '../../utils';
import Select from '../Select';
import CTAList from '../CTAList';
import EditCTAs from '../EditCTAs';

import { DOMAIN } from '../../constants/base';
import { putReq } from '../../utils/api';
import './manageagent.scss';

import Bulb from '../../assets/info-bulb.svg';
import CTAPhone from '../../assets/phone_number.png';
import CTAMail from '../../assets/mail.png';
import CTACal from '../../assets/calendar-circle.svg';
import CTAWhatsapp from '../../assets/whatsapp-circle.svg';
import CTATelegram from '../../assets/telegram-circle.svg';
import CTAMessenger from '../../assets/messager-circle.svg';
import CTACustomLink from '../../assets/custom-link.png';
import CTATypeForm from '../../assets/typeform-icon.svg';

export default function ManageAgent(props) {
    const {
        canChangeAssigne,
        availableAgents,
        availableTemplates,
        setAvailableTemplates,
        setSpaceUpdated,
        setCurrentStep,
        selectedTemplate,
        setSelectedTemplate,
        agentAssigned,
        setAssigneId,
        setAssigneChanged,
        setAgentAssigned,
        assigneId,
        defaultCtas,
        setIsNewTemplate,
        currentCta,
        setCurrentCta,
        spaceId,
        setIsUpdated,
        ctaTemplateName,
        setCtaTemplateName,
        isNewTemplate,
        currentStep,
        setPreventUpdates,
        assigneChanged,
        isSectionEdit,
        setEditSpaceSectionPopUp
    } = props;

    const userDetails = getSessionStorage('ss-user-spaces') || {};
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const [assignedAgentProfileDetails, setAssignedAgentProfileDetails] = useState(agentAssigned);
    const { name, avatar, id, role } = userDetails;
    const admin = role === 'ADMIN',
        owner = role === 'OWNER';
    const member = !(admin || owner);
    const [templateChanged, setTemplateChanged] = useState(false);

    const [ctaLicenseNo, setCtaLicenseNo] = useState({
        value: '',
        enable: false,
        order: 10
    });
    const [ctaWelcomeMsg, setCtaWelcomeMsg] = useState({
        value: '',
        enable: false,
        order: 9
    });
    const [ctaEmail, setCtaEmail] = useState({
        icon: CTAMail,
        value: '',
        enable: false,
        order: 8
    });
    const [ctaPhone, setCtaPhone] = useState({
        icon: CTAPhone,
        value: {},
        enable: false,
        order: 1
    });
    const [ctaWhatsapp, setCtaWhatsapp] = useState({
        icon: CTAWhatsapp,
        value: {},
        enable: false,
        order: 2
    });
    const [ctaMessenger, setCtaMessenger] = useState({
        icon: CTAMessenger,
        value: '',
        enable: false,
        order: 4
    });
    const [ctaTelegram, setCtaTelegram] = useState({
        icon: CTATelegram,
        value: '',
        enable: false,
        order: 3
    });
    const [ctaCustomButton, setCtaCustomButton] = useState({
        icon: CTACustomLink,
        value: '',
        name: '',
        enable: false,
        order: 6
    });

    const [ctaTypeform, setCtaTypeform] = useState({
        icon: CTATypeForm,
        formId: '',
        buttonText: '',
        enable: false,
        order: 7
    });

    const [ctaCal, setCtaCal] = useState({
        icon: CTACal,
        value: '',
        enable: false,
        order: 5
    });

    // const [backButtonClickedWhileEditingCtas, setBackButtonClickedWhileEditingCtas] = useState(false);
    // const [ctaType]

    const { t } = useTranslation();

    // use effect to update assigned agent profile details
    useEffect(() => {
        let assignedAgent = availableAgents.find((agent) => agent.agentId === assigneId);
        if (assignedAgent?.agentId) {
            setAssignedAgentProfileDetails({
                name: assignedAgent.name,
                avatar: assignedAgent.avatar,
                id: assignedAgent.agentId
            });
        }
    }, [assigneId]);

    useEffect(() => {
        if (isNewTemplate) {
            setCtaTemplateName('');
            // resetContacts();
        }
    }, [isNewTemplate]);

    useEffect(() => {
        resetContacts();
        for (let cta of defaultCtas) {
            let ctaKey = Object.keys(cta)[0];
            switch (ctaKey) {
                case 'email':
                    if (cta[ctaKey] !== null) setCtaEmail({ ...ctaEmail, value: cta[ctaKey], enable: true });
                    break;
                case 'phoneNumber':
                    if (cta[ctaKey].phone !== null && cta[ctaKey].phone !== undefined)
                        setCtaPhone({ ...ctaPhone, value: { ...cta[ctaKey] }, enable: true });
                    break;
                case 'whatsAppPhoneNumber':
                    if (cta[ctaKey].phone !== null && cta[ctaKey].phone !== undefined)
                        setCtaWhatsapp({ ...ctaWhatsapp, value: { ...cta[ctaKey] }, enable: true });
                    break;
                case 'licenceNo':
                    if (cta[ctaKey] !== null) setCtaLicenseNo({ value: cta[ctaKey], enable: true });
                    break;
                case 'message':
                    if (cta[ctaKey] !== null) setCtaWelcomeMsg({ value: cta[ctaKey], enable: true });
                    break;
                case 'telegram':
                    if (cta[ctaKey] !== null) setCtaTelegram({ ...ctaTelegram, value: cta[ctaKey], enable: true });
                    break;
                case 'facebookMessenger':
                    if (cta[ctaKey] !== null) setCtaMessenger({ ...ctaMessenger, value: cta[ctaKey], enable: true });
                    break;
                case 'calendar':
                    if (cta[ctaKey] !== null) setCtaCal({ ...ctaCal, value: cta[ctaKey], enable: true });
                    break;
                case 'customButton':
                    if (cta[ctaKey]?.value && cta[ctaKey]?.name) {
                        setCtaCustomButton({ ...ctaCustomButton, value: cta[ctaKey].value, name: cta[ctaKey].name, enable: true });
                    }
                    break;
                case 'typeForm':
                    if (cta[ctaKey]?.buttonText && cta[ctaKey]?.formId) {
                        setCtaTypeform({ ...ctaTypeform, buttonText: cta[ctaKey].buttonText, formId: cta[ctaKey].formId, enable: true });
                    }
                    break;
                default:
                    break;
            }
        }
    }, [defaultCtas]);

    useEffect(() => {
        if (ctaTelegram.value !== null) updateCtas('telegram', ctaTelegram);
        if (ctaEmail.value !== null) updateCtas('email', ctaEmail);
        if (ctaCal.value !== null) updateCtas('calendar', ctaCal);
        if (ctaMessenger.value !== null) updateCtas('messenger', ctaMessenger);
        if (ctaTypeform.buttonText !== null && ctaTypeform.formId !== null) updateCtas('typeform', ctaTypeform);
        if (ctaCustomButton.value !== null && ctaCustomButton.name !== null) updateCtas('customlink', ctaCustomButton);
        if (ctaWhatsapp.value?.phone !== null) updateCtas('whatsapp', ctaWhatsapp);
        if (ctaPhone.value?.phone !== null) updateCtas('phone', ctaPhone);
        if (ctaWelcomeMsg.value !== null) updateCtas('message', ctaWelcomeMsg);
        if (ctaLicenseNo.value !== null) updateCtas('license', ctaLicenseNo);
    }, [ctaEmail, ctaTelegram, ctaMessenger, ctaCal, ctaLicenseNo, ctaWelcomeMsg, ctaCustomButton, ctaTypeform, ctaWhatsapp, ctaPhone]);

    const updateSpace = async () => {
        if (spaceId && (assigneChanged || templateChanged)) {
            const url = `${DOMAIN}/v1/dashboard/space/${spaceId}`;
            let payload = {};
            if ((assigneId && assigneChanged) || selectedTemplate?.templateId === null) {
                payload = { ...payload, agentId: assigneId };
            }
            if (selectedTemplate.templateId !== null) {
                payload = { ...payload, templateId: selectedTemplate.templateId };
            } else if (selectedTemplate.templateId === null) {
                payload = { ...payload, templateId: '' };
            }
            const requestHeaders = {
                authorization: userToken.accessToken
            };
            const createResponse = await putReq({
                url,
                payload,
                requestHeaders
            });
            const { code } = createResponse;
            if (code === 200) {
                if (isSectionEdit) {
                    setEditSpaceSectionPopUp(false);
                    return;
                }
                setCurrentStep('4');
                setAssigneChanged(false);
                setTemplateChanged(false);
            }
        } else {
            if (isSectionEdit) {
                setEditSpaceSectionPopUp(false);
                return;
            }
            setCurrentStep('4');
        }
    };
    const resetContacts = () => {
        setCtaCal({ ...ctaCal, value: '', enable: false });
        setCtaLicenseNo({ value: '', enable: false });
        setCtaWelcomeMsg({ value: '', enable: false });
        setCtaEmail({ ...ctaEmail, value: '', enable: false });
        setCtaTelegram({ ...ctaTelegram, value: '', enable: false });
        setCtaMessenger({ ...ctaMessenger, value: '', enable: false });
        setCtaTypeform({ ...ctaTypeform, formId: '', buttonText: '', enable: false });
        setCtaCustomButton({ ...ctaCustomButton, value: '', name: '', enable: false });
        setCtaWhatsapp({ ...ctaWhatsapp, value: '', enable: false });
        setCtaPhone({ ...ctaPhone, value: '', enable: false });
        setCurrentCta({});
    };

    const updateCtas = (key, value) => {
        setCurrentCta((prevState) => {
            return {
                ...prevState,
                [key]: { ...value }
            };
        });
    };

    return (
        <>
            {currentStep === '3' && (
                <>
                    <div className="manage-agent-wrapper">
                        <div className="assigned-container">
                            <label>{t('agent.managing_agent_title')}</label>
                            <Select
                                defaultOption={t('rent')}
                                optionsList={availableAgents}
                                name={name}
                                avatar={avatar && avatar.length > 0 ? avatar : null}
                                type="assigne"
                                agentAssigned={agentAssigned}
                                setAssigneId={setAssigneId}
                                setAssigneChanged={setAssigneChanged}
                                setAgentAssigned={setAgentAssigned}
                                member={member}
                                canChangeAssigne={canChangeAssigne}
                                loggedInUserId={id}
                                addSpace={true}
                                setSpaceUpdated={setSpaceUpdated}
                                setSelectedTemplate={setSelectedTemplate}
                                selectedTemplate={selectedTemplate}
                                allowDropDown={canChangeAssigne ? true : false}
                            ></Select>
                        </div>

                        <div className="template-listings">
                            <label>{t('agent.templates.title')}</label>
                            <Select
                                defaultOption={selectedTemplate && selectedTemplate?.templateName}
                                optionsList={availableTemplates}
                                type="template"
                                loggedInUserId={id}
                                setIsNewTemplate={setIsNewTemplate}
                                setCurrentScreen={setCurrentStep}
                                setSelectedTemplate={setSelectedTemplate}
                                setSpaceUpdated={setSpaceUpdated}
                                setTemplateChanged={setTemplateChanged}
                                selectedTemplate={selectedTemplate}
                                allowDropDown={true}
                            />
                        </div>

                        {defaultCtas.length > 0 && (
                            <div className="agents-info">
                                <div className="agents-note">
                                    <img src={Bulb} alt="bulb" />
                                    {t('cta.cta_note')}
                                </div>
                                <div className="available-contacts">
                                    <div className="sub-title">{t('contacts')}</div>
                                    <div className="available-contacts-cta">
                                        <div className="contacts-list">
                                            <CTAList ctas={currentCta} excludeCTA={true}></CTAList>
                                        </div>

                                        <div
                                            className="edit-contacts"
                                            onClick={() => {
                                                setPreventUpdates(true);
                                                setCurrentStep('6');
                                            }}
                                        >
                                            {selectedTemplate?.templateId ? t('edit') + ' ' + t('template_single') : 'edit contatcs'}
                                        </div>
                                    </div>
                                </div>

                                {currentCta?.license?.value ? (
                                    <div className="agents-msg">
                                        <div className="welcome-msg-title">{t('agent.lc_no.label')}</div>
                                        <div className="msg">{currentCta?.license?.value}</div>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {currentCta?.message?.value ? (
                                    <div className="agents-msg">
                                        <div className="welcome-msg-title">{t('agent.wl_msg.label')}</div>
                                        <div className="msg">{currentCta?.message?.value}</div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        )}
                    </div>
                    <div className="bottom-wrapper">
                        <button
                            onClick={() => {
                                updateSpace();
                            }}
                        >
                            {t('continue')}
                        </button>
                    </div>
                </>
            )}
            {currentStep === '6' && (
                <EditCTAs
                    isAgentContacts={selectedTemplate?.templateName === 'Agent’s default contact' ? true : false}
                    customPaddingTop={selectedTemplate?.templateId ? '' : '42px'}
                    profileAvatar={assignedAgentProfileDetails.avatar}
                    profileName={assignedAgentProfileDetails.name}
                    assigneId={assignedAgentProfileDetails.id}
                    ctas={currentCta}
                    setIsUpdated={setIsUpdated}
                    ctaTemplateName={ctaTemplateName}
                    setCtaTemplateName={setCtaTemplateName}
                    isNewTemplate={isNewTemplate}
                    setIsNewTemplate={setIsNewTemplate}
                    setTemplateChanged={setTemplateChanged}
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                    availableTemplates={availableTemplates}
                    setAvailableTemplates={setAvailableTemplates}
                    setCurrentStep={setCurrentStep}
                    spaceId={spaceId}
                    setAssigneChanged={setAssigneChanged}
                    setCurrentCta={setCurrentCta}
                    ctaEmail={ctaEmail}
                    setCtaEmail={setCtaEmail}
                    ctaLicenseNo={ctaLicenseNo}
                    setCtaLicenseNo={setCtaLicenseNo}
                    ctaWelcomeMsg={ctaWelcomeMsg}
                    setCtaWelcomeMsg={setCtaWelcomeMsg}
                    ctaWhatsapp={ctaWhatsapp}
                    setCtaWhatsapp={setCtaWhatsapp}
                    ctaPhone={ctaPhone}
                    setCtaPhone={setCtaPhone}
                    ctaTelegram={ctaTelegram}
                    setCtaTelegram={setCtaTelegram}
                    ctaMessenger={ctaMessenger}
                    setCtaMessenger={setCtaMessenger}
                    ctaCustomButton={ctaCustomButton}
                    setCtaCustomButton={setCtaCustomButton}
                    ctaTypeform={ctaTypeform}
                    ctaCal={ctaCal}
                    setCtaCal={setCtaCal}
                    setCtaTypeform={setCtaTypeform}
                    setPreventUpdates={setPreventUpdates}
                    isUserProfileEdit={assignedAgentProfileDetails.id === userDetails.id ? true : false}
                ></EditCTAs>
            )}
        </>
    );
}
