/* eslint-disable */
export const isEmpty = (obj) => {
    if (obj instanceof Date) {
        return false;
    }
    if (obj == null || obj === 'undefined') {
        return true;
    }

    const isNumber = (value) => Object.prototype.toString.call(value) === '[object Number]';
    const isNaN = (value) => isNumber(value) && value.toString() === 'NaN';

    if (isNumber(obj)) {
        return isNaN(obj);
    }

    if (obj.length > 0) {
        return false;
    }
    if (obj.length === 0) {
        return true;
    }

    if (typeof obj !== 'object') {
        return true;
    }

    const keys = Object.keys(obj);
    for (let i = 0, key = keys[i]; i < keys.length; i += 1) {
        if (hasOwnProperty.call(obj, key)) {
            return false;
        }
    }
    return true;
};

export const validateEmail = (email) => {
    const reg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,8}$/g;
    if (!reg.test(email)) {
        return false;
    }
    return true;
};

export const validateCompany = (website) => {
    const reg = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    if (!reg.test(website)) {
        return false;
    }
    return true;
};

export const apiPostFormDataHelper = async ({ url, payload = {}, requestHeaders }) => {
    try {
        let headers = {};
        if (!isEmpty(requestHeaders)) {
            headers = {
                ...requestHeaders
            };
        }

        const responseData = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin', // include, *same-origin, omit
            headers,
            referrerPolicy: 'no-referrer',
            body: payload,
            redirect: 'follow'
        });
        return responseData.json();
    } catch (e) {
        return e || {};
    }
};
export const apiPutFormDataHelper = async ({ url, payload = {}, requestHeaders }) => {
    try {
        let headers = {};
        if (!isEmpty(requestHeaders)) {
            headers = {
                ...requestHeaders
            };
        }

        const responseData = await fetch(url, {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin', // include, *same-origin, omit
            headers,
            referrerPolicy: 'no-referrer',
            body: payload,
            redirect: 'follow'
        });
        return responseData.json();
    } catch (e) {
        return e || {};
    }
};
export const apiPostHelper = async ({ url, payload = {}, contentType, requestHeaders }) => {
    try {
        let contentTypeText = 'application/json';
        if (contentType) {
            contentTypeText = contentType;
        }
        let headers = {
            Accept: 'application/json',
            'Content-Type': contentTypeText,
            'Accept-Language': localStorage.getItem('ss-lang')
            // "Access-Control-Allow-Origin": "*"
        };
        if (!isEmpty(requestHeaders)) {
            headers = {
                ...headers,
                ...requestHeaders
            };
        }
        // const responseData = await axios.post(URL, PAYLOAD, {
        //   headers
        // });
        const responseData = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin', // include, *same-origin, omit
            headers,
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(payload)
        });
        return responseData.json();
    } catch (e) {
        return e || {};
    }
};
export const apiPutHelper = async ({ url, payload = {}, contentType, requestHeaders }) => {
    try {
        let contentTypeText = 'application/json';
        if (contentType) {
            contentTypeText = contentType;
        }
        let headers = {
            Accept: 'application/json',
            'Content-Type': contentTypeText,
            'Accept-Language': localStorage.getItem('ss-lang')
        };
        if (!isEmpty(requestHeaders)) {
            headers = {
                ...headers,
                ...requestHeaders
            };
        }
        // const responseData = await axios.post(URL, PAYLOAD, {
        //   headers
        // });
        const responseData = await fetch(url, {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin', // include, *same-origin, omit
            headers,
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(payload)
        });
        return responseData.json();
    } catch (e) {
        return e || {};
    }
};

export const apiGetHelper = async ({ url, payload = {}, contentType, requestHeaders }) => {
    try {
        let contentTypeText = 'application/json';
        if (contentType) {
            contentTypeText = contentType;
        }
        let headers = {
            Accept: 'application/json',
            'Content-Type': contentTypeText,
            'Accept-Language': localStorage.getItem('ss-lang')
        };
        if (!isEmpty(requestHeaders)) {
            headers = {
                ...headers,
                ...requestHeaders
            };
        }
        // const responseData = await axios.post(URL, PAYLOAD, {
        //   headers
        // });
        const responseData = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin', // include, *same-origin, omit
            headers,
            referrerPolicy: 'no-referrer'
        });
        return responseData.json();
    } catch (e) {
        return e.response || {};
    }
};

export const apiDeleteHelper = async ({ url, payload = {}, contentType, requestHeaders }) => {
    try {
        let contentTypeText = 'application/json';
        if (contentType) {
            contentTypeText = contentType;
        }
        let headers = {
            Accept: 'application/json',
            'Content-Type': contentTypeText,
            'Accept-Language': localStorage.getItem('ss-lang')
            // "Access-Control-Allow-Origin": "*"
        };
        if (!isEmpty(requestHeaders)) {
            headers = {
                ...headers,
                ...requestHeaders
            };
        }
        // const responseData = await axios.post(URL, PAYLOAD, {
        //   headers
        // });
        const responseData = await fetch(url, {
            method: 'DELETE',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin', // include, *same-origin, omit
            headers,
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(payload)
        });
        return responseData.json();
    } catch (e) {
        return e || {};
    }
};
export const getParameterByName = (name) => {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const setSessionStorage = (data) => {
    if (typeof Storage !== 'undefined') {
        let { key, value, expiry } = data;
        const now = new Date().getTime();
        const item = {
            value,
            expiry: expiry ? now + expiry * 60 * 1000 : undefined
        };
        sessionStorage.setItem(key, JSON.stringify(item));
    } else {
        console.log('No Web Storage support...');
    }
};
export const getSessionStorage = (key) => {
    if (typeof Storage !== 'undefined') {
        const itemStr = sessionStorage.getItem(key);
        if (!itemStr) {
            return;
        }
        const item = JSON.parse(itemStr);
        const { value, expiry } = item;
        const now = new Date();
        if (expiry && now.getTime() > expiry) {
            sessionStorage.removeItem(key);
            return;
        }
        return value;
    } else {
        console.log('No Web Storage support...');
    }
};
export const removeSessionStorage = (key) => {
    if (typeof Storage !== 'undefined') {
        return sessionStorage.removeItem(key);
    } else {
        console.log('No Web Storage support...');
    }
};

let timer;
export const debounce = (fn, delay) => {
    return (...args) => {
        let context = this;
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.call(context, ...args);
        }, delay);
    };
};

export const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.set(key, object[key]));
    return formData;
};

export const setCookie = (name, value, daysToLive) => {
    var cookie = name + '=' + encodeURIComponent(value);
    if (typeof daysToLive === 'number') {
        cookie += '; max-age=' + daysToLive * 24 * 60 * 60;
        document.cookie = cookie;
    }
};

export const getCookie = (name) => {
    var cookieArr = document.cookie.split(';');
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split('=');
        if (name === cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return null;
};

export const debounceFunction = (func, delay) => {
    let debounceHandler;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceHandler);
        debounceHandler = setTimeout(() => func.apply(context, args), delay);
    };
};

export const formatNumber = (nuString) => {
    let num = parseFloat(nuString);
    let nf = new Intl.NumberFormat('en-US');
    return nf.format(num);
};

export const toolTipDateFormat = (val) => {
    // 2021-11-11 / YYYY-MM-DD
    const newDate = new Date(val);
    let month = newDate.getMonth() + 1;
    let dateNumber = newDate.getDate();
    let j = parseInt(dateNumber) % 10;
    let k = parseInt(dateNumber) % 100;
    if (j == 1 && k != 11) {
        dateNumber += 'ST';
    } else if (j == 2 && k != 12) {
        dateNumber += 'ND';
    } else if (j == 3 && k != 13) {
        dateNumber += 'RD';
    } else {
        dateNumber += 'TH';
    }
    switch (month) {
        case 1:
            return 'JAN, ' + dateNumber;
        case 2:
            return 'FEB, ' + dateNumber;
        case 3:
            return 'MAR, ' + dateNumber;
        case 4:
            return 'APR, ' + dateNumber;
        case 5:
            return 'MAY, ' + dateNumber;
        case 6:
            return 'JUN, ' + dateNumber;
        case 7:
            return 'JUL, ' + dateNumber;
        case 8:
            return 'AUG, ' + dateNumber;
        case 9:
            return 'SEP, ' + dateNumber;
        case 10:
            return 'OCT, ' + dateNumber;
        case 11:
            return 'NOV, ' + dateNumber;
        case 12:
            return 'DEC, ' + dateNumber;
        default:
            return;
    }
};

export const humanFormatDate = (data) => {
    var date = new Date(data); // Date 2011-05-09T06:08:45.178Z
    var year = date.getFullYear();
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
};

export const formatDate = (dateVal, inString) => {
    const newDate = new Date(dateVal);
    var month = '' + (newDate.getMonth() + 1);
    var day = '' + newDate.getDate();
    var year = newDate.getFullYear();
    if (inString) {
        switch (month) {
            case '1':
                return 'JAN ' + day + ', ' + year;
            case '2':
                return 'FEb ' + day + ', ' + year;
            case '3':
                return 'MAR ' + day + ', ' + year;
            case '4':
                return 'APR ' + day + ', ' + year;
            case '5':
                return 'MAY ' + day + ', ' + year;
            case '6':
                return 'JUN ' + day + ', ' + year;
            case '7':
                return 'JUL ' + day + ', ' + year;
            case '8':
                return 'AUG ' + day + ', ' + year;
            case '9':
                return 'SEP ' + day + ', ' + year;
            case '10':
                return 'OCT ' + day + ', ' + year;
            case '11':
                return 'NOV ' + day + ', ' + year;
            case '12':
                return 'DEC ' + day + ', ' + year;
            default:
                break;
        }
    } else {
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }
};

export const getDateFormat = (myDate) => {
    if (myDate) {
        myDate?.setTime(
            myDate?.getTime() - myDate?.getHours() * 3600 * 1000 - myDate?.getMinutes() * 60 * 1000 - myDate?.getSeconds() * 1000 - 1000
        );
        let dami = myDate?.getTime();

        return dami;
    }
};

export const getEnergyRatings = (data) => {
    if (data >= 92) return 'a';
    else if (data >= 81 && data <= 91) return 'b';
    else if (data >= 69 && data <= 80) return 'c';
    else if (data >= 55 && data <= 68) return 'd';
    else if (data >= 39 && data <= 54) return 'e';
    else if (data >= 21 && data <= 38) return 'f';
    else if (data >= 1 && data <= 20) return 'g';
    else return '';
};

export const isSafari = typeof window !== 'undefined' && /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
