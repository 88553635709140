import React, { useState } from 'react';
import './mobile-input.scss';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Fade } from 'react-reveal';
import '../Input/styles.scss';
import { useTranslation } from 'react-i18next';
const PhnInputAtom = ({
    phone,
    setPhone,
    info,
    onBlur,
    customWidth,
    customMargin,
    disabled,
    label,
    icon,
    iconType,
    setIsUpdated = null,
    type = null
}) => {
    const { t: phoneInput } = useTranslation();
    const phoneNumberHandler = (value, country) => {
        setInitialPhoneVal('');
        const phoneValue = value;
        const countryCode = country.dialCode;
        const ph = phoneValue.substring(countryCode.length);
        setIsUpdated(true);
        if (type) {
            setPhone({
                ...phone,
                value: {
                    phone: ph,
                    countryCode: `+${country.dialCode}`,
                    countryName: country.countryCode.toUpperCase()
                },
                enable: true
            });
        } else {
            setPhone({
                phone: ph,
                countryCode: `+${country.dialCode}`,
                countryName: country.countryCode.toUpperCase()
            });
        }
    };

    const [initalPhoneVal, setInitialPhoneVal] = useState(
        type === 'cta'
            ? phone?.value?.phone
                ? phone?.value?.countryCode + phone?.value?.phone
                : ''
            : phone?.phone
            ? phone?.countryCode + phone?.phone
            : ''
    );

    return (
        <div className="mb-20">
            <div className="d-flex space-between" style={{ width: customWidth ? customWidth : '100%' }}>
                <label className="d-ib fs-8 fw-900 mb-5">{label ? label : phoneInput('phone_number')}</label>
                {info && (
                    <Fade bottom duration="150">
                        <label className={'d-ib fs-8 fw-900 c-error mb-5'}>{info}</label>
                    </Fade>
                )}
            </div>
            {icon ? (
                <div
                    className={`cta ${iconType}`}
                    style={{
                        position: 'absolute',
                        right: '236px',
                        top: '609px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: '99'
                    }}
                >
                    <img src={icon} alt="icons" />
                </div>
            ) : (
                ''
            )}
            <PhoneInput
                inputStyle={{
                    width: customWidth ? customWidth : '80%',
                    border: '0px'
                }}
                country={'gb'}
                value={
                    initalPhoneVal.phone !== ''
                        ? initalPhoneVal.toString()
                        : type === 'cta'
                        ? phone.value.phone.toString()
                        : phone.phone.toString()
                }
                inputProps={{
                    name: 'phone',
                    required: true,
                    autoFocus: false
                }}
                onBlur={onBlur}
                onChange={phoneNumberHandler}
                containerClass="mobile-input-container"
                inputClass={customMargin ? 'mobile-input-main margin-25p' : 'mobile-input-main margin-20p'}
                buttonClass="mobile-input-btn"
                dropdownClass="mobile-input-dropdown"
                disabled={disabled}
            />
        </div>
    );
};

export default PhnInputAtom;
