import React, { useState, useEffect, useContext } from 'react';
import './teams.scss';
import { Redirect } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import { Fade } from 'react-reveal';
import { postReq } from '../../utils/api';
import { DOMAIN } from '../../constants/base';
import { getSessionStorage, isEmpty } from '../../utils';
import MemberItem from '../../components/MemberItem';
import Search from '../../components/Search';
import StatusMsg from '../../components/StatusMsg';
import { EditMemberContext, SettingsContext, GlobalProfile } from '../../context';
import EmptyState from '../../components/EmptyState';
import ClickAwayListener from 'react-click-away-listener';
import BlockerComponent from '../../components/BlockerComponent';
import SetUpTeam from '../../components/SetUpTeam';
import { useTranslation } from 'react-i18next';
import UserDetailPopUp from '../../components/UserDetailPopUp';
import { InView } from 'react-intersection-observer';

// Assets
import AddIcon from '../../assets/addIconBlack.png';
import EmptySearch from '../../assets/empty-search.png';
import SearchIcon from '../../assets/search.svg';
import CaretTop from '../../assets/caret-top.svg';
import CaretBot from '../../assets/caret-bot.svg';
import RemoveTextImg from '../../assets/ClearText.png';
import Check from '../../assets/check.svg';
import NoTeamMembers from '../../assets/noTeamMembers.png';

const Teams = () => {
    const [, dispatch] = useContext(EditMemberContext);
    const [globalProfile] = useContext(GlobalProfile);

    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const userDetails = getSessionStorage('ss-user-spaces') || {};

    const { role, department } = userDetails;
    const admin = role === 'ADMIN',
        owner = role === 'OWNER';
    const [activeMemberList, setActiveMemberList] = useState([]);
    const [inviteMemberPopup, setInviteMemberPopup] = useState(false);
    const [setUpTeamPopUp, setSetUpTeamPopUp] = useState(false);
    const [editMemberPopUp, setEditMemberPopUp] = useState(false);
    const [isTeamsLoading, setIsTeamsLoading] = useState(true);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [globalSettings] = useContext(SettingsContext);
    const [memberProfile, setMemberProfile] = useState(false);

    const [noData, setNoData] = useState(false);
    const [changeState, setChangeState] = useState(true);

    // Pagination States
    const [pageNo, setPageNo] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);

    const [searchParam, setSearchParam] = useState('');

    // Status Messages
    const [statusMsg, setStatusMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    //settings navigations
    const [teamsNav, setTeamsNav] = useState('AGENT');
    const [toggleFilters, setToggleFilters] = useState({
        department: false,
        sortBy: false
    });
    const [departmentType, setDepartmentType] = useState(department ? department : 'ALL');
    const [sortByType, setSortByType] = useState(null);

    const [managementData, setManagementData] = useState(0);
    const [agentData, setAgentData] = useState(0);
    const [marketingData, setMarketingData] = useState(0);

    const isMountedRef = React.useRef(null);
    const { t: teamTranslate } = useTranslation();

    useEffect(() => {
        if (isEmpty(userToken)) {
            setShouldRedirect(true);
        }
    }, []);

    useEffect(() => {
        if (statusMsg) {
            setTimeout(() => {
                setStatusMsg(false);
            }, 1500);
        }
    }, [statusMsg]);

    useEffect(() => {
        isMountedRef.current = true;
        setPageNo(1);
        fetchActiveMembers();
        return () => (isMountedRef.current = false);
    }, [changeState]);

    useEffect(() => {
        if (pageNo > 1) fetchActiveMembers(true);
    }, [pageNo]);

    const checkForNoData = (data) => {
        if (teamsNav === 'agent') setAgentData(data?.docs?.length ? data.docs.length : 0);
        if (teamsNav === 'management') setManagementData(data?.docs?.length ? data.docs.length : 0);
        if (teamsNav === 'marketing') setMarketingData(data?.docs?.length ? data.docs.length : 0);
        if (
            !globalProfile &&
            data.docs.length === 0 &&
            searchParam.length === 0 &&
            pageNo === 1 &&
            (managementData === 0 || agentData === 0 || marketingData === 0)
        ) {
            setNoData(true);
        } else {
            setNoData(false);
        }
    };

    async function fetchActiveMembers(scroller) {
        let payload;
        setIsTeamsLoading(true);
        const url = `${DOMAIN}/v1/dashboard/space/user/v2/team-members/page/${scroller ? pageNo : 1}?searchStr=${searchParam}`;
        if (!isEmpty(userToken)) {
            payload = {
                teamRole: teamsNav,
                ...(departmentType?.length && { department: departmentType }),
                ...(sortByType?.length && { sortBy: sortByType })
            };
            const requestHeaders = {
                authorization: userToken.accessToken
            };
            const teamMembersResponse = await postReq({
                url,
                payload,
                requestHeaders
            });
            const { code, data = {} } = teamMembersResponse;

            if (code === 200) {
                if (data.totalPages === pageNo) setHasNextPage(false);
                else if (data.totalPages === 0) setHasNextPage(false);
                else {
                    setHasNextPage(true);
                }
                setIsTeamsLoading(false);
                checkForNoData(data);
                if (isMountedRef.current && scroller) {
                    setActiveMemberList([...activeMemberList, ...data.docs]);
                } else {
                    setActiveMemberList(data.docs);
                    setPageNo(1);
                }
            }
        }
    }

    // Handle Edit Member
    const handleEditMember = (index) => {
        const currentMember = activeMemberList[index];
        const { id, avatar, name, email, admin, verified, invitee, owner, cta, lastActiveInMillis, department, role, teamRole } =
            currentMember;

        const phone = {
            phone: currentMember.phone,
            countryCode: currentMember.countryCode,
            countryName: currentMember.countryName
        };
        dispatch({
            type: 'EDIT_MEMBER',
            payload: {
                id,
                avatar,
                name,
                email,
                admin,
                phone,
                verified,
                invitee,
                owner,
                cta,
                lastActiveInMillis,
                department,
                role,
                teamRole
            }
        });
        setEditMemberPopUp(true);
    };

    let timer;
    const searchActiveMembers = (value) => {
        if (!isTeamsLoading) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                setSearchParam(value);
                setPageNo(1);
                setChangeState(!changeState);
            }, 1000);
        }
    };

    if (shouldRedirect) {
        return <Redirect to="/signin" />;
    }

    const { adminGlobalSettings, memberGlobalSettings } = globalSettings;
    //Team conditions
    const sendInvitationsCheck = () => {
        if (owner) return true;
        else if (admin && adminGlobalSettings?.globalAdminSendInvitations) return true;
        else if (!owner && !admin && memberGlobalSettings?.globalMemberSendInvitations) return true;
        else return false;
    };

    const renderTeamsList = () => {
        const memberList = activeMemberList.map((props, index) => (
            <Fade duration={200} key={index}>
                <MemberItem
                    memberData={props}
                    onClick={() => handleEditMember(index)}
                    globalSettings={globalSettings}
                    setMemberProfile={setMemberProfile}
                    teamsNav={teamsNav}
                ></MemberItem>
            </Fade>
        ));
        if (activeMemberList.length > 0) {
            return (
                <>
                    {memberList}
                    {isTeamsLoading ? (
                        <>
                            <div className="member-item-wrapper">
                                <div className="member-item h-75 shimmerBG">
                                    <div className="member-item-avatar  shimmerBG" />
                                </div>
                            </div>
                            <div className="member-item-wrapper">
                                <div className="member-item h-75 shimmerBG">
                                    <div className="member-item-avatar  shimmerBG" />
                                </div>
                            </div>
                            <div className="member-item-wrapper">
                                <div className="member-item h-75 shimmerBG">
                                    <div className="member-item-avatar  shimmerBG" />
                                </div>
                            </div>
                        </>
                    ) : (
                        <InView
                            as="div"
                            onChange={(inView) => {
                                if (inView && hasNextPage) {
                                    setPageNo(pageNo + 1);
                                }
                            }}
                            className="member-item-wrapper d-flex center-align o-00"
                        ></InView>
                    )}
                </>
            );
        } else if (activeMemberList.length === 0 && searchParam?.length === 0 && !isTeamsLoading) {
            return (
                <div className="loading-teams">
                    <img src={NoTeamMembers} alt="" />
                    <span
                        style={{
                            maxWidth: '260px'
                        }}
                    >
                        {teamTranslate('team.no_members') + getTextForNoTeamMembers(teamsNav)}
                    </span>
                </div>
            );
        } else if (activeMemberList.length === 0 && searchParam?.length)
            return (
                <div className="loading-teams">
                    <img src={EmptySearch} alt="" />
                    <span>{teamTranslate('team.no_data')}</span>
                </div>
            );
    };

    const navigateSettings = (nav) => {
        setPageNo(1);
        setChangeState(!changeState);
        if (nav === 'management') setTeamsNav('MANAGEMENT');
        else if (nav === 'agent') setTeamsNav('AGENT');
        else if (nav === 'marketing') setTeamsNav('MARKETING');
    };

    const getTextForNoTeamMembers = (value) => {
        if (value === 'AGENT') {
            return 'Agents';
        } else if (value === 'MARKETING') {
            return 'Marketing';
        } else {
            return 'Management';
        }
    };
    const toggleFiltersMethod = (type) => {
        if (type === 'department') {
            if (toggleFilters.department) {
                setToggleFilters({
                    ...toggleFilters,
                    department: false
                });
            } else {
                setToggleFilters({
                    department: true,
                    sortBy: false
                });
            }
        }
        if (type === 'sortBy') {
            if (toggleFilters.sortBy) {
                setToggleFilters({
                    ...toggleFilters,
                    sortBy: false
                });
            } else {
                setToggleFilters({
                    department: false,
                    sortBy: true
                });
            }
        }
    };
    const handleClickAway = () => {
        setToggleFilters({
            department: false,
            sortBy: false
        });
    };

    const toggleDepartmentMethod = (e, val) => {
        setPageNo(1);
        setChangeState(!changeState);
        e.stopPropagation();
        setDepartmentType(val);
        handleClickAway();
    };

    const toggleSortByMethod = (e, val) => {
        e.stopPropagation();
        setSortByType(val);
        handleClickAway();
    };

    const departmentSortBtnText = () => {
        if (departmentType === 'ALL') return teamTranslate('team.departement.all_dept');
        if (departmentType === 'SALE') return teamTranslate('team.departement.sale');
        if (departmentType === 'LETTINGS') return teamTranslate('team.departement.lettings');
    };

    const sortByBtnText = () => {
        if (sortByType === 'latest') return teamTranslate('team.sort.latest');
        if (sortByType === 'alphabetical') return teamTranslate('team.sort.alphabetical');
        if (sortByType === 'spaces') return teamTranslate('team.sort.no_of_space');
        if (sortByType === 'leads') return teamTranslate('team.sort.no_of_leads');
        else return teamTranslate('team.sort.title');
    };

    const departmentCheck = () => {
        if (department === 'ALL' || globalProfile?.department === 'ALL') return true;
        else return false;
    };
    return (
        <>
            {userDetails.currentPlan === 'BASIC TRIAL' ||
            userDetails.currentPlan === 'PRO TRIAL' ||
            userDetails.currentPlan === 'BASIC PAID' ||
            userDetails.currentPlan === 'PRO PAID' ? (
                <BlockerComponent></BlockerComponent>
            ) : (
                <div className="d-flex main-dash-container">
                    <NavBar selected="myTeam" />
                    {statusMsg && <StatusMsg type="error" message={errorMsg}></StatusMsg>}

                    {activeMemberList ? (
                        <>
                            {!noData && userDetails?.teamExists ? (
                                <div className="w-100p mt-20 ml-20 mr-20 content-width">
                                    {/* {userDetails.currentPlan === 'BASIC' && <div className="blocker-screen"></div>} */}
                                    <div className="d-flex f-col space-between mb-5">
                                        <div className="products-heading d-flex f-row space-between center-align fs-18 fw-900 h-60 mb-20">
                                            <h1>{teamTranslate('team.title')}</h1>
                                            <button
                                                disabled={!sendInvitationsCheck()}
                                                className="button-link mt-5"
                                                onClick={() => setInviteMemberPopup(true)}
                                            >
                                                {' '}
                                                <img src={AddIcon} alt="ADD"></img> {teamTranslate('team.invite_new')}
                                            </button>
                                        </div>
                                        <div className="d-flex publish-heading-container">
                                            <h4
                                                onClick={() => {
                                                    if (teamsNav !== 'agent') navigateSettings('agent');
                                                }}
                                                style={{
                                                    color: teamsNav === 'AGENT' ? '#000' : '#0000003D',
                                                    fontWeight: teamsNav === 'AGENT' ? 800 : 700
                                                }}
                                                className="mr-20"
                                            >
                                                {teamTranslate('team.team_role.agent')}
                                            </h4>
                                            <h4
                                                onClick={() => {
                                                    if (teamsNav !== 'MARKETING') navigateSettings('marketing');
                                                }}
                                                style={{
                                                    color: teamsNav === 'MARKETING' ? '#000' : '#0000003D',
                                                    fontWeight: teamsNav === 'MARKETING' ? 800 : 700
                                                }}
                                                className="mr-20"
                                            >
                                                {teamTranslate('team.team_role.marketing')}
                                            </h4>
                                            <h4
                                                onClick={() => {
                                                    if (teamsNav !== 'MANAGEMENT') navigateSettings('management');
                                                }}
                                                style={{
                                                    color: teamsNav === 'MANAGEMENT' ? '#000' : '#0000003D',
                                                    fontWeight: teamsNav === 'MANAGEMENT' ? 800 : 700
                                                }}
                                            >
                                                {teamTranslate('team.team_role.management')}
                                            </h4>
                                        </div>
                                    </div>

                                    <div className="active-item-header">
                                        <div className="active-item-header-search">
                                            <img src={SearchIcon} alt="Search" />
                                            <Search
                                                type="text"
                                                labelStyle="mb-10"
                                                placeholder={teamTranslate('team.search_placeholder')}
                                                onChange={searchActiveMembers}
                                                appendImg={RemoveTextImg}
                                                setCurrentPage={() => {
                                                    setPageNo(1);
                                                }}
                                            />
                                        </div>
                                        <div className="edit-btn-wrapper d-flex center-align">
                                            <button
                                                className="button-drop-link"
                                                disabled={departmentCheck() ? false : true}
                                                onClick={() => toggleFiltersMethod('department')}
                                                style={{
                                                    color:
                                                        departmentType === 'SALE' || departmentType === 'LETTINGS'
                                                            ? '#000'
                                                            : 'rgba(0, 0, 0, 0.5)'
                                                }}
                                            >
                                                {' '}
                                                {departmentSortBtnText()}
                                                <img
                                                    onClick={(e) => {
                                                        if (departmentCheck()) toggleDepartmentMethod(e, 'ALL');
                                                        else return;
                                                    }}
                                                    src={
                                                        toggleFilters.department
                                                            ? CaretTop
                                                            : departmentType === 'SALE' || departmentType === 'LETTINGS'
                                                            ? RemoveTextImg
                                                            : CaretBot
                                                    }
                                                    style={{
                                                        height: departmentType === 'SALE' || departmentType === 'LETTINGS' ? '19px' : '16px'
                                                    }}
                                                    alt=""
                                                />
                                                {toggleFilters.department && (
                                                    <Fade duration={200}>
                                                        <div
                                                            className="dropdown-filter-wrapper"
                                                            style={{
                                                                top: '40px',
                                                                left: '8px'
                                                            }}
                                                        >
                                                            <ClickAwayListener onClickAway={handleClickAway}>
                                                                <ul>
                                                                    <li
                                                                        className="li-web-paired"
                                                                        onClick={(e) => toggleDepartmentMethod(e, 'SALE')}
                                                                    >
                                                                        {teamTranslate('team.departement.sale')}
                                                                        {departmentType === 'SALE' && <img src={Check} alt="" />}
                                                                    </li>

                                                                    <li
                                                                        className="li-web-paired"
                                                                        onClick={(e) => toggleDepartmentMethod(e, 'LETTINGS')}
                                                                    >
                                                                        {teamTranslate('team.departement.lettings')}
                                                                        {departmentType === 'LETTINGS' && <img src={Check} alt="" />}
                                                                    </li>

                                                                    <li
                                                                        className="li-web-paired"
                                                                        onClick={(e) => toggleDepartmentMethod(e, 'ALL')}
                                                                    >
                                                                        {teamTranslate('team.departement.all')}
                                                                        {departmentType === 'ALL' && <img src={Check} alt="" />}
                                                                    </li>
                                                                </ul>
                                                            </ClickAwayListener>
                                                        </div>
                                                    </Fade>
                                                )}
                                            </button>

                                            <button
                                                className="button-drop-link"
                                                onClick={() => toggleFiltersMethod('sortBy')}
                                                style={{
                                                    color:
                                                        sortByType === 'latest' ||
                                                        sortByType === 'alphabetical' ||
                                                        sortByType === 'spaces' ||
                                                        sortByType === 'leads'
                                                            ? '#000'
                                                            : 'rgba(0, 0, 0, 0.5)'
                                                }}
                                            >
                                                {' '}
                                                {sortByBtnText()}
                                                <img
                                                    onClick={(e) => toggleSortByMethod(e, '')}
                                                    src={
                                                        toggleFilters.sortBy
                                                            ? CaretTop
                                                            : sortByType === 'latest' ||
                                                              sortByType === 'alphabetical' ||
                                                              sortByType === 'spaces' ||
                                                              sortByType === 'leads'
                                                            ? RemoveTextImg
                                                            : CaretBot
                                                    }
                                                    style={{
                                                        height:
                                                            sortByType === 'latest' ||
                                                            sortByType === 'alphabetical' ||
                                                            sortByType === 'spaces' ||
                                                            sortByType === 'leads'
                                                                ? '19px'
                                                                : '16px'
                                                    }}
                                                    alt=""
                                                />
                                                {toggleFilters.sortBy && (
                                                    <Fade duration={200}>
                                                        <div
                                                            className="dropdown-filter-wrapper"
                                                            style={{
                                                                top: '40px',
                                                                right: 0
                                                            }}
                                                        >
                                                            <ClickAwayListener onClickAway={handleClickAway}>
                                                                <ul>
                                                                    <li
                                                                        className="li-web-paired"
                                                                        onClick={(e) => toggleSortByMethod(e, 'latest')}
                                                                    >
                                                                        {teamTranslate('team.sort.latest')}
                                                                        {sortByType === 'latest' && <img src={Check} alt="" />}
                                                                    </li>
                                                                    <li
                                                                        className="li-web-paired"
                                                                        onClick={(e) => toggleSortByMethod(e, 'alphabetical')}
                                                                    >
                                                                        {teamTranslate('team.sort.alphabetical')}
                                                                        {sortByType === 'alphabetical' && <img src={Check} alt="" />}
                                                                    </li>
                                                                    {teamsNav === 'agent' ? (
                                                                        <>
                                                                            <li
                                                                                className="li-web-paired"
                                                                                onClick={(e) => toggleSortByMethod(e, 'spaces')}
                                                                            >
                                                                                {teamTranslate('team.sort.no_of_space')}
                                                                                {sortByType === 'spaces' && <img src={Check} alt="" />}
                                                                            </li>
                                                                            <li
                                                                                className="li-web-paired"
                                                                                onClick={(e) => toggleSortByMethod(e, 'leads')}
                                                                            >
                                                                                {teamTranslate('team.sort.no_of_leads')}
                                                                                {sortByType === 'leads' && <img src={Check} alt="" />}
                                                                            </li>
                                                                        </>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </ul>
                                                            </ClickAwayListener>
                                                        </div>
                                                    </Fade>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="pt-15 h-100p ovf-y-scroll-show pos-rel">
                                        <div className="team-wrapper">{renderTeamsList()}</div>
                                    </div>
                                </div>
                            ) : (
                                <EmptyState renderComp={'teams'} setInviteMemberPopup={setSetUpTeamPopUp} />
                            )}
                        </>
                    ) : (
                        ''
                    )}
                    {setUpTeamPopUp && (
                        <SetUpTeam
                            setisBeginSetup={setSetUpTeamPopUp}
                            fetchActiveMembers={fetchActiveMembers}
                            beginSetUp={true}
                        ></SetUpTeam>
                    )}
                    {inviteMemberPopup ? (
                        <SetUpTeam
                            setisBeginSetup={setInviteMemberPopup}
                            notSetupTeam={true}
                            title="Invite new Team Members"
                            currentStep={2}
                            fetchActiveMembers={fetchActiveMembers}
                            setStatusMsg={setStatusMsg}
                            setErrorMsg={setErrorMsg}
                        ></SetUpTeam>
                    ) : (
                        ''
                    )}
                    {editMemberPopUp ? (
                        <UserDetailPopUp
                            setUserDetailPopUp={setEditMemberPopUp}
                            isMemberEdit={true}
                            fetchActiveMembers={fetchActiveMembers}
                            memberProfile={memberProfile}
                        ></UserDetailPopUp>
                    ) : (
                        ''
                    )}
                </div>
            )}
        </>
    );
};

export default Teams;
