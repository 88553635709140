import React, { useEffect, useState } from 'react';
import TopBar from '../../components/TopBar';
import { postReq } from '../../utils/api';
import ReactPaginate from 'react-paginate';
import SearchShadow from '../../assets/search-with-shadow.svg';
import { DOMAIN } from '../../constants/base';

import { validateEmail } from '../../utils';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import LoadingSpin from '../../components/LoadingSpin';

import '../IssuesPage/issuespage.scss';
export default function IssuesPage() {
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [userKey, setUserKey] = useState('');
    const [allIssues, setAllIssues] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [pageMetadata, setPageMetadata] = useState({
        totalDocs: 0,
        totalPages: 0
    });
    const [isLoading, setIsLoading] = useState(false);
    let keyInput = useRef(null);
    useEffect(() => {
        if (pageNo) {
            getIssues();
        }
    }, [pageNo]);

    const getIssues = async () => {
        if (userKey) {
            setIsLoading(true);
            const url = `${DOMAIN}/v1/dashboard/account/backend-logs/page/${pageNo}`;
            const requestHeaders = {
                authorization: userToken.accessToken
            };
            let payload = {
                startMillis: startDate?.getTime(),
                endMillis: endDate?.getTime(),
                key: userKey
            };
            const statusResponse = await postReq({
                url,
                payload,
                requestHeaders
            });
            const { code, data } = statusResponse;
            if (code === 200) {
                setIsLoading(false);
                setAllIssues(data?.docs);
                setPageMetadata({
                    totalDocs: data?.totalDocs,
                    totalPages: data?.totalPages
                });
            }
        }
    };

    const updateIssue = async (issue, value) => {
        const url = `${DOMAIN}/v1/dashboard/account/backend-logs`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        let payload = {
            id: issue.id,
            key: userKey
        };
        if (value) {
            payload = {
                ...payload,
                assignee: value
            };
        } else {
            payload = {
                ...payload,
                isResolved: true
            };
        }
        const statusResponse = await postReq({
            url,
            payload,
            requestHeaders
        });
        const { successCode } = statusResponse;
        if (successCode) {
            getIssues();
        }
    };
    const handlePageClick = (e) => {
        let selectedPage = e.selected;
        setPageNo(selectedPage + 1);
    };
    return (
        <div>
            <TopBar />
            <div className="issue-container">
                <div className="header">
                    <div className="date-container">
                        <label>Start Date</label>
                        <input
                            type="date"
                            value={startDate?.timeStamp}
                            onChange={(date) => {
                                setStartDate(new Date(date.target.value));
                            }}
                        />
                    </div>
                    <div className="date-container">
                        <label>End Date</label>
                        <input
                            type="date"
                            value={endDate?.timeStamp}
                            onChange={(date) => {
                                setEndDate(new Date(date.target.value));
                            }}
                        />
                    </div>
                    <div
                        className="date-container"
                        style={{
                            width: '300px'
                        }}
                    >
                        <label>Key</label>
                        <input
                            value={userKey}
                            ref={keyInput}
                            onChange={(e) => {
                                setUserKey(e.target.value);
                                keyInput.current.style.border = '';
                            }}
                        />
                    </div>
                    <div
                        className="search-icon"
                        onClick={() => {
                            if (userKey) {
                                getIssues();
                            } else {
                                keyInput.current.focus();
                                keyInput.current.style.border = '1px solid red';
                                toast.error('Please add key to get the issues', {
                                    position: 'top-center',
                                    autoClose: 2000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: false,
                                    progress: undefined
                                });
                            }
                        }}
                    >
                        <img src={SearchShadow} alt="search"></img>
                    </div>
                </div>
                <div className="body">
                    <table className="main-table">
                        <col style={{ width: '5%' }}></col>
                        <col style={{ width: '13%' }}></col>
                        <col style={{ width: '13%' }}></col>
                        <col style={{ width: '18%' }}></col>

                        <tr>
                            <th>Resolved</th>
                            <th>ID</th>
                            <th>Account ID</th>
                            <th>Message</th>
                            <th>Data</th>
                            <th>Stack</th>
                            <th>Date</th>
                            {/* <th>URL</th> */}
                            <th>Assigne</th>
                        </tr>

                        {!isLoading &&
                            allIssues &&
                            allIssues.map((issue, key) => {
                                return (
                                    <tr key={key} issue={issue}>
                                        <td issue={issue}>
                                            <input
                                                type="checkbox"
                                                name="check"
                                                value={issue.isResolved}
                                                checked={issue.isResolved ? true : false}
                                                onClick={() => {
                                                    let updatingIdx;
                                                    let itemUpdated;
                                                    itemUpdated = allIssues.find((template, idx) => {
                                                        if (template.id === issue.id) {
                                                            updatingIdx = idx;
                                                            return template;
                                                        }
                                                        return undefined;
                                                    });

                                                    if (itemUpdated) {
                                                        itemUpdated.isResolved = true;
                                                        let updatedItems = [
                                                            ...allIssues.slice(0, updatingIdx),
                                                            itemUpdated,
                                                            ...allIssues.slice(updatingIdx + 1)
                                                        ];
                                                        setAllIssues(updatedItems);
                                                    }
                                                    updateIssue(issue);
                                                }}
                                            ></input>
                                        </td>
                                        <td>{issue.id}</td>
                                        <td>{issue.log.accountId}</td>
                                        <td>{issue.log.message}</td>
                                        <td>{JSON.stringify(issue.log.data, null, 2)}</td>
                                        <td>{JSON.stringify(issue.log.stack, null, 2)}</td>
                                        <td>{JSON.stringify(new Date(issue.createdAtInMillis), null, 2)}</td>
                                        {/* <td>{issue.log.currentUrl}</td> */}

                                        <td>
                                            {issue.assignee ? (
                                                <div className="email-container">{issue.assignee}</div>
                                            ) : (
                                                <div className="email-container">
                                                    <input
                                                        placeholder="enter email"
                                                        onChange={(e) => {
                                                            let isValid = validateEmail(e.target.value);
                                                            if (isValid) {
                                                                updateIssue(issue, e.target.value);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        {isLoading ? (
                            <div className="loading-container">
                                <span>
                                    <LoadingSpin></LoadingSpin>
                                </span>
                            </div>
                        ) : (
                            allIssues &&
                            allIssues?.length === 0 && (
                                <div className="loading-container">
                                    <span>No Data Found</span>
                                </div>
                            )
                        )}
                    </table>

                    {/* {allIssues.map((item) => (
                        <IssueCard issue={item} />
                    ))} */}
                </div>

                {pageMetadata.totalPages > 1 && (
                    <div className="pagination-wrapper">
                        <ReactPaginate
                            currentPage={pageNo - 1}
                            forcePage={pageNo - 1}
                            pageCount={pageMetadata.totalPages}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            previousLabel={'❮'}
                            nextLabel={'❯'}
                            breakLabel={'...'}
                            onPageChange={handlePageClick}
                            containerClassName={'paginate-container'}
                            pageClassName={'page-item'}
                            activeClassName={'page-item-active'}
                            pageLinkClassName={'page-item-link'}
                            activeLinkClassName={'active-link'}
                            nextClassName={'next-previous-class'}
                            previousClassName={'next-previous-class'}
                            nextLinkClassName={'next-previous-link-class'}
                            previousLinkClassName={'next-previous-link-class'}
                            disabledClassName={'disabled-class'}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
