import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import RootRouter from './RootRouter';
import MobileBlock from "../containers/MobileBlock";


function SkillsRouter(props) {
  return (
    <Router>
      <MobileBlock />
      <RootRouter />
    </Router>
  );
}

export default SkillsRouter;
