import React, { useEffect, useState } from 'react';
import './generalSettings.scss';
import Dropdown from '../../components/Dropdown';
import cc from 'currency-codes';

//Assets
import FeetWhite from '../../assets/feet.svg';
import MeterGrey from '../../assets/meter.svg';
import FeetGrey from '../../assets/feet-grey.svg';
import MeterWhite from '../../assets/meter-white.svg';
import { useTranslation } from 'react-i18next';

function GeneralSettings(props) {
    const { generalSettingsState, setGeneralSettingsState, updateGeneralSettings } = props;
    const { language, currency, unitOfMeasurement } = generalSettingsState;
    const [tempLanguage, setTempLanguage] = useState([]);

    const { t: gs } = useTranslation();
    useEffect(() => {
        setTempLanguage(['English', 'Portuguese']);
    }, []);
    const languageSelect = (type) => {
        setGeneralSettingsState({ ...generalSettingsState, language: type });
        updateGeneralSettings({ language: type });
    };

    const currencySelect = (type) => {
        setGeneralSettingsState({ ...generalSettingsState, currency: type });
        updateGeneralSettings({ currency: type });
    };

    const unitSelect = (type) => {
        setGeneralSettingsState({ ...generalSettingsState, unitOfMeasurement: type });
        updateGeneralSettings({ unitOfMeasurement: type });
    };

    return (
        <div className="general-settings-container">
            <h4 className="general-heading">{gs('settings.general.title')}</h4>
            <div className="general-settings-container-wrapper">
                <h4 className="general-sub-heading">{gs('settings.general.personlize')}</h4>
                <div className="d-flex space-between center-align general-settings-container-wrapper-heading">
                    <div className="pr-60">
                        <h4>{gs('settings.general.language.title')}</h4>
                        <p>{gs('settings.general.language.subtitle')}</p>
                    </div>
                    <Dropdown onChange={languageSelect} renderData={tempLanguage} defaultValue={language} isLang={true}></Dropdown>
                </div>
                <hr />
                <div className="d-flex space-between center-align general-settings-container-wrapper-heading">
                    <div className="pr-60">
                        <h4>{gs('settings.general.currency.title')}</h4>
                        <p>{gs('settings.general.currency.subtitle')}</p>
                    </div>
                    <Dropdown onChange={currencySelect} renderData={cc.codes()} defaultValue={currency}></Dropdown>
                </div>
                <hr />
                <div className="d-flex space-between center-align general-settings-container-wrapper-heading">
                    <div className="pr-60">
                        <h4>{gs('settings.general.measure.title')}</h4>
                        <p>{gs('settings.general.measure.subtitle')}</p>
                    </div>
                    <div className="general-settings-container-wrapper-heading-toggle">
                        <div
                            className={`general-settings-container-wrapper-heading-toggle-sub ${
                                unitOfMeasurement === 'SQFT' ? 'active' : ''
                            }`}
                            onClick={() => unitSelect('SQFT')}
                        >
                            <img src={unitOfMeasurement === 'SQFT' ? FeetWhite : FeetGrey} alt="" />
                        </div>
                        <div
                            className={`general-settings-container-wrapper-heading-toggle-sub ${
                                unitOfMeasurement === 'SQMT' ? 'active' : ''
                            }`}
                            onClick={() => unitSelect('SQMT')}
                        >
                            <img src={unitOfMeasurement === 'SQMT' ? MeterWhite : MeterGrey} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GeneralSettings;
