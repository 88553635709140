import React, { useEffect, useRef } from 'react';
import VideoMp4 from '../../assets/dashvideo.mp4';
import dashFallbackImage from '../../assets/dashVideoFallbackImage.jpeg';
import './video.scss';

const VideoAtom = ({ FallbackImg, src }) => {
    const videoParentRef = useRef();
    useEffect(() => {
        videoParentRef.current.src = src ? src : VideoMp4;
    }, [src]);
    return (
        <video ref={videoParentRef} autoPlay loop muted playsInline preload="auto" className="video-component">
            {/* <source src={src?.length ? src : VideoMp4} type="video/mp4"></source>
            <img
                src={FallbackImg ? FallbackImg : dashFallbackImage}
                title="Your browser does not support the <video> tag"
                alt="Fallback for video"
            /> */}
        </video>
    );
};

export default VideoAtom;
