import React, { useEffect, useState } from 'react';
import ProductAtom from '../../components/ProductAtom';
import './androidtv.scss';

import TickMarkSuccessTV from '../../assets/tick-mark-success.png';
import BackBtn from '../../assets/back.png';
import OtpInput from 'react-otp-input';
import NoteComponent from '../../components/NoteComponent';
import CheckboxAtom from '../../components/CheckboxAtom';
import { validateEmail, getParameterByName } from '../../utils';
import { ANDROID_TV_GOOGLE_CLIENT_ID, DOMAIN } from '../../constants/base';
import { postReq } from '../../utils/api';
import TextAtom from '../../components/TextAtom';
import Input from '../../components/Input';
import ButtonAtom from '../../components/ButtonAtom';
import LoginGoogle from '../../components/LoginGoogle';
import LoginApple from '../../components/LoginApple';
import AndroidTVPreview from '../../components/androidTVPreview';
import StatusMsgAtom from '../../components/StatusMsgAtom';
import Avatar from 'react-avatar';

export default function AndroidTV() {
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const [statusMsg, setStatusMsg] = useState('');
    const [statusMsgType, setStatusMsgType] = useState('');
    const [typeOfError, setTypeOfError] = useState('');
    const ssoLoginError = false;
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState(false);
    const [otpErrorMsg, setOtpErrorMsg] = useState('');
    const [otptimeLeft, setOtpTimeLeft] = useState(60);
    const [otpTCCChecked, setOtpTCCChecked] = useState(false);
    const [showOtpTNC, setShowOtpTNC] = useState(false);
    const [showOtpScreen, setShowOtpScreen] = useState(false);
    const [isAndroidTvPaired, setIsAndroidTvPaired] = useState(false);
    const [showTVinputCode, setShowTVInputCode] = useState(false);
    const [showTVVerificationSuccess, setShowTVVerificationSuccess] = useState(false);
    const [pairingCode, setPairingCode] = useState('');
    const [pairingCodeError, setPairingCodeError] = useState(false);
    const [authHeader, setAuthHeader] = useState('');
    const [pairingCodeErroMsg, setPairingCodeErrorMsg] = useState('');

    const [ssoConfirmation, setSsoConfirmation] = useState(false);
    const [ssoTCChecked, setSSOTCChecked] = useState(false);
    const [TNCData, setTNCData] = useState({ name: '', email: '' });

    // Apple SSO Data
    const appleToken = getParameterByName('user');
    const appleUserName = getParameterByName('name');
    const appleEmail = getParameterByName('email');
    const appleNewUser = getParameterByName('newUser');

    useEffect(() => {
        window.gapi.load('client:auth2', () => {
            window.gapi.client.init({
                clientId: ANDROID_TV_GOOGLE_CLIENT_ID,
                scope: 'email',
                plugin_name: 'android tv login'
            });
        });
    }, []);
    // Handling SSO APPLE
    useEffect(() => {
        if (appleNewUser) {
            setSsoConfirmation(true);
            return;
        }
        const ssoApple = async () => {
            const url = `${DOMAIN}/v1/dashboard/space/user/android-tv/login/sso/apple`;
            const requestHeaders = {
                authorization: appleToken
            };
            if (appleToken) {
                const {
                    error = '',
                    successCode = '',
                    data
                } = await postReq({
                    url,
                    requestHeaders
                });

                if (successCode === 'SUCC200') {
                    setAuthHeader(data.accessToken);
                    setSsoConfirmation(false);
                    setShowTVInputCode(true);
                }
                if (error === 'ERR500') {
                    displayErrorMsg('Something went wrong. Please try after sometime.');
                }
            }
        };
        ssoApple();
    }, []);

    useEffect(() => {
        if (otp.length === 5) {
            handleOTPVerification();
        }
    }, [otp]);

    useEffect(() => {
        if (pairingCode.length === 5) {
            handleAndroidTVVerification();
        }
    }, [pairingCode]);

    useEffect(() => {
        if (!otptimeLeft) {
            return;
        }
        const intervalId = setInterval(() => {
            setOtpTimeLeft(otptimeLeft - 1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [otptimeLeft]);

    function TimeFormat(duration) {
        var mins = ~~((duration % 3600) / 60);
        var secs = ~~duration % 60;
        var ret = '';
        ret += '' + mins + ':' + (secs < 10 ? '0' : '');
        ret += '' + secs;
        return ret;
    }

    // generate OTP
    const generateOTP = async () => {
        setShowLoader(true);
        if (!validateEmail(email)) {
            setStatusMsg('Please input a valid email!');
            setStatusMsgType('error');
            setShowLoader(false);
            return;
        }
        const url = `${DOMAIN}/v1/dashboard/space/user/android-tv/login/generate-otp`;
        let payload = {
            email: email
        };
        const otpResp = await postReq({ url, payload });
        const { code, data } = otpResp;
        if (code === 200) {
            setOtpTimeLeft(60);
            setShowOtpScreen(true);
            if (data && data.newUser) {
                setShowOtpTNC(true);
            } else {
                setShowOtpTNC(false);
            }
        }
    };

    const handleOTPVerification = async () => {
        const url = `${DOMAIN}/v1/dashboard/space/user/android-tv/login`;
        let payload = { email: email, otp: parseInt(otp) };
        const userSigninResponse = await postReq({ url, payload });
        const { error = '', successCode = '', data = {} } = userSigninResponse;
        if (error === 'ERR400') {
            setOtpError(true);
            setOtpErrorMsg('SOMETHING WENT WRONG PLEASE TRY AFTER SOME TIME');
            return;
        }
        if (error === 'ERR406') {
            setOtpError(true);
            setOtpErrorMsg('CODE IS INCORRECT');
            return;
        }
        if (error === 'ERR403') {
            setOtpError(true);
            setOtpErrorMsg('CODE IS EXPIRED');
            return;
        }

        if (error === 'ERR404') {
            setOtpError(true);
            setOtpErrorMsg('USER NOT FOUND');
            return;
        }
        if (error === 'ERR500') {
            setOtpError(true);
            setOtpErrorMsg('SOMETHING WENT WRONG PLEASE TRY AFTER SOME TIME');
            return;
        }
        if (successCode === 'SUCC200') {
            setShowTVInputCode(true);
            setAuthHeader(data.accessToken);
        }
    };

    const handleAndroidTVVerification = async () => {
        const url = `${DOMAIN}/v1/dashboard/space/user/android-tv/login/verify-tv-code`;
        let payload = { otp: parseInt(pairingCode) };
        const requestHeaders = {
            authorization: authHeader
        };
        const userAndoridTVResponse = await postReq({
            url,
            requestHeaders,
            payload
        });
        const { error, successCode } = userAndoridTVResponse;
        if (error === 'ERR406') {
            setOtpError(true);
            setOtpErrorMsg('CODE IS INCORRECT');
            return;
        }
        if (successCode === 'SUCC200') {
            setIsAndroidTvPaired(true);
            setShowTVVerificationSuccess(true);
        }
    };
    const successCallback = (res) => {
        const url = `${DOMAIN}/v1/dashboard/space/user/android-tv/login/sso/google`;
        const requestHeaders = {
            authorization: res.tokenId
        };
        postReq({
            url,
            requestHeaders
        }).then((res) => {
            const { error = '', successCode = '', data = {} } = res;
            if (error === 'ERR404') {
                displayErrorMsg('User does not exist');
            }
            if (error === 'ERR403') {
                displayErrorMsg('Your email is incorrect');
                setTypeOfError('ERR403');
            }
            if (error === 'ERR500') {
                displayErrorMsg('Something went wrong. Please try after sometime.');
            }
            if (successCode === 'SUCC200') {
                // if (data.tnc) {
                //     setSsoConfirmation(true);
                //     setTNCData({ name: data.name, email: data.email, token: data.accessToken });
                // } else {
                //     setTNCData({ ...TNCData, token: data.accessToken });
                //     SecondSSOCall('google', data.accessToken);
                // }
                setAuthHeader(data.accessToken);
                setSsoConfirmation(false);
                setShowTVInputCode(true);
            }
        });
    };

    const SecondSSOCall = (currentSSO, googleToken) => {
        const url = `${DOMAIN}/v2/user/android-tv/login/sso/${currentSSO}`;
        const payload = {
            holofy: 'Space',
            tnc: false
        };
        const requestHeaders = {
            authorization: appleToken ? appleToken : TNCData.token ? TNCData.token : googleToken
        };
        postReq({
            url,
            payload,
            requestHeaders
        }).then((res) => {
            const { error = '', successCode = '' } = res;
            if (error === 'ERR404') {
                displayErrorMsg('User does not exist');
            }
            if (error === 'ERR403') {
                displayErrorMsg('Your email is incorrect');
                setTypeOfError('ERR403');
            }
            if (error === 'ERR500') {
                displayErrorMsg('Something went wrong. Please try after sometime.');
            }
            if (successCode === 'SUCC200') {
                setSsoConfirmation(false);
                setShowTVInputCode(true);
            }
        });
    };
    const emailChangeHandler = (e) => {
        const email = e.target.value;
        setEmail(email);
    };

    const emailValidateHandler = () => {
        const isValid = validateEmail(email);
        setIsEmailValid(isValid);
    };

    const displayErrorMsg = (msg) => {
        setStatusMsg(msg);
        setStatusMsgType('error');
        //   setShowDialogMobile(true);
        return;
    };
    return (
        <div className="android-tv-container">
            <>
                <ProductAtom isWhite={true} customClass="andorid-tv-product-logo"></ProductAtom>
                {statusMsg && statusMsgType && (
                    <StatusMsgAtom
                        LineOne={'We could not recognise this account'}
                        LineTwo={statusMsg}
                        linkLabel={typeOfError === 'ERR403' ? 'RESET YOUR PASSWORD' : 'SIGN UP'}
                        linkUrl={typeOfError === 'ERR403' ? '/forgetPassword' : '/signup'}
                        type={statusMsgType}
                    ></StatusMsgAtom>
                )}
                {ssoLoginError && (
                    <StatusMsgAtom
                        LineOne={'Something went wrong.'}
                        LineTwo={'Please try again'}
                        type="error"
                        showDialogMobile={false}
                    ></StatusMsgAtom>
                )}
                {ssoConfirmation ? (
                    <div className="form-card-wrapper custom-blur">
                        <h1 className="sso-tac-heading">
                            Hi{' '}
                            {TNCData.name
                                ? TNCData.name.length > 12
                                    ? TNCData.name.substring(0, 12) + '...'
                                    : TNCData.name
                                : appleUserName !== undefined && appleUserName !== 'undefined'
                                ? appleUserName && appleUserName.length > 12
                                    ? appleUserName.substring(0, 12) + '...'
                                    : appleUserName
                                : appleEmail && appleEmail.length > 12
                                ? appleEmail.substring(0, 12) + '...'
                                : appleEmail}
                        </h1>
                        <div className="mt-20 w-100p">
                            <div className="email-inpt d-flex f-col space-between">
                                <label className="d-ib mb-5">Email</label>
                                <div className="input-wrap">
                                    <Avatar
                                        name={
                                            TNCData.name
                                                ? TNCData.name
                                                : appleUserName !== undefined && appleUserName !== 'undefined'
                                                ? appleUserName
                                                : appleEmail
                                        }
                                        size="24"
                                        round={true}
                                        color="black"
                                        maxInitials={1}
                                        textSizeRatio={2}
                                        textMarginRatio={0}
                                        style={{ fontWeight: '700', marginLeft: '10px' }}
                                    />
                                    <h2 className="email-custom">{TNCData.email ? TNCData.email : appleEmail}</h2>
                                </div>
                            </div>
                            <div className="important-note-wrapper mt-40">
                                <p>Before you continue, you need to know about our term & policy.</p>
                                <CheckboxAtom isChecked={ssoTCChecked} setIsChecked={setSSOTCChecked}></CheckboxAtom>
                            </div>
                            <div className="d-flex f-col vertical-center mt-20">
                                <ButtonAtom
                                    label="CONTINUE"
                                    disabled={!ssoTCChecked}
                                    onClick={() => SecondSSOCall(appleToken ? 'apple' : 'google')}
                                ></ButtonAtom>
                            </div>
                        </div>
                    </div>
                ) : showTVVerificationSuccess ? (
                    <div className="tv-verification-success">
                        <img src={TickMarkSuccessTV} alt="tick mark" />
                        <div className="paired-msg">Your TV has been paired to your team account!</div>
                    </div>
                ) : showTVinputCode ? (
                    <div className="verify-container">
                        <button
                            onClick={() => {
                                setShowTVInputCode(false);
                            }}
                            className="back-btn"
                        >
                            <img src={BackBtn} alt="back" />
                        </button>
                        <h1 className="mt-60">
                            Input your TV’s unique <br></br>Pairing Code
                        </h1>
                        <div className="mt-30">
                            <h2 className="otp-title">PAIRING CODE</h2>
                            <OtpInput
                                value={pairingCode}
                                onChange={(pairingCodeText) => {
                                    setPairingCode(pairingCodeText);
                                }}
                                numInputs={5}
                                containerStyle={'otp-container'}
                                inputStyle={'otp-input'}
                                errorStyle={'otp-error'}
                            />
                            {pairingCodeError && pairingCodeErroMsg && <p className="otp-error-text">{pairingCodeErroMsg}</p>}
                        </div>
                        <div className="pairing-note-container">
                            <NoteComponent message="To get a Unique Pairing Code , install the Holofy TV app on your android TV by accessing the playstore. " />
                        </div>
                    </div>
                ) : showOtpScreen ? (
                    <div className="verify-container">
                        <button
                            className="back-btn"
                            onClick={() => {
                                setShowOtpScreen(false);
                                setShowLoader(false);
                                setOtp('');
                                setOtpError(false);
                                setOtpTimeLeft(60);
                            }}
                        >
                            <img src={BackBtn} alt="back-btn"></img>
                        </button>
                        <h1 className="mt-60">
                            Verify your Email <br></br> Address
                        </h1>
                        <div className="mt-30">
                            <h2 className="otp-title">SECURITY CODE</h2>
                            <OtpInput
                                value={otp}
                                onChange={(otpText) => {
                                    setOtp(otpText);
                                }}
                                numInputs={5}
                                containerStyle={'otp-container'}
                                inputStyle={'otp-input'}
                                errorStyle={'otp-error'}
                                hasErrored={otpError}
                                isDisabled={showOtpTNC ? !otpTCCChecked : false}
                            />
                            {otpError && otpErrorMsg && <p className="otp-error-text">{otpErrorMsg}</p>}
                            {showOtpTNC && (
                                <div className="mt-20">
                                    <CheckboxAtom isChecked={otpTCCChecked} setIsChecked={setOtpTCCChecked}></CheckboxAtom>
                                </div>
                            )}
                        </div>
                        <div className="mt-20">
                            <p className="otp-text">
                                <span>We’ve sent a security code to your email.</span> Please allow up to a minute for the email to arrive,
                                and check your spam folder if it may have accidentally ended up in there.
                            </p>
                        </div>

                        <div className="mt-80 w-100p">
                            {otptimeLeft <= 0 ? (
                                <button
                                    onClick={() => {
                                        setOtpTimeLeft(60);
                                        generateOTP();
                                    }}
                                    className="otp-resend-btn"
                                >
                                    RESEND CODE
                                </button>
                            ) : (
                                <button disabled className="otp-resend-btn">
                                    <span>{TimeFormat(otptimeLeft)}</span> RESEND CODE
                                </button>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="verify-container">
                        <TextAtom
                            textContent="Sign in to pair your Holofy TV"
                            fontFamily="Montserrat"
                            fontWeight="700"
                            fontSize="17px"
                            color="#3A3A3A"
                            className="holofy-tv"
                        ></TextAtom>
                        <div className="mt-30">
                            <Input
                                type="text"
                                label="Your Email"
                                labelStyle="uc mb-5"
                                placeholder="your@email.com"
                                value={email}
                                onChange={emailChangeHandler}
                                onBlur={emailValidateHandler}
                                isValid={isEmailValid}
                                handleEnter={() => {
                                    generateOTP();
                                }}
                            />
                        </div>
                        <div className="d-flex f-col vertical-center">
                            <ButtonAtom
                                label="CONTINUE WITH EMAIL"
                                disabled={false}
                                onClick={generateOTP}
                                showLoader={showLoader}
                            ></ButtonAtom>
                            <div className="mt-60 d-flex f-col vertical-center">
                                <div className="separator">Or</div>
                                <div className="mt-10">
                                    <LoginGoogle isTV={true} successCallback={successCallback} />
                                    <LoginApple isTV={true}></LoginApple>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
            <div className="preview">
                <AndroidTVPreview isAndroidTvPaired={isAndroidTvPaired} />
            </div>
        </div>
    );
}
