import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import LoadingSpin from '../LoadingSpin';
import MultiStepPopup from '../MultiStepPopUp';
import Camera from '../../assets/camera.svg';
import { DOMAIN } from '../../constants/base';
import { toast } from 'react-toastify';
import { getFormData, getSessionStorage, setSessionStorage } from '../../utils';
import Input from '../Input';
import { useTranslation } from 'react-i18next';
import BottomWrapper from '../BottomWrapper';
import { postFormReq, putFormReq } from '../../utils/api';
import EditCTAs from '../EditCTAs';
import CustomSelect from '../CustomSelect';
import NoteComponent from '../NoteComponent';
import PopUpRemoveMember from '../PopUpRemoveMember';
import MinusIcon from '../../assets/minus.png';
import AddIconBlack from '../../assets/addIconBlack.png';
import './editprofiledetails.scss';
export default function EditProfileDetails(props) {
    const {
        setEditAllDetails,
        profileId,
        isMemberEdit,
        profileAvatar,
        setProfileAvatar,
        handleBrokenImage,
        profileName,
        setProfileName,
        isUserProfileEdit,
        currentMember,
        profileEmail,
        currentCta,
        setCurrentCta,
        fetchActiveMembers,
        userDepartment,
        setUserDepartment,
        userTeamRole,
        setUserTeamRole,
        userPermission,
        setUserPermission,
        currentStep,
        editSectionDetails,
        setEditSectionDetails,
        ctaEmail,
        setCtaEmail,
        ctaLicenseNo,
        setCtaLicenseNo,
        ctaWelcomeMsg,
        setCtaWelcomeMsg,
        ctaWhatsapp,
        setCtaWhatsapp,
        ctaPhone,
        setCtaPhone,
        ctaTelegram,
        setCtaTelegram,
        ctaMessenger,
        setCtaMessenger,
        ctaCustomButton,
        setCtaCustomButton,
        ctaTypeform,
        ctaCal,
        setCtaCal,
        setCtaTypeform,
        setUserDetailPopUp
    } = props;

    const [activeStep, setActiveStep] = useState(currentStep ? currentStep : 1);
    const [editProfileName, setEditProfileName] = useState(profileName);
    const [editUserPermission, setEditUserPermission] = useState(userPermission);
    const [editUserDepartment, setEditUserDepartment] = useState(userDepartment);
    const [editUserTeamRole, setEditUserTeamRole] = useState(userTeamRole);
    const userDetails = getSessionStorage('ss-user-spaces') || {};
    const { role } = userDetails;
    const [imageUploading, setImageUploading] = useState(false);
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const [isLoading, setIsLoading] = useState(false);
    const [isNameUpdated, setIsNameUpdated] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const { t } = useTranslation();
    const [deleteAccountPopUp, setDeleteAccountPopUp] = useState(false);
    const [removeMemberPopUp, setRemoveMemberPopUp] = useState(false);
    const [editProfileAvatar, setEditProfileAvatar] = useState('');
    const [editCtaLicenseNo, setEditCtaLicenseNo] = useState('');
    const [editCtaWelcomeMsg, setEditCtaWelcomeMsg] = useState('');
    const admin = role === 'ADMIN',
        owner = role === 'OWNER';
    useEffect(() => {
        setEditCtaLicenseNo(currentCta?.license);
        setEditCtaWelcomeMsg(currentCta?.message);
    }, [currentCta]);

    useEffect(() => {
        if (!imageUploading && editProfileAvatar) {
            if (isUserProfileEdit) {
                setSessionStorage({
                    key: 'ss-user-spaces',
                    value: {
                        ...userDetails,
                        avatar: editProfileAvatar
                    }
                });
            }
            setProfileAvatar(editProfileAvatar);
        }
    }, [imageUploading, editProfileAvatar]);
    const handleBackBtn = () => {
        setEditAllDetails(false);
    };

    const uploadProfileImage = (e) => {
        setImageUploading(true);
        let url = '';
        if (!isUserProfileEdit) {
            url = `${DOMAIN}/v1/dashboard/space/user/edit-member/v2/${currentMember?.currentEditMemberId}`;
        } else {
            url = `${DOMAIN}/v1/dashboard/space/user/profile/edit`;
        }
        let headers = new Headers();
        headers.append('Authorization', userToken.accessToken);
        let formData = new FormData();
        formData.append('userImage', e.target.files[0], e.target.files[0].name);
        setEditProfileAvatar(URL.createObjectURL(e.target.files[0]));
        if (!isUserProfileEdit) {
            formData.append('invitee', currentMember?.currentEditMemberInvitee);
        }

        let requestOptions = {
            method: isUserProfileEdit ? 'POST' : 'PUT',
            headers: headers,
            body: formData,
            redirect: 'follow'
        };
        fetch(url, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setImageUploading(false);
                setIsNameUpdated(true);
                fetchActiveMembers && fetchActiveMembers();
                toast.success(t('editprofile.toast.photo_update'), {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
            })
            .catch((error) => console.log('error', error));
    };
    const renderDots = () => {
        if (activeStep === 1) {
            return (
                <>
                    <div className={activeStep === 1 ? 'circle active' : 'circle'}></div>
                    <div className={activeStep === 2 ? 'circle active' : 'circle'}></div>
                    {isMemberEdit && (owner || admin) && <div className={activeStep === 3 ? 'circle active' : 'circle'}></div>}
                </>
            );
        } else if (activeStep === 2) {
            return (
                <>
                    <div className={activeStep === 1 ? 'circle active' : 'circle'}></div>
                    <div className={activeStep === 2 ? 'circle active' : 'circle'}></div>
                    {isMemberEdit && (owner || admin) && <div className={activeStep === 3 ? 'circle active' : 'circle'}></div>}
                </>
            );
        } else if (activeStep === 3) {
            return (
                <>
                    <div className={activeStep === 1 ? 'circle active' : 'circle'}></div>
                    <div className={activeStep === 2 ? 'circle active' : 'circle'}></div>
                    {isMemberEdit && (owner || admin) && <div className={activeStep === 3 ? 'circle active' : 'circle'}></div>}
                </>
            );
        }
    };

    const handleProfileNameImg = async () => {
        if (true) {
            setIsLoading(true);
            let url = '';
            if (!isUserProfileEdit) {
                url = `${DOMAIN}/v1/dashboard/space/user/edit-member/v2/${currentMember?.currentEditMemberId}`;
            } else {
                url = `${DOMAIN}/v1/dashboard/space/user/profile/edit`;
            }
            let payload = {
                name: editProfileName,
                ...(!isUserProfileEdit && {
                    invitee: currentMember?.currentEditMemberInvitee ? currentMember?.currentEditMemberInvitee : false
                }),
                ...(editCtaLicenseNo !== null && { licenceNo: editCtaLicenseNo.value }),
                ...(editCtaWelcomeMsg !== null && { message: editCtaWelcomeMsg.value })
            };
            const formData = getFormData(payload);
            const requestHeaders = {
                authorization: userToken.accessToken
            };
            let statusResponse;
            if (!isUserProfileEdit) {
                statusResponse = await putFormReq({ url, payload: formData, requestHeaders });
            } else {
                statusResponse = await postFormReq({ url, payload: formData, requestHeaders });
            }
            const { code, message } = statusResponse;
            if (code === 200) {
                setProfileName(payload.name);
                setIsNameUpdated(true);
                setIsUpdated(false);
                setCurrentCta({ ...currentCta, license: { ...editCtaLicenseNo }, message: { ...editCtaWelcomeMsg } });
                isUserProfileEdit &&
                    setSessionStorage({
                        key: 'ss-user-spaces',
                        value: {
                            ...userDetails,
                            name: payload.name
                        }
                    });
                setIsLoading(false);
                if (activeStep === 3) {
                    setEditAllDetails(false);
                    fetchActiveMembers();
                } else if (editSectionDetails) {
                    if (!isUserProfileEdit) {
                        fetchActiveMembers();
                    }
                    setEditSectionDetails(false);
                    setEditAllDetails(false);
                } else {
                    setActiveStep(2);
                }
                setCtaWelcomeMsg(editCtaWelcomeMsg);
                setCtaLicenseNo(editCtaLicenseNo);
                if (message?.length) {
                    toast.success(message, {
                        position: 'top-center',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined
                    });
                }
            }
        }
    };

    const handleTeamUpdate = async () => {
        setIsLoading(true);
        let url = '';
        if (!isUserProfileEdit) {
            url = `${DOMAIN}/v1/dashboard/space/user/edit-member/v2/${currentMember?.currentEditMemberId}`;
        } else {
            url = `${DOMAIN}/v1/dashboard/space/user/edit-member/v2/${profileId}`;
        }
        let payload = {
            name: profileName,
            invitee: currentMember?.currentEditMemberInvitee,
            ...(editUserDepartment && { department: editUserDepartment }),
            ...(editUserTeamRole && { teamRole: editUserTeamRole }),
            ...(editUserPermission && { role: editUserPermission })
        };
        const formData = getFormData(payload);
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const statusResponse = await putFormReq({ url, payload: formData, requestHeaders });
        const { code, message } = statusResponse;
        if (code === 200) {
            setUserDepartment(editUserDepartment);
            setUserTeamRole(editUserTeamRole);
            setUserPermission(editUserPermission);
            setEditAllDetails(false);
            setEditSectionDetails(false);
            setIsLoading(false);
            if (message?.length) {
                toast.success(message, {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
            }

            fetchActiveMembers();
        }
    };

    return (
        <>
            <MultiStepPopup
                setMultiStepPopUp={setEditAllDetails}
                handleBackButton={handleBackBtn}
                renderDots={renderDots}
                extraPadding="50px"
            >
                <div className="edit-profile-container">
                    <div className="current-screen-title">
                        {activeStep === 1 && 'Profile Details'}
                        {activeStep === 2 && 'Agent Contacts'}
                        {activeStep === 3 && 'Team Role and Permissions'}
                    </div>
                    {activeStep === 1 && (
                        <>
                            <div className="edit-profile">
                                <h4>{t('profile.profile_photo')}</h4>
                                <div className="edit-profile-pic-upload">
                                    {profileAvatar?.length ? (
                                        <img onError={handleBrokenImage} src={profileAvatar} alt="profile" />
                                    ) : (
                                        <Avatar
                                            name={profileName}
                                            value=""
                                            size="140"
                                            round={true}
                                            color="black"
                                            maxInitials={1}
                                            textSizeRatio={2}
                                            style={{ fontWeight: '700' }}
                                        />
                                    )}

                                    {imageUploading ? (
                                        <div className="upload center-align d-flex">
                                            <LoadingSpin />
                                        </div>
                                    ) : (
                                        <img className="upload" src={Camera} alt="camera" />
                                    )}
                                    <input
                                        type="file"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            opacity: 0,
                                            position: 'absolute',
                                            cursor: 'pointer'
                                        }}
                                        onChange={(e) => {
                                            uploadProfileImage(e);
                                        }}
                                    />
                                </div>
                                <hr />
                                <h4>{t('profile.acc_info')}</h4>
                                <Input
                                    type="text"
                                    value={editProfileName}
                                    onChange={(e) => {
                                        setEditProfileName(e.target.value);

                                        setIsUpdated(true);
                                    }}
                                    label={'profile name'}
                                    labelStyle="uc mb-5"
                                />
                                <Input
                                    type="email"
                                    placeholder={profileEmail}
                                    disabled={true}
                                    label={'Account email'}
                                    labelStyle="uc mb-5"
                                />

                                <div className="agent-field">
                                    {editCtaLicenseNo.enable ? (
                                        <>
                                            <div className="input-container">
                                                <div className="flat-inpt-wrapper">
                                                    <Input
                                                        type="text"
                                                        label={t('agent.lc_no.label')}
                                                        labelStyle="uc mb-5"
                                                        placeholder="121A"
                                                        value={editCtaLicenseNo.value}
                                                        onChange={(e) => {
                                                            setEditCtaLicenseNo({ value: e.target.value, enable: true });
                                                            setIsUpdated(true);
                                                        }}
                                                        customHeight="44px"
                                                        customWidth="504px"
                                                        agent={true}
                                                    />
                                                </div>
                                                <button
                                                    className="optional-btn"
                                                    onClick={() => {
                                                        setEditCtaLicenseNo({ value: '', enable: false });
                                                        setIsUpdated(true);
                                                    }}
                                                >
                                                    {' '}
                                                    <img src={MinusIcon} alt="minimize icon"></img>
                                                </button>
                                            </div>
                                        </>
                                    ) : (
                                        <div
                                            className="optional-field"
                                            onClick={() => setEditCtaLicenseNo({ ...editCtaLicenseNo, enable: true })}
                                        >
                                            <button className="optional-btn">
                                                <img src={AddIconBlack} alt="add icon"></img>
                                            </button>
                                            <div className="title">{t('agent.lc_no.title')}</div>
                                        </div>
                                    )}
                                </div>
                                <div className="agent-field">
                                    {editCtaWelcomeMsg.enable ? (
                                        <>
                                            <div className="input-container">
                                                <div className="flat-inpt-wrapper flex-col">
                                                    <label className="d-ib fs-8 fw-900  uc mb-5">{t('agent.wl_msg.label')}</label>
                                                    <textarea
                                                        className="msg-text-inp"
                                                        placeholder="Hello "
                                                        value={editCtaWelcomeMsg.value}
                                                        onChange={(e) => {
                                                            if (e.target.value.length > 280) return;
                                                            setEditCtaWelcomeMsg({ value: e.target.value, enable: true });
                                                            setIsUpdated(true);
                                                        }}
                                                        onPaste={(e) => {
                                                            e.stopPropagation();
                                                            let pasted = (e.clipboardData || window.clipboardData).getData('text');
                                                            pasted = pasted.substring(0, 280);
                                                            setEditCtaWelcomeMsg({ value: pasted, enable: true });
                                                            setIsUpdated(true);
                                                        }}
                                                    ></textarea>
                                                    <div className="character-count">
                                                        <span
                                                            style={{
                                                                color: 'rgba(0, 0, 0, 0.5)'
                                                            }}
                                                        >
                                                            {editCtaWelcomeMsg?.value?.length}
                                                        </span>{' '}
                                                        / <span>280</span>
                                                    </div>
                                                </div>
                                                <button
                                                    className="optional-btn"
                                                    onClick={() => {
                                                        setEditCtaWelcomeMsg({ value: '', enable: false });
                                                        setIsUpdated(true);
                                                    }}
                                                >
                                                    {' '}
                                                    <img src={MinusIcon} alt="minimize icon"></img>
                                                </button>
                                            </div>
                                        </>
                                    ) : (
                                        <div
                                            className="optional-field"
                                            onClick={() => setEditCtaWelcomeMsg({ ...editCtaWelcomeMsg, enable: true })}
                                        >
                                            <button className="optional-btn">
                                                <img src={AddIconBlack} alt="add icon"></img>
                                            </button>
                                            <div className="title">{t('agent.wl_msg.title')}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <BottomWrapper
                                onClick={handleProfileNameImg}
                                removeMember={isUserProfileEdit}
                                onHyperClicked={() => {
                                    if (isUserProfileEdit) {
                                        setDeleteAccountPopUp(true);
                                    }
                                }}
                                hyperText={isUserProfileEdit ? 'Delete account' : ''}
                            ></BottomWrapper>
                        </>
                    )}
                    {activeStep === 2 && (
                        <>
                            <EditCTAs
                                isAgentContacts={true}
                                noTemplateNameCheck={true}
                                profileAvatar={profileAvatar}
                                profileName={profileName}
                                ctas={currentCta}
                                setIsUpdated={setIsUpdated}
                                setCurrentCta={setCurrentCta}
                                ctaEmail={ctaEmail}
                                setCtaEmail={setCtaEmail}
                                ctaLicenseNo={ctaLicenseNo}
                                setCtaLicenseNo={setCtaLicenseNo}
                                ctaWelcomeMsg={ctaWelcomeMsg}
                                setCtaWelcomeMsg={setCtaWelcomeMsg}
                                ctaWhatsapp={ctaWhatsapp}
                                setCtaWhatsapp={setCtaWhatsapp}
                                ctaPhone={ctaPhone}
                                setCtaPhone={setCtaPhone}
                                ctaTelegram={ctaTelegram}
                                setCtaTelegram={setCtaTelegram}
                                ctaMessenger={ctaMessenger}
                                setCtaMessenger={setCtaMessenger}
                                ctaCustomButton={ctaCustomButton}
                                setCtaCustomButton={setCtaCustomButton}
                                ctaTypeform={ctaTypeform}
                                ctaCal={ctaCal}
                                setCtaCal={setCtaCal}
                                setCtaTypeform={setCtaTypeform}
                                isUserProfileEdit={isUserProfileEdit}
                                currentEditMemberId={currentMember?.currentEditMemberId}
                                setEditAllDetails={setEditAllDetails}
                                fetchActiveMembers={fetchActiveMembers}
                                activeStep={activeStep}
                                setActiveStep={setActiveStep}
                                editSectionDetails={editSectionDetails}
                                setEditSectionDetails={setEditSectionDetails}
                            ></EditCTAs>
                            {/* <BottomWrapper></BottomWrapper> */}
                        </>
                    )}
                    {activeStep === 3 && (
                        <>
                            <div className="edit-profile">
                                <div className="edit-profile__dept">
                                    <CustomSelect
                                        selectedOption={editUserDepartment ? editUserDepartment : 'ALL'}
                                        containerClass="edit-dept-container"
                                        optionsList={[
                                            { display: t('team.departement.all'), key: 'ALL' },
                                            { display: t('team.departement.lettings'), key: 'LETTINGS' },
                                            { display: t('team.departement.sale'), key: 'SALE' }
                                        ]}
                                        label={t('department')}
                                        labelClass="label-style"
                                        setSelectedOption={setEditUserDepartment}
                                        selectType="department"
                                        isAllowedDropdown={true}
                                    ></CustomSelect>
                                    <NoteComponent message={t('editprofile.invite.note_department')} />
                                </div>
                                <div className="edit-profile__teamRole">
                                    <CustomSelect
                                        selectedOption={editUserTeamRole ? editUserTeamRole : 'AGENT'}
                                        containerClass="edit-team-container"
                                        optionsList={[
                                            { display: t('team.team_role.agent'), key: 'AGENT' },
                                            { display: t('team.team_role.marketing'), key: 'MARKETING' },
                                            { display: t('team.team_role.management'), key: 'MANAGEMENT' }
                                        ]}
                                        label={t('team_role')}
                                        labelClass="label-style"
                                        setSelectedOption={setEditUserTeamRole}
                                        selectType="teamRole"
                                        isAllowedDropdown={true}
                                    ></CustomSelect>
                                </div>
                                <div className="edit-profile__permission">
                                    <CustomSelect
                                        selectedOption={editUserPermission}
                                        containerClass="edit-perm-container"
                                        optionsList={[
                                            { display: t('team.role.admin'), key: 'ADMIN' },
                                            { display: t('team.role.member'), key: 'MEMBER' }
                                        ]}
                                        label={t('permission')}
                                        labelClass="label-style"
                                        setSelectedOption={setEditUserPermission}
                                        selectType="role"
                                        customWidth="100%"
                                        isAllowedDropdown={true}
                                    ></CustomSelect>
                                    <NoteComponent message={t('editprofile.invite.note_perm')} />
                                </div>
                            </div>
                            <BottomWrapper
                                onClick={handleTeamUpdate}
                                removeMember={admin || owner}
                                onHyperClicked={() => {
                                    setRemoveMemberPopUp(true);
                                }}
                                hyperText={t('profile.remove_team')}
                                isLoading={isLoading}
                            ></BottomWrapper>
                        </>
                    )}
                </div>
                {deleteAccountPopUp && (
                    <PopUpRemoveMember
                        setRemoveMemberPopUp={setDeleteAccountPopUp}
                        isUserProfileEdit={true}
                        setShowEditPopUp={setUserDetailPopUp}
                    ></PopUpRemoveMember>
                )}
                {removeMemberPopUp && (
                    <PopUpRemoveMember
                        setRemoveMemberPopUp={setRemoveMemberPopUp}
                        fetchActiveMembers={fetchActiveMembers}
                        setShowEditPopUp={setUserDetailPopUp}
                    ></PopUpRemoveMember>
                )}
            </MultiStepPopup>
        </>
    );
}
