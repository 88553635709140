import React from 'react';
import './switch-atom.scss';

const Switch = ({ toggleVal, handleToggle, disabled }) => {
    return (
        <>
            <label className="switch">
                <input
                    checked={toggleVal}
                    onChange={() => {
                        !disabled && handleToggle();
                    }}
                    className="switch-input"
                    type="checkbox"
                ></input>
                <span
                    className={toggleVal ? 'switch-slider checked' : 'switch-slider unchecked'}
                    style={{
                        cursor: disabled ? 'not-allowed' : ''
                    }}
                ></span>
            </label>
        </>
    );
};

export default Switch;
