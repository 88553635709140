import React, { useState } from 'react';
import Pulse from 'react-reveal/Pulse';
import ClickAwayListener from 'react-click-away-listener';
import DownArrIcon from '../../assets/downarrow.svg';
import UpArrIcon from '../../assets/uparrow.svg';
import './customselect.scss';
import { useTranslation } from 'react-i18next';
export default function CustomSelect(props) {
    const {
        selectedOption,
        setSelectedOption,
        optionsList,
        containerClass,
        label,
        labelClass,
        selectType,
        updateInvites,
        currentIndex,
        customWidth,
        isAllowedDropdown
    } = props;

    const [selectedValue, setSelectedValue] = useState(selectedOption);
    const [isExpanded, setIsExpanded] = useState(false);
    const { t } = useTranslation();
    return (
        <div className="select-container">
            {label && <div className={labelClass}>{label}</div>}
            <div className={`containerselect ${containerClass}`} onClick={() => isAllowedDropdown && setIsExpanded(!isExpanded)}>
                {selectType === 'role' ? (
                    <div className={selectedValue === 'MEMBER' ? 'selected member' : 'selected admin'}>{selectedValue}</div>
                ) : (
                    <div className="selected-value">{selectedValue}</div>
                )}

                <div className="expand-btn" onClick={() => isAllowedDropdown && setIsExpanded(true)}>
                    <img src={isExpanded ? UpArrIcon : DownArrIcon} alt="" />
                </div>

                {isExpanded && (
                    <Pulse duration={200}>
                        <div className="list-div">
                            <ClickAwayListener onClickAway={() => setIsExpanded(false)}>
                                <ul
                                    className="list-container"
                                    style={{
                                        width: customWidth ? customWidth : selectType === 'role' ? '235px' : '',
                                        boxSizing: customWidth ? 'border-box' : ''
                                    }}
                                >
                                    {optionsList.map((option, index) => {
                                        if (selectType === 'role') {
                                            return (
                                                <li
                                                    className={option.key === 'MEMBER' ? 'option member' : 'option admin'}
                                                    key={index}
                                                    onClick={() => {
                                                        setSelectedOption(option.key);
                                                        setSelectedValue(option.key);
                                                        setIsExpanded(false);
                                                        updateInvites && updateInvites(currentIndex, selectType, option.key);
                                                    }}
                                                >
                                                    {option.display}
                                                    <span>
                                                        {option.key === 'MEMBER' ? t('profile.perm_member') : t('profile.perm_admin')}
                                                    </span>
                                                </li>
                                            );
                                        }
                                        return (
                                            <li
                                                className="option"
                                                key={index}
                                                onClick={() => {
                                                    setSelectedOption(option.key);
                                                    setSelectedValue(option.key);
                                                    setIsExpanded(false);
                                                    updateInvites && updateInvites(currentIndex, selectType, option.key);
                                                }}
                                            >
                                                {option.display}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </ClickAwayListener>
                        </div>
                    </Pulse>
                )}
            </div>
        </div>
    );
}
