import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import NoteComponent from '../NoteComponent';
import LoadingSpin from '../LoadingSpin';
import SwitchAtom from '../../components/SwitchAtom';
import { getSessionStorage, validateCompany } from '../../utils';
import { DOMAIN } from '../../constants/base';
import { postReq, putReq } from '../../utils/api';
import WebsiteLayout from '../../assets/website-layout.svg';
import VideoPlaceHolder from '../../assets/video-placeholder.png';
import CheckPaired from '../../assets/check-paired.svg';
import ClearTextIcon from '../../assets/ClearText.png';
import MinimizeCircleIcon from '../../assets/minimize-circle.svg';
import AlertIconBlack from '../../assets/warning-triangle-black.svg';

import './publishspace.scss';
import { toast } from 'react-toastify';
import TogglePrivateSpace from './togglePrivateSpace';

export default function PublishSpace(props) {
    const {
        spacePoster,
        videoFiles,
        setCurrentStep,
        spaceId,
        showPopupItem,
        setAvailableSkillsPoster,
        fetchActiveSpaces,
        directLink,
        privateSpace,
        webUrl,
        isEdit,
        setOpenEditProductPopUp,
        isSectionEdit,
        setEditSpaceSectionPopUp,
        setPairedLink,
        currentCta,
        setSpaceCtasUpdated,
        setPrivateSpace,
        spaceAgentId,
        isWidgetInstalled,
        setNoWidgetInstalled
    } = props;
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const userDetails = getSessionStorage('ss-user-spaces') || {};
    const [widgetLiveWarning, setWidgetLiveWarning] = useState(isWidgetInstalled);
    const [webPageError, setWebPageError] = useState(false);
    const [webPagePair, setWebPagePair] = useState(webUrl ? webUrl : '');
    const [webPageErrorMsg, setWebPageErrorMsg] = useState('');
    const [pairedSpaceExisting, setPairedSpaceExisting] = useState('');
    const [isMagicPaired, setIsMagicPaired] = useState(webUrl ? true : false);
    const [allowMagicPairing, setAllowMagicPairing] = useState(webUrl ? true : false);
    const [copyDirectLink, setCopyDirectLink] = useState(false);
    const [magicLinkSearchLoading, setIsMagicLinkSearchLoading] = useState(false);
    const { t } = useTranslation();
    const magicLinkRef = useRef(null);
    useEffect(() => {
        return debouncedWebPageSearchPair.cancel();
    });

    const handleWebPageSearchPair = async (value) => {
        setWebPagePair(value);
        let validURL = validateCompany(value);
        setWebPageError(!validURL);
        if (validURL) {
            setIsMagicLinkSearchLoading(true);
            const url = `${DOMAIN}/v1/dashboard/space/create-space/spaceURL/search`;
            let payload = {
                spaceURL: value
            };
            const requestHeaders = {
                authorization: userToken.accessToken
            };
            const statusResponse = await postReq({ url, payload, requestHeaders });
            const { code, data } = statusResponse;
            if (code === 200) {
                if (data?.address) {
                    setWebPageError(true);
                    setPairedSpaceExisting(data.address);
                } else {
                    setWebPageError(false);
                }
                setIsMagicLinkSearchLoading(false);
                setWidgetLiveWarning(data.isWidgetInstalled);
                setNoWidgetInstalled && setNoWidgetInstalled(data.isWidgetInstalled);
                setIsMagicPaired(false);
            }
        } else {
            setWebPageError(true);
            setWebPageErrorMsg(t('spaces.publish.webpage_error.invalid_url'));
        }
    };
    const debouncedWebPageSearchPair = useMemo(() => {
        return debounce(handleWebPageSearchPair, 300);
    }, []);

    const handleBrokenImage = (e) => {
        e.target.src = VideoPlaceHolder;
    };

    // Handling Session And Local Storage
    const handlePersistenStorage = () => {
        localStorage.setItem('ss-auth', JSON.stringify(userDetails));
        window.open('mySettings', '_blank');
    };

    const finishSpaceCreate = async () => {
        if (isMagicPaired) {
            document.body.classList.remove('body-ovef-hdn');
            setEditSpaceSectionPopUp && setEditSpaceSectionPopUp(false);
            showPopupItem(false);
            fetchActiveSpaces(true);
            return;
        }
        if (spaceId) {
            const url = `${DOMAIN}/v1/dashboard/space/${spaceId}`;
            const requestHeaders = {
                authorization: userToken.accessToken
            };
            let payload = {
                spaceURL: webPagePair
            };
            const updateResponse = await putReq({ url, payload, requestHeaders });
            const { code } = updateResponse;
            if (code === 200) {
                if (isSectionEdit) {
                    setPairedLink(webPagePair);
                    setEditSpaceSectionPopUp(false);
                    if (currentCta && setSpaceCtasUpdated) {
                        setSpaceCtasUpdated(currentCta);
                    }
                    return;
                }
                if (magicLinkRef.current && magicLinkRef.current.value.length === 0) {
                    showPopupItem(false);
                    document.body.classList.remove('body-ovef-hdn');
                }
                if (!isEdit) setAvailableSkillsPoster([]);
                setIsMagicPaired(true);
                showPopupItem(false);
                if (isEdit) {
                    setOpenEditProductPopUp(false);
                }
                fetchActiveSpaces();
            }
        }
    };

    const handleOpenSpacePairedWebPage = () => {
        if (webPagePair.includes('http://') || webPagePair.includes('https://')) {
            window.open(webPagePair, '_blank');
        } else {
            window.open(`//${webPagePair}`, '_blank');
        }
    };
    // Copying the Direct Link
    const doCopyDirectLink = (e) => {
        navigator.clipboard.writeText(directLink).then(
            function () {
                setCopyDirectLink(true);
                toast.success('Link copied to clipboard', {
                    position: 'top-center',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
                setTimeout(() => {
                    setCopyDirectLink(false);
                }, 3000);
            },
            function (err) {
                console.error('Async: Could not copy text: ', err);
            }
        );
    };
    return (
        <div className="publish-space-container">
            <>
                <div className="publish-space-wrapper">
                    <div className="demo">
                        <img src={WebsiteLayout} alt="web layout" />
                        <div className="widget">
                            <div className="poster">
                                <div className="poster-close">
                                    <img src={MinimizeCircleIcon} alt="minimize circle" />
                                </div>
                                {spacePoster ? (
                                    <div className="video-tour">
                                        <div className="green-cirlce"></div>
                                        <div className="text">{t('video_tour')}</div>
                                    </div>
                                ) : (
                                    ''
                                )}
                                <img src={spacePoster ? spacePoster : VideoPlaceHolder} onError={handleBrokenImage} alt="space poster" />
                            </div>
                        </div>
                    </div>

                    <div className={`link-container ${webPagePair.length ? 'link-container-link' : ''}`}>
                        <div className="header">
                            <div className="header-title">
                                <div className="title">{t('spaces.publish.pair.title')}</div>
                                <div className="subtitle">{t('spaces.publish.pair.subtitle')}</div>
                            </div>
                            <SwitchAtom
                                toggleVal={allowMagicPairing}
                                handleToggle={() => {
                                    setAllowMagicPairing(!allowMagicPairing);
                                    if (allowMagicPairing) {
                                        setIsMagicPaired(false);
                                        setWebPagePair('');
                                        setPairedLink('');
                                        magicLinkRef.current.value = '';
                                    }
                                }}
                            ></SwitchAtom>
                        </div>

                        {allowMagicPairing && (
                            <>
                                <div className="input-wrapper">
                                    <input
                                        type="text"
                                        className={webPagePair.length === 0 ? 'custom-placeholder' : ''}
                                        placeholder={webPagePair.length ? webPagePair : t('spaces.publish.pair.placeholder')}
                                        style={{
                                            border: webPageError ? '1px solid #F6655A' : ''
                                        }}
                                        // disabled={isMagicPaired ? true : false}
                                        ref={magicLinkRef}
                                        onChange={(e) => {
                                            debouncedWebPageSearchPair(e.target.value, 0);
                                            setIsMagicPaired(false);
                                        }}
                                    ></input>
                                    <button
                                        onClick={() => {
                                            setWidgetLiveWarning(true);
                                            setWebPagePair('');
                                            setIsMagicPaired(false);
                                        }}
                                    >
                                        <img
                                            src={ClearTextIcon}
                                            onClick={() => {
                                                magicLinkRef.current.value = '';
                                                setWebPageError(false);
                                            }}
                                            alt="clear-text"
                                        ></img>
                                    </button>
                                </div>
                                {!webPageError && !magicLinkSearchLoading && widgetLiveWarning && !webUrl && (
                                    <div className="magiclink-status">
                                        <NoteComponent message={t('spaces.publish.pair.initial_msg')} />
                                    </div>
                                )}
                                {magicLinkSearchLoading && !webPageError && (
                                    <div className="magiclink-search-loading">
                                        <LoadingSpin></LoadingSpin>
                                        <span>{t('checking')}</span>
                                    </div>
                                )}
                                {webPageError && magicLinkRef.current.value.length > 0 && (
                                    <div className="webpage-input-container-error">
                                        <div className="webpage-input-container-error">
                                            {pairedSpaceExisting ? (
                                                <div className="warning-wrapper paired-error-wrapper">
                                                    <img src={AlertIconBlack} alt="minimize icon"></img>
                                                    <div className="warning-text error-text">
                                                        <div className="title">
                                                            You already paired a Listing to this URL on your website
                                                        </div>
                                                        <div className="subtitle">{pairedSpaceExisting}</div>
                                                    </div>
                                                </div>
                                            ) : (
                                                webPageErrorMsg
                                            )}
                                        </div>
                                    </div>
                                )}
                                {!widgetLiveWarning && !webPageError && !magicLinkSearchLoading && (
                                    <div className="warning-wrapper">
                                        <img src={AlertIconBlack} alt="minimize icon"></img>
                                        <div className="warning-text">
                                            <div className="title">{t('spaces.publish.pair.warning.title')}</div>
                                            <div className="subtitle">{t('spaces.publish.pair.warning.subtitle')}</div>
                                        </div>
                                        <div className="warning-btn" onClick={handlePersistenStorage}>
                                            {t('spaces.publish.pair.warning.install')}
                                        </div>
                                    </div>
                                )}
                                {widgetLiveWarning && isMagicPaired && videoFiles.length > 0 && (
                                    <div className="success-pair">
                                        <div className="msg">
                                            <img src={CheckPaired} alt="paired icon" />
                                            <span>{t('spaces.publish.pair.success.title')}</span>
                                        </div>
                                        <div className="view-btn" onClick={handleOpenSpacePairedWebPage}>
                                            {t('spaces.publish.pair.success.view')}
                                        </div>
                                    </div>
                                )}
                                {widgetLiveWarning && isMagicPaired && videoFiles.length === 0 && (
                                    <div
                                        className="warning-wrapper success-pair"
                                        style={{
                                            height: 'auto',
                                            justifyContent: isSectionEdit ? 'flex-start' : ''
                                        }}
                                    >
                                        <img src={CheckPaired} alt="paired check"></img>
                                        <div
                                            className="warning-text"
                                            style={{
                                                maxWidth: '380px',
                                                margin: '0px'
                                            }}
                                        >
                                            <div className="title">{t('spaces.publish.pair.success.title')}</div>
                                            <div className="subtitle">{t('spaces.publish.pair.success.no_videos_msg')}</div>
                                        </div>
                                        {isSectionEdit ? (
                                            ''
                                        ) : (
                                            <div
                                                className="warning-btn"
                                                onClick={() => {
                                                    setCurrentStep('4');
                                                }}
                                            >
                                                {t('spaces.add_space.add_videos')}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <div className="link-container-br"></div>
                    <div className="link-container">
                        <div className="title">{t('spaces.share.title')}</div>
                        <div className="subtitle">{t('spaces.share.subtitle')}</div>
                        <div className="input-wrapper direct-link">
                            <input placeholder="Enter the url of your web page..." value={directLink}></input>
                            <button className="copy-btn" onClick={doCopyDirectLink}>
                                {copyDirectLink ? t('copied') : t('copy')}
                            </button>
                        </div>
                    </div>

                    <div
                        className="publish-space-card"
                        style={{
                            background: 'transparent',
                            height: '10px',
                            margin: '0px',
                            padding: '5px'
                        }}
                    ></div>

                    <div className="link-container-br"></div>
                    <TogglePrivateSpace
                        privateSpace={privateSpace}
                        setPrivateSpace={setPrivateSpace}
                        fetchActiveSpaces={fetchActiveSpaces}
                        spaceId={spaceId}
                        spaceAgentId={spaceAgentId}
                    ></TogglePrivateSpace>
                </div>
                <div className="bottom-wrapper">
                    <button
                        disabled={webPageError && !pairedSpaceExisting && magicLinkRef.current.value.length > 0 ? true : false}
                        onClick={finishSpaceCreate}
                    >
                        {isMagicPaired ? t('finish') : t('save')}
                    </button>
                </div>
            </>
        </div>
    );
}
