import React from "react";

const TextAtom = ({
  textContent,
  fontFamily,
  fontWeight,
  fontSize,
  lineHeight,
  color,
  className,
}) => {
  return (
    <div
      className={`text + " " + ${className}`}
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
        fontWeight: fontWeight,
        lineHeight: lineHeight,
        color: color,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
    >
      {textContent}
    </div>
  );
};

export default TextAtom;
