import Signin from '../pages/signin';
import MySpaces from '../pages/mySpaces';
import MySettings from '../pages/mySettings';
import Teams from '../pages/teams';
import MobileBlock from '../pages/mobileBlock';
import MyStats from '../pages/myStats';
import Invite from '../pages/invite';
import InviteLogin from '../pages/inviteLogin';
import Signup from '../pages/signup';
import HomePage from '../pages/homePage';
import TVIssues from '../pages/tvIssues';
import BackEndIssuePage from '../pages/BackEndIssuePage';
import AndroidTvPage from '../pages/androidTv';

export default [
    {
        path: '/',
        component: HomePage,
        exact: true
    },
    {
        path: '/signup',
        component: Signup,
        exact: true
    },
    {
        path: '/signin',
        component: Signin,
        exact: true
    },
    {
        path: '/myTeam',
        component: Teams,
        exact: true
    },
    {
        path: '/mySpaces',
        component: MySpaces,
        exact: true
    },
    {
        path: '/mySettings',
        component: MySettings,
        exact: true
    },
    {
        path: '/mobileBlock',
        component: MobileBlock,
        exact: true
    },
    {
        path: '/myStats',
        component: MyStats,
        exact: true
    },
    {
        path: '/invite',
        component: Invite,
        exact: true
    },
    {
        path: '/invite-login',
        component: InviteLogin,
        exact: true
    },
    {
        path: '/df4S1lgpw4/issues',
        component: TVIssues,
        exact: true
    },
    {
        path: '/4S0UXraL4J/backend-issues',
        component: BackEndIssuePage,
        exact: true
    },
    {
        path: '/android-tv',
        component: AndroidTvPage,
        exact: true
    },
    {
        path: '/*',
        component: HomePage,
        exact: true
    }
];
