import React, { useEffect, useState, useRef, useCallback } from 'react';
import './widgetSettings.scss';
import SwitchAtom from '../../components/SwitchAtom';
import LoadingSpin from '../../components/LoadingSpin';

import { postReq } from '../../utils/api';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
// Accordion
import {
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';
import VideoUploader from '../VideoUploader';
import { getSessionStorage, isEmpty, validateCompany, isSafari } from '../../utils';
import { DOMAIN } from '../../constants/base';
import debounce from 'lodash.debounce';
import VideoAtom from '../../components/VideoAtom';
import VideoTourLabel from '../../components/VideoTourLabel';

//Assets
import Caret from '../../assets/caret.svg';
import Bulb from '../../assets/info-bulb.svg';
import CopyCode from '../../assets/copy-code.svg';
import DesktopActive from '../../assets/desktop-active.svg';
import DesktopInActive from '../../assets/desktop-inactive.svg';
import MobileActive from '../../assets/mobile-active.svg';
import MobileInActive from '../../assets/mobile-inactive.svg';
import SpaceDetails from '../../assets/space-details.svg';
import Eye from '../../assets/eye-svg.svg';
import EyeWhite from '../../assets/eye-white.svg';
import Label from '../../assets/video-tour-new.svg';
import Minimise from '../../assets/minimise.svg';
import PaddingArea from '../../assets/padding-area.svg';
import PaddingAreaRight from '../../assets/padding-area-right.svg';
import PaddingAreaBottom from '../../assets/padding-entry-bottom.svg';
import DeleteWhite from '../../assets/delete-white-btn.svg';
import PreviewAssets from '../../assets/fs-assets.png';
import Dots from '../../assets/fs-dots.svg';
import Type from '../../assets/fs-type.svg';
import Left from '../../assets/fs-left.png';
import Right from '../../assets/fs-right.png';
import Skills from '../../assets/fs-tab.png';
import EntrySearchIcon from '../../assets/entry-search.svg';
import RoomDetails from '../../assets/fs-details.svg';
import Powered from '../../assets/powered.svg';
import CheckPaired from '../../assets/check-paired.svg';
import HomepageBg from '../../assets/homepage-bg.png';
import SmallPlay from '../../assets/small-play.svg';
import SearchIconW from '../../assets/search-icon-w.svg';
import SearchShadow from '../../assets/search-with-shadow.svg';
import CaretDownWhite from '../../assets/caret-down-white.svg';
import ContextualVideo from '../../assets/contextual-bg-video.mp4';
import FullscreenVideo from '../../assets/fullscreen-bg-video.mp4';
import HomepageVideo from '../../assets/homepage-bg-video.mp4';
import AlertIconBlack from '../../assets/warning-triangle-black.svg';
import BlackBg from '../../assets/black-bg.jpeg';
import EmailLead from '../../assets/lead-email.png';
import PhoneLead from '../../assets/lead-phone.png';
import CheckMark from '../../assets/check-blue.svg';
import UnCheck from '../../assets/uncheck.svg';
import { useTranslation } from 'react-i18next';

function WidgetSettings(props) {
    const {
        isEmbedCodeLoading,
        embedCode,
        doCopyCode,
        isCodeCopied,
        contextualWidgetDesktopSettings,
        setContextualWidgetDesktopSettings,
        contextualWidgetMobileSettings,
        setContextualWidgetMobileSettings,
        setContextualWidgetFlag,
        fullScreenSettings,
        setFullScreenSettings,
        updateFullScreeenSettings,
        homePageWidgetDesktopSettings,
        setHomePageWidgetDesktopSettings,
        homePageWidgetMobileSettings,
        setHomePageWidgetMobileSettings,
        setHomepageWidgetFlag,
        handleNumberChangeBottom,
        handleNumberChangeSide,
        widgetCustomize,
        setLeadRegistrationFlag,
        leadRegistrationSettings,
        setLeadRegistrationSettings
    } = props;

    const [toggleDesktopMobile, setToggleDesktopMobile] = useState(false);

    // const [widgetSettings, setWidgetSettings] = useState(contextualWidgetDesktopSettings);

    // states for homescreen video
    // const webPageUrl = homePageWidgetDesktopSettings.webPageURL || homePageWidgetMobileSettings.webPageURL;
    const [showVideoUpload, setShowVideoUpload] = useState(false);
    const [homeWidgetVideo, setHomeWidgetVideo] = useState(null);

    const [webPageErrorMsg, setWebPageErrorMsg] = useState('');
    const [isMagicPaired, setIsMagicPaired] = useState(false);
    const [webPageError, setWebPageError] = useState(false);
    const [magicLinkSearchLoading, setIsMagicLinkSearchLoading] = useState(false);
    const magicLinkRef = useRef(null);
    // if (webPagePair.length && magicLinkRef && magicLinkRef.current) {
    //     magicLinkRef.current.value = webPagePair;
    // }
    const [homePageVideoDetails, setHomePageVideoDetails] = useState({});
    const [widgetLiveWarning, setWidgetLiveWarning] = useState(true);
    const [showSuccessPaired, setShowSuccessPaired] = useState(false);
    const [emailChoosen, setEmailChoosen] = useState(leadRegistrationSettings.viaEmail ? true : false);
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const userDetails = getSessionStorage('ss-user-spaces') || {};
    const [homePagePairingURL, setHomePagePairingURL] = useState('');
    const { t: widget } = useTranslation();
    const [isHomePageWidgetPaired, setIsHomePageWidgetPaired] = useState(false);

    const scrollBug = useRef(null);

    useEffect(() => {
        if (homePageWidgetDesktopSettings.webPageURL || homePageWidgetMobileSettings.webPageURL) {
            setHomePagePairingURL(homePageWidgetMobileSettings.webPageURL);
            setIsHomePageWidgetPaired(true);
        }
    }, [homePageWidgetDesktopSettings, homePageWidgetMobileSettings]);

    useEffect(() => {
        if (magicLinkRef.current) {
            if (toggleDesktopMobile && homePageWidgetMobileSettings?.webPageURL) {
                setHomePagePairingURL(homePageWidgetMobileSettings.webPageURL);
            } else if (homePageWidgetDesktopSettings?.webPageURL) {
                setHomePagePairingURL(homePageWidgetDesktopSettings.webPageURL);
            }
        }
    }, [toggleDesktopMobile]);
    // useEffect to trigger video upload
    useEffect(() => {
        if (!isEmpty(homePageVideoDetails)) {
            if (toggleDesktopMobile) {
                setHomepageWidgetFlag(true);
                setHomePageWidgetMobileSettings({
                    ...homePageWidgetMobileSettings,
                    videoBackgroundUrl: homePageVideoDetails.videoPath,
                    videoExt: homePageVideoDetails.ext
                });
            } else {
                setHomepageWidgetFlag(true);
                setHomePageWidgetDesktopSettings({
                    ...homePageWidgetDesktopSettings,
                    videoBackgroundUrl: homePageVideoDetails.videoPath,
                    videoExt: homePageVideoDetails.ext
                });
            }
        }
    }, [homePageVideoDetails]);

    // method to toggle between desktop and mobile
    const togglerMethod = () => {
        setToggleDesktopMobile(!toggleDesktopMobile);
    };

    // Handling Drag & Drop
    const onDrop = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            toast.error('File Limit Size Exceeded (99 MB)', {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
            return;
        }
        acceptedFiles[0].id = 1;
        setHomeWidgetVideo(acceptedFiles[0]);
        setShowVideoUpload(true);
    };

    // video upload controller
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.mp4,.mov',
        maxSize: 103809024
    });

    const handleWebPageSearchPair = async (value, index) => {
        if (value.length === 0) {
            setHomepageWidgetFlag(true);
            setHomePageWidgetDesktopSettings({
                ...homePageWidgetDesktopSettings,
                webPageURL: value
            });
            setHomePageWidgetMobileSettings({
                ...homePageWidgetMobileSettings,
                webPageURL: value
            });
            setHomePagePairingURL('');
            setIsHomePageWidgetPaired(false);
        }
        const isValidUrl = validateCompany(value);
        setWebPageError(!isValidUrl);
        if (isValidUrl) {
            setIsMagicLinkSearchLoading(true);
            const url = `${DOMAIN}/v1/dashboard/space/create-space/spaceURL/search`;
            let payload = {
                spaceURL: value
            };
            const requestHeaders = {
                authorization: userToken.accessToken
            };
            const statusResponse = await postReq({ url, payload, requestHeaders });
            const { error, data, successCode } = statusResponse;
            if (successCode === 'SUCC200') {
                setHomepageWidgetFlag(true);
                setHomePageWidgetMobileSettings({
                    ...homePageWidgetMobileSettings,
                    webPageURL: value
                });
                setHomepageWidgetFlag(true);
                setHomePageWidgetDesktopSettings({
                    ...homePageWidgetDesktopSettings,
                    webPageURL: value
                });
                setIsMagicLinkSearchLoading(false);
                setWidgetLiveWarning(data.isWidgetInstalled);
                if (data.isWidgetInstalled) {
                    setShowSuccessPaired(true);
                } else {
                    setShowSuccessPaired(false);
                }
                setIsHomePageWidgetPaired(true);
                if (scrollBug?.current && isSafari) {
                    scrollBug.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center'
                    });
                }
                return;
            }
            if (error === 'ERR409') {
                setWebPageErrorMsg('You already paired a Space to this URL. Please use a different URL to avoid conflict');
                setWebPageError(true);
                setIsMagicLinkSearchLoading(false);
            } else {
                setWebPageError(false);
                setIsMagicLinkSearchLoading(false);
                setIsMagicPaired(false);
            }
        } else {
            setWebPageError(true);
            setWebPageErrorMsg(
                "You input an invalid url, it's important you paste the link to the exact listing page you want the content to appear on your website."
            );
        }
    };

    const debouncedFn = useCallback(debounce(handleWebPageSearchPair, 2500), []);

    const handleOpenSpacePairedWebPage = () => {
        if (homePagePairingURL.includes('http://') || homePagePairingURL.includes('https://')) {
            window.open(homePagePairingURL, '_blank');
        } else {
            window.open(`//${homePagePairingURL}`, '_blank');
        }
    };

    // Handling Session And Local Storage
    const handlePersistenStorage = () => {
        localStorage.setItem('ss-auth', JSON.stringify(userDetails));
        window.open('mySettings', '_blank');
    };

    const handleBrokenImage = (e) => {
        e.target.src = BlackBg;
    };

    return (
        <>
            {/* website info */}
            <div className="widget-settings-accordion">
                <AccordionItem uuid={1}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <div className="pos-rel widget-settings-accordion-container">
                                <h4>
                                    <span>1</span>
                                    {widget('settings.widget.website.title')}
                                </h4>

                                <AccordionItemState>
                                    {({ expanded }) => (
                                        <img className={`pos-abs ${expanded ? 'active-caret' : 'passive-caret'}`} src={Caret} alt="caret" />
                                    )}
                                </AccordionItemState>
                            </div>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className="widget-settings-accordion-container-website">
                            <div className="widget-settings-accordion-container-website-info">
                                <img src={Bulb} alt="Bulb" />
                                <h4>{widget('settings.widget.website.install_note')}</h4>
                            </div>
                            <div className="widget-settings-accordion-container-website-snippet">
                                {isEmbedCodeLoading ? (
                                    <div className="d-flex f-col center-align h-100vh">
                                        {widget('settings.widget.website.loading_note')}
                                    </div>
                                ) : (
                                    <div className="d-flex pos-rel fs-12">
                                        <pre className="code w-100p">
                                            <code>{embedCode}</code>
                                        </pre>
                                        <div
                                            className="pos-abs d-flex fs-10 center-align bg-white c-black p-10 cc-btn"
                                            onClick={doCopyCode}
                                        >
                                            <div className="cur-pointer d-flex center-align">
                                                <img src={CopyCode} className="pr-12" alt="Copy Icon"></img>
                                                {isCodeCopied ? widget('copied') : widget('settings.widget.website.copy_code')}{' '}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
            </div>
            {widgetCustomize() ? (
                <>
                    {/* contextual widget */}
                    <div className="widget-settings-accordion">
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <div className="pos-rel widget-settings-accordion-container">
                                        <h4>
                                            <span>2</span>
                                            {widget('settings.widget.contextual.title')}
                                        </h4>

                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                <img
                                                    className={`pos-abs ${expanded ? 'active-caret' : 'passive-caret'}`}
                                                    src={Caret}
                                                    alt="caret"
                                                />
                                            )}
                                        </AccordionItemState>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="widget-settings-accordion-container-context">
                                    <div className="widget-settings-accordion-container-context-content">
                                        <div className="widget-settings-accordion-container-context-content-heading">
                                            <h4>{widget('settings.widget.contextual.sub_title')}</h4>
                                            <div className="toggler">
                                                <h4>
                                                    {toggleDesktopMobile
                                                        ? widget('settings.widget.contextual.mobile_mode')
                                                        : widget('settings.widget.contextual.desktop_mode')}
                                                </h4>
                                                <div className="toggler-container">
                                                    <div
                                                        onClick={() => togglerMethod()}
                                                        className={`toggler-container-box ${toggleDesktopMobile ? 'inactive' : 'active'}`}
                                                    >
                                                        <img src={toggleDesktopMobile ? DesktopInActive : DesktopActive} alt="Desktop" />
                                                    </div>
                                                    <div
                                                        onClick={() => togglerMethod()}
                                                        className={`toggler-container-box ${toggleDesktopMobile ? 'active' : 'inactive'}`}
                                                    >
                                                        <img src={toggleDesktopMobile ? MobileActive : MobileInActive} alt="Mobile" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="widget-settings-accordion-container-context-content-middle">
                                            <div className="control-section">
                                                <div className="control-section-info">
                                                    <img src={Bulb} alt="Bulb" />
                                                    <h4>{widget('settings.widget.contextual.note')}</h4>
                                                </div>
                                                <h4 className="control-section-looks">{widget('looks')}</h4>
                                                <h4 className="control-section-size mb-10">{widget('widget_size')}</h4>
                                                <div className="control-section-selectors">
                                                    {toggleDesktopMobile ? (
                                                        <>
                                                            <div
                                                                onClick={() => {
                                                                    setContextualWidgetFlag(true);
                                                                    setContextualWidgetMobileSettings({
                                                                        ...contextualWidgetMobileSettings,
                                                                        widgetSize: 's',
                                                                        showViews: false,
                                                                        showLabel: false,
                                                                        allowMinimisation: false
                                                                    });
                                                                }}
                                                                className={`control-section-selectors-sizes mobile ${
                                                                    contextualWidgetMobileSettings.widgetSize === 's' ? 'active' : ''
                                                                }`}
                                                            >
                                                                S
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    setContextualWidgetFlag(true);
                                                                    setContextualWidgetMobileSettings({
                                                                        ...contextualWidgetMobileSettings,
                                                                        widgetSize: 'm'
                                                                    });
                                                                }}
                                                                className={`control-section-selectors-sizes mobile ${
                                                                    contextualWidgetMobileSettings.widgetSize === 'm' ? 'active' : ''
                                                                }`}
                                                            >
                                                                M
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div
                                                                onClick={() => {
                                                                    setContextualWidgetFlag(true);
                                                                    setContextualWidgetDesktopSettings({
                                                                        ...contextualWidgetDesktopSettings,
                                                                        widgetSize: 's'
                                                                    });
                                                                }}
                                                                className={`control-section-selectors-sizes mobile ${
                                                                    contextualWidgetDesktopSettings.widgetSize === 's' ? 'active' : ''
                                                                }`}
                                                            >
                                                                S
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    setContextualWidgetFlag(true);
                                                                    setContextualWidgetDesktopSettings({
                                                                        ...contextualWidgetDesktopSettings,
                                                                        widgetSize: 'm'
                                                                    });
                                                                }}
                                                                className={`control-section-selectors-sizes mobile ${
                                                                    contextualWidgetDesktopSettings.widgetSize === 'm' ? 'active' : ''
                                                                }`}
                                                            >
                                                                M
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    setContextualWidgetFlag(true);
                                                                    setContextualWidgetDesktopSettings({
                                                                        ...contextualWidgetDesktopSettings,
                                                                        widgetSize: 'l'
                                                                    });
                                                                }}
                                                                className={`control-section-selectors-sizes mobile ${
                                                                    contextualWidgetDesktopSettings.widgetSize === 'l' ? 'active' : ''
                                                                }`}
                                                            >
                                                                L
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <h4 className="control-section-size mb-5">{widget('show_views')}</h4>
                                                <div className="d-flex space-between general-switch">
                                                    <div className="general-switch-info">
                                                        <img src={Eye} alt="eye" />
                                                        <span>111</span>
                                                    </div>
                                                    {toggleDesktopMobile ? (
                                                        contextualWidgetMobileSettings.widgetSize === 's' ? (
                                                            <SwitchAtom toggleVal={false} disabled={true} />
                                                        ) : (
                                                            <SwitchAtom
                                                                toggleVal={contextualWidgetMobileSettings.showViews}
                                                                handleToggle={() => {
                                                                    setContextualWidgetFlag(true);
                                                                    setContextualWidgetMobileSettings({
                                                                        ...contextualWidgetMobileSettings,
                                                                        showViews: !contextualWidgetMobileSettings.showViews
                                                                    });
                                                                }}
                                                            />
                                                        )
                                                    ) : (
                                                        <SwitchAtom
                                                            toggleVal={contextualWidgetDesktopSettings.showViews}
                                                            handleToggle={() => {
                                                                setContextualWidgetFlag(true);
                                                                setContextualWidgetDesktopSettings({
                                                                    ...contextualWidgetDesktopSettings,
                                                                    showViews: !contextualWidgetDesktopSettings.showViews
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                <h4 className="control-section-size mb-5">{widget('show_label')}</h4>
                                                <div className="d-flex space-between general-switch">
                                                    <div className="general-switch-info">
                                                        <img src={Label} alt="eye" />
                                                    </div>
                                                    {toggleDesktopMobile ? (
                                                        contextualWidgetMobileSettings.widgetSize === 's' ? (
                                                            <SwitchAtom toggleVal={false} disabled={true} />
                                                        ) : (
                                                            <SwitchAtom
                                                                toggleVal={contextualWidgetMobileSettings.showLabel}
                                                                handleToggle={() => {
                                                                    setContextualWidgetFlag(true);
                                                                    setContextualWidgetMobileSettings({
                                                                        ...contextualWidgetMobileSettings,
                                                                        showLabel: !contextualWidgetMobileSettings.showLabel
                                                                    });
                                                                }}
                                                            />
                                                        )
                                                    ) : (
                                                        <SwitchAtom
                                                            toggleVal={contextualWidgetDesktopSettings.showLabel}
                                                            handleToggle={() => {
                                                                setContextualWidgetFlag(true);
                                                                setContextualWidgetDesktopSettings({
                                                                    ...contextualWidgetDesktopSettings,
                                                                    showLabel: !contextualWidgetDesktopSettings.showLabel
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                <h4 className="control-section-size mb-5">{widget('minimize')}</h4>
                                                <div className="d-flex space-between general-switch">
                                                    <div className="general-switch-info">
                                                        <img src={Minimise} alt="eye" />
                                                    </div>
                                                    {toggleDesktopMobile ? (
                                                        contextualWidgetMobileSettings.widgetSize === 's' ? (
                                                            <SwitchAtom toggleVal={false} disabled={true} />
                                                        ) : (
                                                            <SwitchAtom
                                                                toggleVal={contextualWidgetMobileSettings.allowMinimisation}
                                                                handleToggle={() => {
                                                                    setContextualWidgetFlag(true);
                                                                    setContextualWidgetMobileSettings({
                                                                        ...contextualWidgetMobileSettings,
                                                                        allowMinimisation: !contextualWidgetMobileSettings.allowMinimisation
                                                                    });
                                                                }}
                                                            />
                                                        )
                                                    ) : (
                                                        <SwitchAtom
                                                            toggleVal={contextualWidgetDesktopSettings.allowMinimisation}
                                                            handleToggle={() => {
                                                                setContextualWidgetFlag(true);
                                                                setContextualWidgetDesktopSettings({
                                                                    ...contextualWidgetDesktopSettings,
                                                                    allowMinimisation: !contextualWidgetDesktopSettings.allowMinimisation
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                <hr />
                                                <h4 className="control-section-looks">{widget('position')}</h4>
                                                <h4 className="control-section-size mb-10">{widget('widget_position')}</h4>
                                                <div className="control-section-selectors">
                                                    {toggleDesktopMobile ? (
                                                        <div
                                                            onClick={() => {
                                                                setContextualWidgetFlag(true);
                                                                setContextualWidgetMobileSettings({
                                                                    ...contextualWidgetMobileSettings,
                                                                    position: false
                                                                });
                                                            }}
                                                            className={`control-section-selectors-position ${
                                                                contextualWidgetMobileSettings.position ? '' : 'active'
                                                            }`}
                                                        >
                                                            {widget('position_left')}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            onClick={() => {
                                                                setContextualWidgetFlag(true);
                                                                setContextualWidgetDesktopSettings({
                                                                    ...contextualWidgetDesktopSettings,
                                                                    position: false
                                                                });
                                                            }}
                                                            className={`control-section-selectors-position ${
                                                                contextualWidgetDesktopSettings.position ? '' : 'active'
                                                            }`}
                                                        >
                                                            {widget('position_left')}
                                                        </div>
                                                    )}
                                                    {toggleDesktopMobile ? (
                                                        <div
                                                            onClick={() => {
                                                                setContextualWidgetFlag(true);
                                                                setContextualWidgetMobileSettings({
                                                                    ...contextualWidgetMobileSettings,
                                                                    position: true
                                                                });
                                                            }}
                                                            className={`control-section-selectors-position ${
                                                                contextualWidgetMobileSettings.position ? 'active' : ''
                                                            }`}
                                                        >
                                                            {widget('position_right')}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            onClick={() => {
                                                                setContextualWidgetFlag(true);
                                                                setContextualWidgetDesktopSettings({
                                                                    ...contextualWidgetDesktopSettings,
                                                                    position: true
                                                                });
                                                            }}
                                                            className={`control-section-selectors-position ${
                                                                contextualWidgetDesktopSettings.position ? 'active' : ''
                                                            }`}
                                                        >
                                                            {widget('position_right')}
                                                        </div>
                                                    )}
                                                </div>
                                                <h4 className="control-section-size mb-5">{widget('spacing')}</h4>
                                                <div className="control-section-padding">
                                                    {toggleDesktopMobile ? (
                                                        <>
                                                            <img
                                                                src={
                                                                    contextualWidgetMobileSettings.position ? PaddingAreaRight : PaddingArea
                                                                }
                                                                alt="PaddingArea"
                                                            />
                                                            <input
                                                                className={`styling ${
                                                                    contextualWidgetMobileSettings.position ? 'side-right' : 'side'
                                                                }`}
                                                                type="text"
                                                                maxLength="3"
                                                                placeholder="100"
                                                                defaultValue={'0'}
                                                                value={
                                                                    contextualWidgetMobileSettings?.sidePX
                                                                        ? contextualWidgetMobileSettings.sidePX
                                                                        : 0
                                                                }
                                                                onChange={(e) => {
                                                                    setContextualWidgetFlag(true);
                                                                    handleNumberChangeSide(e, toggleDesktopMobile, true);
                                                                }}
                                                            />
                                                            <input
                                                                className="styling bottom"
                                                                type="text"
                                                                maxLength="3"
                                                                placeholder="100"
                                                                defaultValue={'0'}
                                                                value={
                                                                    contextualWidgetMobileSettings?.bottomPX
                                                                        ? contextualWidgetMobileSettings.bottomPX
                                                                        : 0
                                                                }
                                                                onChange={(e) => {
                                                                    setContextualWidgetFlag(true);
                                                                    handleNumberChangeBottom(e, toggleDesktopMobile, true);
                                                                }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <img
                                                                src={
                                                                    contextualWidgetDesktopSettings.position
                                                                        ? PaddingAreaRight
                                                                        : PaddingArea
                                                                }
                                                                alt="PaddingArea"
                                                            />
                                                            <input
                                                                className={`styling ${
                                                                    contextualWidgetDesktopSettings.position ? 'side-right' : 'side'
                                                                }`}
                                                                type="text"
                                                                maxLength="3"
                                                                placeholder="100"
                                                                defaultValue={'0'}
                                                                value={
                                                                    contextualWidgetDesktopSettings?.sidePX
                                                                        ? contextualWidgetDesktopSettings.sidePX
                                                                        : 0
                                                                }
                                                                onChange={(e) => {
                                                                    setContextualWidgetFlag(true);
                                                                    handleNumberChangeSide(e, toggleDesktopMobile, true);
                                                                }}
                                                            />
                                                            <input
                                                                className="styling bottom"
                                                                type="text"
                                                                maxLength="3"
                                                                placeholder="100"
                                                                defaultValue={'0'}
                                                                value={
                                                                    contextualWidgetDesktopSettings?.bottomPX
                                                                        ? contextualWidgetDesktopSettings.bottomPX
                                                                        : 0
                                                                }
                                                                onChange={(e) => {
                                                                    setContextualWidgetFlag(true);
                                                                    handleNumberChangeBottom(e, toggleDesktopMobile, true);
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="preview-section">
                                                <h4>{widget('preview_area')}</h4>
                                                <div
                                                    className={
                                                        toggleDesktopMobile
                                                            ? `preview-section-container ${
                                                                  contextualWidgetMobileSettings.widgetSize === 's'
                                                                      ? 'mobile-small'
                                                                      : contextualWidgetMobileSettings.widgetSize === 'm'
                                                                      ? 'small'
                                                                      : ''
                                                              }`
                                                            : `preview-section-container ${
                                                                  contextualWidgetDesktopSettings.widgetSize === 's'
                                                                      ? 'small'
                                                                      : contextualWidgetDesktopSettings.widgetSize === 'm'
                                                                      ? 'medium'
                                                                      : 'large'
                                                              }`
                                                    }
                                                >
                                                    {/* <img className="preview-bg" src={Preview} alt="Preview" /> */}
                                                    <VideoAtom
                                                        src={homePageVideoDetails?.videoExt ? HomepageVideo.data : ContextualVideo}
                                                    />
                                                    {toggleDesktopMobile
                                                        ? contextualWidgetMobileSettings.showLabel &&
                                                          contextualWidgetMobileSettings.widgetSize !== 's' && (
                                                              <VideoTourLabel
                                                                  size={contextualWidgetMobileSettings.widgetSize === 'm' && 's'}
                                                                  top="10px"
                                                                  left="10px"
                                                              />
                                                          )
                                                        : contextualWidgetDesktopSettings.showLabel && (
                                                              <VideoTourLabel
                                                                  size={contextualWidgetDesktopSettings.widgetSize}
                                                                  top="10px"
                                                                  left="10px"
                                                              />
                                                          )}
                                                    {toggleDesktopMobile && contextualWidgetMobileSettings.widgetSize === 's' && (
                                                        <img className="small-play" src={SmallPlay} alt="" />
                                                    )}
                                                    {toggleDesktopMobile
                                                        ? contextualWidgetMobileSettings.allowMinimisation &&
                                                          contextualWidgetMobileSettings.widgetSize !== 's' && (
                                                              <img className="minimise" src={Minimise} alt="Minimise" />
                                                          )
                                                        : contextualWidgetDesktopSettings.allowMinimisation && (
                                                              <img className="minimise" src={Minimise} alt="Minimise" />
                                                          )}
                                                    {toggleDesktopMobile
                                                        ? contextualWidgetMobileSettings.showViews &&
                                                          contextualWidgetMobileSettings.widgetSize !== 's' && (
                                                              <div className="eye">
                                                                  <img src={EyeWhite} alt="eye" />
                                                                  <span>111</span>
                                                              </div>
                                                          )
                                                        : contextualWidgetDesktopSettings.showViews && (
                                                              <div className="eye">
                                                                  <img src={EyeWhite} alt="eye" />
                                                                  <span>111</span>
                                                              </div>
                                                          )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </div>

                    {/* full screen experience widget */}
                    <div className="widget-settings-accordion">
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <div className="pos-rel widget-settings-accordion-container">
                                        <h4>
                                            <span>3</span>
                                            {widget('settings.widget.fullscreen.title')}
                                        </h4>

                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                <img
                                                    className={`pos-abs ${expanded ? 'active-caret' : 'passive-caret'}`}
                                                    src={Caret}
                                                    alt="caret"
                                                />
                                            )}
                                        </AccordionItemState>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="widget-settings-accordion-container-context">
                                    <div className="widget-settings-accordion-container-context-content">
                                        <div className="widget-settings-accordion-container-context-content-middle">
                                            <div className="control-section">
                                                <div className="control-section-info">
                                                    <img src={Bulb} alt="Bulb" />
                                                    <h4>{widget('settings.widget.fullscreen.note')}</h4>
                                                </div>
                                                <h4 className="control-section-size mb-5">
                                                    {widget('settings.widget.fullscreen.cross_exp.title')}
                                                </h4>
                                                <div className="d-flex space-between general-switch">
                                                    <div className="general-switch-info">
                                                        <span>{widget('settings.widget.fullscreen.cross_exp.subtitle')} </span>
                                                    </div>
                                                    <SwitchAtom
                                                        toggleVal={fullScreenSettings.crossSellingExperience}
                                                        handleToggle={() => {
                                                            updateFullScreeenSettings({
                                                                ...fullScreenSettings,
                                                                crossSellingExperience: !fullScreenSettings.crossSellingExperience
                                                            });
                                                            setFullScreenSettings({
                                                                ...fullScreenSettings,
                                                                crossSellingExperience: !fullScreenSettings.crossSellingExperience
                                                            });
                                                        }}
                                                    ></SwitchAtom>
                                                </div>
                                                <h4 className="control-section-size mb-5">
                                                    {widget('settings.widget.fullscreen.show_address.title')}
                                                </h4>
                                                <div className="d-flex space-between general-switch">
                                                    <div className="general-switch-info">
                                                        <span>{widget('settings.widget.fullscreen.show_address.subtitle')}</span>
                                                    </div>
                                                    <SwitchAtom
                                                        toggleVal={fullScreenSettings.showAddress}
                                                        handleToggle={() => {
                                                            updateFullScreeenSettings({
                                                                ...fullScreenSettings,
                                                                showAddress: !fullScreenSettings.showAddress
                                                            });
                                                            setFullScreenSettings({
                                                                ...fullScreenSettings,
                                                                showAddress: !fullScreenSettings.showAddress
                                                            });
                                                        }}
                                                    ></SwitchAtom>
                                                </div>
                                                <h4 className="control-section-size mb-5">
                                                    {widget('settings.widget.fullscreen.show_price.title')}
                                                </h4>
                                                <div className="d-flex space-between general-switch">
                                                    <div className="general-switch-info">
                                                        <span>{widget('settings.widget.fullscreen.show_price.subtitle')}</span>
                                                    </div>
                                                    <SwitchAtom
                                                        toggleVal={fullScreenSettings.showPrice}
                                                        handleToggle={() => {
                                                            updateFullScreeenSettings({
                                                                ...fullScreenSettings,
                                                                showPrice: !fullScreenSettings.showPrice
                                                            });
                                                            setFullScreenSettings({
                                                                ...fullScreenSettings,
                                                                showPrice: !fullScreenSettings.showPrice
                                                            });
                                                        }}
                                                    ></SwitchAtom>
                                                </div>
                                                <h4 className="control-section-size mb-5">
                                                    {widget('settings.widget.fullscreen.show_details.title')}
                                                </h4>
                                                <div className="d-flex space-between general-switch">
                                                    <div className="general-switch-info">
                                                        <img src={SpaceDetails} alt="Space Setails" />
                                                    </div>
                                                    <SwitchAtom
                                                        toggleVal={fullScreenSettings.showDetails}
                                                        handleToggle={() => {
                                                            updateFullScreeenSettings({
                                                                ...fullScreenSettings,
                                                                showDetails: !fullScreenSettings.showDetails
                                                            });
                                                            setFullScreenSettings({
                                                                ...fullScreenSettings,
                                                                showDetails: !fullScreenSettings.showDetails
                                                            });
                                                        }}
                                                    ></SwitchAtom>
                                                </div>
                                            </div>
                                            <div className="preview-section">
                                                <h4>{widget('preview_area')}</h4>
                                                <div className="preview-section-container full-screen">
                                                    <VideoAtom src={FullscreenVideo} />
                                                    {/* <img className="preview-bg" src={Preview} alt="Preview" /> */}
                                                    {fullScreenSettings.showPrice ? (
                                                        <div className="price-container">
                                                            <span className="currency-type">£</span>
                                                            <span>4000</span>
                                                            <span className="price-type"> /{widget('month')}</span>
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {fullScreenSettings.showAddress ? (
                                                        <div className="fs-address">
                                                            Riverside Building, County Hall, South Bank, London SE1 7PB, UK
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                    <div className="fs-details">
                                                        <div>
                                                            <img src={Type} alt="rent banner" />{' '}
                                                        </div>
                                                        {fullScreenSettings.showDetails ? (
                                                            <div
                                                                style={{
                                                                    height: '100%'
                                                                }}
                                                            >
                                                                <img src={RoomDetails} alt="space details" />{' '}
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>

                                                    <img className="preview-assets" src={PreviewAssets} alt="Preview" />
                                                    <img className="preview-skills" src={Skills} alt="Preview" />
                                                </div>
                                                {fullScreenSettings.crossSellingExperience ? (
                                                    <>
                                                        <img className="preview-dots" src={Dots} alt="Preview" />
                                                        <img className="preview-left" src={Left} alt="Preview" />
                                                        <img className="preview-right" src={Right} alt="Preview" />
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </div>

                    {/* homepage/entry point widget */}
                    <div className="widget-settings-accordion">
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <div className="pos-rel widget-settings-accordion-container">
                                        <h4>
                                            <span>4</span>
                                            {widget('settings.widget.homepage.title')}
                                            <span className="beta">Beta</span>
                                        </h4>

                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                <img
                                                    className={`pos-abs ${expanded ? 'active-caret' : 'passive-caret'}`}
                                                    src={Caret}
                                                    alt="caret"
                                                />
                                            )}
                                        </AccordionItemState>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="widget-settings-accordion-container-context">
                                    {isHomePageWidgetPaired ? (
                                        <div className="widget-settings-accordion-container-context-content">
                                            <div className="widget-settings-accordion-container-context-content-heading">
                                                <h4>{widget('settings.widget.homepage.sub_title')}</h4>
                                                <div className="toggler">
                                                    <h4>
                                                        {' '}
                                                        {toggleDesktopMobile
                                                            ? widget('settings.widget.homepage.mobile_mode')
                                                            : widget('settings.widget.homepage.desktop_mode')}
                                                    </h4>
                                                    <div className="toggler-container">
                                                        <div
                                                            onClick={() => togglerMethod()}
                                                            className={`toggler-container-box ${
                                                                toggleDesktopMobile ? 'inactive' : 'active'
                                                            }`}
                                                        >
                                                            <img
                                                                src={toggleDesktopMobile ? DesktopInActive : DesktopActive}
                                                                alt="Desktop"
                                                            />
                                                        </div>
                                                        <div
                                                            onClick={() => togglerMethod()}
                                                            className={`toggler-container-box ${
                                                                toggleDesktopMobile ? 'active' : 'inactive'
                                                            }`}
                                                        >
                                                            <img src={toggleDesktopMobile ? MobileActive : MobileInActive} alt="Mobile" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="widget-settings-accordion-container-context-content-middle">
                                                <div className="control-section">
                                                    <div className="control-section-info">
                                                        <img src={Bulb} alt="Bulb" />
                                                        <h4>{widget('settings.widget.homepage.note')}</h4>
                                                    </div>
                                                    <h4 className="control-section-looks">{widget('looks')}</h4>
                                                    <h4 className="control-section-size mb-10">{widget('size_mode')}</h4>
                                                    <div className="control-section-selectors">
                                                        {toggleDesktopMobile ? (
                                                            <>
                                                                <div
                                                                    onClick={() => {
                                                                        setHomepageWidgetFlag(true);
                                                                        setHomePageWidgetMobileSettings({
                                                                            ...homePageWidgetMobileSettings,
                                                                            widgetSize: 's',
                                                                            showFilters: false,
                                                                            showLabel: false,
                                                                            allowMinimisation: false
                                                                        });
                                                                    }}
                                                                    className={`control-section-selectors-sizes mobile ${
                                                                        homePageWidgetMobileSettings.widgetSize === 's' ? 'active' : ''
                                                                    }`}
                                                                >
                                                                    S
                                                                </div>
                                                                <div
                                                                    onClick={() => {
                                                                        setHomepageWidgetFlag(true);
                                                                        setHomePageWidgetMobileSettings({
                                                                            ...homePageWidgetMobileSettings,
                                                                            widgetSize: 'm',
                                                                            showFilters: false
                                                                        });
                                                                    }}
                                                                    className={`control-section-selectors-sizes mobile ${
                                                                        homePageWidgetMobileSettings.widgetSize === 'm' ? 'active' : ''
                                                                    }`}
                                                                >
                                                                    M
                                                                </div>
                                                                <div
                                                                    onClick={() => {
                                                                        setHomepageWidgetFlag(true);
                                                                        setHomePageWidgetMobileSettings({
                                                                            ...homePageWidgetMobileSettings,
                                                                            widgetSize: 'l'
                                                                        });
                                                                    }}
                                                                    className={`control-section-selectors-sizes mobile ${
                                                                        homePageWidgetMobileSettings.widgetSize === 'l' ? 'active' : ''
                                                                    }`}
                                                                >
                                                                    L
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div
                                                                    onClick={() => {
                                                                        setHomepageWidgetFlag(true);
                                                                        setHomePageWidgetDesktopSettings({
                                                                            ...homePageWidgetDesktopSettings,
                                                                            widgetSize: 's',
                                                                            showFilters: false,
                                                                            showLabel: false,
                                                                            allowMinimisation: false
                                                                        });
                                                                    }}
                                                                    className={`control-section-selectors-sizes mobile ${
                                                                        homePageWidgetDesktopSettings.widgetSize === 's' ? 'active' : ''
                                                                    }`}
                                                                >
                                                                    S
                                                                </div>
                                                                <div
                                                                    onClick={() => {
                                                                        setHomepageWidgetFlag(true);
                                                                        setHomePageWidgetDesktopSettings({
                                                                            ...homePageWidgetDesktopSettings,
                                                                            widgetSize: 'm',
                                                                            showFilters: false
                                                                        });
                                                                    }}
                                                                    className={`control-section-selectors-sizes mobile ${
                                                                        homePageWidgetDesktopSettings.widgetSize === 'm' ? 'active' : ''
                                                                    }`}
                                                                >
                                                                    M
                                                                </div>
                                                                <div
                                                                    onClick={() => {
                                                                        setHomepageWidgetFlag(true);
                                                                        setHomePageWidgetDesktopSettings({
                                                                            ...homePageWidgetDesktopSettings,
                                                                            widgetSize: 'l'
                                                                        });
                                                                    }}
                                                                    className={`control-section-selectors-sizes mobile ${
                                                                        homePageWidgetDesktopSettings.widgetSize === 'l' ? 'active' : ''
                                                                    }`}
                                                                >
                                                                    L
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                    <h4 className="control-section-size mb-5">
                                                        {widget('settings.widget.homepage.show_filters.title')}
                                                    </h4>
                                                    <div className="d-flex space-between general-switch">
                                                        <div className="general-switch-info">
                                                            <span>{widget('settings.widget.homepage.show_filters.subtitle')}</span>
                                                        </div>
                                                        {toggleDesktopMobile ? (
                                                            homePageWidgetMobileSettings.widgetSize === 's' ||
                                                            homePageWidgetMobileSettings.widgetSize === 'm' ? (
                                                                <SwitchAtom toggleVal={false} disabled={true} />
                                                            ) : (
                                                                <SwitchAtom
                                                                    toggleVal={homePageWidgetMobileSettings.showFilters}
                                                                    handleToggle={() => {
                                                                        setHomepageWidgetFlag(true);
                                                                        setHomePageWidgetMobileSettings({
                                                                            ...homePageWidgetMobileSettings,
                                                                            showFilters: !homePageWidgetMobileSettings.showFilters
                                                                        });
                                                                    }}
                                                                />
                                                            )
                                                        ) : homePageWidgetDesktopSettings.widgetSize === 's' ||
                                                          homePageWidgetDesktopSettings.widgetSize === 'm' ? (
                                                            <SwitchAtom toggleVal={false} disabled={true} />
                                                        ) : (
                                                            <SwitchAtom
                                                                toggleVal={homePageWidgetDesktopSettings.showFilters}
                                                                handleToggle={() => {
                                                                    setHomepageWidgetFlag(true);
                                                                    setHomePageWidgetDesktopSettings({
                                                                        ...homePageWidgetDesktopSettings,
                                                                        showFilters: !homePageWidgetDesktopSettings.showFilters
                                                                    });
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    <h4 className="control-section-size mb-5">{widget('show_label')}</h4>
                                                    <div className="d-flex space-between general-switch">
                                                        <div className="general-switch-info">
                                                            <img src={Label} alt="eye" />
                                                        </div>
                                                        {toggleDesktopMobile ? (
                                                            homePageWidgetMobileSettings.widgetSize === 's' ? (
                                                                <SwitchAtom toggleVal={false} disabled={true} />
                                                            ) : (
                                                                <SwitchAtom
                                                                    toggleVal={homePageWidgetMobileSettings.showLabel}
                                                                    handleToggle={() => {
                                                                        setHomepageWidgetFlag(true);
                                                                        setHomePageWidgetMobileSettings({
                                                                            ...homePageWidgetMobileSettings,
                                                                            showLabel: !homePageWidgetMobileSettings.showLabel
                                                                        });
                                                                    }}
                                                                />
                                                            )
                                                        ) : homePageWidgetDesktopSettings.widgetSize === 's' ? (
                                                            <SwitchAtom toggleVal={false} disabled={true} />
                                                        ) : (
                                                            <SwitchAtom
                                                                toggleVal={homePageWidgetDesktopSettings.showLabel}
                                                                handleToggle={() => {
                                                                    setHomepageWidgetFlag(true);
                                                                    setHomePageWidgetDesktopSettings({
                                                                        ...homePageWidgetDesktopSettings,
                                                                        showLabel: !homePageWidgetDesktopSettings.showLabel
                                                                    });
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    <h4 className="control-section-size mb-5">{widget('minimize')}</h4>
                                                    <div className="d-flex space-between general-switch">
                                                        <div className="general-switch-info">
                                                            <img src={Minimise} alt="eye" />
                                                        </div>
                                                        {toggleDesktopMobile ? (
                                                            homePageWidgetMobileSettings.widgetSize === 's' ? (
                                                                <SwitchAtom toggleVal={false} disabled={true} />
                                                            ) : (
                                                                <SwitchAtom
                                                                    toggleVal={homePageWidgetMobileSettings.allowMinimisation}
                                                                    handleToggle={() => {
                                                                        setHomepageWidgetFlag(true);
                                                                        setHomePageWidgetMobileSettings({
                                                                            ...homePageWidgetMobileSettings,
                                                                            allowMinimisation:
                                                                                !homePageWidgetMobileSettings.allowMinimisation
                                                                        });
                                                                    }}
                                                                />
                                                            )
                                                        ) : homePageWidgetDesktopSettings.widgetSize === 's' ? (
                                                            <SwitchAtom toggleVal={false} disabled={true} />
                                                        ) : (
                                                            <SwitchAtom
                                                                toggleVal={homePageWidgetDesktopSettings.allowMinimisation}
                                                                handleToggle={() => {
                                                                    setHomepageWidgetFlag(true);
                                                                    setHomePageWidgetDesktopSettings({
                                                                        ...homePageWidgetDesktopSettings,
                                                                        allowMinimisation: !homePageWidgetDesktopSettings.allowMinimisation
                                                                    });
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    <h4 className="control-section-size mb-5">
                                                        {widget('settings.widget.homepage.video.background')}
                                                    </h4>
                                                    {toggleDesktopMobile ? (
                                                        !homePageWidgetMobileSettings.videoBackgroundUrl ? (
                                                            showVideoUpload ? (
                                                                <VideoUploader
                                                                    file={homeWidgetVideo}
                                                                    id={homeWidgetVideo.id}
                                                                    fileNumber={homeWidgetVideo.id}
                                                                    isHomePage={true}
                                                                    setHomePageVideoDetails={setHomePageVideoDetails}
                                                                />
                                                            ) : (
                                                                <div className="video-upload" {...getRootProps()}>
                                                                    <input {...getInputProps()} />+{' '}
                                                                    {widget('settings.widget.homepage.video.upload')}
                                                                </div>
                                                            )
                                                        ) : (
                                                            <div
                                                                className="video-upload"
                                                                style={{
                                                                    border: 'none',
                                                                    backgroundColor: homePageWidgetMobileSettings.videoBackgroundPosterUrl
                                                                        ?.length
                                                                        ? 'unset'
                                                                        : '#000'
                                                                }}
                                                            >
                                                                <img
                                                                    onError={handleBrokenImage}
                                                                    src={homePageWidgetMobileSettings.videoBackgroundPosterUrl}
                                                                    alt=""
                                                                />
                                                                <div
                                                                    className="video-remove"
                                                                    onClick={() => {
                                                                        setHomepageWidgetFlag(true);
                                                                        setHomePageWidgetMobileSettings({
                                                                            ...homePageWidgetMobileSettings,
                                                                            videoExt: '',
                                                                            videoBackgroundUrl: '',
                                                                            videoBackgroundPosterUrl: ''
                                                                        });
                                                                        setHomePageVideoDetails({});
                                                                        setShowVideoUpload(false);
                                                                    }}
                                                                >
                                                                    <img src={DeleteWhite} alt="delete"></img>
                                                                    {widget('settings.widget.homepage.video.remove')}
                                                                </div>
                                                            </div>
                                                        )
                                                    ) : !homePageWidgetDesktopSettings.videoBackgroundUrl ? (
                                                        showVideoUpload ? (
                                                            <VideoUploader
                                                                file={homeWidgetVideo}
                                                                id={homeWidgetVideo.id}
                                                                fileNumber={homeWidgetVideo.id}
                                                                isHomePage={true}
                                                                setHomePageVideoDetails={setHomePageVideoDetails}
                                                            />
                                                        ) : (
                                                            <div className="video-upload" {...getRootProps()}>
                                                                <input {...getInputProps()} />+{' '}
                                                                {widget('settings.widget.homepage.video.upload')}
                                                            </div>
                                                        )
                                                    ) : (
                                                        <div
                                                            className="video-upload"
                                                            style={{
                                                                border: 'none',
                                                                backgroundColor: homePageWidgetDesktopSettings.videoBackgroundPosterUrl
                                                                    ?.length
                                                                    ? 'unset'
                                                                    : '#000'
                                                            }}
                                                        >
                                                            <img
                                                                onError={handleBrokenImage}
                                                                src={homePageWidgetDesktopSettings.videoBackgroundPosterUrl}
                                                                alt=""
                                                            />
                                                            <div
                                                                className="video-remove"
                                                                onClick={() => {
                                                                    setHomepageWidgetFlag(true);
                                                                    setHomePageWidgetDesktopSettings({
                                                                        ...homePageWidgetDesktopSettings,
                                                                        videoExt: '',
                                                                        videoBackgroundUrl: '',
                                                                        videoBackgroundPosterUrl: ''
                                                                    });
                                                                    setHomePageVideoDetails({});
                                                                    setShowVideoUpload(false);
                                                                }}
                                                            >
                                                                <img src={DeleteWhite} alt="delete"></img>
                                                                {widget('settings.widget.homepage.video.remove')}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <h4 className="video-upload-size">{widget('settings.widget.homepage.video.size')}</h4>
                                                    <hr />
                                                    <h4 className="control-section-looks">{widget('position')}</h4>
                                                    <h4 className="control-section-size mb-5">
                                                        {widget('settings.widget.homepage.sticky.title')}
                                                    </h4>
                                                    <div className="d-flex space-between general-switch">
                                                        <div className="general-switch-info">
                                                            <span>
                                                                {!homePageWidgetDesktopSettings.stickyPosition
                                                                    ? widget('settings.widget.homepage.sticky.subtitle_off')
                                                                    : widget('settings.widget.homepage.sticky.subtitle_on')}
                                                            </span>
                                                        </div>

                                                        {toggleDesktopMobile ? (
                                                            homePageWidgetMobileSettings.widgetSize !== 'l' ? (
                                                                <SwitchAtom toggleVal={false} disabled={true} />
                                                            ) : (
                                                                <SwitchAtom
                                                                    toggleVal={homePageWidgetMobileSettings.stickyPosition}
                                                                    handleToggle={() => {
                                                                        setHomepageWidgetFlag(true);
                                                                        setHomePageWidgetMobileSettings({
                                                                            ...homePageWidgetMobileSettings,
                                                                            stickyPosition: !homePageWidgetMobileSettings.stickyPosition
                                                                        });
                                                                    }}
                                                                />
                                                            )
                                                        ) : homePageWidgetDesktopSettings.widgetSize !== 'l' ? (
                                                            <SwitchAtom toggleVal={false} disabled={true} />
                                                        ) : (
                                                            <SwitchAtom
                                                                toggleVal={homePageWidgetDesktopSettings.stickyPosition}
                                                                handleToggle={() => {
                                                                    setHomepageWidgetFlag(true);
                                                                    setHomePageWidgetDesktopSettings({
                                                                        ...homePageWidgetDesktopSettings,
                                                                        stickyPosition: !homePageWidgetDesktopSettings.stickyPosition
                                                                    });
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    {toggleDesktopMobile ? (
                                                        <h4
                                                            className={
                                                                homePageWidgetMobileSettings.widgetSize === 'l'
                                                                    ? 'control-section-size mb-10 disable'
                                                                    : 'control-section-size mb-10'
                                                            }
                                                        >
                                                            {widget('widget_position')}
                                                        </h4>
                                                    ) : (
                                                        <h4
                                                            className={
                                                                homePageWidgetDesktopSettings.widgetSize === 'l'
                                                                    ? 'control-section-size mb-10 disable'
                                                                    : 'control-section-size mb-10'
                                                            }
                                                        >
                                                            {widget('widget_position')}
                                                        </h4>
                                                    )}

                                                    {toggleDesktopMobile ? (
                                                        <div
                                                            className={
                                                                homePageWidgetMobileSettings.widgetSize === 'l'
                                                                    ? 'control-section-selectors disable'
                                                                    : 'control-section-selectors'
                                                            }
                                                        >
                                                            <div
                                                                onClick={() => {
                                                                    setHomepageWidgetFlag(true);
                                                                    setHomePageWidgetMobileSettings({
                                                                        ...homePageWidgetMobileSettings,
                                                                        position: false
                                                                    });
                                                                }}
                                                                className={
                                                                    homePageWidgetMobileSettings.widgetSize === 'l'
                                                                        ? 'control-section-selectors-position'
                                                                        : `control-section-selectors-position ${
                                                                              homePageWidgetMobileSettings.position ? '' : 'active'
                                                                          }`
                                                                }
                                                            >
                                                                {widget('position_left')}
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    setHomepageWidgetFlag(true);
                                                                    setHomePageWidgetMobileSettings({
                                                                        ...homePageWidgetMobileSettings,
                                                                        position: true
                                                                    });
                                                                }}
                                                                className={
                                                                    homePageWidgetMobileSettings.widgetSize === 'l'
                                                                        ? 'control-section-selectors-position'
                                                                        : `control-section-selectors-position ${
                                                                              homePageWidgetMobileSettings.position ? 'active' : ''
                                                                          }`
                                                                }
                                                            >
                                                                {widget('position_right')}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={
                                                                homePageWidgetDesktopSettings.widgetSize === 'l'
                                                                    ? 'control-section-selectors disable'
                                                                    : 'control-section-selectors'
                                                            }
                                                        >
                                                            <div
                                                                onClick={() => {
                                                                    setHomepageWidgetFlag(true);
                                                                    setHomePageWidgetDesktopSettings({
                                                                        ...homePageWidgetDesktopSettings,
                                                                        position: false
                                                                    });
                                                                }}
                                                                className={
                                                                    homePageWidgetDesktopSettings.widgetSize === 'l'
                                                                        ? 'control-section-selectors-position'
                                                                        : `control-section-selectors-position ${
                                                                              homePageWidgetDesktopSettings.position ? '' : 'active'
                                                                          }`
                                                                }
                                                            >
                                                                {widget('position_left')}
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    setHomepageWidgetFlag(true);
                                                                    setHomePageWidgetDesktopSettings({
                                                                        ...homePageWidgetDesktopSettings,
                                                                        position: true
                                                                    });
                                                                }}
                                                                className={
                                                                    homePageWidgetDesktopSettings.widgetSize === 'l'
                                                                        ? 'control-section-selectors-position'
                                                                        : `control-section-selectors-position ${
                                                                              homePageWidgetDesktopSettings.position ? 'active' : ''
                                                                          }`
                                                                }
                                                            >
                                                                {widget('position_right')}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <h4 className="control-section-size mb-5">{widget('spacing')}</h4>
                                                    <div className="control-section-padding">
                                                        {toggleDesktopMobile ? (
                                                            <>
                                                                <img
                                                                    src={
                                                                        homePageWidgetMobileSettings.widgetSize === 'l'
                                                                            ? PaddingAreaBottom
                                                                            : homePageWidgetMobileSettings.position
                                                                            ? PaddingAreaRight
                                                                            : PaddingArea
                                                                    }
                                                                    alt="padding area"
                                                                />

                                                                <input
                                                                    className={`styling ${
                                                                        homePageWidgetMobileSettings.position ? 'side-right' : 'side'
                                                                    }`}
                                                                    type="text"
                                                                    maxLength="3"
                                                                    placeholder="100"
                                                                    value={
                                                                        homePageWidgetMobileSettings?.sidePX
                                                                            ? homePageWidgetMobileSettings.sidePX
                                                                            : 0
                                                                    }
                                                                    onChange={(e) => {
                                                                        setHomepageWidgetFlag(true);
                                                                        handleNumberChangeSide(e, toggleDesktopMobile, false);
                                                                    }}
                                                                    style={{
                                                                        display:
                                                                            homePageWidgetMobileSettings.widgetSize === 'l' ? 'none' : ''
                                                                    }}
                                                                />

                                                                <input
                                                                    className={'styling bottom'}
                                                                    type="text"
                                                                    maxLength="3"
                                                                    placeholder="100"
                                                                    defaultValue={'0'}
                                                                    value={
                                                                        homePageWidgetMobileSettings?.bottomPX
                                                                            ? homePageWidgetMobileSettings.bottomPX
                                                                            : 0
                                                                    }
                                                                    onChange={(e) => {
                                                                        setHomepageWidgetFlag(true);
                                                                        handleNumberChangeBottom(e, toggleDesktopMobile, false);
                                                                    }}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <img
                                                                    src={
                                                                        homePageWidgetDesktopSettings.widgetSize === 'l'
                                                                            ? PaddingAreaBottom
                                                                            : homePageWidgetDesktopSettings.position
                                                                            ? PaddingAreaRight
                                                                            : PaddingArea
                                                                    }
                                                                    alt="padding area"
                                                                />

                                                                <input
                                                                    className={`styling ${
                                                                        homePageWidgetDesktopSettings.position ? 'side-right' : 'side'
                                                                    }`}
                                                                    type="text"
                                                                    maxLength="3"
                                                                    placeholder="100"
                                                                    value={
                                                                        homePageWidgetDesktopSettings?.sidePX
                                                                            ? homePageWidgetDesktopSettings.sidePX
                                                                            : 0
                                                                    }
                                                                    onChange={(e) => {
                                                                        setHomepageWidgetFlag(true);
                                                                        handleNumberChangeSide(e, toggleDesktopMobile, false);
                                                                    }}
                                                                    style={{
                                                                        display:
                                                                            homePageWidgetDesktopSettings.widgetSize === 'l' ? 'none' : ''
                                                                    }}
                                                                />
                                                                <input
                                                                    className={'styling bottom'}
                                                                    type="text"
                                                                    maxLength="3"
                                                                    placeholder="100"
                                                                    defaultValue={'0'}
                                                                    value={
                                                                        homePageWidgetDesktopSettings?.bottomPX
                                                                            ? homePageWidgetDesktopSettings.bottomPX
                                                                            : 0
                                                                    }
                                                                    onChange={(e) => {
                                                                        setHomepageWidgetFlag(true);
                                                                        handleNumberChangeBottom(e, toggleDesktopMobile, false);
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="preview-section-home">
                                                    <h4>{widget('preview_area')}</h4>
                                                    <div
                                                        className={
                                                            toggleDesktopMobile
                                                                ? `preview-section-container ${
                                                                      homePageWidgetMobileSettings.widgetSize === 's'
                                                                          ? 'entry-small'
                                                                          : homePageWidgetMobileSettings.widgetSize === 'm'
                                                                          ? 'entry-medium'
                                                                          : 'mobile-large'
                                                                  }`
                                                                : `preview-section-container ${
                                                                      homePageWidgetDesktopSettings.widgetSize === 's'
                                                                          ? 'entry-small'
                                                                          : homePageWidgetDesktopSettings.widgetSize === 'm'
                                                                          ? 'entry-medium'
                                                                          : 'large'
                                                                  }`
                                                        }
                                                    >
                                                        {/* <img className="entry-bg" src={EntryBg} alt="Preview" /> */}
                                                        <VideoAtom src={homePageVideoDetails?.videoExt ? HomepageVideo : HomepageVideo} />
                                                        {toggleDesktopMobile
                                                            ? homePageWidgetMobileSettings.showLabel &&
                                                              homePageWidgetMobileSettings.widgetSize !== 's' && (
                                                                  <VideoTourLabel
                                                                      top="10px"
                                                                      left="10px"
                                                                      size={homePageWidgetMobileSettings.widgetSize === 'm' && 's'}
                                                                  />
                                                              )
                                                            : homePageWidgetDesktopSettings.showLabel &&
                                                              homePageWidgetDesktopSettings.widgetSize !== 's' && (
                                                                  <VideoTourLabel
                                                                      top="10px"
                                                                      left="10px"
                                                                      size={homePageWidgetDesktopSettings.widgetSize === 'm' && 's'}
                                                                  />
                                                              )}
                                                        {toggleDesktopMobile
                                                            ? homePageWidgetMobileSettings.widgetSize === 'm' && (
                                                                  <div className="search-icon-only">
                                                                      <img src={EntrySearchIcon} alt="search-box" />
                                                                  </div>
                                                              )
                                                            : homePageWidgetDesktopSettings.widgetSize === 'm' && (
                                                                  <div className="search-icon-only">
                                                                      <img src={EntrySearchIcon} alt="search-box" />
                                                                  </div>
                                                              )}

                                                        {toggleDesktopMobile
                                                            ? homePageWidgetMobileSettings.widgetSize === 's' && (
                                                                  <div className="search-icon-only-m">
                                                                      <img src={SearchShadow} alt="search-box" />
                                                                  </div>
                                                              )
                                                            : homePageWidgetDesktopSettings.widgetSize === 's' && (
                                                                  <div className="search-icon-only-m">
                                                                      <img src={SearchShadow} alt="search-box" />
                                                                  </div>
                                                              )}
                                                        <div
                                                            className="filters-container"
                                                            style={{
                                                                bottom:
                                                                    toggleDesktopMobile && homePageWidgetMobileSettings.widgetSize === 'l'
                                                                        ? '50px'
                                                                        : '25px'
                                                            }}
                                                        >
                                                            {toggleDesktopMobile
                                                                ? homePageWidgetMobileSettings.widgetSize === 'l' && (
                                                                      <div className="search-icon">
                                                                          <img src={SearchIconW} alt="" />
                                                                          <span>{widget('settings.widget.homepage.filters.search')}</span>
                                                                      </div>
                                                                  )
                                                                : homePageWidgetDesktopSettings.widgetSize === 'l' && (
                                                                      <div className="search-icon">
                                                                          <img src={SearchIconW} alt="" />
                                                                          <span>{widget('settings.widget.homepage.filters.search')}</span>
                                                                      </div>
                                                                  )}
                                                            {toggleDesktopMobile
                                                                ? homePageWidgetMobileSettings.showFilters &&
                                                                  homePageWidgetMobileSettings.widgetSize === 'l' && (
                                                                      <div className="filters-dd">
                                                                          <div className="filters-dd-type">
                                                                              {widget('settings.widget.homepage.filters.rent')}
                                                                              <img src={CaretDownWhite} alt="" />
                                                                          </div>
                                                                          <div className="filters-dd-type">
                                                                              {widget('settings.widget.homepage.filters.any_price')}
                                                                              <img src={CaretDownWhite} alt="" />
                                                                          </div>
                                                                          <div className="filters-dd-type">
                                                                              {widget('settings.widget.homepage.filters.rooms')}
                                                                              <img src={CaretDownWhite} alt="" />
                                                                          </div>
                                                                          <div className="filters-dd-type">
                                                                              {widget('settings.widget.homepage.filters.size')}
                                                                              <img src={CaretDownWhite} alt="" />
                                                                          </div>
                                                                      </div>
                                                                  )
                                                                : homePageWidgetDesktopSettings.showFilters &&
                                                                  homePageWidgetDesktopSettings.widgetSize === 'l' && (
                                                                      <div className="filters-dd">
                                                                          <div className="filters-dd-type">
                                                                              {widget('settings.widget.homepage.filters.rent')}
                                                                              <img src={CaretDownWhite} alt="" />
                                                                          </div>
                                                                          <div className="filters-dd-type">
                                                                              {widget('settings.widget.homepage.filters.any_price')}
                                                                              <img src={CaretDownWhite} alt="" />
                                                                          </div>
                                                                          <div className="filters-dd-type">
                                                                              {widget('settings.widget.homepage.filters.rooms')}
                                                                              <img src={CaretDownWhite} alt="" />
                                                                          </div>
                                                                          <div className="filters-dd-type">
                                                                              {widget('settings.widget.homepage.filters.size')}
                                                                              <img src={CaretDownWhite} alt="" />
                                                                          </div>
                                                                      </div>
                                                                  )}
                                                        </div>

                                                        {toggleDesktopMobile
                                                            ? homePageWidgetMobileSettings.allowMinimisation &&
                                                              homePageWidgetMobileSettings.widgetSize !== 's' && (
                                                                  <img className="minimise" src={Minimise} alt="Minimise" />
                                                              )
                                                            : homePageWidgetDesktopSettings.allowMinimisation &&
                                                              homePageWidgetDesktopSettings.widgetSize !== 's' && (
                                                                  <img className="minimise" src={Minimise} alt="Minimise" />
                                                              )}

                                                        {toggleDesktopMobile && homePageWidgetMobileSettings.widgetSize === 'l' && (
                                                            <div className="powered">
                                                                <img src={Powered} alt="" />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="widget-settings-accordion-container-context-homepage">
                                            <div className="widget-settings-accordion-container-context-homepage-info">
                                                <h4>{widget('settings.widget.homepage.pair.engage_message')}</h4>
                                                <p>
                                                    {widget('settings.widget.homepage.pair.embed_message')}
                                                    <br />
                                                    <br />
                                                    <span>{widget('settings.widget.homepage.pair.enter_url')}</span>
                                                </p>
                                            </div>
                                            <div className="widget-settings-accordion-container-context-homepage-image">
                                                <img src={HomepageBg} alt="" />
                                            </div>
                                        </div>
                                    )}

                                    <div className="widget-settings-accordion-container-context-pair">
                                        <h4>{widget('settings.widget.homepage.pair.set_up')}</h4>
                                        <label>{widget('settings.widget.homepage.pair.homepage_url')}</label>
                                        <input
                                            type="text"
                                            placeholder={widget('spaces.publish.pair.placeholder')}
                                            style={{
                                                border: webPageError ? '1px solid #F6655A' : ''
                                            }}
                                            ref={magicLinkRef}
                                            value={homePagePairingURL}
                                            onChange={(e) => {
                                                setHomePagePairingURL(e.target.value);
                                                debouncedFn(e.target.value);
                                                // magicLinkRef.current.value = e.target.value;
                                                setIsMagicPaired(false);
                                            }}
                                        />
                                        {magicLinkSearchLoading && !webPageError && (
                                            <div className="loading-status">
                                                <LoadingSpin></LoadingSpin>
                                                <span>{widget('checking')}...</span>
                                            </div>
                                        )}
                                        {!widgetLiveWarning && !webPageError && !magicLinkSearchLoading && !isMagicPaired && (
                                            <div className="warning-wrapper">
                                                <img src={AlertIconBlack} alt=""></img>
                                                <div className="warning-text">
                                                    <div className="title">{widget('spaces.publish.pair.warning.title')}</div>
                                                    <div className="subtitle">{widget('spaces.publish.pair.warning.subtitle')}</div>
                                                </div>
                                                <div className="warning-btn" onClick={handlePersistenStorage}>
                                                    {widget('spaces.publish.pair.warning.install')}
                                                </div>
                                            </div>
                                        )}

                                        {webPageError && magicLinkRef.current.value.length > 0 && (
                                            <div className="webpage-input-container-error">{webPageErrorMsg}</div>
                                        )}
                                        {showSuccessPaired && !webPageError && !magicLinkSearchLoading && (
                                            <div className="success-pair">
                                                <div className="msg">
                                                    <img src={CheckPaired} alt="" />
                                                    <span>{widget('spaces.publish.pair.success.title')}</span>
                                                </div>
                                                <div className="view-btn" onClick={handleOpenSpacePairedWebPage}>
                                                    {widget('spaces.publish.pair.success.view')}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <div ref={scrollBug} />
                    </div>

                    {/* lead registration flow */}
                    <div className="widget-settings-accordion">
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <div className="pos-rel widget-settings-accordion-container">
                                        <h4>
                                            <span>5</span>
                                            Lead Registration
                                        </h4>

                                        <AccordionItemState>
                                            {({ expanded }) => (
                                                <img
                                                    className={`pos-abs ${expanded ? 'active-caret' : 'passive-caret'}`}
                                                    src={Caret}
                                                    alt="caret"
                                                />
                                            )}
                                        </AccordionItemState>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div className="widget-settings-accordion-container-context">
                                    <div className="widget-settings-accordion-container-context-content">
                                        <div className="widget-settings-accordion-container-context-content-middle lead-registration">
                                            <div className="control-section">
                                                <div className="control-section-info">
                                                    <img src={Bulb} alt="Bulb" />
                                                    <h4>
                                                        Holofy requests users verify their contact details whenever wanting to wishlist a
                                                        space, view private listings or private campaigns.
                                                    </h4>
                                                </div>
                                                <div className="control-lead">
                                                    <div className="lead email">
                                                        <h4>VERIFY Email ADDRESS</h4>
                                                        <p>
                                                            Request users to verify their Email Address via a One Time Passcode sent to
                                                            their Inbox
                                                        </p>

                                                        <div
                                                            className="tick"
                                                            onClick={() => {
                                                                setLeadRegistrationFlag(true);
                                                                setEmailChoosen(true);
                                                                setLeadRegistrationSettings({ viaEmail: true });
                                                            }}
                                                        >
                                                            <img src={emailChoosen ? CheckMark : UnCheck} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="lead phone-num">
                                                        <h4>VERIFY PHONE NUMBER</h4>
                                                        <p>
                                                            Request users to verify their Phone Number via a One Time Passcode sent as an
                                                            SMS
                                                        </p>

                                                        <div
                                                            className="tick"
                                                            onClick={() => {
                                                                setLeadRegistrationFlag(true);
                                                                setEmailChoosen(false);
                                                                setLeadRegistrationSettings({ viaEmail: false });
                                                            }}
                                                        >
                                                            <img src={emailChoosen ? UnCheck : CheckMark} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="preview-section">
                                                <h4>{widget('preview_area')}</h4>
                                                <div className="preview-section-container full-screen lead-registration-screen">
                                                    <VideoAtom src={FullscreenVideo} />
                                                    <img className="preview-skills" src={Skills} alt="Preview" />
                                                    <div className="lead-container">
                                                        <img
                                                            className="lead-registration"
                                                            src={leadRegistrationSettings.viaEmail ? EmailLead : PhoneLead}
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </div>
                </>
            ) : null}
        </>
    );
}

export default WidgetSettings;
