import React, { useEffect, useState, useContext } from 'react';
import TextAtom from '../TextAtom';
import ButtonAtom from '../ButtonAtom';
import { validateCompany } from '../../utils';
import { Fade } from 'react-reveal';
import { DOMAIN } from '../../constants/base';
import { currentSpaceContext } from '../../context';
import Pulse from 'react-reveal/Pulse';
import ClickAwayContentWrapper from '../../components/ClickAwayContentWrapper';
import { toast } from 'react-toastify';

// Assets
import BackImg from '../../assets/back.png';

const PopUpMagicPairing = ({ setMagicLinkPopUp, fetchActiveSpaces }) => {
    const [currentSpace] = useContext(currentSpaceContext);
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};

    // Local State
    const [spaceAddress] = useState(currentSpace.spaceAddress);
    const [flatNumber] = useState(currentSpace.flatNumber);
    const [magicLink, setMagicLink] = useState(currentSpace.currentMagicLink);
    const [spaceId] = useState(currentSpace.currentSpaceId);
    const [currentMagicLinkError, setCurrentMagicLinkError] = useState(false);
    const [enableDeleteLink, setEnableDeleteLink] = useState(false);

    // Pop Up Magic Link Blur Handler
    const PopUpBlurMagicLink = () => {
        if (enableDeleteLink && magicLink === '') {
            return;
        }
        let isValid = validateCompany(magicLink);
        setCurrentMagicLinkError(!isValid);
    };

    useEffect(() => {
        setEnableDeleteLink(magicLink ? true : false);
    }, []);

    const editMagicLink = (e) => {
        if (validateCompany(magicLink) || (enableDeleteLink && magicLink === '')) {
            const url = `${DOMAIN}/v1/dashboard/space/${spaceId}`;
            const payload = {
                spaceURL: magicLink
            };

            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    authorization: userToken.accessToken
                },
                body: JSON.stringify(payload)
            };
            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    const { error } = data;
                    if (error === 'ERR409') {
                        toast.error('Duplicate Magic Link', {
                            position: 'top-center',
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined
                        });
                    }
                    fetchActiveSpaces();
                    setMagicLinkPopUp(false);
                });
        }
    };

    return (
        <div className="popups-wrapper">
            <ClickAwayContentWrapper onClose={() => setMagicLinkPopUp(false)}>
                <Pulse duration={150}>
                    <div className="form-card-wrapper">
                        <div className="d-flex w-100p">
                            <button className="back-button" onClick={() => setMagicLinkPopUp(false)}>
                                <img src={BackImg} alt="back-icon"></img>
                            </button>
                            <h1 className="popup-heading ml-10">Magic Pairing</h1>
                        </div>
                        <div className="form-wrapper mt-30">
                            <TextAtom
                                textContent={`${flatNumber ? flatNumber + ',' : ''} ${spaceAddress}`}
                                fontFamily="Montserrat"
                                fontWeight="700"
                                fontSize="1rem"
                                color="#3A3A3A"
                            ></TextAtom>
                            <div className="input-wrapper mt-20">
                                <div className="d-flex vertical-center">
                                    <div className="textarea-wrapper d-flex f-col">
                                        <div className="d-flex space-between">
                                            <label className="d-ib fs-8 fw-900 mb-10">WEB ADDRESS</label>
                                            {currentMagicLinkError ? (
                                                <Fade bottom duration="150">
                                                    <span className="c-error fs-8 fw-700">PLEASE ENTER VALID LINK</span>
                                                </Fade>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div className="d-flex vertical-center text-area-wrapper">
                                            <textarea
                                                placeholder="www.yourwebsite.com/your_space_name"
                                                value={magicLink}
                                                onChange={(e) => setMagicLink(e.target.value)}
                                                onBlur={PopUpBlurMagicLink}
                                                style={{ resize: 'none' }}
                                            ></textarea>
                                        </div>

                                        <span className="info-text mt-20">
                                            It’s important you paste the link to the exact page
                                            <br></br> you want the content to appear on your website.
                                            <br></br> You can always edit this later.
                                        </span>
                                    </div>
                                </div>
                                <div className="mt-20">
                                    <ButtonAtom label="SAVE" onClick={(e) => editMagicLink(e)}></ButtonAtom>
                                </div>
                            </div>
                        </div>
                    </div>
                </Pulse>
            </ClickAwayContentWrapper>
        </div>
    );
};

export default PopUpMagicPairing;
