import React, { useState, useEffect } from 'react';
import { getReq } from '../../utils/api';
import { DOMAIN } from '../../constants/base';
import { toast } from 'react-toastify';
import './popupeditspaceskill.scss';
import LoadingSpin from '../../components/LoadingSpin';

// Assets
import PlayBtn from '../../assets/playbtn.svg';
import equalDrag from '../../assets/drag-equal.svg';
import VideoPlaceholder from '../../assets/video-placeholder.png';
import Checked from '../../assets/Vector 316checked.svg';
import DownloadIcon from '../../assets/download-skill.svg';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

const PopUpEditSpaceSkill = (props) => {
    const {
        itemKey,
        item,
        editSpace,
        widgetLink,
        selectSkillMethod,
        toggleSkillFalse,
        setToggleSkillFalse,
        skillNameError,
        skillName,
        setIsNameUpdated,
        cardSkills,
        setCardSkills
    } = props;
    const { t: editSkill } = useTranslation();
    const skillId = item.id;
    const [toggleSkill, setToggleSkill] = useState(false);
    const [downloadingStatus, setDownloadingStatus] = useState(false);
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const [name, setName] = useState('');
    const [toastMessage, setToastMessage] = useState({});
    const [aspectRatio, setAspectRatio] = useState({
        height: 2,
        width: 1
    });
    const [ratioSkillId, setRatioSkillId] = useState('');

    const downloadTrialsRef = useRef(5);
    const downloadAPITimeoutRef = useRef(null);

    const toggleSkillCheck = (id) => {
        setToggleSkillFalse(false);
        setToggleSkill(!toggleSkill);
        if (toggleSkill) {
            selectSkillMethod(id, false);
        } else if (!toggleSkill) selectSkillMethod(id, true);
    };

    useEffect(() => {
        item.name ? setName(item.name) : item.rename ? setName(item.rename) : setName('');
        setAspectRatio({
            height: item?.aspectRatio?.height ? item.aspectRatio.height : 2,
            width: item?.aspectRatio?.width ? item.aspectRatio.width : 1
        });
    }, [item]);

    useEffect(() => {
        if (toggleSkillFalse) {
            setToggleSkill(false);
        }
    }, [toggleSkillFalse]);

    useEffect(() => {
        item.rename = name;
        item.name = name;
    }, [name]);

    useEffect(() => {
        let newArray = [...cardSkills];
        if (newArray.length) {
            for (let i = 0; i < newArray.length; i++) {
                if (newArray[i].id === ratioSkillId) {
                    newArray[i].height = aspectRatio.height;
                    newArray[i].width = aspectRatio.width;
                }
            }
            setCardSkills(newArray);
            if (item?.aspectRatio) {
                item.aspectRatio.height = aspectRatio.height;
                item.aspectRatio.width = aspectRatio.width;
            } else {
                item.aspectRatio = {
                    height: aspectRatio.height,
                    width: aspectRatio.width
                };
            }
        }
    }, [aspectRatio]);

    useEffect(() => {
        if (toastMessage?.type === 'error') {
            toast.error(toastMessage?.msg, {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        }
    }, [toastMessage]);

    const handleImageError = (e) => {
        e.target.src = VideoPlaceholder;
    };

    // temp showing video image while uploading
    const renderImage = () => {
        if (item?.content?.video?.length) {
            return (
                <video
                    src={item.content.video}
                    style={{
                        position: 'absolute',
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        borderRadius: '10px',
                        zIndex: 1
                    }}
                />
            );
        }
        return '';
    };

    const downloadSkill = async () => {
        if (downloadTrialsRef.current < 0) {
            setDownloadingStatus(false);
            return;
        }
        setDownloadingStatus(true);
        const url = `${DOMAIN}/v1/dashboard/space/skill/${skillId}/download`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const response = await getReq({ url, requestHeaders });
        const { code, data } = response;
        if (code === 200 && !data) {
            if (downloadTrialsRef.current === 5) {
                setToastMessage({
                    type: 'error',
                    msg: editSkill('download.will_start')
                });
            }
            downloadTrialsRef.current = downloadTrialsRef.current - 1;
            downloadAPITimeoutRef.current = setTimeout(downloadSkill, 5000);
        } else if (code === 200 && data?.conversionInProgress) {
            if (downloadTrialsRef.current) {
                setToastMessage({
                    type: 'error',
                    msg: editSkill('download.in_progress')
                });
                downloadTrialsRef.current = downloadTrialsRef.current - 1;
                downloadAPITimeoutRef.current = setTimeout(downloadSkill, 5000);
            } else {
                setToastMessage({
                    type: 'error',
                    msg: editSkill('download.error')
                });
                setDownloadingStatus(false);
                return;
            }
        } else if (code === 200 && data?.video) {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', data?.video, true);
            xhr.responseType = 'blob';
            xhr.onload = function () {
                let urlCreator = window.URL || window.webkitURL;
                let videoUrl = urlCreator.createObjectURL(this.response);
                let tag = document.createElement('a');
                tag.href = videoUrl;
                tag.target = '_blank';
                tag.download = skillName.includes('.mp4') ? skillName : skillName + '.mp4';
                document.body.appendChild(tag);
                tag.click();
                document.body.removeChild(tag);
                setDownloadingStatus(false);
            };
            xhr.onerror = (err) => {};
            xhr.send();
            setToastMessage({
                type: 'error',
                msg: editSkill('download.start')
            });
            if (downloadAPITimeoutRef.current) clearTimeout(downloadAPITimeoutRef.current);
        }
    };

    //method to set aspect ratio
    const aspectRatioSet = () => {
        if (aspectRatio.height === 2 && aspectRatio.width === 1) {
            setAspectRatio({
                height: 1,
                width: 1
            });
        } else if (aspectRatio.height === 1 && aspectRatio.width === 1) {
            setAspectRatio({
                height: 1,
                width: 3
            });
        } else if (aspectRatio.height === 1 && aspectRatio.width === 3) {
            setAspectRatio({
                height: 2,
                width: 1
            });
        }
    };

    const setVideoWidth = () => {
        if (aspectRatio.height === 2 && aspectRatio.width === 1) return '202px';
        else if (aspectRatio.height === 1 && aspectRatio.width === 1) return '360px';
        else if (aspectRatio.height === 1 && aspectRatio.width === 3) return '606px';
    };

    return (
        <div
            key={itemKey}
            className="video-container-item"
            style={{
                minWidth: setVideoWidth()
            }}
        >
            {renderImage()}
            <img className="bg-image" src={item.poster || VideoPlaceholder} onError={handleImageError} alt=" Bimage" />

            <div className="space-custom-checkbox m-8" onClick={() => toggleSkillCheck(item.id)}>
                {toggleSkill && (
                    <div>
                        <img src={Checked} alt="Checked" />
                    </div>
                )}
            </div>
            <button
                className="aspect-ratio-btn"
                onClick={() => {
                    aspectRatioSet();
                    setRatioSkillId(itemKey);
                }}
            >
                {aspectRatio.width + ':' + aspectRatio.height}
            </button>
            <a
                className="video-container-item-btn"
                style={{
                    textAlign: 'center'
                }}
                href={widgetLink ? widgetLink : ''}
                target="_blank"
                rel="noopener noreferrer"
            >
                <img className="mr-10" src={PlayBtn} alt="play-btn"></img>
                {editSkill('settings.widget.website.play_video')}
            </a>
            <span className="video-container-item-dur">
                {item.videoDuration && new Date(item.videoDuration * 1000).toISOString().substr(14, 5)}
            </span>
            <div
                className="video-container-item-download"
                onClick={() => {
                    if (downloadingStatus) {
                        return;
                    }
                    downloadSkill();
                }}
            >
                {downloadingStatus ? <LoadingSpin /> : <img src={DownloadIcon} alt="download" />}
            </div>
            <div
                className="video-container-item-desc"
                style={{
                    border: skillNameError && name.length === 0 ? '1px solid #F6655A' : '1px solid #fff'
                }}
            >
                <img src={equalDrag} alt="Drag" style={{ cursor: 'move' }} />
                <input
                    type="text"
                    placeholder={editSkill('download.video_title')}
                    disabled={editSpace ? false : true}
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                        setIsNameUpdated(true);
                    }}
                />
            </div>
        </div>
    );
};

export default PopUpEditSpaceSkill;
