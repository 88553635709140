import React from 'react';
import HomePageComponent from '../../containers/HomePage';

function HomePage(props) {
    return (
        <>
            <HomePageComponent />
        </>
    );
}

export default HomePage;
