import React from 'react';
import { useTranslation } from 'react-i18next';

import WhiteRightArrow from '../../assets/white-right-arrow.svg';
import ButtonAtom from '../ButtonAtom';

export default function MarkerComponent({ loadingMarkerId, renderedMarkerId, handleMarkerClick }) {
    const { t: marker } = useTranslation();
    return (
        <div className="map-marker">
            <div className="dot">
                <div className="tooltip">
                    <div className="map-marker__box"></div>
                    <div className="marker-use search" id={loadingMarkerId}>
                        <ButtonAtom label="use this address" showLoader={true} buttonStyle="marker-btn"></ButtonAtom>
                    </div>
                    <div
                        className="marker-use"
                        id={renderedMarkerId}
                        onClick={(e) => {
                            handleMarkerClick();
                        }}
                    >
                        <ButtonAtom
                            label={marker('spaces.add_space.map.marker_label')}
                            showLoader={false}
                            buttonStyle="marker-btn"
                        ></ButtonAtom>
                        <img src={WhiteRightArrow} alt="arrow-icon" />
                    </div>
                </div>
            </div>
        </div>
    );
}
