import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import SpaceField from '../SpaceField';
import EnergyRatings from '../EnergyRatings';
import Select from '../Select';
import LoadingSpin from '../LoadingSpin';

// utility
import { isEmpty } from '../../utils';
import { DOMAIN } from '../../constants/base';
import { postReq, putReq } from '../../utils/api';
// Assets
import SpaceIcon from '../../assets/allSpaces.svg';

import './spacedetails.scss';
import { useState } from 'react';
export default function SpaceDetails(props) {
    const {
        spaceAddress,
        spaceLatLng,
        prevSpaceLatLng,
        handleSpaceAddressEdit,
        enableFlat,
        setEnableFlat,
        flatNumber,
        setFlatNumber,
        enablePrice,
        setEnablePrice,
        price,
        setPrice,
        setSpaceUpdated,
        spaceType,
        setSpaceType,
        rentalPriceType,
        setRentalPriceType,
        setRentalPriceToShow,
        currency,
        setCurrency,
        enableRooms,
        setEnableRooms,
        bedrooms,
        setBedrooms,
        bathrooms,
        setBathrooms,
        enableSurface,
        setEnableSurface,
        spaceSize,
        setSpaceSize,
        measure,
        setMeasure,
        nextClickLoader,
        setNextClickLoader,
        setSpaceLimitNotification,
        setSpaceId,
        spaceId,
        setPrevSpaceLatLng,
        setCurrentStep,
        spaceUpdated,
        setDirectLink,
        setDefaultCtas,
        member,
        editSpace,
        isSectionEdit,
        setEditSpaceSectionPopUp,
        currentCta,
        setSpaceCtasUpdated,
        userPermissionType,
        errCurrent,
        errPotential,
        setErrCurrent,
        setErrPotential,
        enableEnergyRating,
        setEnableEnergyRating
    } = props;
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    const { t } = useTranslation();
    const [invalidBedRoomNumber, setInvalidBedRoomNumber] = useState(false);
    const [invalidBathRoomNumber, setInvalidBathRoomNumber] = useState(false);
    const [errCurrentVal, setErrCurrentVal] = useState('');
    const [errPotentialVal, setErrPotentialVal] = useState('');

    const ratingMethodCurrent = () => {
        if (errCurrent >= 92) setErrCurrentVal('bg-a');
        else if (errCurrent >= 81 && errCurrent <= 91) setErrCurrentVal('bg-b');
        else if (errCurrent >= 69 && errCurrent <= 80) setErrCurrentVal('bg-c');
        else if (errCurrent >= 55 && errCurrent <= 68) setErrCurrentVal('bg-d');
        else if (errCurrent >= 39 && errCurrent <= 54) setErrCurrentVal('bg-e');
        else if (errCurrent >= 21 && errCurrent <= 38) setErrCurrentVal('bg-f');
        else if (errCurrent >= 1 && errCurrent <= 20) setErrCurrentVal('bg-g');
        else setErrCurrentVal('');
    };

    const ratingMethodPotential = () => {
        if (errPotential >= 92) setErrPotentialVal('bg-a');
        else if (errPotential >= 81 && errPotential <= 91) setErrPotentialVal('bg-b');
        else if (errPotential >= 69 && errPotential <= 80) setErrPotentialVal('bg-c');
        else if (errPotential >= 55 && errPotential <= 68) setErrPotentialVal('bg-d');
        else if (errPotential >= 39 && errPotential <= 54) setErrPotentialVal('bg-e');
        else if (errPotential >= 21 && errPotential <= 38) setErrPotentialVal('bg-f');
        else if (errPotential >= 1 && errPotential <= 20) setErrPotentialVal('bg-g');
        else setErrPotentialVal('');
    };

    useEffect(() => {
        ratingMethodCurrent();
        ratingMethodPotential();
    }, [errCurrent, errPotential]);

    const callAPI = async (url, isPut) => {
        // default payload
        let payload = {
            address: spaceAddress,
            priceType:
                userPermissionType === 'SALE'
                    ? 'SALE'
                    : spaceType.toUpperCase() === 'RENTAL' &&
                      (rentalPriceType?.toUpperCase() === 'YEAR' || rentalPriceType?.toUpperCase() === 'PER YEAR')
                    ? 'YEAR'
                    : spaceType.toUpperCase() === 'RENTAL'
                    ? 'MONTH'
                    : spaceType.toUpperCase(),
            lat: prevSpaceLatLng ? prevSpaceLatLng.lat : spaceLatLng.lat,
            lng: prevSpaceLatLng ? prevSpaceLatLng.lng : spaceLatLng.lng,
            ...((errCurrent || errPotential) && {
                energyMeter: {
                    current: Number(errCurrent),
                    potential: Number(errPotential)
                }
            })
        };
        // additional payload based on property
        if (flatNumber !== null) {
            payload = {
                ...payload,
                flat: flatNumber
            };
        }

        if (price !== null) {
            payload = {
                ...payload,
                price: parseInt(price) ? parseInt(price) : 0,
                currency: currency ? currency : 'AED'
            };
        }

        if (bedrooms !== null && bathrooms !== null) {
            payload = {
                ...payload,
                rooms: { bed: bedrooms ? parseFloat(bedrooms) : 0, bath: bathrooms ? parseFloat(bathrooms) : 0 }
            };
        }

        if (spaceSize !== null) {
            payload = {
                ...payload,
                dimensions: {
                    size: spaceSize ? parseFloat(spaceSize) : 0,
                    measure: measure ? measure : 'SQMT'
                }
            };
        }
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        if (isPut) {
            delete payload?.energyMeter;
            payload = {
                ...payload,
                current: Number(errCurrent) ? Number(errCurrent) : 0,
                potential: Number(errPotential) ? Number(errPotential) : 0
            };
            const createResponse = await putReq({
                url,
                payload,
                requestHeaders
            });
            const { code, data = {} } = createResponse;
            return { code, data };
        }
        const createResponse = await postReq(
            {
                url,
                payload,
                requestHeaders
            },
            setNextClickLoader
        );
        const { code, data = {} } = createResponse;
        return { code, data };
    };
    // removing the error handling as fields are not made compulsory
    // const errorMessage = () => {
    //     if (!parseInt(price)) return 'Please add the price to continue.';
    //     if (!parseInt(bedrooms)) return 'Please add the bedrooms to continue.';
    //     if (!parseInt(bathrooms)) return 'Please add the bathrooms to continue.';
    //     // if (!parseInt(spaceSize)) return 'Please add the space size to continue.';
    // };
    // all APIs
    const createSpaceAPI = async () => {
        if (invalidBathRoomNumber || invalidBedRoomNumber) {
            return;
        }
        if ((!Number.isInteger(Number(errCurrent)) && errCurrent > 0) || (!Number.isInteger(Number(errPotential)) && errPotential > 0)) {
            toast.error('Invalid ERR CURRENT or ERR POTENTIAL', {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
            return;
        }
        setNextClickLoader(true);
        if (editSpace) {
            const url = `${DOMAIN}/v1/dashboard/space/${spaceId}`;
            const { code } = await callAPI(url, true);
            if (code === 200) {
                setInvalidBathRoomNumber(false);
                setInvalidBedRoomNumber(false);
                if (isSectionEdit) {
                    if (currentCta && setSpaceCtasUpdated) {
                        setSpaceCtasUpdated(currentCta);
                    }
                    setEditSpaceSectionPopUp(false);
                    return;
                }
                setNextClickLoader(false);
                setSpaceUpdated(false);
                setCurrentStep('3');
            }
            return;
        }
        if (isEmpty(spaceAddress) && spaceLatLng !== { lat: 0, lng: 0 }) {
            setCurrentStep('1');
        } else if (prevSpaceLatLng && prevSpaceLatLng.lat === -1 && prevSpaceLatLng.lng === -1) {
            toast.error('The address seems to be invalid. To choose a valid address, Please click the EDIT button below.', {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        } else if (spaceId && !spaceUpdated) {
            setCurrentStep('3');
            setNextClickLoader(false);
        } else if (spaceId && spaceUpdated) {
            const url = `${DOMAIN}/v1/dashboard/space/${spaceId}`;
            const { code } = await callAPI(url, true);
            if (code === 200) {
                setInvalidBathRoomNumber(false);
                setInvalidBedRoomNumber(false);
                setNextClickLoader(false);
                setSpaceUpdated(false);
                setCurrentStep('3');
            }
        } else {
            const url = `${DOMAIN}/v1/dashboard/space/create-space`;
            const { code, data = {} } = await callAPI(url);
            if (code === 201) {
                setInvalidBathRoomNumber(false);
                setInvalidBedRoomNumber(false);
                if (data) {
                    setSpaceId(data.id);
                    setDirectLink(data.widgetLink);
                }
                if (member) {
                    const arr = data?.cta;
                    let arr2 = [];
                    const keys = Object.keys(arr);
                    keys.forEach((key) => {
                        let x = {};
                        x[key] = arr[key];
                        arr2 = [...arr2, x];
                    });
                    setDefaultCtas([...arr2]);
                }

                setPrevSpaceLatLng(null);
                setCurrentStep('3');
                setNextClickLoader(false);
            }
            if (code === 403) {
                setSpaceLimitNotification(true);
            }
            setSpaceUpdated(false);
        }
    };

    return (
        <>
            <div className="overall-space-container">
                <div className="space-details-container">
                    <div className="space-address-input create-space">
                        <label>{t('space_details.address')}</label>
                        <div className="space-address-input-wrapper">
                            <div className="space-address-text-wrapper">
                                <img src={SpaceIcon} alt="icon"></img>

                                <div className="space-adress-text">{spaceAddress}</div>
                            </div>
                            <button onClick={handleSpaceAddressEdit}>{t('edit')}</button>
                        </div>
                    </div>

                    {/* space fields */}
                    <SpaceField
                        isEnabled={enableFlat}
                        setIsEnabled={setEnableFlat}
                        spacefieldValue={flatNumber}
                        setSpaceFieldValue={setFlatNumber}
                        labelString={t('space_details.flat_no.label')}
                        placeholderString="121A"
                        title={t('space_details.flat_no.title')}
                        setSpaceUpdated={setSpaceUpdated}
                    ></SpaceField>

                    <div className="space-field">
                        <div className="space-type-wrapper">
                            <label>{t('space_details.type.title')}</label>
                            <Select
                                defaultOption={userPermissionType !== 'ALL' ? userPermissionType : spaceType ? spaceType : t('rent')}
                                optionsList={[
                                    { display: t('space_details.type.rent'), key: 'RENTAL' },
                                    { display: t('space_details.type.sale'), key: 'SALE' }
                                ]}
                                spaceType={spaceType}
                                setSpaceType={setSpaceType}
                                allowDropDown={userPermissionType === 'ALL' ? true : false}
                                setSpaceUpdated={setSpaceUpdated}
                            ></Select>
                        </div>
                    </div>

                    <SpaceField
                        isEnabled={enablePrice}
                        setIsEnabled={setEnablePrice}
                        spacefieldValue={price}
                        setSpaceFieldValue={setPrice}
                        secSpaceFieldValue={currency}
                        setSecSpaceFieldValue={setCurrency}
                        labelString={t('space_details.price.label_price')}
                        labelStringSec={t('space_details.price.label_currency')}
                        title={t('space_details.price.title')}
                        type="price"
                        setSpaceUpdated={setSpaceUpdated}
                        spaceType={spaceType}
                        rentalPriceType={rentalPriceType}
                        setRentalPriceType={setRentalPriceType}
                        setRentalPriceToShow={setRentalPriceToShow}
                        userPermissionType={userPermissionType}
                    ></SpaceField>

                    <SpaceField
                        isEnabled={enableRooms}
                        setIsEnabled={setEnableRooms}
                        spacefieldValue={bedrooms}
                        setSpaceFieldValue={setBedrooms}
                        secSpaceFieldValue={bathrooms}
                        setSecSpaceFieldValue={setBathrooms}
                        labelString={t('space_details.rooms.label_bed')}
                        labelStringSec={t('space_details.rooms.label_bath')}
                        title={t('space_details.rooms.title')}
                        type="rooms"
                        setSpaceUpdated={setSpaceUpdated}
                        invalidBedRoomNumber={invalidBedRoomNumber}
                        setInvalidBedRoomNumber={setInvalidBedRoomNumber}
                        invalidBathRoomNumber={invalidBathRoomNumber}
                        setInvalidBathRoomNumber={setInvalidBathRoomNumber}
                    ></SpaceField>
                    <SpaceField
                        isEnabled={enableSurface}
                        setIsEnabled={setEnableSurface}
                        spacefieldValue={spaceSize}
                        setSpaceFieldValue={setSpaceSize}
                        secSpaceFieldValue={measure}
                        setSecSpaceFieldValue={setMeasure}
                        labelString={t('space_details.size.label_size')}
                        labelStringSec={t('space_details.size.label_measure')}
                        type="size"
                        title={t('space_details.size.title')}
                        setSpaceUpdated={setSpaceUpdated}
                    ></SpaceField>
                    <SpaceField
                        isEnabled={enableEnergyRating}
                        setIsEnabled={setEnableEnergyRating}
                        spacefieldValue={errCurrent}
                        setSpaceFieldValue={setErrCurrent}
                        secSpaceFieldValue={errPotential}
                        setSecSpaceFieldValue={setErrPotential}
                        labelString={'err current'}
                        labelStringSec={'err potential'}
                        title="ADD ENERGY EFFICIENCY RATING"
                        type="ratings"
                        setSpaceUpdated={setSpaceUpdated}
                    ></SpaceField>

                    {enableEnergyRating && (
                        <EnergyRatings
                            errCurrentVal={errCurrentVal}
                            errPotentialVal={errPotentialVal}
                            errCurrent={errCurrent}
                            errPotential={errPotential}
                        />
                    )}
                </div>
            </div>
            <div className="bottom-wrapper">
                <button
                    onClick={() => {
                        if (nextClickLoader) return;
                        createSpaceAPI();
                    }}
                >
                    {nextClickLoader ? <LoadingSpin /> : t('continue')}
                </button>
            </div>
        </>
    );
}
