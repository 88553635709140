import {
    apiPostHelper,
    apiGetHelper,
    apiPostFormDataHelper,
    apiPutFormDataHelper,
    apiPutHelper,
    removeSessionStorage,
    apiDeleteHelper
} from '../utils';
import { toast } from 'react-toastify';

export const postReq = (req, setLoader) => {
    const response = apiPostHelper(req);
    Promise.resolve(response).then((data) => {
        if (data.code === 401) {
            removeSessionStorage('ss-user-spaces');
            window.location = '/signin';
        } else if (data.code !== 200) {
            setLoader && setLoader(false);
        }
        if (data.code !== 200 && data.message) {
            setLoader && setLoader(false);
            toast.error(data.message, {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        }
    });
    return response;
};
export const getReq = (req, setLoader) => {
    const response = apiGetHelper(req);
    Promise.resolve(response).then((data) => {
        if (data.code === 401) {
            removeSessionStorage('ss-user-spaces');
            localStorage.removeItem('ss-auth-spaces');
            localStorage.setItem('ss-lang', 'en');
            window.location = '/signin';
        } else if (data.code !== 200) {
            setLoader && setLoader(false);
        }
        if (data.code !== 200 && data.message) {
            setLoader && setLoader(false);
            toast.error(data.message, {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        }
    });
    return response;
};
export const postFormReq = (req, setLoader) => {
    const response = apiPostFormDataHelper(req);
    Promise.resolve(response).then((data) => {
        if (data.code === 401) {
            removeSessionStorage('ss-user-spaces');
            window.location = '/signin';
        } else if (data.code !== 200) {
            setLoader && setLoader(false);
        }
        if (data.code !== 200 && data.message) {
            setLoader && setLoader(false);
            toast.error(data.message, {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        }
    });
    return response;
};

export const putReq = (req, setLoader) => {
    const response = apiPutHelper(req);
    Promise.resolve(response).then((data) => {
        if (data.code === 401) {
            removeSessionStorage('ss-user-spaces');
            window.location = '/signin';
        } else if (data.code !== 200) {
            setLoader && setLoader(false);
        }
        if (data.code !== 200 && data.message) {
            setLoader && setLoader(false);
            toast.error(data.message, {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        }
    });
    return response;
};

export const putFormReq = (req, setLoader) => {
    const response = apiPutFormDataHelper(req);
    Promise.resolve(response).then((data) => {
        if (data.code === 401) {
            removeSessionStorage('ss-user-spaces');
            window.location = '/signin';
        } else if (data.code !== 200) {
            setLoader && setLoader(false);
        }
        if (data.code !== 200 && data.message) {
            setLoader && setLoader(false);
            toast.error(data.message, {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        }
    });
    return response;
};

export const deleteReq = (req, setLoader) => {
    const response = apiDeleteHelper(req);
    Promise.resolve(response).then((data) => {
        if (data.code === 401) {
            removeSessionStorage('ss-user-spaces');
            window.location = '/signin';
        } else if (data.code !== 200) {
            setLoader && setLoader(false);
        }
        if (data.code !== 200 && data.message) {
            setLoader && setLoader(false);
            toast.error(data.message, {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        }
    });
    return response;
};
