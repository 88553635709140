import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { removeSessionStorage } from '../../utils';
import HolofyProductLogo from '../../assets/holofy-black.png';
import MacMin from '../../assets/mac-min.png';
import MacMax from '../../assets/max_mac.png';
import './mobileblock.scss';
import { GlobalProfile } from '../../context';

let iOS, isSafari;
const MobileBlock = () => {
    const [maxImg, setMaxImg] = useState(false);
    const [shouldRedirect, setRedirect] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [, dispatchProfile] = useContext(GlobalProfile);
    const location = useLocation();

    const browserResizeCalc = () => {
        if (width > 600 && width < 800) {
            setMaxImg(true);
        } else {
            setMaxImg(false);
        }
    };

    const proceedHandler = () => {
        localStorage.removeItem('ss-auth-spaces');
        removeSessionStorage('ss-user-spaces');
        localStorage.setItem('ss-lang', 'en');
        setRedirect(true);
        dispatchProfile({
            type: 'EDIT_GLOBAL_PROFILE',
            payload: {
                id: null,
                avatar: null,
                name: null,
                email: null,
                role: null,
                phone: {
                    phone: null,
                    countryCode: null,
                    countryName: null
                },
                teamExists: false,
                plan: null,
                department: null
            }
        });
        try {
            window.Intercom('shutdown');
        } catch (e) {
            console.log(e);
        }
    };

    useLayoutEffect(() => {
        function updateSize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        browserResizeCalc();
        const mobileBlock = document.getElementById('mobileBlock');
        if (
            location.pathname === '/signin' ||
            location.pathname === '' ||
            location.pathname === '/' ||
            location.pathname === '/newPassword' ||
            location.pathname === '/forgetPassword' ||
            location.pathname === '/resetConfirmation' ||
            location.pathname === '/signup' ||
            location.pathname === '/setpassword' ||
            location.pathname === '/invite-login' ||
            location.pathname === '/invite' ||
            location.pathname === '/newpassword' ||
            location.pathname === '/accountVerified' ||
            location.pathname === '/accountConfirmation' ||
            location.pathname === '/android-tv'
        ) {
            if (mobileBlock) {
                mobileBlock.style.display = 'none';
            }
        } else if (mobileBlock && width < 800) {
            mobileBlock.style.display = 'flex';
        } else if (mobileBlock && width > 800) {
            mobileBlock.style.display = 'none';
        }
    }, [width]);

    if (shouldRedirect) {
        setTimeout(() => {
            window.location.reload();
        }, 100);
        return <Redirect to="/signin" />;
    }

    iOS = typeof window !== 'undefined' && window.navigator.platform && /iPad|iPhone|iPod/.test(window.navigator.platform);
    isSafari = typeof window !== 'undefined' && /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);

    const appLinkRedirect = () => {
        if (iOS || isSafari) {
            window.location.href = 'https://apps.apple.com/gb/app/holofy-spaces/id1518492855';
        } else {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.holofy.store';
        }
    };

    return (
        <>
            <div
                id="mobileBlock"
                style={{
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    zIndex: 99999,
                    top: 0
                }}
            >
                <div className="outer-mobile-block">
                    <div className="enter-fix block-container">
                        <div className="block-container-logo">
                            <img src={HolofyProductLogo} alt="logo" />
                        </div>
                        <div className="block-container-main mt-15 br-5">
                            <h2>We’ve built this dashboard with the power of desktops in mind.</h2>
                            <div className="block-container-min">
                                <img src={maxImg ? MacMax : MacMin} alt="" />
                            </div>
                            <div className="block-container-main-padd">
                                <h4>Switch to a larger screen and unlock its awesome potential</h4>
                                <span>OR</span>
                                <button className="btn-link" onClick={appLinkRedirect}>
                                    DOWNLOAD APP
                                </button>
                            </div>
                        </div>
                        <button onClick={proceedHandler} className="secondary-btn-link">
                            SIGN OUT
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MobileBlock;
