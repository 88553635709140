import React, { useEffect, useMemo, useState } from 'react';
import Input from '../Input';
import CustomSelect from '../CustomSelect';
import { useTranslation } from 'react-i18next';
import DownArrIcon from '../../assets/downarrow.svg';
import UpArrIcon from '../../assets/uparrow.svg';
import { validateEmail } from '../../utils';
import './inviteemail.scss';
export default function InviteEmailInput(props) {
    const { currentIndex, setInvites, invites, duplicatesIdx } = props;
    const { t } = useTranslation();
    const [inviteEmail, setInviteEmail] = useState(invites[currentIndex].email);
    const [departement, setDepartment] = useState(invites[currentIndex].department);
    const [teamRole, setTeamRole] = useState(invites[currentIndex].teamRole);
    const [permission, setPermission] = useState(invites[currentIndex].role);
    const [expandEmailInput, setExapandEmailInput] = useState(invites.length === 1 ? true : false);
    const [validEmail, setValidEmail] = useState(true);
    const [emailExists, setEmailExists] = useState(false);
    useEffect(() => {
        if (invites.length === 1) {
            if (invites[currentIndex]?.role) {
                setPermission(invites[currentIndex]?.role);
            } else {
                setPermission('member');
            }
        }
    }, []);

    useEffect(() => {
        updateInvites(currentIndex, 'department', departement);
        updateInvites(currentIndex, 'teamRole', teamRole);
    }, [departement, teamRole]);

    useEffect(() => {
        if (validateEmail(inviteEmail)) {
            updateInvites(currentIndex, 'email', inviteEmail);
        }
    }, [inviteEmail]);

    const handleValidEmailCheck = () => {
        if (validateEmail(inviteEmail)) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    };

    const handleDownArrowClick = () => {
        setExapandEmailInput(!expandEmailInput);
    };

    const updateInvites = (currentIndex, selectType, option) => {
        let cur = [...invites];
        cur[currentIndex][selectType] = option;
        setInvites([...cur]);
    };

    return (
        <div className="invite-container">
            <div className="top-row">
                <Input
                    type="text"
                    label="Email"
                    inputStyle="email-input"
                    labelStyle="uc mb-5"
                    placeholder="Please input the email of the person you want to invite to the team"
                    value={inviteEmail}
                    onChange={(e) => {
                        setInviteEmail(e.target.value);
                    }}
                    onPaste={(e) => {
                        e.stopPropagation();
                        let pasted = (e.clipboardData || window.clipboardData).getData('text');
                        pasted = pasted.substring(0, pasted.length);
                        setInviteEmail(pasted);
                    }}
                    onBlur={handleValidEmailCheck}
                    info={validEmail ? (duplicatesIdx.includes(currentIndex) ? 'EMAIL ALREADY EXISTS ABOVE' : '') : t('agent.email.error')}
                    customHeight="44px"
                    icon={expandEmailInput ? UpArrIcon : DownArrIcon}
                    iconClass="arrow-icon-invite-email"
                    onDownArrowClick={handleDownArrowClick}
                />
                {permission && (
                    <div
                        className={permission === 'MEMBER' ? 'selected-permission member' : 'selected-permission admin'}
                        onClick={handleDownArrowClick}
                    >
                        {permission ? permission : ''}
                    </div>
                )}
            </div>
            {expandEmailInput && (
                <div className="more-invite-fields">
                    <CustomSelect
                        selectedOption={invites[currentIndex]?.departement ? invites[currentIndex]?.departement : 'ALL'}
                        containerClass="dept-container"
                        optionsList={[
                            { display: t('team.departement.all'), key: 'ALL' },
                            { display: t('team.departement.lettings'), key: 'LETTINGS' },
                            { display: t('team.departement.sale'), key: 'SALE' }
                        ]}
                        label="department"
                        labelClass="label-style"
                        setSelectedOption={setDepartment}
                        updateInvites={updateInvites}
                        selectType="department"
                        currentIndex={currentIndex}
                        isAllowedDropdown={true}
                    ></CustomSelect>
                    <CustomSelect
                        selectedOption={invites[currentIndex]?.teamRole ? invites[currentIndex]?.teamRole : 'AGENT'}
                        containerClass="team-container"
                        optionsList={[
                            { display: t('team.team_role.agent'), key: 'AGENT' },
                            { display: t('team.team_role.marketing'), key: 'MARKETING' },
                            { display: t('team.team_role.management'), key: 'MANAGEMENT' }
                        ]}
                        label="team role"
                        labelClass="label-style"
                        setSelectedOption={setTeamRole}
                        updateInvites={updateInvites}
                        selectType="teamRole"
                        currentIndex={currentIndex}
                        isAllowedDropdown={true}
                    ></CustomSelect>
                    <CustomSelect
                        selectedOption={invites[currentIndex]?.role}
                        containerClass="perm-container"
                        optionsList={[
                            { display: t('team.role.admin'), key: 'ADMIN' },
                            { display: t('team.role.member'), key: 'MEMBER' }
                        ]}
                        label="permission"
                        labelClass="label-style"
                        setSelectedOption={setPermission}
                        selectType="role"
                        updateInvites={updateInvites}
                        currentIndex={currentIndex}
                        isAllowedDropdown={true}
                    ></CustomSelect>
                </div>
            )}
        </div>
    );
}
