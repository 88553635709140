import React, { useEffect } from 'react';
import Pulse from 'react-reveal/Pulse';
import ClickAwayListener from 'react-click-away-listener';
import './popup.scss';

// Assets
import BackImg from '../../assets/back.png';
export default function MultiStepPopup(props) {
    const { setMultiStepPopUp, handleBackButton, renderDots, componentClass, additionalBtnToWrapper, extraPadding } = props;
    useEffect(() => {
        document.body.classList.add('body-ovef-hdn');
        return () => {
            document.body.classList.remove('body-ovef-hdn');
        };
    }, []);
    return (
        <ClickAwayListener
            onClickAway={() => {
                setMultiStepPopUp(false);
            }}
        >
            <div className="popups-wrapper" style={{ zIndex: '101' }}>
                <Pulse duration={150}>
                    <div className="step-wrapper">
                        <div className="header">
                            <button
                                className="back-button"
                                onClick={() => {
                                    handleBackButton();
                                }}
                            >
                                <img src={BackImg} alt="back img"></img>
                            </button>
                            <div className="step-indicator"> {renderDots && renderDots()}</div>
                            {additionalBtnToWrapper && additionalBtnToWrapper()}
                        </div>
                        <div
                            className={componentClass ? `${componentClass} current-step-layout` : 'current-step-layout'}
                            // style={{
                            //     paddingBottom: extraPadding ? extraPadding : ''
                            // }}
                        >
                            {props.children}
                        </div>
                    </div>
                </Pulse>
            </div>
        </ClickAwayListener>
    );
}
