export const DOMAIN =
    process.env.REACT_APP_ENV === 'development'
        ? 'https://b845-122-173-28-232.ngrok.io'
        : process.env.REACT_APP_ENV === 'staging'
        ? 'https://staging.theskill.store'
        : 'https://dashboard.api.theskill.store';

export const TIME_LIMIT = 60;
export const MOBILE_NUMBER_LENGTH = 13;
export const APP_STORE_LINK = 'https://apps.apple.com/gb/app/holofy-products/id1532086171';
export const PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.holofy.store.products&gl=GB';
export const TERMS_LINK = 'https://www.holofy.io/terms-of-service';
export const TUTORIAL_LINK = 'https://vimeo.com/462632088/1a8e53b89c';
export const WIX_LINK = 'https://vimeo.com/461815361';
export const SQUARE_SPACE_LINK = 'https://vimeo.com/461805969';
export const SHOPIFY_LINK = 'https://vimeo.com/461784906';
export const WEBFLOW_LINK = 'https://vimeo.com/461821787';
export const TEAM_PERFORMANCE_MAP = {
    averageClose: 'Average Close',
    spacesLet: 'Spaces Let',
    spacesAdded: 'Spaces Added'
};
export const TEAM_SIZE_LIMIT = 10;

export const APPLE_CLIENT_ID = process.env.REACT_APP_ENV === 'staging' ? 'ios.holofy.spacesstaging' : 'ios.holofy.spaces';
export const APPLE_ANDROID_TV_ID =
    process.env.REACT_APP_ENV === 'staging' ? 'io.holofy.android-tv-service' : 'io.holofy.android-tv-service-prod';

export const GOOGLE_CLIENT_ID =
    process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_SSO_CLIENT_PRODUCTION : process.env.REACT_APP_SSO_CLIENT_STAGING;

export const ANDROID_TV_GOOGLE_CLIENT_ID =
    process.env.REACT_APP_ENV === 'production'
        ? process.env.REACT_APP_SSO_ANDROID_TV_PRODUCTION
        : process.env.REACT_APP_SSO_ANDROID_TV_STAGING;

export const S3_VIDEO_BUCKET =
    process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_S3_BUCKET_PRODUCTION : process.env.REACT_APP_S3_BUCKET_STAGING;

export const PLAN_DETAILS = [
    {
        spaces: 50,
        price: 999,
        name: 'Business Plan'
    },
    {
        spaces: 100,
        price: 1500,
        name: 'Enterprise Plan'
    },
    {
        spaces: 150,
        price: 1750,
        name: 'Enterprise Plan'
    },
    {
        spaces: 300,
        price: 2500,
        name: 'Enterprise Plan'
    },
    {
        spaces: 500,
        price: 3500,
        name: 'Enterprise Plan'
    },
    {
        spaces: 550,
        price: 3500,
        name: 'Enterprise Plan'
    }
];
