import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '../Input';
import PhnInputAtom from '../PhnInputAtom';
import AgentTemplateSavePopUp from '../AgentTemplateSavePopUp';

import { DOMAIN } from '../../constants/base';
import { validateCompany, validateEmail } from '../../utils';
import { deleteReq, postReq, putReq } from '../../utils/api';

import './editctas.scss';
import MinusIcon from '../../assets/minus.png';
import AddIconBlack from '../../assets/addIconBlack.png';
import Bulb from '../../assets/info-bulb.svg';
import { toast } from 'react-toastify';
import Avatar from 'react-avatar';
import CTAPhone from '../../assets/phone_number.png';
import CTAWhatsapp from '../../assets/whatsapp-circle.svg';

export default function EditCTAs(props) {
    const {
        isAgentContacts,
        profileAvatar,
        profileName,
        assigneId,
        setIsUpdated,
        noTemplateNameCheck,
        ctaTemplateName,
        setCtaTemplateName,
        isNewTemplate,
        selectedTemplate,
        setSelectedTemplate,
        availableTemplates,
        setAvailableTemplates,
        setCurrentStep,
        spaceId,
        setTemplateChanged,
        setIsNewTemplate,
        setCurrentCta,
        ctaEmail,
        setCtaEmail,
        ctaLicenseNo,
        setCtaLicenseNo,
        ctaWelcomeMsg,
        setCtaWelcomeMsg,
        ctaWhatsapp,
        setCtaWhatsapp,
        ctaPhone,
        setCtaPhone,
        ctaTelegram,
        setCtaTelegram,
        ctaMessenger,
        setCtaMessenger,
        ctaCustomButton,
        setCtaCustomButton,
        ctaTypeform,
        ctaCal,
        setCtaCal,
        setCtaTypeform,
        setPreventUpdates,
        isUserProfileEdit,
        currentEditMemberId,
        fetchActiveMembers,
        setEditAllDetails,
        activeStep,
        setActiveStep,
        editSectionDetails,
        setEditSectionDetails,
        customPaddingTop
    } = props;
    const userToken = JSON.parse(localStorage.getItem('ss-auth-spaces')) || {};
    // console.log(isUserProfileEdit, profileAvatar, profileName, assigneId);
    const [validEmail, setValidEmail] = useState(true);
    const [errorInPhone, setErrorInPhone] = useState(false);

    const [showTemplateSavePopup, setShowTemplateSavePopup] = useState(false);
    const [isDeleteTemplatePopup, setIsDeleteTemplatePopup] = useState(false);

    const [editCtaLicenseNo, setEditCtaLicenseNo] = useState(ctaLicenseNo);
    const [editCtaWelcomeMsg, setEditCtaWelcomeMsg] = useState(ctaWelcomeMsg);
    const [editCtaEmail, setEditCtaEmail] = useState(ctaEmail);
    const [editCtaPhone, setEditCtaPhone] = useState(ctaPhone);
    const [editCtaWhatsapp, setEditCtaWhatsapp] = useState(ctaWhatsapp);
    const [editCtaMessenger, setEditCtaMessenger] = useState(ctaMessenger);
    const [editCtaTelegram, setEditCtaTelegram] = useState(ctaTelegram);
    const [editCtaCustomButton, setEditCtaCustomButton] = useState(ctaCustomButton);

    const [editCtaTypeform, setEditCtaTypeform] = useState(ctaTypeform);

    const [editCtaCal, setEditCtaCal] = useState(ctaCal);
    const [ctaCount, setCtaCount] = useState(0);

    const { t } = useTranslation();

    const [calendarLinkError, setCalendarErrorLink] = useState(false);
    const [invalidTelegramUsername, setInvalidTelegramUserName] = useState(false);
    const [invalidMessengerUsername, setInvalidMessengerUserName] = useState(false);
    const [customLinkError, setCustomLinkError] = useState(false);

    useEffect(() => {
        setEditCtaLicenseNo(ctaLicenseNo);
        setEditCtaWelcomeMsg(ctaWelcomeMsg);
        setEditCtaCal(ctaCal);
        setEditCtaEmail(ctaEmail);
        setEditCtaPhone(ctaPhone);
        setEditCtaWhatsapp(ctaWhatsapp);
        setEditCtaMessenger(ctaMessenger);
        setEditCtaTelegram(ctaTelegram);
        setEditCtaCustomButton(ctaCustomButton);
        setEditCtaTypeform(ctaTypeform);
    }, [ctaLicenseNo, ctaWelcomeMsg, ctaCal, ctaEmail, ctaPhone, ctaWhatsapp, ctaMessenger, ctaTelegram, ctaCustomButton, ctaTypeform]);

    useEffect(() => {
        if (editCtaEmail.value) {
            setCtaCount((prev) => prev + 1);
        }
        if (editCtaCustomButton.value || editCtaCustomButton.name) {
            setCtaCount((prev) => prev + 1);
        }
        if (editCtaCal.value) {
            setCtaCount((prev) => prev + 1);
        }
        if (editCtaMessenger.value) {
            setCtaCount((prev) => prev + 1);
        }
        if (editCtaPhone.value?.phone?.length) {
            setCtaCount((prev) => prev + 1);
        }
        if (editCtaTelegram.value) {
            setCtaCount((prev) => prev + 1);
        }
        if (editCtaTypeform.buttonText || editCtaTypeform.formId) {
            setCtaCount((prev) => prev + 1);
        }
        if (editCtaWhatsapp.value?.phone?.length) {
            setCtaCount((prev) => prev + 1);
        }
    }, []);

    const handleCtaCount = () => {
        setCtaCount((prev) => prev + 1);
    };

    const checkForSingleCta = () => {
        if (ctaCount === 1) {
            toast.error('Action not permitted when there is single CTA', {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
            return false;
        } else {
            setCtaCount((prev) => prev - 1);
            return true;
        }
    };
    const checkForSingleCtaRemoval = () => {
        if (ctaCount === 1) {
            toast.error('Need atleast one cta to continue', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
            // setRemovingSingleCta(true);
        } else {
            // setCtaCount((prev) => prev - 1);
            // setRemovingSingleCta(false);
        }
    };

    const handleValidCalendar = () => {
        if (editCtaCal.value) {
            const isValid = validateCompany(editCtaCal.value);
            setCalendarErrorLink(!isValid);
        }
        if (editCtaCustomButton.value) {
            const isValid = validateCompany(editCtaCustomButton.value);
            setCustomLinkError(!isValid);
        }
    };

    const handleValidUserName = () => {
        if (editCtaTelegram?.value?.trim()?.split(' ')?.length > 1) {
            setInvalidTelegramUserName(true);
        } else {
            setInvalidTelegramUserName(false);
        }
        if (editCtaMessenger?.value?.trim().split(' ')?.length > 1) {
            setInvalidMessengerUserName(true);
        } else {
            setInvalidMessengerUserName(false);
        }
    };

    const handleValidEmailCheck = () => {
        if (validateEmail(editCtaEmail.value)) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    };

    const deleteTemplate = async () => {
        const url = `${DOMAIN}/v1/dashboard/space/template/${selectedTemplate.templateId}`;
        const requestHeaders = {
            authorization: userToken.accessToken
        };
        const deleteResponse = await deleteReq({ url, requestHeaders });
        const { code } = deleteResponse;
        if (code === 200) {
            setShowTemplateSavePopup(false);
            setSelectedTemplate({
                templateName: 'Agent’s default contact',
                templateId: null,
                ctas: null
            });
            // remove from the list
            let newList = availableTemplates.filter((item) => item.templateId !== selectedTemplate.templateId);
            setAvailableTemplates(newList);
            setCurrentStep('3');
            setPreventUpdates(false);
        }
    };

    const handlePhoneNumberInput = () => {
        if (editCtaPhone?.enable) {
            if (editCtaPhone?.value?.phone?.length === 0) {
                checkForSingleCtaRemoval();
            }
        }
        if (editCtaWhatsapp?.enable) {
            if (editCtaWhatsapp?.value?.phone?.length === 0) {
                checkForSingleCtaRemoval();
            }
        }
    };

    const saveTemplate = async () => {
        if (spaceId || isAgentContacts) {
            let url = `${DOMAIN}/v1/dashboard/space/template`;
            if (isAgentContacts) {
                url = `${DOMAIN}/v1/dashboard/space/user/${isUserProfileEdit ? 'profile' : 'edit-member'}/cta`;
            }
            let payload = {
                ...(editCtaWhatsapp.value?.phone !== null && { whatsAppPhone: editCtaWhatsapp.value?.phone?.trim() }),
                ...(editCtaWhatsapp.value?.countryCode !== null && { whatsAppCountryCode: editCtaWhatsapp.value?.countryCode }),
                ...(editCtaWhatsapp.value?.countryName !== null && { whatsAppCountryName: editCtaWhatsapp.value?.countryName }),
                ...(editCtaPhone.value?.phone !== null && { phone: editCtaPhone.value?.phone?.trim() }),
                ...(editCtaPhone.value?.countryCode !== null && { countryCode: editCtaPhone.value?.countryCode }),
                ...(editCtaPhone.value?.countryName !== null && { countryName: editCtaPhone.value?.countryName }),
                // ...(editCtaLicenseNo && { licenceNo: editCtaLicenseNo.value.trim() }),
                // ...(editCtaWelcomeMsg && { message: editCtaWelcomeMsg.value.trim() }),
                ...(editCtaMessenger && { facebookMessenger: editCtaMessenger.value?.trim() }),
                ...(editCtaTelegram && { telegram: editCtaTelegram.value?.trim() }),
                ...(editCtaCal && { calendar: editCtaCal.value?.trim() }),
                ...(editCtaCustomButton && { customButtonValue: editCtaCustomButton.value.trim() }),
                ...(editCtaCustomButton && { customButtonName: editCtaCustomButton.name.trim() }),
                ...(editCtaTypeform && { typeFormFormId: editCtaTypeform.formId?.trim() }),
                ...(editCtaTypeform && { typeFormButtonText: editCtaTypeform.buttonText?.trim() }),
                ...(ctaTemplateName && !isAgentContacts && { name: ctaTemplateName?.trim() }),
                ...(selectedTemplate?.templateId && { templateId: selectedTemplate?.templateId }),
                ...(editCtaEmail && { email: editCtaEmail.value?.trim() }),
                ...(isAgentContacts && !isUserProfileEdit && { id: currentEditMemberId ? currentEditMemberId : assigneId })
            };
            let emptyValues = 0;
            let payloadKeys = Object.keys(payload);
            for (let pKey of payloadKeys) {
                if (
                    pKey !== 'whatsAppCountryCode' &&
                    pKey !== 'whatsAppCountryName' &&
                    pKey !== 'countryCode' &&
                    pKey !== 'countryName' &&
                    pKey !== 'licenceNo' &&
                    pKey !== 'message' &&
                    pKey !== 'templateId' &&
                    pKey !== 'name'
                ) {
                    if (payload[pKey] !== '') {
                        emptyValues = 0;
                        break;
                    } else {
                        emptyValues = 1;
                    }
                }
            }

            if (emptyValues !== 0) {
                toast.error('Please add one value for atleast one field', {
                    position: 'top-center',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
                return;
            }
            const requestHeaders = {
                authorization: userToken.accessToken
            };
            let statusResponse;
            if (isAgentContacts) {
                statusResponse = await putReq({ url, payload, requestHeaders });
            } else {
                statusResponse = await postReq({ url, payload, requestHeaders });
            }
            const { code, data } = statusResponse;
            if (code === 200) {
                setPreventUpdates && setPreventUpdates(false);
                payload = {
                    ...payload,
                    customButton: {
                        name: payload.customButtonName?.trim(),
                        value: payload.customButtonValue?.trim()
                    },
                    typeForm: {
                        buttonText: payload.typeFormButtonText?.trim(),
                        formId: payload.typeFormFormId?.trim()
                    },
                    phoneNumber: {
                        phone: payload.phone?.trim(),
                        countryCode: payload.countryCode,
                        countryName: payload.countryName
                    },
                    whatsAppPhoneNumber: {
                        phone: payload.whatsAppPhone?.trim(),
                        countryCode: payload.whatsAppCountryCode,
                        countryName: payload.whatsAppCountryName
                    }
                };
                if (isNewTemplate) {
                    setIsNewTemplate && setIsNewTemplate(false);
                    setTemplateChanged && setTemplateChanged(true);
                    setSelectedTemplate &&
                        setSelectedTemplate({
                            templateName: ctaTemplateName,
                            templateId: data?.templateId,
                            ctas: payload
                        });
                    setAvailableTemplates &&
                        setAvailableTemplates([
                            ...availableTemplates,
                            {
                                templateName: ctaTemplateName,
                                templateId: data?.templateId,
                                ctas: payload
                            }
                        ]);
                } else {
                    setSelectedTemplate &&
                        setSelectedTemplate({
                            templateName: ctaTemplateName,
                            templateId: selectedTemplate.templateId,
                            ctas: payload
                        });
                    if (selectedTemplate) {
                        let updatedTemplate = {
                            templateName: ctaTemplateName,
                            templateId: selectedTemplate.templateId,
                            ctas: payload
                        };
                        let filteredTemplates = availableTemplates.filter((tem) => tem.templateId !== selectedTemplate.templateId);
                        setAvailableTemplates && setAvailableTemplates([...filteredTemplates, updatedTemplate]);
                    }
                }
                setCurrentCta &&
                    setCurrentCta((prev) => {
                        return { ...prev, license: { ...ctaLicenseNo }, message: { ...ctaWelcomeMsg } };
                    });
                setCtaLicenseNo({ ...editCtaLicenseNo, value: payload.licenceNo ? payload.licenceNo : ctaLicenseNo.value });
                setCtaWelcomeMsg({ ...editCtaWelcomeMsg, value: payload.message ? payload.message : ctaWelcomeMsg.value });
                setCtaEmail({ ...editCtaEmail, value: payload.email });
                setCtaPhone({
                    ...editCtaPhone,
                    value: { phone: payload.phone?.trim(), countryCode: payload.countryCode, countryName: payload.countryName }
                });
                setCtaWhatsapp({
                    ...editCtaWhatsapp,
                    value: {
                        phone: payload.whatsAppPhone?.trim(),
                        countryCode: payload.whatsAppCountryCode,
                        countryName: payload.whatsAppCountryName
                    }
                });
                setCtaTelegram({ ...editCtaTelegram, value: payload.telegram });
                setCtaMessenger({ ...editCtaMessenger, value: payload.facebookMessenger });
                setCtaCal({ ...editCtaCal, value: payload.calendar });
                setCtaCustomButton({
                    ...editCtaCustomButton,
                    name: payload.customButtonName?.trim(),
                    value: payload.customButtonValue?.trim()
                });
                setCtaTypeform({
                    ...editCtaTypeform,
                    buttonText: payload.typeFormButtonText?.trim(),
                    formId: payload.typeFormFormId?.trim()
                });
                setIsUpdated(false);
                setShowTemplateSavePopup && setShowTemplateSavePopup(false);
                setCurrentStep && setCurrentStep('3');
                // setAssigneChanged && setAssigneChanged(false);
                if (editSectionDetails) {
                    setEditSectionDetails(false);
                    setEditAllDetails && setEditAllDetails(false);
                    toast.success(
                        isUserProfileEdit ? t('editprofile.toast.profile_update') : t('editprofile.toast.member_update_details'),
                        {
                            position: 'top-center',
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined
                        }
                    );
                } else {
                    if (isUserProfileEdit) {
                        setEditAllDetails && setEditAllDetails(false);
                    }
                    setActiveStep && setActiveStep(activeStep + 1);
                }
                fetchActiveMembers && fetchActiveMembers();
            }
        }
    };

    const handleTemplateSave = () => {
        if (customLinkError || invalidTelegramUsername || invalidMessengerUsername || calendarLinkError || !validEmail) {
            return;
        }
        if (!noTemplateNameCheck && (!ctaTemplateName || ctaTemplateName?.trim().length === 0)) {
            toast.error(t('agent.templates.error_template_name'), {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        } else if (
            ((editCtaCustomButton?.value?.trim() && !editCtaCustomButton?.name?.trim()) ||
                (!editCtaCustomButton?.value?.trim() && editCtaCustomButton?.name?.trim())) &&
            editCtaCustomButton.enable
        ) {
            toast.error(t('agent.error.custom_btn'), {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        } else if (
            ((editCtaTypeform?.formId?.trim() && !editCtaTypeform?.buttonText?.trim()) ||
                (!editCtaTypeform?.formId?.trim() && editCtaTypeform?.buttonText?.trim())) &&
            editCtaTypeform.enable
        ) {
            toast.error(t('agent.error.typeform'), {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        } else if (
            !(
                editCtaTelegram?.value?.trim() ||
                editCtaMessenger?.value?.trim() ||
                editCtaEmail?.value?.trim() ||
                editCtaPhone?.value?.phone?.trim() ||
                editCtaWhatsapp?.value?.phone?.trim() ||
                editCtaCal?.value?.trim() ||
                editCtaTypeform?.formId?.trim() ||
                editCtaTypeform?.buttonText?.trim() ||
                editCtaCustomButton?.value?.trim() ||
                editCtaCustomButton?.name?.trim()
            )
        ) {
            return;
        } else if (isAgentContacts) {
            saveTemplate();
        } else {
            setShowTemplateSavePopup(true);
            setIsDeleteTemplatePopup(false);
        }
    };
    return (
        <>
            <div
                className="edit-contact-wrapper-cta"
                style={{
                    paddingTop: !isAgentContacts ? '42px' : customPaddingTop ? '42px' : ''
                }}
            >
                <div className="agent-details">
                    {isAgentContacts && !isNewTemplate ? (
                        <div className="agent-avatar">
                            {profileAvatar ? (
                                <div className="avatar-div">
                                    <img src={profileAvatar} style={{ objectFit: 'cover' }} className="avatar-img" alt="icon"></img>
                                    <span className="assigne__name">{profileName}</span>
                                </div>
                            ) : (
                                <>
                                    <Avatar
                                        name={profileName}
                                        value=""
                                        size="56"
                                        round={true}
                                        color="black"
                                        maxInitials={1}
                                        textSizeRatio={2}
                                        style={{ fontWeight: '700' }}
                                    ></Avatar>
                                    <span className="assigne__avatar__name">{profileName}</span>
                                </>
                            )}
                        </div>
                    ) : (
                        <div className="agent-field">
                            <div className="input-container">
                                <div className="flat-inpt-wrapper">
                                    <Input
                                        type="text"
                                        label={t('agent.cta_template.label')}
                                        labelStyle="uc mb-5"
                                        placeholder="121A"
                                        value={ctaTemplateName}
                                        onChange={(e) => {
                                            setCtaTemplateName(e.target.value);
                                        }}
                                        customHeight="44px"
                                        customWidth="504px"
                                        agent={true}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className="line-break"
                    style={{
                        marginTop: isAgentContacts ? '30px' : ''
                    }}
                ></div>
                <div className="agent-details">
                    <div className="agent-field">
                        {editCtaEmail.enable ? (
                            <>
                                <div className="input-container">
                                    <div className="flat-inpt-wrapper">
                                        <Input
                                            type="text"
                                            label={t('agent.email.label')}
                                            labelStyle="uc mb-5"
                                            placeholder={t('agent.email.placeholder')}
                                            value={editCtaEmail.value}
                                            onChange={(e) => {
                                                if (e.target.value.length === 0) {
                                                    checkForSingleCtaRemoval();
                                                }
                                                setEditCtaEmail({ ...editCtaEmail, value: e.target.value, enable: true });
                                            }}
                                            onBlur={handleValidEmailCheck}
                                            info={validEmail ? '' : t('agent.email.error')}
                                            customHeight="44px"
                                            customWidth="504px"
                                            agent={true}
                                            icon={editCtaEmail.icon}
                                            iconType="email"
                                        />
                                    </div>
                                    <button
                                        className="optional-btn"
                                        onClick={() => {
                                            if (!checkForSingleCta()) return;
                                            setEditCtaEmail({ ...editCtaEmail, value: '', enable: false });
                                        }}
                                    >
                                        {' '}
                                        <img src={MinusIcon} alt="minimize icon"></img>
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div
                                className="optional-field"
                                onClick={() => {
                                    handleCtaCount();
                                    setEditCtaEmail({ ...editCtaEmail, enable: true });
                                }}
                            >
                                <button className="optional-btn">
                                    <img src={AddIconBlack} alt="add icon"></img>
                                </button>
                                <div className="title">{t('agent.email.title')}</div>
                            </div>
                        )}
                    </div>

                    <div className="agent-field">
                        {editCtaPhone.enable ? (
                            <>
                                <div className="input-container">
                                    <div className="flat-inpt-wrapper">
                                        <PhnInputAtom
                                            phone={editCtaPhone}
                                            setPhone={setEditCtaPhone}
                                            info={errorInPhone ? '' : ''}
                                            type="cta"
                                            onBlur={handlePhoneNumberInput}
                                            customWidth="436px"
                                            setIsUpdated={setIsUpdated}
                                            setErrorInPhone={setErrorInPhone}
                                        ></PhnInputAtom>
                                        <img className="cta-phone-img" src={CTAPhone} alt="" />
                                    </div>
                                    <button
                                        className="optional-btn"
                                        onClick={() => {
                                            if (editCtaPhone?.value?.phone) {
                                                if (!checkForSingleCta()) return;
                                            }
                                            setEditCtaPhone({
                                                ...editCtaPhone,
                                                value: {
                                                    phone: '',
                                                    countryCode: '',
                                                    countryName: ''
                                                },
                                                enable: false
                                            });
                                        }}
                                    >
                                        {' '}
                                        <img src={MinusIcon} alt="minimize icon"></img>
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div
                                className="optional-field"
                                onClick={() => {
                                    handleCtaCount();
                                    setEditCtaPhone({ ...editCtaPhone, enable: true });
                                }}
                            >
                                <button className="optional-btn">
                                    <img src={AddIconBlack} alt="add icon"></img>
                                </button>
                                <div className="title">{t('agent.phone.title')}</div>
                            </div>
                        )}
                    </div>

                    <div className="agent-field">
                        {editCtaWhatsapp.enable ? (
                            <>
                                <div className="input-container">
                                    <div className="flat-inpt-wrapper">
                                        <PhnInputAtom
                                            phone={editCtaWhatsapp}
                                            setPhone={setEditCtaWhatsapp}
                                            info={errorInPhone ? '' : ''}
                                            type="cta"
                                            label={t('agent.wp.label')}
                                            customWidth="436px"
                                            setIsUpdated={setIsUpdated}
                                            onBlur={handlePhoneNumberInput}
                                        ></PhnInputAtom>
                                        <img className="cta-phone-img" src={CTAWhatsapp} alt="" />
                                    </div>
                                    <button
                                        className="optional-btn"
                                        onClick={() => {
                                            if (editCtaWhatsapp?.value?.phone) {
                                                if (!checkForSingleCta()) return;
                                            }
                                            setEditCtaWhatsapp({
                                                ...editCtaWhatsapp,
                                                value: { phone: '', countryCode: '', countryName: '' },
                                                enable: false
                                            });
                                        }}
                                    >
                                        {' '}
                                        <img src={MinusIcon} alt="minimize icon"></img>
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div
                                className="optional-field"
                                onClick={() => {
                                    handleCtaCount();
                                    setEditCtaWhatsapp({ ...editCtaWhatsapp, enable: true });
                                }}
                            >
                                <button className="optional-btn">
                                    <img src={AddIconBlack} alt="add icon"></img>
                                </button>
                                <div className="title">{t('agent.wp.title')}</div>
                            </div>
                        )}
                    </div>

                    <div className="agent-field">
                        {editCtaTelegram.enable ? (
                            <>
                                <div className="input-container">
                                    <div className="flat-inpt-wrapper">
                                        <Input
                                            type="text"
                                            label={t('agent.telegram.label')}
                                            labelStyle="uc mb-5"
                                            placeholder={t('agent.telegram.placeholder')}
                                            value={editCtaTelegram.value}
                                            onChange={(e) => {
                                                if (e.target.value.length === 0) {
                                                    checkForSingleCtaRemoval();
                                                }
                                                setEditCtaTelegram({ ...editCtaTelegram, value: e.target.value, enable: true });
                                            }}
                                            onBlur={handleValidUserName}
                                            info={invalidTelegramUsername ? 'Invalid Username' : ''}
                                            customHeight="44px"
                                            customWidth="504px"
                                            agent={true}
                                            icon={editCtaTelegram.icon}
                                            // iconType="telegram"
                                        />
                                    </div>
                                    <button
                                        className="optional-btn"
                                        onClick={() => {
                                            if (!checkForSingleCta()) return;
                                            setEditCtaTelegram({ ...editCtaTelegram, value: '', enable: false });
                                        }}
                                    >
                                        {' '}
                                        <img src={MinusIcon} alt="minimize icon"></img>
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div
                                className="optional-field"
                                onClick={() => {
                                    handleCtaCount();
                                    setEditCtaTelegram({ ...editCtaTelegram, enable: true });
                                }}
                            >
                                <button className="optional-btn">
                                    <img src={AddIconBlack} alt="add icon"></img>
                                </button>
                                <div className="title">{t('agent.telegram.title')}</div>
                            </div>
                        )}
                    </div>

                    <div className="agent-field">
                        {editCtaMessenger.enable ? (
                            <>
                                <div className="input-container">
                                    <div className="flat-inpt-wrapper">
                                        <Input
                                            type="text"
                                            label={t('agent.facebook.label')}
                                            labelStyle="uc mb-5"
                                            placeholder={t('agent.facebook.placeholder')}
                                            value={editCtaMessenger.value}
                                            onChange={(e) => {
                                                if (e.target.value.length === 0) {
                                                    checkForSingleCtaRemoval();
                                                }
                                                setEditCtaMessenger({ ...editCtaMessenger, value: e.target.value, enable: true });
                                            }}
                                            onBlur={handleValidUserName}
                                            info={invalidMessengerUsername ? 'Invalid Username' : ''}
                                            customHeight="44px"
                                            customWidth="504px"
                                            agent={true}
                                            icon={editCtaMessenger.icon}
                                            iconType="fbmessenger"
                                        />
                                    </div>
                                    <button
                                        className="optional-btn"
                                        onClick={() => {
                                            if (!checkForSingleCta()) return;
                                            setEditCtaMessenger({ ...editCtaMessenger, value: '', enable: false });
                                        }}
                                    >
                                        {' '}
                                        <img src={MinusIcon} alt="minimize icon"></img>
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div
                                className="optional-field"
                                onClick={() => {
                                    handleCtaCount();
                                    setEditCtaMessenger({ ...editCtaMessenger, enable: true });
                                }}
                            >
                                <button className="optional-btn">
                                    <img src={AddIconBlack} alt="add icon"></img>
                                </button>
                                <div className="title">{t('agent.facebook.title')}</div>
                            </div>
                        )}
                    </div>

                    <div className="agent-field">
                        {editCtaCal.enable ? (
                            <>
                                <div className="input-container">
                                    <div className="flat-inpt-wrapper">
                                        <Input
                                            type="text"
                                            label={t('agent.calendar.label')}
                                            labelStyle="uc mb-5"
                                            placeholder="https://calendly.com/cal"
                                            value={editCtaCal.value}
                                            onChange={(e) => {
                                                if (e.target.value.length === 0) {
                                                    checkForSingleCtaRemoval();
                                                }
                                                setEditCtaCal({ ...editCtaCal, value: e.target.value, enable: true });
                                            }}
                                            onBlur={handleValidCalendar}
                                            info={calendarLinkError ? 'Invalid Calendar Link' : ''}
                                            customHeight="44px"
                                            customWidth="504px"
                                            agent={true}
                                            icon={editCtaCal.icon}
                                            iconType="cal"
                                        />
                                    </div>
                                    <button
                                        className="optional-btn"
                                        onClick={() => {
                                            if (!checkForSingleCta()) return;
                                            setEditCtaCal({ ...editCtaCal, value: '', enable: false });
                                        }}
                                    >
                                        {' '}
                                        <img src={MinusIcon} alt="minimize icon"></img>
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div
                                className="optional-field"
                                onClick={() => {
                                    handleCtaCount();
                                    setEditCtaCal({ ...editCtaCal, enable: true });
                                }}
                                style={{
                                    marginBottom: '10px'
                                }}
                            >
                                <button className="optional-btn">
                                    <img src={AddIconBlack} alt="add icon"></img>
                                </button>
                                <div className="title">{t('agent.calendar.title')}</div>
                            </div>
                        )}
                    </div>

                    <div className="agent-field">
                        {editCtaCustomButton.enable ? (
                            <>
                                <div className="input-container">
                                    <div className="flat-inpt-wrapper agent-custom-button-input">
                                        <Input
                                            type="text"
                                            label={t('agent.custom_link.label')}
                                            labelStyle="uc mb-5"
                                            placeholder="www.customlink.com/page"
                                            value={editCtaCustomButton.value}
                                            onChange={(e) => {
                                                if (e.target.value.length === 0 && editCtaCustomButton?.name?.trim() === '') {
                                                    checkForSingleCtaRemoval();
                                                }
                                                setEditCtaCustomButton({
                                                    ...editCtaCustomButton,
                                                    value: e.target.value,
                                                    enable: true
                                                });
                                            }}
                                            onBlur={handleValidCalendar}
                                            info={customLinkError ? 'Invalid Custom Link' : ''}
                                            customHeight="44px"
                                            customWidth="298px"
                                            agent={true}
                                        />
                                        <Input
                                            type="text"
                                            label={t('agent.custom_link.label')}
                                            labelStyle="uc mb-5 op-0"
                                            placeholder={t('agent.custom_link.placeholder_btn')}
                                            value={editCtaCustomButton.name}
                                            onChange={(e) => {
                                                if (e.target.value.length === 0 && editCtaCustomButton?.value?.trim() === '') {
                                                    checkForSingleCtaRemoval();
                                                }
                                                setEditCtaCustomButton({
                                                    ...editCtaCustomButton,
                                                    name: e.target.value,
                                                    enable: true
                                                });
                                            }}
                                            customHeight="44px"
                                            customWidth="196px"
                                            agent={true}
                                            icon={editCtaCustomButton.icon}
                                            inputStyle="custom-btn-input"
                                            iconType="custom-link"
                                        />
                                    </div>
                                    <button
                                        className="optional-btn"
                                        onClick={() => {
                                            if (!checkForSingleCta()) return;
                                            setEditCtaCustomButton({ ...editCtaCustomButton, value: '', name: '', enable: false });
                                        }}
                                    >
                                        {' '}
                                        <img src={MinusIcon} alt="minimize icon"></img>
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div
                                className="optional-field"
                                style={{
                                    marginBottom: '10px'
                                }}
                                onClick={() => {
                                    handleCtaCount();
                                    setEditCtaCustomButton({ ...editCtaCustomButton, enable: true });
                                }}
                            >
                                <button className="optional-btn">
                                    <img src={AddIconBlack} alt="add icon"></img>
                                </button>
                                <div className="title">{t('agent.custom_link.title')}</div>
                            </div>
                        )}
                    </div>

                    <div className="agent-field">
                        {editCtaTypeform.enable ? (
                            <>
                                <div className="input-container">
                                    <div className="flat-inpt-wrapper agent-custom-button-input">
                                        <Input
                                            type="text"
                                            label={t('agent.typeform.label')}
                                            labelStyle="uc mb-5"
                                            placeholder={t('agent.typeform.placeholder')}
                                            value={editCtaTypeform.formId}
                                            onChange={(e) => {
                                                if (e.target.value.length === 0 && editCtaTypeform?.buttonText?.trim() === '') {
                                                    checkForSingleCtaRemoval();
                                                }
                                                setEditCtaTypeform({
                                                    ...editCtaTypeform,
                                                    formId: e.target.value,
                                                    enable: true
                                                });
                                            }}
                                            customHeight="44px"
                                            customWidth="298px"
                                            agent={true}
                                        />
                                        <Input
                                            type="text"
                                            label={t('agent.typeform.btn_label')}
                                            labelStyle="uc mb-5 op-0"
                                            placeholder={t('agent.typeform.placeholder_btn')}
                                            value={editCtaTypeform.buttonText}
                                            onChange={(e) => {
                                                if (e.target.value.length === 0 && editCtaTypeform?.formId?.trim() === '') {
                                                    checkForSingleCtaRemoval();
                                                }
                                                setEditCtaTypeform({
                                                    ...editCtaTypeform,
                                                    buttonText: e.target.value,
                                                    enable: true
                                                });
                                            }}
                                            customHeight="44px"
                                            customWidth="196px"
                                            agent={true}
                                            icon={editCtaTypeform.icon}
                                            iconType="typeform"
                                            inputStyle="custom-btn-input"
                                        />
                                    </div>
                                    <button
                                        className="optional-btn"
                                        onClick={() => {
                                            if (!checkForSingleCta()) return;
                                            setEditCtaTypeform({ ...editCtaTypeform, buttonText: '', formId: '', enable: false });
                                        }}
                                    >
                                        {' '}
                                        <img src={MinusIcon} alt="minimize icon"></img>
                                    </button>
                                </div>
                                <div className="type-form-note">
                                    <img src={Bulb} alt="bulb" />
                                    <p>
                                        {t('agent.typeform.note_first_part') + ' '}
                                        <a href="https://www.typeform.com/">Typeform</a>
                                        {' ' + t('agent.typeform.note_sec_part')}
                                    </p>
                                </div>
                            </>
                        ) : (
                            <div
                                className="optional-field"
                                onClick={() => {
                                    handleCtaCount();
                                    setEditCtaTypeform({ ...editCtaTypeform, enable: true });
                                }}
                            >
                                <button className="optional-btn">
                                    <img src={AddIconBlack} alt="add icon"></img>
                                </button>
                                <div className="title">{t('agent.typeform.title')}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {isAgentContacts ? (
                <div className="bottom-wrapper">
                    <button
                        className="save-template"
                        disabled={ctaEmail && ctaEmail?.value.length > 0 && !validEmail ? true : false}
                        onClick={() => {
                            handleTemplateSave();
                        }}
                    >
                        save
                    </button>
                </div>
            ) : (
                <div className="bottom-wrapper">
                    {isNewTemplate ? (
                        ''
                    ) : (
                        <button
                            className="delete-template"
                            onClick={() => {
                                setShowTemplateSavePopup(true);
                                setIsDeleteTemplatePopup(true);
                            }}
                        >
                            {t('agent.templates.delete_template')}
                        </button>
                    )}

                    <button
                        className="save-template"
                        disabled={ctaEmail && ctaEmail?.value.length > 0 && !validEmail ? true : false}
                        onClick={() => {
                            handleTemplateSave();
                        }}
                    >
                        {t('agent.templates.save_template')}
                    </button>
                </div>
            )}

            {showTemplateSavePopup && (
                <AgentTemplateSavePopUp
                    isDeleteTemplate={isDeleteTemplatePopup}
                    setTemplateSavePopUp={setShowTemplateSavePopup}
                    handleSaveTemplate={saveTemplate}
                    deleteTemplate={deleteTemplate}
                ></AgentTemplateSavePopUp>
            )}
        </>
    );
}
