import React from 'react';
import { useTranslation } from 'react-i18next';
import './statscardheatmap.scss';

const StatsCardHeatmap = ({ icon, graph, cardsSpinner }) => {
    let xAxis = ['12a', '2a', '4a', '6a', '8a', '10a', '12p', '2p', '4p', '6p', '8p', '10p'];
    let yAxis = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const { t } = useTranslation();
    let newGraphData = graph?.length && graph?.map((arr) => arr?.map((ele) => (ele === 0 ? 2 : ele + 1)));
    return (
        <div className="stats-card-heatmap">
            {cardsSpinner && (
                <div className="pos-abs">
                    <div className="loading-card-wrapper">
                        <div className="loading-top-wrapper">
                            <div className="shimmerBG loading-circle"></div>
                            <div className="shimmerBG loading-top-rectangle"></div>
                        </div>
                        <div className="shimmerBG loading-bottom-heatmap-rectangle"></div>
                    </div>
                </div>
            )}
            {!cardsSpinner && (
                <div className="stats-card-heatmap-content">
                    <div className="stats-card-heatmap-header">
                        <div className="icon-wrapper">
                            <img src={icon} alt=""></img>
                        </div>
                        {t('stats.most_viewed_times')}
                    </div>
                    <div className="stats-card-heatmap-graph">
                        {yAxis?.length &&
                            yAxis.map((row, index) => (
                                <div className="stats-card-heatmap-grid" key={row}>
                                    <div className="stats-card-heatmap-grid-cell">{row}</div>
                                    {graph?.length &&
                                        newGraphData[index].map((value, idx) => (
                                            <div
                                                key={idx}
                                                className="stats-card-heatmap-grid-cell-color"
                                                style={{ backgroundColor: `rgba(0,0,0,${value / 100})` }}
                                            ></div>
                                        ))}
                                </div>
                            ))}
                        <div className="stats-card-heatmap-grid">
                            <div className="stats-card-heatmap-grid-cell-x-axis"></div>
                            {xAxis?.length &&
                                xAxis.map((value) => (
                                    <div key={value} className="stats-card-heatmap-grid-cell-x-axis">
                                        {value}
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StatsCardHeatmap;
