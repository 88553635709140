import React from 'react';
import { Switch, Route } from 'react-router-dom';
import skillsRoutes from './skillsRoutes.config';

function RootRouter(props) {
  return (
    <>
      <Switch>
        {skillsRoutes.map((route, i) => (
          <Route {...route} key={i} />
        ))}
      </Switch>
    </>
  );
}

export default RootRouter;
